import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import {
    IMetadataDoctoInspeccionHomologacion
} from '../interfaces';
import styles from '../ItemInspeccionHomologacion.module.scss';
import UploadFile from '../../Shared/UploadFile/uploadFile';
import { CommonComboBox } from '../../Shared/CustomFields/ComboBoxField';
import { IComboBoxOption } from 'office-ui-fabric-react/lib/index';

import { OrdenaDocumentosTiposList, isEqualArrayObject } from '../../utils/functions';
import { CustomTextField } from '../../Shared/CustomFields/TextField';
import { IDocumentosTipo } from '../../ItemSuministro/interfaces';
import { IDocumentosInspeccionHomologacion, IInspeccionHomologacion } from '../../ItemHomologacion/interfaces';
import { ItemHomologacionesInspeccionActionCreators } from '../actions';
import { IUsuarioLogin } from '../../utils/interfaces';


interface ICargaDocumentosState {
    isLoaded: boolean;
    metadataDocumentosList: IMetadataDoctoInspeccionHomologacion[];
    optionsBox: IComboBoxOption[];
}

interface IConnectedState {
    itemInspeccionHomologacion: IInspeccionHomologacion;
    documentosInspeccionList: IDocumentosInspeccionHomologacion[];
    documentosTipoInspeccionList: IDocumentosTipo[];
    currentUser : IUsuarioLogin;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        itemInspeccionHomologacion: state.itemHomologacionInspeccion.inspeccionItem,
        documentosInspeccionList: state.itemHomologacionInspeccion.documentosInspeccionesList,
        documentosTipoInspeccionList: state.itemInspeccion.doctosTipoInspeccionList,
        currentUser : state.main.user
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

type ICargaDocumentosProps = IConnectedState &{ dispatch(action: any): void };

export class CargaDocumentos extends React.Component<ICargaDocumentosProps,ICargaDocumentosState> {

    constructor(props){
        super(props);
        this.state = {
            metadataDocumentosList: [],
            isLoaded: false,
            optionsBox: [],
        };
    }

    public componentDidMount(){
        const { documentosTipoInspeccionList, documentosInspeccionList } = this.props;
        if (documentosTipoInspeccionList && documentosInspeccionList){
            this.setState({
                isLoaded: true,
                optionsBox: this._onBuildBoxOptions(documentosTipoInspeccionList, documentosInspeccionList),
            });
        }
    }

    public componentDidUpdate(prevProps: ICargaDocumentosProps){
        const { isLoaded } = this.state;
        const { documentosTipoInspeccionList, documentosInspeccionList } = this.props;
        if ( (!isLoaded && documentosTipoInspeccionList && documentosInspeccionList) || (documentosInspeccionList && !isEqualArrayObject(documentosInspeccionList, prevProps.documentosInspeccionList)) ){
            this.setState({
                isLoaded: true,
                optionsBox: this._onBuildBoxOptions(documentosTipoInspeccionList, documentosInspeccionList),
            });
        }
    }

    private _onChangeMetadata = (ev, option):void => {
        const { itemInspeccionHomologacion, currentUser } = this.props;
        const { metadataDocumentosList } = this.state;
        const { id, value } = ev.target;

        const idSplited: string = id.split("@@");

        let metadataListModified: IMetadataDoctoInspeccionHomologacion[] = [];
        
        const metadataExist: IMetadataDoctoInspeccionHomologacion = metadataDocumentosList.find( m => m.key === idSplited[1] );
        if (!metadataExist) {
            let metadataToAdd: IMetadataDoctoInspeccionHomologacion = {
                key: idSplited[1],
                DocumentosTipoId: -1,
                CodigoHomologacionInspeccion: itemInspeccionHomologacion.ID,
                CodigoHomologacionGeneral: itemInspeccionHomologacion.HomologacionGeneralId,
                DetalleDocumento: "",
                EmailSolicitante : currentUser.usuario
            };
            switch(idSplited[0]){
                case 'tipoDocumento':
                    metadataToAdd = { ...metadataToAdd, DocumentosTipoId: Number(option.key) };
                    break;
                case 'detalle':
                    metadataToAdd = { ...metadataToAdd, DetalleDocumento: value as string };
                    break;
                default:
                    metadataToAdd = { ...metadataToAdd };
            }
          metadataListModified = metadataDocumentosList;
          metadataListModified.push(metadataToAdd);

        } else {
          let metadataToUpdate: IMetadataDoctoInspeccionHomologacion = undefined;
          switch(idSplited[0]){
            case 'tipoDocumento':
                metadataToUpdate = { ...metadataExist, DocumentosTipoId: Number(option.key) };
                break;
            case 'detalle':
                metadataToUpdate = { ...metadataExist, DetalleDocumento: value as string};
                break;
            default:
                metadataToUpdate = { ...metadataExist };
            }
          metadataListModified = metadataDocumentosList.filter( m => m.key !== metadataExist.key );
          metadataListModified.push(metadataToUpdate);
        }

        this.setState({
            metadataDocumentosList: metadataListModified
        });
    }
    
    private _onUploadFile = (files: any[], fileData: any[], metadataFile: IMetadataDoctoInspeccionHomologacion[]):void => {
        const { itemInspeccionHomologacion } = this.props;

        let metadataOrdenada: IMetadataDoctoInspeccionHomologacion[] = [];
        for(let f=0; f<files.length; f+=1){
            metadataOrdenada.push(metadataFile.find(m => m.key === fileData[f].key));
        }
        
        this.setState({
            optionsBox: [],
        });

        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.handleLoadingDocumentosInspeccion(true));
        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.uploadDocumentoHomologacionInspeccion(files, metadataOrdenada, itemInspeccionHomologacion.ID));
    }

    private _onBuildBoxOptions = ( doctoTipoList:IDocumentosTipo[], documentosList: IDocumentosInspeccionHomologacion[] ):IComboBoxOption[] => {

        // tengo que dibujar opciones dependiendo los documentos que tengo...
        let tipoDoctoFilter: IDocumentosTipo[] = [];
        let doctoExist: boolean = true;

        const tipoDoctoPrev: IDocumentosTipo[] = doctoTipoList.filter( t => t.MaestroFases.lookupValue === 'Previa' );
        // tengo que revisar que la documentación previa esté cargada al menos 1 documento de cada uno y en la documentación posterior mostrare todo
        for (let td=0; td<tipoDoctoPrev.length; td+=1){
            if ( !documentosList.map(t=> t.DocumentosTipoId).includes(tipoDoctoPrev[td].ID) ){
                doctoExist = false;
                break;
            }
        }

        // solo documentos de previos
        if (!doctoExist) {
            tipoDoctoFilter = doctoTipoList.filter( t => t.MaestroFases.lookupValue !== 'Posterior' && t.MaestroFases.lookupValue !== 'Resultado');
        } else {
            // solo documentos posteriores
            tipoDoctoFilter = doctoTipoList.filter( t => t.MaestroFases.lookupValue !== 'Resultado');
        }

        return OrdenaDocumentosTiposList(tipoDoctoFilter);
    }

    render(): JSX.Element {
        const { metadataDocumentosList, optionsBox } = this.state;
        return (
            <div className={ styles.CargaDocumentos }>
                { optionsBox.length > 0 && (
                    <UploadFile
                        onUploadFile={this._onUploadFile}
                        metadataList={metadataDocumentosList}
                        metadataOptionsColumns = {[
                            {
                                key: 'columnSelectDocType',
                                name: 'Tipo Documento',
                                fieldName: 'DocumentosTipoId',
                                minWidth: 200,
                                maxWidth: 200,
                                onRender: (item) => {
                                    const metadata = metadataDocumentosList.filter(m => m.key === item.key)[0];
                                    let tipoDocumentoItem = metadata? metadata.DocumentosTipoId : '';
                                    return (<CommonComboBox id={`tipoDocumento@@${item.key}`} key={`${item.key}comboboxTipoDocumento`} options={optionsBox} onChange={this._onChangeMetadata} defaultSelectedKey={tipoDocumentoItem}/>);
                                }
                            },
                            {
                                key: 'columnAddDetails',
                                name: 'Detalle',
                                fieldName: 'DetalleDocumento',
                                minWidth: 300,
                                maxWidth: 300,
                                onRender: (item) => {
                                    const metadata = metadataDocumentosList.find(m => m.key === item.key);
                                    let detalleItem = metadata? metadata.DetalleDocumento : '';
                                    return (<CustomTextField id={`detalle@@${item.key}`} defaultValue={detalleItem} onBlur={this._onChangeMetadata} />);
                                }
                            }
                        ]}
                    />
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CargaDocumentos);