import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IComentarioBase } from '../../ItemSuministro/interfaces';
import { CustomTextField } from '../../Shared/CustomFields/TextField';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { CustomIconButton } from '../../Shared/IconButton';
import { IUsuarioLogin } from '../../utils/interfaces';
import { ItemHomologacionesActionCreators } from '../actions';
import { IDoctosRequeridoHomologacion } from '../interfaces';
import PanelDocumento from './PanelDocumento';

import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { EnumEstadosDocumentosSuministros } from '../../utils/enums';

interface IDoctosRequeridosProps {
    doctosRequeridosList: IDoctosRequeridoHomologacion[];
    etapaHomologacion: string;
    onChangeFiles: (ev: any) => void;
    downloadDocument: (uri:string, filename: string) => void;
    loadingDoctoDownload: any;
}

const iconClass = mergeStyles({
    fontSize: 15,
    padding: '0px 2px',
    marginTop: 1,
  });

const baseContainerClass =  mergeStyles({
    color: 'white',
    borderRadius: 20,
    padding: '6px 36px',
    textAlign: 'center'
  });

const containerClass = mergeStyleSets({
    aprobado: [{ backgroundColor: 'green' }, baseContainerClass],
    rechazado: [{ backgroundColor: 'red' }, baseContainerClass],
    enRevision: [{ backgroundColor: 'gold' }, baseContainerClass],
    pendiente: [{ backgroundColor: 'grey' }, baseContainerClass],
  });

const DoctosRequeridos: React.FunctionComponent<IDoctosRequeridosProps> = (props) =>{
    const dispatch = useDispatch();
    const { doctosRequeridosList, etapaHomologacion, loadingDoctoDownload } = props;

    const [openPanel, setOpenPanel] = React.useState<boolean>(false);
    const [doctoRequeridoSelected, setDoctoRequeridoSelected] = React.useState<IDoctosRequeridoHomologacion>(undefined);

    function _onOpenPanel (doctoRequeridoId: number):void {
        const selectedDoctoRequerido: IDoctosRequeridoHomologacion = doctosRequeridosList.find(dr => Number(dr.ID) === doctoRequeridoId);
        setOpenPanel(true);
        setDoctoRequeridoSelected(selectedDoctoRequerido);
        if (selectedDoctoRequerido.Documento){
            dispatch(ItemHomologacionesActionCreators.handleLoadingComentarios(true));
            dispatch(ItemHomologacionesActionCreators.getComentariosDocumentoById(selectedDoctoRequerido.Documento.ID));
        }
    }

    function _onClosePanel () {
        setOpenPanel(false);
        setDoctoRequeridoSelected(undefined);
        dispatch(ItemHomologacionesActionCreators.clearComentariosDocumento());
    }
    return(
        <div>
            <PanelDocumento
                onClose={_onClosePanel}
                isOpen={openPanel}
                onDescargarDocumento={props.downloadDocument}
                selectedDoctoRequerido={doctoRequeridoSelected}
                loadingDoctoDownload={loadingDoctoDownload}
                disabled={false} // se bloquea en algun momento?
            />
            { doctosRequeridosList.length > 0 && (
                <CustomDetailsList
                    id={`doctosrequeridoslist${etapaHomologacion}`}
                    items={doctosRequeridosList}
                    columns={[
                        {
                            key: 'columnFileIconDocumentoObject',
                            name: 'iconfile',
                            fieldName: 'iconfile',
                            minWidth: 16,
                            maxWidth: 16,
                            iconName: 'Page',
                            clickToSort: false,
                        },
                        {
                            key: 'columnAccion',
                            name: 'Más',
                            fieldName: 'CalculaAvance', // este es un parche
                            minWidth: 40,
                            maxWidth: 40,
                            clickToSort: false,
                            onRender: (item: IDoctosRequeridoHomologacion) => (<CustomIconButton id={`opciones_${String(item.key)}`} icon='More' label={item.Documento === undefined? 'Sin Documento':'Más información'} onClick={() => _onOpenPanel(Number(item.key))} disabled={item.Documento === undefined} />)
                        },
                        {
                            key: 'columnTipoDocumento',
                            name: 'Tipo de documento',
                            fieldName: 'DocumentosTipo',
                            minWidth: 300,
                            maxWidth: 450,
                            isResizable: true,
                            onRender: (item: IDoctosRequeridoHomologacion) => (<span>{item.DocumentosTipo && item.DocumentosTipo.lookupValue} <span style={{ fontSize: 9, color: 'red'}}>{!item.CalculaAvance && '(Documento extra)'}</span></span>)
                        },
                        {
                            key: 'columnDetalle',
                            name: 'Detalle',
                            fieldName: 'Title',
                            minWidth: 100,
                            maxWidth: 150,
                            isResizable: true,
                        },
                        {
                            key: 'columnNombreDoc',
                            name: 'Nombre Documento',
                            fieldName: 'Title',
                            minWidth: 300,
                            maxWidth: 450,
                            isResizable: true,
                            onRender : (item:IDoctosRequeridoHomologacion) => item.Documento && item.Documento.FileLeafRef ? <p>{item.Documento.FileLeafRef}</p> : <p></p>
                        },
                        {
                            key: 'columnstate',
                            name: 'Estado',
                            fieldName: 'Documento',
                            minWidth: 160,
                            maxWidth: 160,
                            onRender: (item: IDoctosRequeridoHomologacion) => {
                            if (item.Documento === undefined){
                                return (<div className={containerClass.pendiente}><FontIcon iconName="PageRemove" className={iconClass} /> Sin Documento</div>);
                            }
                            switch(item.Documento.Estado.lookupId){
                                case EnumEstadosDocumentosSuministros.EnRevision:
                                    return (<div className={containerClass.enRevision}><FontIcon iconName="ReadingMode" className={iconClass} /> En Revisión</div>);
                                case EnumEstadosDocumentosSuministros.Aprobado:
                                    return (<div className={containerClass.aprobado}><FontIcon iconName="Accept" className={iconClass} /> Aprobado</div>);
                                case EnumEstadosDocumentosSuministros.Rechazado:
                                    return (<div className={containerClass.rechazado}><FontIcon iconName="Cancel" className={iconClass} /> Rechazado</div>);
                            }
                                }
                        },
                        {
                            key: 'columnasubirarchivo',
                            name: 'Cargar',
                            fieldName: 'Documento',
                            minWidth: 300,
                            maxWidth: 450,
                            isResizable: true,
                            clickToSort: false,
                            onRender: (item : IDoctosRequeridoHomologacion) => {
                                if(item.Documento === undefined){
                                    return <CustomTextField name={`InputFileUpdateDoctos.${etapaHomologacion}`} onChange={props.onChangeFiles} id={String(item.key)} type='file'/>
                                }
                                else{
                                    if(item.Documento.Estado.lookupValue === 'Rechazado'){
                                        return <CustomTextField name={`InputFileUpdateDoctos.${etapaHomologacion}`} onChange={props.onChangeFiles} id={String(item.key)} type='file'/> 
                                    }
                                    else return <>No se puede actualizar este archivo</>
                                    
                                }
                            }
                        },
                    ]}
                />
            )}
            
        </div>
    );
}


export default DoctosRequeridos;