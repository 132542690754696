import * as React from 'react';

interface ILayoutProps {
    children?: React.ReactNode,
}

export const Layout: React.FunctionComponent<ILayoutProps> = ({children}) => (
    <React.Fragment>
        {children}
    </React.Fragment>
);


