import * as React from 'react';
import { useDispatch } from 'react-redux';
import styles from '../ItemInspeccion.module.scss';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IDocumentoSuministro } from '../../ItemSuministro/interfaces';
import { CustomIconButton } from '../../Shared/IconButton';
import ApiConfig from '../../../config/apiConfigOficial';
import { ItemSuministroActionCreators } from '../../ItemSuministro/actions';

interface IDocumentosSuministroProps {
}

export const DocumentosSuministros: React.FunctionComponent<IDocumentosSuministroProps> = () => {
    const documentosSuministrosList: IDocumentoSuministro[]= useSelector( (state: ApplicationState) => state.itemInspeccion.documentosSuministosList );
    const documentosAntecedentesList: IDocumentoSuministro[]= useSelector( (state: ApplicationState) => state.itemInspeccion.doctosAntecedentesProveedorList );
    const dispatch = useDispatch();

    function _onDownloadDocto(url:string, fileName: string): void {
        dispatch(ItemSuministroActionCreators.loadingDownloadDocumento(true, fileName));
        dispatch(ItemSuministroActionCreators.downloadDocumento(url, fileName)); 
    }

    return (
        <div className={ styles.VistaDocumentos }>
            <h1>2. Documentos del suministro</h1>
            <div className={ styles.VistaDocumentosContent }>
                <div className={styles.DocumentacionContainer}>
                    <div className={styles.GrillaDocumentos}>
                        <CustomDetailsList
                            items={documentosAntecedentesList && documentosSuministrosList? documentosAntecedentesList.concat(documentosSuministrosList): documentosSuministrosList ? documentosSuministrosList : []}
                            columns={[
                                {
                                    key: 'columniconfileDocSuministro',
                                    name: 'iconfile',
                                    fieldName: 'iconfile',
                                    minWidth: 16,
                                    maxWidth: 16,
                                    iconName: 'Page',
                                    clickToSort: false,
                                },
                                {
                                    key: 'columnDownload',
                                    name: 'Nombre',
                                    iconName: 'Download',
                                    fieldName: 'FileRef',
                                    minWidth: 40,
                                    maxWidth: 40,
                                    isResizable: false,
                                    onRender: (item: IDocumentoSuministro) => (<CustomIconButton id={`doownload_${item.ID}`} icon='Download' label='Descargar' onClick={() => _onDownloadDocto(`${ApiConfig.baseUriDocuments}${item.FileRef}`,item.FileLeafRef)} />)
                                },
                                {
                                    key: 'columnname',
                                    name: 'Nombre',
                                    fieldName: 'FileLeafRef',
                                    minWidth: 200,
                                    maxWidth: 350,
                                    isResizable: true,
                                    isRowHeader: true,
                                },
                                {
                                    key: 'columnTipoDocumento',
                                    name: 'Tipo documento',
                                    fieldName: 'DocumentosTipo',
                                    minWidth: 150,
                                    maxWidth: 200,
                                    isResizable: true,
                                    onRender: (item: IDocumentoSuministro) => item.DocumentosTipo.lookupValue,
                                },
                                {
                                    key: 'columnaDetalle',
                                    name: 'Detalle',
                                    fieldName: 'CodigoDoctoRequerido_x003a_Detalle',
                                    minWidth: 150,
                                    maxWidth: 200,
                                    isResizable: true,
                                    onRender: (item: IDocumentoSuministro) => item.CodigoDoctoRequerido_x003a_Detalle? item.CodigoDoctoRequerido_x003a_Detalle.lookupValue:'',
                                },
                            ]}
                            addFilterInput={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}