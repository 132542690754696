import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IInspeccionFabrica } from '../../ItemInspeccion/interfaces';
import { Loading } from '../../Shared/Loading/loading';
import { ItemSuministroActionCreators } from '../actions';
import { IDocumentoSuministro, IMetadataDocumentoItem, IProcesoSuministro } from '../interfaces';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import styles from '../ItemSuministro.module.scss';
import { EnumEstadoInspeccion } from '../../utils/enums';
import { Separador } from '../../Shared/Separador/separador';
import { CustomIconButton } from '../../Shared/IconButton';
import { ConvertSharepointDate } from '../../utils/functions';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import Resumen from '../../Shared/Resumen/Resumen';
import ApiConfig from '../../../config/apiConfigOficial';
import { IUsuarioLogin, IUsuarioProveedor } from '../../utils/interfaces';

const iconClass = mergeStyles({
	fontSize: 15,
	padding: '0px 2px',
	marginTop: 1,
});
  
const baseContainerClass =  mergeStyles({
	color: 'white',
	borderRadius: 20,
	padding: '6px 36px'
});
  
const containerClass = mergeStyleSets({
	aprobada: [{ backgroundColor: 'green' }, baseContainerClass],
	rechazada: [{ backgroundColor: 'red' }, baseContainerClass],
	enProceso: [{ backgroundColor: 'gold' }, baseContainerClass],
});


interface IConnectedState {
    itemSuministro: IProcesoSuministro;
    inspeccionesList: IInspeccionFabrica[];
    loadingInspecciones: boolean;
    documentosProtocolosFAT : IDocumentoSuministro[];
    currentUser : IUsuarioLogin;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        itemSuministro: state.itemSuministro.itemSuministro,
        inspeccionesList: state.itemSuministro.inspeccionesList,
        loadingInspecciones: state.itemSuministro.loadingInspecciones,
        documentosProtocolosFAT : state.itemSuministro.documentosProtocolosFAT,
        currentUser : state.main.user
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IProtocolosPruebasState {
    isLoaded: boolean;
    metadataDocumentosList: IMetadataDocumentoItem[];
}

interface IProtocolosPruebasOwnProps {
    match: any;
    history: any;
}

type IProtocolosPruebasProps = IProtocolosPruebasOwnProps & IConnectedState & { dispatch(action: any): void };

class ProtocolosPruebas extends React.PureComponent<IProtocolosPruebasProps, IProtocolosPruebasState> {

    constructor(props: IProtocolosPruebasProps){
        super(props);
        this.state = {
            metadataDocumentosList: [],
            isLoaded: false,
        }
    }

    public componentDidMount(){
        const { itemSuministro } = this.props;
        if ( itemSuministro && itemSuministro.EtapaSuministroId >= 4 ){
            this.props.dispatch(ItemSuministroActionCreators.loadingInspeccionesFabrica(true));
            this.props.dispatch(ItemSuministroActionCreators.getInspeccionesFabrica(itemSuministro.CodigoSuministroPadre ? itemSuministro.CodigoSuministroPadre.lookupId : itemSuministro.ID));
        }

        this.props.dispatch(ItemSuministroActionCreators.getDocumentosProtocolosFAT(itemSuministro.CodigoSuministroPadre ? itemSuministro.CodigoSuministroPadre.lookupId : itemSuministro.ID));

    }
    
    private _onClickProcesarItem = (item :IInspeccionFabrica): void => {
        const { history } = this.props;
        history.push(`/itemInspeccion/${item.UniqueId}`);
    }

    private _onDownloadDocument = (uri: string, fileName: string):void => {

        this.props.dispatch(ItemSuministroActionCreators.downloadDocumento(uri,fileName));

    }
    
    public render() {
        const { loadingInspecciones, inspeccionesList, itemSuministro, documentosProtocolosFAT, currentUser } = this.props;
        if (loadingInspecciones){
            return (<Loading message='Cargando inspecciones...' fullScreen={false} />)
        }

        const documentosAprobados = documentosProtocolosFAT && documentosProtocolosFAT.filter( doc => doc.DocumentosEstado.lookupId === 2);
        return (
            <div className={ "ms-Grid ".concat(styles.protocolosPruebasContainer)} dir="ltr">
                <h2>Protocolos de pruebas e inspecciones</h2>
                <Resumen itemSuministro={itemSuministro} />
                {currentUser.usuarioProveedor.proveedorInspector !== null && (
                    <>
                    <Separador mensaje="Listado de inspecciones"/>
                    <CustomDetailsList
                        columns={[
                            {
                                key: 'columnaAcciones',
                                name: 'Procesar',
                                fieldName: 'UniqueId',
                                iconName: 'Processing',
                                minWidth: 40,
                                maxWidth: 40,
                                isResizable: false,
                                clickToSort: false,
                                onRender: (item: IInspeccionFabrica) => (<CustomIconButton icon='Processing' onClick={() => this._onClickProcesarItem(item)} label='Procesar' id={`procesar_${item.UniqueId}`} />)
                            },
                            {
                                key: 'columnaCodigo',
                                name: 'Código inspección',
                                fieldName: 'Codigo',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                isRowHeader: true,
                                onRender: (item: IInspeccionFabrica) => item.Codigo !== null? item.Codigo:'Fecha no definida',
                            },
                            {
                                key: 'columnaFechaInicio',
                                name: 'Fecha inicio estimada',
                                fieldName: 'FechaInicio',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item: IInspeccionFabrica) => item.FechaInicio !== null? ConvertSharepointDate(item.FechaInicio):'Fecha no definida',
                            },
                            {
                                key: 'columnaFechaTermino',
                                name: 'Fecha término estimada',
                                fieldName: 'FechaTermino',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item: IInspeccionFabrica) => item.FechaTermino !== null? ConvertSharepointDate(item.FechaTermino):'Fecha no definida',
                            },
                            {
                                key: 'columnaEstado',
                                name: 'Estado',
                                fieldName: 'EstadoInspeccionId',
                                minWidth: 150,
                                maxWidth: 150,
                                onRender: (item: IInspeccionFabrica) => {
                                    switch(item.EstadoInspeccionId){
                                        case EnumEstadoInspeccion.EnProceso:
                                            return (<div className={containerClass.enProceso}><FontIcon iconName="Processing" className={iconClass} /> En proceso</div>);
                                        case EnumEstadoInspeccion.Rechazada:
                                            return (<div className={containerClass.rechazada}><FontIcon iconName="Cancel" className={iconClass} /> Rechazada</div>);
                                        case EnumEstadoInspeccion.Aprobada:
                                            return (<div className={containerClass.aprobada}><FontIcon iconName="Accept" className={iconClass} /> Aprobada</div>);
                                    }
                                }
                            },
                        ]}
                        items={inspeccionesList? inspeccionesList:[]}
                        addFilterInput={false}
                    />
                    </>
                )}


                {currentUser.usuarioProveedor.proveedorInspector === null && documentosAprobados && (
                    <>
                        <Separador mensaje="Documentos"/>
                        <CustomDetailsList
                        items={documentosAprobados}
                        columns={[
                            {
                                key: 'columniconfileDocSuministro',
                                name: 'iconfile',
                                fieldName: 'iconfile',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Page',
                                clickToSort: false,
                            },
                            {
                                key: 'columnDownload',
                                name: 'download',
                                fieldName: 'FileRef',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Link',
                                clickToSort: false,
                                onRender: (item: IDocumentoSuministro) => (<CustomIconButton id={`downloadButton${item.FileLeafRef}`} icon='Link' onClick={() => this._onDownloadDocument(`${ApiConfig.baseUriDocuments}${item.FileRef}`, item.FileLeafRef)} label='Descargar' />)
                            },
                            {
                                key: 'columnNombreArchivo',
                                name: 'Nombre de archivo',
                                fieldName: 'FileLeafRef',
                                isRowHeader: true,
                                minWidth: 200,
                                maxWidth: 300,
                                isResizable: true,
                            },
                            {
                                key: 'columnTipoDocumento',
                                name: 'Tipo de documento',
                                fieldName: 'DocumentosTipo',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item : IDocumentoSuministro) => item.DocumentosTipo? item.DocumentosTipo.lookupValue : 'Tipo desconocido'
                            },
                            {
                                key: 'columnDetalle',
                                name: 'Nombre Documento',
                                fieldName: 'CodigoDoctoRequerido_x003a_Detalle',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item: IDocumentoSuministro) => item.CodigoDoctoRequerido_x003a_Detalle? item.CodigoDoctoRequerido_x003a_Detalle.lookupValue : 'Detalle desconocido'
                            },
                        ]}
                        addFilterInput={false}
                    />
                    </>
                   
                )}
            </div>
        );
    }
    
}


export default connect(mapStateToProps, mapDispatchToProps)(ProtocolosPruebas);



