import React from 'react';
import styles from '../ItemInspeccion.module.scss';
import { RichText } from '../../Shared/CustomFields/richtext/richtext';

const ComentariosRechazo = ({itemInspeccion}) => {
  return (
    <div className={ styles.VistaDocumentos }>
        <h1>4. Comentarios rechazo</h1>
        <RichText
            id='comentariosRechazo'
            label='Comentarios'
            value={itemInspeccion.Comentarios}
            onChange={void(0)}
            disabled={true}
        ></RichText>

    </div>
  )
}

export default ComentariosRechazo;