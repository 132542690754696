import { Action, Reducer } from 'redux';
import {
    GET_INSPECCIONBYID,
    GET_DOCUMENTOSINPSECCIONLIST,
    GET_DOCUMENTOSHOMOLOGACIONLIST,

    LOADING,
    LOADING_DOCUMENTOSINSPECCION,
    LOADING_DOCUMENTOSHOMOLOGACION,

    CLEAR_INSPECCIONITEM,
    LOADING_COMENTARIOSHOMOLOGACIONISPECCION,
    GET_COMENTARIOSHOMOLOGACIONINSPECCION,
    CLEAR_COMENTARIOSHOMOLOGACIONINSPECCION,
} from './constants';
import { ItemHomologacionInspeccionKnownAction } from './actions';
import { IDocumentoHomologacion, IDocumentosInspeccionHomologacion, IInspeccionHomologacion } from '../ItemHomologacion/interfaces';
import { IComentarioBase } from '../ItemSuministro/interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ItemHomologacionInspeccionState {
    inspeccionItem?: IInspeccionHomologacion;
    documentosInspeccionesList?: IDocumentosInspeccionHomologacion[];
    documentosHomologacionList?: IDocumentoHomologacion[];
    comentariosInspeccionList?: IComentarioBase[];
    loading: boolean;
    loadingDocumentosInspeccion: boolean;
    loadingDocumentosHomologacion: boolean;
    loadingComentarios: boolean;
    ComentariosList?: IComentarioBase[];
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const ItemHomologacionInspeccionReducer: Reducer<ItemHomologacionInspeccionState> = (state: ItemHomologacionInspeccionState | undefined, incomingAction: Action): ItemHomologacionInspeccionState => {
    if (state === undefined) {
        return {
            loading: false,
            loadingDocumentosInspeccion: false,
            loadingDocumentosHomologacion: false,
            loadingComentarios: false,
        };
    }
    const action = incomingAction as ItemHomologacionInspeccionKnownAction;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading };
        case CLEAR_INSPECCIONITEM:
            return {
                ...state,
                inspeccionItem: undefined,
                documentosInspeccionesList: undefined,
                documentosHomologacionList: undefined,
            };

        case GET_INSPECCIONBYID:
            return {
                ...state,
                inspeccionItem: action.itemInspeccion && {
                    ...action.itemInspeccion,
                    EstadoId: action.itemInspeccion.Estado && action.itemInspeccion.Estado.lookupId,
                    ProveedorId: action.itemInspeccion.Proveedor && action.itemInspeccion.Proveedor.lookupId,
                    HomologacionGeneralId: action.itemInspeccion.HomologacionGeneral && action.itemInspeccion.HomologacionGeneral.lookupId,
                },
                loading: false
            };

        case LOADING_DOCUMENTOSHOMOLOGACION:
            return { ...state, loadingDocumentosHomologacion: action.loading };
        case GET_DOCUMENTOSHOMOLOGACIONLIST:
            return { ...state, loadingDocumentosHomologacion: false, documentosHomologacionList: action.doctosList };

        case LOADING_DOCUMENTOSINSPECCION:
            return { ...state, loadingDocumentosInspeccion: action.loading };
        case GET_DOCUMENTOSINPSECCIONLIST:
            return {
                ...state, loadingDocumentosInspeccion: false, documentosInspeccionesList: action.doctosList.map(d => ({
                    ...d,
                    DocumentosTipoId: d.DocumentosTipo.lookupId,
                    DocumentosEstadoId: d.DocumentosEstado.lookupId,
                    HomologacionGeneralId: d.HomologacionGeneral.lookupId,
                    HomologacionInspeccionId: d.HomologacionInspeccion.lookupId,
                }))
            };
        case LOADING_COMENTARIOSHOMOLOGACIONISPECCION:
            return { ...state, loadingComentarios: action.loadingComentarios };
        case GET_COMENTARIOSHOMOLOGACIONINSPECCION:
            const comentariosInspeccionList: IComentarioBase[] = action.comentariosList.map(c => ({
                ...c,
            }));
            return { ...state, comentariosInspeccionList, loadingComentarios: false };
        case CLEAR_COMENTARIOSHOMOLOGACIONINSPECCION:
            return { ...state, comentariosInspeccionList: [] };

        default:
            return state;
    }
};


