import {
    LOADING,
    GET_INSPECCIONESHOMOLOGACION,
} from './constants';
import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions'

import apiConfig from '../../config/apiConfigOficial';
import { IInspeccionHomologacion } from '../ItemHomologacion/interfaces';

// Decalaraciones de las acciones
interface HanldeLoadingAction { type: typeof LOADING; loading: boolean; }
interface GetInspeccionesListAction { type: typeof GET_INSPECCIONESHOMOLOGACION; inspeccionesList: IInspeccionHomologacion[]; }

// lista de acciones conocidas
export type GrillaHomologacionKnownAction =
    MainKnownAction
|   HanldeLoadingAction
|   GetInspeccionesListAction
;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

export const GrillaHomologacionesInspeccionActionCreators = {
    handleLoading: (loading) => ({ type: LOADING, loading } as HanldeLoadingAction),
    getHomologacionesByProveedor: (rutInspector: string): AppThunkAction<GrillaHomologacionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetInspeccionesByInspector`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(rutInspector),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IInspeccionHomologacion[]>;
            })
            .then(data => {
                dispatch({ type: GET_INSPECCIONESHOMOLOGACION, inspeccionesList: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },
};