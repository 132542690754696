import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IDocumentoSuministro, IProcesoSuministro } from '../interfaces';
import { CustomTextField } from '../../Shared/CustomFields/TextField';
import styles from '../ItemSuministro.module.scss';
import { Separador } from '../../Shared/Separador/separador';
import { ItemSuministroActionCreators } from '../actions';
import { Loading } from '../../Shared/Loading/loading';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { CustomIconButton } from '../../Shared/IconButton';
import ApiConfig from '../../../config/apiConfigOficial';
import Resumen from '../../Shared/Resumen/Resumen';
import { EnumEtapasSuministro } from '../../utils/enums';
import { Spinner, SpinnerSize, getTheme } from '@fluentui/react';
import { RichText } from '../../Shared/CustomFields/richtext/richtext';
import { CustomButton } from '../../Shared/Button';
import * as html2pdf from 'html2pdf.js';
import logo from '../../../img/newLogo.jpg'
import { DateFormatInput } from '../../ItemHomologacion/InternalComponents/VistaPrevia';

interface IConnectedState {
    itemSuministro: IProcesoSuministro;
    loadingAntecedentes: boolean;
    doctosRecepcion: IDocumentoSuministro[];
}

function mapStateToProps(state: ApplicationState): IConnectedState {
    return {
        itemSuministro: state.itemSuministro.itemSuministro,
        loadingAntecedentes: state.itemSuministro.loadingDoctosAntecedentes,
        doctosRecepcion: state.itemSuministro.documentosRecepcionList,
    };
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

const theme = getTheme();

interface IRecepcionOwnProps {
}

interface IRecepcionState {
    actaRecepcion: IDocumentoSuministro;
    nuevaActa: boolean;
    loadingDonwload: boolean;
}

// define combined props
type IRecepcionProps = IRecepcionOwnProps & IConnectedState & { dispatch(action: any): void };

class Recepcion extends React.PureComponent<IRecepcionProps, IRecepcionState> {

    constructor(props: IRecepcionProps) {
        super(props);
        this.state = {
            actaRecepcion: null,
            nuevaActa: false,
            loadingDonwload: false,
        };
    }

    public componentDidMount = (): void => {
        const { itemSuministro } = this.props;
        if (itemSuministro) {
            this.props.dispatch(ItemSuministroActionCreators.loadingDocumentosAntecedentesByProveedor(true));
            this.props.dispatch(ItemSuministroActionCreators.getDocumentosAntecedentesByProveedor(itemSuministro.CodigoSuministroPadre ? itemSuministro.CodigoSuministroPadre.lookupId : itemSuministro.ID));
            this.props.dispatch(ItemSuministroActionCreators.getDocumentosRecepcion(itemSuministro.ID));
        }
    }

    public componentDidUpdate = (prevProps: IRecepcionProps): void => {
        const { doctosRecepcion } = this.props;
        const { actaRecepcion, nuevaActa, loadingDonwload } = this.state;
        if (doctosRecepcion) {
            const doctosFiltrados = doctosRecepcion.filter((item) => item.DocumentosTipo.lookupId === 46);
            this.setState({ actaRecepcion: doctosFiltrados[0] });
        }

        if (actaRecepcion && nuevaActa && loadingDonwload) {
            this._onDownloadDocument(`${ApiConfig.baseUriDocuments}${actaRecepcion.FileRef}`, actaRecepcion.FileLeafRef);
            this.setState({ loadingDonwload: false });
        }
    }

    private _onDownloadDocument = (uri: string, fileName: string): void => {
        this.props.dispatch(ItemSuministroActionCreators.downloadDocumento(uri, fileName));
    }

    private _onDownloadPDF = (): Promise<void> => {
        this.setState({ loadingDonwload: true });
        const { itemSuministro } = this.props
        const { nuevaActa, actaRecepcion } = this.state;

        if (actaRecepcion !== undefined && actaRecepcion !== null) {
            this._onDownloadDocument(`${ApiConfig.baseUriDocuments}${actaRecepcion.FileRef}`, actaRecepcion.FileLeafRef);
            //create a delay 
            setTimeout(() => {
                this.setState({ loadingDonwload: false });
            }, 1000);
            return;
        }

        const divToPrint = document.getElementById(`SuministroGeneral-${itemSuministro && itemSuministro.ID}`);
        var opt = {
            margin: 0.5,
            filename: `ActaRecepcion_${itemSuministro && itemSuministro.ID}.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 1, useCORS: true, imageTimeout: 0 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };

        html2pdf()
            .set(opt)
            .from(divToPrint)
            .toPdf()
            .outputPdf("blob", opt.jsPDF)
            .then((pdfDoc: Blob) => {
                this.setState({ nuevaActa: true });
                this.props.dispatch(ItemSuministroActionCreators.uploadActaRecepcion(pdfDoc, opt.filename, itemSuministro.ID));
            });
    }

    public render(): React.ReactNode {

        const { itemSuministro, loadingAntecedentes, doctosRecepcion } = this.props;
        const { loadingDonwload } = this.state;
        const doctosRecepcionFiltrados = doctosRecepcion && doctosRecepcion.filter((item) => item.DocumentosTipo.lookupId !== 46);
        const mostrarActa: boolean = itemSuministro.EtapaSuministroId === EnumEtapasSuministro.Cerrado || itemSuministro.EtapaSuministroId === EnumEtapasSuministro.CerradoSinInspeccion;

        return itemSuministro && (
            <div className={"ms-Grid ".concat(styles.AntecedentesContainer)} dir="ltr" >
                <h2>Recepción</h2>
                <Resumen itemSuministro={itemSuministro} />
                <Separador subtitle mensaje='Antecedentes' />
                <CustomTextField
                    id="OCAsociada"
                    label="Orden de compra asociada"
                    value={itemSuministro.Title}
                    onChange={() => void 0}
                    disabled
                />
                <CustomTextField
                    id="RutProveedor"
                    label="RUT proveedor"
                    value={itemSuministro.NombreProveedor_x003a_Rut.lookupValue}
                    onChange={() => void 0}
                    disabled
                />
                <CustomTextField
                    id="NombreProveedor"
                    label="Nombre Proveedor"
                    value={itemSuministro.NombreProveedor.lookupValue}
                    onChange={() => void 0}
                    disabled
                />
                <CustomTextField
                    id="SAPSuministro"
                    label="Código SAP del equipo/material"
                    value={itemSuministro.NombreSuministro_x003a_CodigoSAP.lookupValue}
                    onChange={() => void 0}
                    disabled
                />
                <CustomTextField
                    id="Cantidad"
                    label="Cantidad OC"
                    type="number"
                    value={itemSuministro.Cantidad.toString()}
                    onChange={() => void 0}
                    disabled
                />
                <CustomTextField
                    id="DescTecnicaSuministro"
                    label="Descripción técnica del equipo/material"
                    value={itemSuministro.Title}
                    onChange={() => void 0}
                    disabled
                />

                <CustomTextField
                    id="CantidadRecepcionada"
                    label="Cantidad Recepcionada"
                    type="number"
                    value={itemSuministro.CantidadRecepcionada}
                    disabled
                />
                <RichText
                    id="AlcanceRecepcion"
                    label="Alcance de la recepción"
                    showLable
                    value={itemSuministro.AlcanceRecepcion}
                    disabled
                    onChange={undefined}
                />
                <Separador subtitle mensaje='Documentos' />
                {loadingAntecedentes && <Loading fullScreen={false} message='Cargando documentos de Recepción' />}
                {doctosRecepcionFiltrados && doctosRecepcionFiltrados.length > 0 ? (
                    <CustomDetailsList
                        items={doctosRecepcionFiltrados}
                        columns={[
                            {
                                key: 'columniconfileDocSuministro',
                                name: 'iconfile',
                                fieldName: 'iconfile',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Page',
                                clickToSort: false,
                            },
                            {
                                key: 'columnDownload',
                                name: 'download',
                                fieldName: 'FileRef',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Link',
                                clickToSort: false,
                                onRender: (item: IDocumentoSuministro) => (<CustomIconButton id={`downloadButton${item.FileLeafRef}`} icon='Link' onClick={() => this._onDownloadDocument(`${ApiConfig.baseUriDocuments}${item.FileRef}`, item.FileLeafRef)} label='Descargar' />)
                            },
                            {
                                key: 'columnNombreArchivo',
                                name: 'Nombre de archivo',
                                fieldName: 'FileLeafRef',
                                isRowHeader: true,
                                minWidth: 200,
                                maxWidth: 300,
                                isResizable: true,
                            },
                            {
                                key: 'columnTipoDocumento',
                                name: 'Tipo de documento',
                                fieldName: 'DocumentosTipo',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item: IDocumentoSuministro) => item.DocumentosTipo ? item.DocumentosTipo.lookupValue : 'Tipo desconocido'
                            },
                            {
                                key: 'columnDetalle',
                                name: 'Nombre Documento',
                                fieldName: 'CodigoDoctoRequerido_x003a_Detalle',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item: IDocumentoSuministro) => item.CodigoDoctoRequerido_x003a_Detalle ? item.CodigoDoctoRequerido_x003a_Detalle.lookupValue : 'Detalle desconocido'
                            },
                        ]}
                        addFilterInput={false}
                    />
                )
                    : (
                        <div style={{ color: '#0078d4', marginLeft: '50px' }}>
                            <p>No hay documentos visibles</p>
                        </div>
                    )
                }
                {mostrarActa && (
                    <>
                        <Separador subtitle mensaje='Acta de Recepción' />
                        <div style={{ textAlign: 'right' }}>
                            <CustomButton
                                id='GenerateCartaHomologacion'
                                //text='Generar Acta'
                                //iconName='PageAdd'
                                onClick={this._onDownloadPDF}
                                disabled={loadingDonwload} 
                                label={'Generar Acta'}
                            />
                            {loadingDonwload &&
                                <div style={{ textAlign: 'right' }}>
                                    <Spinner size={SpinnerSize.medium} label='Generando Acta...' />
                                </div>}
                        </div><div className={styles.Recepcion}>
                            <div className={styles.contentRecepcion}>
                                {itemSuministro && (
                                    <div id={`SuministroGeneral-${itemSuministro && itemSuministro.ID}`} style={{
                                        padding: '10px 20px',
                                        fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                                    }}>
                                        <div style={{ padding: '10px 20px' }}>
                                            <div style={{ float: 'left' }}>
                                                <img alt="SaesaLogo" src={logo} width={200} />
                                            </div>
                                            <div style={{ float: 'right' }}>
                                                <p>Tipo de cierre: <b>{itemSuministro.TipoProceso.lookupValue}</b></p>
                                                <p>Fecha de cierre: <b>{itemSuministro.FechaRecepcionFinalizada && DateFormatInput(itemSuministro.FechaRecepcionFinalizada)}</b></p>
                                            </div>
                                            <div style={{ clear: 'both' }} />
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <h1 style={{ fontSize: 21, fontWeight: 'bold', marginTop: 25, marginBottom: 50 }}>ACTA DE RECEPCIÓN DE SUMINISTRO N° {itemSuministro.ID}</h1>
                                        </div>
                                        <div style={{ marginBottom: 20 }}>
                                            <p>Estimado Proveedor {itemSuministro.NombreProveedor && itemSuministro.NombreProveedor.lookupValue}:</p>
                                            <p style={{ marginBottom: -10 }}>Se informa que el proceso de suministros  ha finalizado, con resultados satisfactorios y ha sido recepcionado conforme, el siguiente suministro:</p>
                                            <h2 style={{ fontSize: 18, fontWeight: 'bold', marginTop: 30, marginBottom: 10 }}>Antecedentes del suministro</h2>
                                            <table style={{
                                                borderLeft: `5px solid ${theme.palette.themeLight}`,
                                                width: '100%',
                                            }}>
                                                <tbody>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>ID proceso suministro:</td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>{itemSuministro.ID}</td>
                                                    </tr>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>Orden de compra:</td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>{itemSuministro.Title}</td>
                                                    </tr>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>Rut Proveedor</td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>{itemSuministro.NombreProveedor && itemSuministro.NombreProveedor_x003a_Rut.lookupValue}</td>
                                                    </tr>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>Nombre Proveedor</td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>{itemSuministro.NombreProveedor && itemSuministro.NombreProveedor.lookupValue}</td>
                                                    </tr>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>Código SAP: </td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>{itemSuministro.NombreSuministro_x003a_CodigoSAP.lookupValue}</td>
                                                    </tr>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>Descripción: </td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>{itemSuministro.NombreSuministro.lookupValue}</td>
                                                    </tr>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>Cantidad de OC:</td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>{itemSuministro.Cantidad}</td>
                                                    </tr>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>Cantidad recepcionada</td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>{itemSuministro.CantidadRecepcionada}</td>
                                                    </tr>
                                                    <tr style={{ width: '100%', height: 22 }}>
                                                        <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 200 }}>Alcance de recepción:</td>
                                                        <td style={{ marginLeft: 10, marginRight: 10 }}>
                                                            <div style={{ border: `1px dotted ${theme.palette.themeLight}`, marginTop: '10px', marginBottom: '10' }} dangerouslySetInnerHTML={{ __html: itemSuministro.AlcanceRecepcion }}>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table style={{
                                                borderTop: `5px solid ${theme.palette.themeLight}`,
                                                borderBottom: `1px solid ${theme.palette.themeLight}`,
                                                width: '100%',
                                                marginTop: 10
                                            }}>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div></>
                )}
            </div>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Recepcion);