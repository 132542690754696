import * as React from 'react';
import { connect } from 'react-redux';
import { IDocumentosInspeccion, IInspeccionFabrica, IMetadataDocumentoInspeccionItem } from '../interfaces';
import styles from '../ItemInspeccion.module.scss';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { ApplicationState } from '../../../config/store';
import PanelDoctoInspeccion from './PanelDocumento';
import { EnumEstadoInspeccion, EnumEstadosDocumentosSuministros } from '../../utils/enums';
import { ItemInspeccionActionCreators } from '../actions';
import { IUsuarioLogin } from '../../utils/interfaces';
import { IComentarioBase } from '../../ItemSuministro/interfaces';
import { CustomIconButton } from '../../Shared/IconButton';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { ItemSuministroActionCreators } from '../../ItemSuministro/actions';

const baseContainerClass =  mergeStyles({
    color: 'white',
    borderRadius: 20,
    padding: '6px 20px',
    textAlign: 'center'
});
  
const containerClass = mergeStyleSets({
    aprobado: [{ backgroundColor: 'green' }, baseContainerClass],
    rechazado: [{ backgroundColor: 'red' }, baseContainerClass],
    enRevision: [{ backgroundColor: 'gold'  }, baseContainerClass],
    sinDocumento: [{ backgroundColor: 'gray' }, baseContainerClass],
});

  
const iconClass = mergeStyles({
    fontSize: 15,
    padding: '0px 2px',
    marginTop: 1,
});

interface IConnectedState {
    documentosInspeccionList: IDocumentosInspeccion[];
    itemInspeccion: IInspeccionFabrica;
    user: IUsuarioLogin;

    loadingComentarios: boolean;
    comentariosInspeccionList: IComentarioBase[];
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        documentosInspeccionList: state.itemInspeccion.documentosInspeccionList,
        itemInspeccion: state.itemInspeccion.itemInspeccion,
        user: state.main.user,
        loadingComentarios: state.itemInspeccion.loadingComentarioInspeccion,
        comentariosInspeccionList: state.itemInspeccion.comentariosInspeccionList,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});
// define combined props
type IVistaDocumentosProps = IConnectedState &{ dispatch(action: any): void };

interface IVistaDocumentosState {
    isOpenPanel: boolean;
    selectedDocument?: IDocumentosInspeccion;
}

class VistaDoctosInpsecciones extends React.PureComponent<IVistaDocumentosProps, IVistaDocumentosState> {

    constructor(props: IVistaDocumentosProps) {
        super(props);
        this.state = {            
            isOpenPanel: false,
        };
    }

    public componentDidMount(){
        const { itemInspeccion, user } = this.props;
        const esProveedor: boolean = !user.usuarioProveedor.proveedorInspector;
        this.props.dispatch(ItemInspeccionActionCreators.handleLoadingDocumentosInspeccion(true));
        this.props.dispatch(ItemInspeccionActionCreators.getDocumentosInpseccion(itemInspeccion.ID, esProveedor));
    }

    private _onOpenDoctoPanel = ( doctoId: number):void => {
        const { documentosInspeccionList } = this.props;
        const selectedDocument: IDocumentosInspeccion = documentosInspeccionList.find(d =>d.ID === doctoId);
        this.setState({
            isOpenPanel: true,
            selectedDocument,
        });
        this.props.dispatch(ItemInspeccionActionCreators.handleLoadingComentariosInspeccion(true));
        this.props.dispatch(ItemInspeccionActionCreators.getComentariosInspeccionesFabricaById(doctoId));
    }

    private _onClosePanel = () => {
        this.setState({
            isOpenPanel: false,
            selectedDocument: undefined,
        });
    }

    private _onUploadFile = (files: any[], fileData: any[], metadataFile: IMetadataDocumentoInspeccionItem[] | any):void => {
        const { itemInspeccion, user } = this.props;
        let metadataOrdenada: IMetadataDocumentoInspeccionItem[] = [];
        this.props.dispatch(ItemInspeccionActionCreators.handleLoadingDocumentosInspeccion(true));
        metadataOrdenada.push({
            ...metadataFile[0],
            CodigoInspeccionId: itemInspeccion.ID,
            CodigoSuministroId: itemInspeccion.CodigoSuministro.lookupId
        });

        const esProveedor: boolean = !!user.usuarioProveedor.proveedor;
        const esProveedorInspector : boolean = !!user.usuarioProveedor.proveedorInspector;

        this.props.dispatch(ItemInspeccionActionCreators.uploadInspeccionesFile(files, metadataOrdenada, itemInspeccion.ID, esProveedor, true, esProveedorInspector));
        this.props.dispatch(ItemInspeccionActionCreators.getDocumentosInpseccion(itemInspeccion.ID, esProveedor));
    }
    
    private _onDownloadDocument = (url: string, fileName: string):void => {
        this.props.dispatch(ItemSuministroActionCreators.loadingDownloadDocumento(true, fileName));
        this.props.dispatch(ItemSuministroActionCreators.downloadDocumento(url, fileName)); 
    }

    private _onAddComentarioInspeccion = (comentario: IComentarioBase):void => {
        this.props.dispatch(ItemInspeccionActionCreators.handleLoadingComentariosInspeccion(true));
        this.props.dispatch(ItemInspeccionActionCreators.addComentarioDocumentoInspeccion(comentario));
    }

    public render(): JSX.Element {
        const { documentosInspeccionList, itemInspeccion, user, comentariosInspeccionList, loadingComentarios } = this.props;
        const { isOpenPanel, selectedDocument } = this.state;
        const usuarioProveedorId = user.usuarioProveedor.id;
        return (
            <div className={ styles.VistaDocumentos }>
                <h1>3. Documentación de inspección</h1>
                <div className={ styles.VistaDocumentosContent }>
                    <div className={styles.DocumentacionContainer}>
                        <div className={styles.GrillaDocumentos}>
                            <CustomDetailsList
                                items={documentosInspeccionList? documentosInspeccionList:[]}
                                columns={[
                                    {
                                        key: 'columniconfileDocSuministro',
                                        name: 'iconfile',
                                        fieldName: 'iconfile',
                                        minWidth: 16,
                                        maxWidth: 16,
                                        iconName: 'Page',
                                        clickToSort: false,
                                    },
                                    {
                                      key: 'columnAccion',
                                      name: 'Más',
                                      fieldName: 'ID',
                                      minWidth: 40,
                                      maxWidth: 40,
                                      onRender: (item: IDocumentosInspeccion) => (<CustomIconButton id={`opciones_${item.ID}`} icon='More' label='Información' onClick={() => this._onOpenDoctoPanel(item.ID)} />)
                                    },
                                    {
                                        key: 'columnname',
                                        name: 'Nombre',
                                        fieldName: 'FileLeafRef',
                                        minWidth: 200,
                                        maxWidth: 350,
                                        isResizable: true,
                                        isRowHeader: true,
                                    },
                                    {
                                        key: 'columnTipoDocumento',
                                        name: 'Tipo documento',
                                        fieldName: 'DocumentosTipo',
                                        minWidth: 150,
                                        maxWidth: 150,
                                        onRender: (item: IDocumentosInspeccion) => item.DocumentosTipo.lookupValue,
                                        
                                    },
                                    {
                                        key: 'columnDetalle',
                                        name: 'Detalle documento',
                                        fieldName: 'Detalle',
                                        minWidth: 150,
                                        maxWidth: 150,
                                        
                                    },
                                    {
                                        key: 'columnDocumentoEstado',
                                        name: 'Estado',
                                        fieldName: 'DocumentosEstadoId',
                                        minWidth: 150,
                                        maxWidth: 150,
                                        isResizable: true,
                                        onRender: (item: IDocumentosInspeccion) => {
                                          switch(item.DocumentosEstadoId){
                                            case EnumEstadosDocumentosSuministros.EnRevision:
                                              return (<div className={containerClass.enRevision}><FontIcon iconName="ReadingMode" className={iconClass} /> En Revisión</div>);
                                            case EnumEstadosDocumentosSuministros.Aprobado:
                                              return (<div className={containerClass.aprobado}><FontIcon iconName="Accept" className={iconClass} /> Aprobado</div>);
                                            case EnumEstadosDocumentosSuministros.Rechazado:
                                              return (<div className={containerClass.rechazado}><FontIcon iconName="Cancel" className={iconClass} /> Rechazado</div>);
                                          }
                                        }
                                        
                                    },
                                    {
                                        key: 'columnResubirDocumento',
                                        name: 'Resubir Documento',
                                        fieldName: 'FileLeafRef',
                                        minWidth: 150,
                                        maxWidth: 150,
                                        isResizable: true,
                                        onRender: ({ DocumentosEstadoId, Detalle, DocumentosTipo, ID, FileLeafRef }) => {
                                            if (DocumentosEstadoId === 3) return <input type="file"             
                                            
                                            onChange={(File) => { 
            
                                                const documento = [File.target.files[0]];          
                                                const metadata  = [{
            
                                                    Detalle: Detalle,
                                                    DocumentosTipoId: DocumentosTipo.lookupId,
                                                    DocumentosEstadoId: 1,
                                                    FaseId: 1,
                                                    IdDocumento: ID,
                                                    key: undefined,
                                                    nombreDocumentoAntiguo: FileLeafRef,                                                  
                                                    }]
            
                                                this._onUploadFile(documento, [], metadata);
                                            
                                            }}                                           
                                            />           
                                            else return "No se puede resubir este documento."
                                    }                                     
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                
                <PanelDoctoInspeccion
                    onClose={() => this._onClosePanel}
                    onDescargarDocumento={this._onDownloadDocument}
                    onAddComentario={this._onAddComentarioInspeccion}
                    isOpen={isOpenPanel}
                    selectedDocument={selectedDocument}
                    comentariosList={comentariosInspeccionList}
                    loadingComentarios={loadingComentarios}
                    disabled={ itemInspeccion && itemInspeccion.EstadoInspeccionId !== EnumEstadoInspeccion.EnProceso }
                    usuarioProveedor={usuarioProveedorId}
                />
            </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VistaDoctosInpsecciones);