export const SIGNIN = 'APP/MAIN/REQUEST_SIGNIN';
export const PRESIGNINMS365 = 'APP/MAIN/PRESIGNINMS365';
export const RESPONSE_SIGNIN = 'APP/MAIN/RESPONSE_SIGNIN';
export const LOADING = 'APP/MAIN/LOADING';

export const SIGNOUT = 'APP/MAIN/SIGNOUT';

export const MESSAGEERROR = 'APP/MAIN/MESSAGEERROR';
export const CLEANMESSAGEERROR = 'APP/MAIN/CLEANMESSAGEERROR';
export const SHOWMESSAGEBAR = 'APP/MAIN/SHOWMESSAGEBAR';
export const HIDEMENSAGEBAR = 'APP/MAIN/HIDEMENSAGEBAR';
export const RESPONSE_RECOVERPASSWD = 'APP/MAIN/RESPONSE_RECOVERPASSWD';
