import { IComboBoxOption, Text } from 'office-ui-fabric-react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ApplicationState } from '../../config/store';
import { IInspeccionHomologacion } from '../ItemHomologacion/interfaces';
import { CommonComboBox } from '../Shared/CustomFields/ComboBoxField';
import { CustomDetailsList } from '../Shared/Detailslist/detailsList';
import { CustomIconButton } from '../Shared/IconButton';
import { Loading } from '../Shared/Loading/loading';
import { Separador } from '../Shared/Separador/separador';
import { EnumEstadoInspeccion } from '../utils/enums';
import { BuildEstadosInspeccion, ConvertSharepointDate } from '../utils/functions';
import { IUsuarioLogin } from '../utils/interfaces';
import { GrillaHomologacionesInspeccionActionCreators } from './actions';
import styles from './GrillaHomologacionInspeccion.module.scss';

interface IFilterInspeccion {
    estadoInspeccionId: EnumEstadoInspeccion;
}

const GrillaHomologacionInspeccion: React.FunctionComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user: IUsuarioLogin = useSelector((state:ApplicationState) => state.main.user);
    const inspeccionesList: IInspeccionHomologacion[] = useSelector((state:ApplicationState) => state.grillaHomologacionInspeccion.inspeccionesList);
    const loading: boolean = useSelector((state:ApplicationState) => state.grillaHomologacionInspeccion.loading);


    const [internalItems, setInternalItems] = React.useState<IInspeccionHomologacion[]>([]);
    const [optionsEstados, setOptionsEstados] = React.useState<IComboBoxOption[]>(BuildEstadosInspeccion());
    const [filterObject, setFilterObject] = React.useState<IFilterInspeccion>({
        estadoInspeccionId: EnumEstadoInspeccion.EnProceso,
    });
    
    React.useEffect(() => {
        dispatch(GrillaHomologacionesInspeccionActionCreators.handleLoading(true));
        dispatch(GrillaHomologacionesInspeccionActionCreators.getHomologacionesByProveedor(user.usuarioProveedor.proveedorInspector_x003a_RUT.lookupValue));
    },[user,dispatch]);

    
    React.useEffect(() => {
        if(inspeccionesList){
            setInternalItems(inspeccionesList.filter(i => i.EstadoId === filterObject.estadoInspeccionId));
        }
    },[filterObject,inspeccionesList]);

    function _onClickProcesarItem (item: IInspeccionHomologacion):void {
        history.push(`/inspeccionHomologacion/${item.UniqueId}`);
    }

    function _onChangeFilter( ev, option ):void{
        let filterObjectModified: IFilterInspeccion = undefined;
        let internalItems: IInspeccionHomologacion[] = [];
        const { id } = ev.target;
        switch(id){
            case 'estadoInspeccionId':
                filterObjectModified = { ...filterObject, estadoInspeccionId: option.key };
                break;
            default:
                filterObjectModified = { ...filterObject };
        }

        internalItems = inspeccionesList.filter( i => i.EstadoId === filterObjectModified.estadoInspeccionId );
        setInternalItems(internalItems);
        setFilterObject(filterObjectModified);
    }

    return(
        <div className={styles.GrillaHomologacionInspeccion}>
            {loading && (<Loading message='Cargando inspecciones de homologacion...' fullScreen />)}
            <Text variant='xxLarge'>Inspecciones de Homologación</Text>
            <Separador mensaje='Filtros' />
            <div className="ms-Grid" dir="ltr" style={{ textAlign: 'left' }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3"><CommonComboBox label='Estado inspecciones' id='estadoInspeccionId' onChange={_onChangeFilter} key='estadoInspeccionId' options={optionsEstados} selectedKey={filterObject.estadoInspeccionId} /></div>
                </div>
            </div>
            <Separador />
            {inspeccionesList && inspeccionesList.length > 0 && (
                <CustomDetailsList
                    columns={[
                        {
                            key: 'columnaAcciones',
                            name: 'Procesar',
                            fieldName: 'UniqueId',
                            iconName: 'Processing',
                            minWidth: 50,
                            maxWidth: 50,
                            isResizable: false,
                            clickToSort: false,
                            onRender: (item: IInspeccionHomologacion) => (<CustomIconButton icon='Processing' onClick={() => _onClickProcesarItem(item)} label='Procesar' id={`procesar_${item.UniqueId}`} />)
                        },
                        {
                            key: 'ID',
                            name: 'ID',
                            fieldName: 'ID',
                            minWidth: 140,
                            maxWidth: 140,
                        },
                        {
                            key: 'columnCodigo',
                            name: 'Código',
                            fieldName: 'Codigo',
                            minWidth: 140,
                            maxWidth: 140,
                        },
                        {
                            key: 'columnaTitle',
                            name: 'Descripción',
                            fieldName: 'Title',
                            minWidth: 200,
                            maxWidth: 300,
                            isResizable: true,
                            isRowHeader:true,
                        },
                        {
                            key: 'columnCreated',
                            name: 'Fecha creación',
                            fieldName: 'Created',
                            minWidth: 150,
                            maxWidth: 150,
                            onRender: (item: IInspeccionHomologacion) => ConvertSharepointDate(item.Created)
                        },
                        {
                            key: 'columnaFechaInicio',
                            name: 'Fecha estimada de inicio',
                            fieldName: 'FechaInicio',
                            minWidth: 150,
                            maxWidth: 150,
                            onRender: (item: IInspeccionHomologacion) => item.FechaInicio ? ConvertSharepointDate(item.FechaInicio) : 'Fecha no declarada',
                        },
                        {
                            key: 'columnaFechaTermino',
                            name: 'Fecha de término',
                            fieldName: 'FechaTermino',
                            minWidth: 150,
                            maxWidth: 150,
                            onRender: (item: IInspeccionHomologacion) => item.FechaTermino? ConvertSharepointDate(item.FechaTermino) : 'Fecha no declarada',
                        },
                        {
                            key: 'columnaEstado',
                            name: 'Estado',
                            fieldName: 'EstadoId',
                            minWidth: 150,
                            maxWidth: 150,
                            onRender: (item: IInspeccionHomologacion) => {
                                switch (item.EstadoId){
                                    case EnumEstadoInspeccion.Aprobada:
                                        return 'Aprobada';
                                    case EnumEstadoInspeccion.EnProceso:
                                        return 'En Proceso';
                                    case EnumEstadoInspeccion.Rechazada:
                                        return 'Rechazada';
                                }
                            }
                        }
                    ]}
                    items={internalItems}
                />
            )}
        </div>
    );
}

export default GrillaHomologacionInspeccion;
