import { Action, Reducer } from 'redux';
import {
    LOADING,
    GET_SUMINISTROSLIST,
    GET_ALLNOCONFORMIDADES
} from './constants';
import { GrillaSuministroKnownAction } from './actions';
import { IProcesoSuministro } from './interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface GrillaSuministrosState {
    listaSuministros?: IProcesoSuministro[];
    loading: boolean;
    loadingNoConformidades?: boolean;
    listaNoConformidades?: any;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const GrillaSuministrosReducer: Reducer<GrillaSuministrosState> = (state: GrillaSuministrosState | undefined, incomingAction: Action): GrillaSuministrosState => {
    if (state === undefined) {
        return {
            loading: false,
        };
    }
    const action = incomingAction as GrillaSuministroKnownAction;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading };
        case GET_SUMINISTROSLIST:
            return { ...state, listaSuministros: action.listaSuministros, loading: false, };
        case GET_ALLNOCONFORMIDADES:
            return {...state, listaNoConformidades: action.listaNoConformidades, loadingNoConformidades: false}
        default:
            return state;
    }
};
