import { Action, Reducer } from 'redux';
import {
    GET_HOMOLOGACIONITEM,
    GET_HOMOLOGACIONDOCTOSREQUERIDOS,
    GET_HOMOLOGACIONDOCUMENTOS,
    GET_HOMOLOGACIONETAPA,
    GET_HOMOLOGACIONDOCTOSTIPO,
    GET_INSPECCIONESLIST,

    LOADING,
    LOADING_MENU,
    LOADING_DOCTOSREQUERIDOS,
    LOADING_DOCUMENTOS,
    LOADING_DOCUMENTOSTIPO,
    LOADING_INSPECCIONESLIST,
    
    CLEAR_HOMOLOGACIONITEM,
    
    LOADING_COMENTARIOSHOMOLOGACION,
    GET_COMENTARIOSHOMOLOGACION,
    CLEAR_COMENTARIOSHOMOLOGACION,
} from './constants';
import { ItemHomologacionKnowActions } from './actions';
import { IHomologacionGeneral } from '../GrillaHomologacion/interfaces';
import { IDoctosRequeridoHomologacion, IDocumentoHomologacion, IDocumentosTipoHomologacion, IHomologacionEtapa, IInspeccionHomologacion } from './interfaces';
import { EnumHomologacionEtapa } from '../utils/enums';
import { IComentarioBase } from '../ItemSuministro/interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ItemHomologacionState {
    loading: boolean;
    loadingMenu: boolean;
    loadingDocumentos: boolean;
    loadingDoctosRequeridos: boolean;
    loadingDoctosTipo: boolean;
    loadingInspecciones: boolean;

    homologacionItem?: IHomologacionGeneral;
    etapasList?: IHomologacionEtapa[];

    doctosListReqTec?: IDocumentoHomologacion[];
    doctosListReqCal?: IDocumentoHomologacion[];
    doctosListReqEst?: IDocumentoHomologacion[];
    doctosListMuestra?: IDocumentoHomologacion[];

    doctosRequeridossListReqTec?: IDoctosRequeridoHomologacion[];
    doctosRequeridossListReqCal?: IDoctosRequeridoHomologacion[];
    doctosRequeridossListReqEst?: IDoctosRequeridoHomologacion[];

    doctosTipoListReqTec?: IDocumentosTipoHomologacion[];
    doctosTipoListReqCal?: IDocumentosTipoHomologacion[];
    doctosTipoListReqEst?: IDocumentosTipoHomologacion[];
    doctosTipoListMuestra?: IDocumentosTipoHomologacion[];

    inspeccionesList?: IInspeccionHomologacion[];

    loadingComentarios: boolean;
    ComentariosList?: IComentarioBase[];
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const ItemHomologacionReducer: Reducer<ItemHomologacionState> = (state: ItemHomologacionState | undefined, incomingAction: Action): ItemHomologacionState => {

    if (state === undefined) {
        return {
            loading: false,
            loadingMenu: false,
            loadingDocumentos: false,
            loadingDoctosRequeridos: false,
            loadingDoctosTipo: false,
            loadingInspecciones: false,
            loadingComentarios: false,
        };
    }

    const action = incomingAction as ItemHomologacionKnowActions;
    switch (action.type) {
        case CLEAR_HOMOLOGACIONITEM:
            return{
                ...state,
                homologacionItem: undefined,
                etapasList: undefined,
                doctosListReqTec: undefined,
                doctosListReqCal: undefined,
                doctosListReqEst: undefined,
                doctosListMuestra: undefined,
                doctosRequeridossListReqTec: undefined,
                doctosRequeridossListReqCal: undefined,
                doctosRequeridossListReqEst: undefined,
                doctosTipoListReqTec: undefined,
                doctosTipoListReqCal: undefined,
                doctosTipoListReqEst: undefined,
                doctosTipoListMuestra: undefined,
                inspeccionesList: undefined,
            };

        case LOADING:
            return { ...state, loading: action.loading };

        case GET_HOMOLOGACIONITEM:
            return { ...state, homologacionItem: action.itemHomologacion, loading: false };
        
        case LOADING_DOCTOSREQUERIDOS:
            return { ...state, loadingDoctosRequeridos: action.loading };
        case GET_HOMOLOGACIONDOCTOSREQUERIDOS:
            const doctosRequeridossListReqTec: IDoctosRequeridoHomologacion[] = action.doctosRequeridosList.filter( dr => dr.HomologacionEtapaId === EnumHomologacionEtapa.ReqTecnicos);
            const doctosRequeridossListReqCal: IDoctosRequeridoHomologacion[] = action.doctosRequeridosList.filter( dr => dr.HomologacionEtapaId === EnumHomologacionEtapa.ReqCalidad);
            const doctosRequeridossListReqEst: IDoctosRequeridoHomologacion[] = action.doctosRequeridosList.filter( dr => dr.HomologacionEtapaId === EnumHomologacionEtapa.ReqEstrategicos);
            return { ...state, doctosRequeridossListReqTec, doctosRequeridossListReqCal, doctosRequeridossListReqEst, loadingDoctosRequeridos: false };

        case LOADING_DOCUMENTOS:
            return { ...state, loadingDocumentos: false };
        case GET_HOMOLOGACIONDOCUMENTOS:
            const doctosListReqTec: IDocumentoHomologacion[] = action.documentosList.filter(d => d.EtapaId === EnumHomologacionEtapa.ReqTecnicos);
            const doctosListReqCal: IDocumentoHomologacion[] = action.documentosList.filter(d => d.EtapaId === EnumHomologacionEtapa.ReqCalidad);
            const doctosListReqEst: IDocumentoHomologacion[] = action.documentosList.filter(d => d.EtapaId === EnumHomologacionEtapa.ReqEstrategicos);
            const doctosListMuestra: IDocumentoHomologacion[] = action.documentosList.filter(d => d.EtapaId === EnumHomologacionEtapa.Muestra);
            return { ...state, doctosListReqTec, doctosListReqCal, doctosListReqEst, doctosListMuestra, loadingDocumentos: false };
        
        case LOADING_MENU:
            return { ...state, loadingMenu: action.loading};
        case GET_HOMOLOGACIONETAPA:
            return { ...state, etapasList: action.etapasList, loadingMenu: false };

        case LOADING_DOCUMENTOSTIPO:
            return { ...state, loadingDoctosTipo: action.loading };
        case GET_HOMOLOGACIONDOCTOSTIPO:
            const doctosTipoListReqTec: IDocumentosTipoHomologacion[] = action.doctosTipoList.filter(dt => dt.HomologacionEtapaId.includes(EnumHomologacionEtapa.ReqTecnicos));
            const doctosTipoListReqCal: IDocumentosTipoHomologacion[] = action.doctosTipoList.filter(dt => dt.HomologacionEtapaId.includes(EnumHomologacionEtapa.ReqCalidad));
            const doctosTipoListReqEst: IDocumentosTipoHomologacion[] = action.doctosTipoList.filter(dt => dt.HomologacionEtapaId.includes(EnumHomologacionEtapa.ReqEstrategicos));
            const doctosTipoListMuestra: IDocumentosTipoHomologacion[] = action.doctosTipoList.filter(dt => dt.HomologacionEtapaId.includes(EnumHomologacionEtapa.Muestra));
            return {...state, doctosTipoListReqTec, doctosTipoListReqCal, doctosTipoListReqEst, doctosTipoListMuestra, loadingDoctosTipo: false};

        case LOADING_INSPECCIONESLIST:
            return {...state, loadingInspecciones: action.loading };
        case GET_INSPECCIONESLIST:
            return {...state, loadingInspecciones: false, inspeccionesList: action.inspeccionesList };
        
        case LOADING_COMENTARIOSHOMOLOGACION:
            return {...state, loadingComentarios: action.loading };
        case GET_COMENTARIOSHOMOLOGACION:
            return {...state, loadingComentarios: false, ComentariosList: action.comentariosList };
        case CLEAR_COMENTARIOSHOMOLOGACION:
            return {...state, ComentariosList: undefined };

        default:
            return state;
    }
};
