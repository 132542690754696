import * as React from 'react';
import { Nav, INavLink, INavLinkGroup } from 'office-ui-fabric-react/lib/Nav';

export interface INavigationProps {
  parentElementId?: string;
  parentElementClass?: string;
  extraPixelsToScroll? : number;
  navigationDataList : INavLinkGroup[];
  defaultSelectedKey: string; 
}

const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
  const { parentElementId, parentElementClass, navigationDataList, extraPixelsToScroll } = props;
  const [ selectedKey, setSelectedKey ] = React.useState(props.defaultSelectedKey);

  const _onClickNavItem = (ev : any, item : INavLink) : void => {
    setSelectedKey(item.key);
    let isIdData : boolean = true;
    if(parentElementId && parentElementClass){
      return;
    } else if(parentElementClass){
      isIdData = false;
    }
    
    if (item.customPropNavItemId){
      let containerElementReference : string = parentElementClass;
      let containerObject : any;
      if (isIdData){
        containerElementReference = parentElementId;
        containerObject = document.getElementById(containerElementReference);
      } else {
        containerObject = document.getElementsByClassName(containerElementReference)[0];
      }
      containerObject.scrollTo(0, document.getElementById(item.customPropNavItemId).offsetTop - (extraPixelsToScroll ? extraPixelsToScroll : 0));
    }
  };  

  return (
    <Nav
      ariaLabel="Navegator cap" 
      groups={navigationDataList} onLinkClick={ _onClickNavItem }
      selectedKey={selectedKey}
    />
  );
};

export default Navigation;