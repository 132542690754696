import * as React from 'react';
import styles from './Accordion.module.scss';

interface IAccordionProps {
    children: any;
    id: string;
    multiple?: boolean;
}

//https://medium.com/@justynazet/passing-props-to-props-children-using-react-cloneelement-and-render-props-pattern-896da70b24f6
const Accordion: React.FunctionComponent <IAccordionProps> = (props) => {
    
    const [childOpen, setChildOpen] = React.useState<object>(undefined);
    React.useEffect(() => {
        if (props){
            let openObject: object = {};
            const childrenList = React.Children.toArray(props.children);
            for (let h of childrenList){
                openObject[`${props.id}.${h.props.id}`] = false;
            }
            setChildOpen(openObject)
        }
    },[props]);

    const childrenWithExtraProps = React.Children.map(props.children, child => {
        return React.cloneElement(child, {
            functionOpenCloseAccordionItem: (childAction: any) => {
                let hijosModified = { ...childOpen };
                if (props.multiple){
                    if(childAction.id === child.props.id){
                        hijosModified[`${props.id}.${child.props.id}`] = !hijosModified[`${props.id}.${child.props.id}`];
                    }
                } else {
                    if (childAction.id === child.props.id){
                        hijosModified[`${props.id}.${child.props.id}`] = !childOpen[`${props.id}.${child.props.id}`];
                    } else {
                        hijosModified[`${props.id}.${child.props.id}`] = false;
                    }
                }
                setChildOpen(hijosModified);
            },
            active: childOpen && childOpen[`${props.id}.${child.props.id}`],
        });
    });
    
    return (
        <div className={styles.AccordionContainer}>
            {childrenWithExtraProps}
        </div>
    );
}

export default Accordion;