import * as React from 'react';
import Dropzone, { FileError } from "react-dropzone";
import { CustomButton } from '../Button';
import { CustomIconButton } from '../IconButton';

import { CustomDetailsList, ICustomColumns } from '../Detailslist/detailsList';
import styles from './UploadFile.module.scss';
import { CleanTextEncode } from '../../utils/functions';

interface IUploadFileProps {
  onUploadFile: any;
  metadataList?: any[];
  metadataOptionsColumns?: ICustomColumns[];
  onChangeMetadata?: any;
  removeButton?: boolean;
}

interface IUploadFileStat {
  dragging: boolean;
  msg: string;
  files: any[];
  filesData: any[];
  columns: ICustomColumns[];
  isLoaded: boolean;
  showConfirmUpload: boolean;
}

export default class UploadFile extends React.Component<IUploadFileProps, IUploadFileStat> {

  constructor(props) {
    super(props);  
    this.state  = {
      isLoaded: false,
      dragging: false,
      showConfirmUpload: true,
      msg: "Arrastra uno o varios archivos o haz clíc para agregar",
      files: [],
      filesData: [],
      columns: [
        {
          key: 'columniconfileUploadTemp',
          name: 'iconfile',
          fieldName: 'iconfile',
          minWidth: 16,
          maxWidth: 16,
          iconName: 'Page',
          clickToSort: false,
        },
        {
          key: 'columnactions',
          name: 'Acciones',
          fieldName: 'actions',
          minWidth: 50,
          maxWidth: 50,
          onRender: item => (
            <CustomIconButton id={`${item.path}_button`} label='Eliminar' icon='Delete' onClick={() => this._onDeleteItemAction(item.key)} />
          ),
          clickToSort: false,
        },
        {
          key: 'columnname',
          name: 'Nombre',
          fieldName: 'name',
          minWidth: 200,
          maxWidth: 200,
        },
        {
          key: 'columnSize',
          name: 'Tamaño',
          fieldName: 'size',
          minWidth: 120,
          maxWidth: 120,
          onRender: (item) => (item.size/1024).toFixed(2).concat(' KB'),
        },
      ],
    };
  }

  public componentDidMount() {
    const { metadataOptionsColumns } = this.props;
    const { columns } = this.state;
    let displayColumns: ICustomColumns[] = columns;

    if ( metadataOptionsColumns && metadataOptionsColumns.length > 0 ){
      for (let mc in metadataOptionsColumns){
        displayColumns.push({...metadataOptionsColumns[mc]});
      }
    }
    
    this.setState({
      columns: displayColumns,
      isLoaded: true,
    });
  }

  public componentDidUpdate(prevProps: Readonly<IUploadFileProps>, prevState: Readonly<IUploadFileStat>, snapshot?: any): void {
    const { removeButton, onUploadFile } = this.props;
    const { isLoaded, filesData , files} = this.state;
    if(removeButton && !isLoaded){
      onUploadFile(files, filesData);
      this.setState({
        isLoaded: true
      });
    }
    
  }
  private handleOnDrop = (accepted, rejected, ev) => {
    //console.log("Dropped in zone...", accepted, rejected, ev);
    const { files, filesData } = this.state;
    
    let acceptedFiles = [];
    let count:number = files.length;
    for(let a in accepted){
      const { path, name, size, type, lastModified, lasModifiedDate } = accepted[a];
      acceptedFiles.push({ key: btoa(`${lastModified}P${CleanTextEncode(path)}R${Math.random()}`), path, name, size, type, lastModified, lasModifiedDate, });
      count+=1;
    }
    this.setState({
      dragging: false,
      msg: "Dropped in zone...",
      files: files.concat(accepted),
      filesData: filesData.concat(acceptedFiles),
    });
    if (this.props.removeButton) {
      this._prepareUploadFile();
    }
  }

  private handleOnDropAccepted = (accepted) => {
    //console.log("ACCEPTED...", accepted);
    this.setState({
      dragging: false,
      msg: "Arrastra uno o varios archivos o haz clíc para agregar",
    });
  }

  private handleOnDropRejected = (rejected) => {
    //console.log("REJECTED...", rejected);
    this.setState({
      dragging: false,
      msg: "Arrastra uno o varios archivos o haz clíc para agregar",
    });
  }

  private handleOnDragEnter = (ev) => {
    //console.log("ENTER...", ev);
    this.setState({
      dragging: true,
      msg: "Suelte el/los archivos seleccionados"
    });
  }

  private handleOnDragLeave = (ev) => {
    //console.log("LEAVE...", ev);
    this.setState({
      dragging: false,
      msg: "Arrastra uno o varios archivos o haz clíc para agregar",
    });
  }

  /* private handleGetFiles = (ev) : Promise<File[]> => {
    console.log('getFiles', ev);
    return ev.dataTransfer ? ev.dataTransfer.files : ev.target.files;
  }*/

  private handleValidator = (file) : Array<FileError> | undefined => {
    //console.log("Validation...", file);
    // aquí van las validaciones de las reglas de negocio
    return undefined;
/*    return [{
      message: 'mensaje de horroooor!',
      code: 'file-invalid-type',
      //code: "file-too-large" | "file-too-small" | "too-many-files" | "file-invalid-type" | string;
    }];*/
  }

  private _disabledUpload = ():boolean => {
    const { metadataList, metadataOptionsColumns } = this.props;
    const { filesData } = this.state;

    if ( filesData.length === 0 ){ return true; }

    if ( metadataOptionsColumns && metadataOptionsColumns.length > 0 ) {
      if ( metadataList && metadataList.length === 0){
        return true;
      } else {
        for (let f =0; filesData.length>f ; f+=1){
          const metadataObject = metadataList.find( m => m.key === filesData[f].key);
          if(metadataObject){
            let keys: string[] = Object.keys(metadataObject);
            for (let o=0; o<metadataOptionsColumns.length; o+=1){
              for ( let k=0; k<keys.length; k+=1 ){
                if (metadataOptionsColumns[o].fieldName === keys[k]){
                  if (metadataObject[keys[k]] !== null){
                    switch(typeof metadataObject[keys[k]]){
                      case 'string':
                        if (metadataObject[keys[k]].trim() === '')
                          return true;
                        break;
                      case 'number':
                        if(metadataObject[keys[k]] <= 0)
                          return true;
                        break;
                    }
                  } else {
                    return true;
                  }
                }
              }
            }
          } else {
            return true;
          }
        }
      }
    }

    return false;
  }

  public render(): React.ReactElement<IUploadFileProps> {
    const { msg, dragging, columns, isLoaded, filesData } = this.state;
    const { removeButton } = this.props;
    return (
      <div className={styles.uploadFileContent}>
        <Dropzone
          onDrop={this.handleOnDrop}
          onDragEnter={this.handleOnDragEnter}
          onDragLeave={this.handleOnDragLeave}
          onDropAccepted={this.handleOnDropAccepted}
          onDropRejected={this.handleOnDropRejected}
          validator={this.handleValidator}
          >
          {({ getRootProps, getInputProps }) => (
            <section className={styles.dropZoneBox}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p
                  className={
                    dragging
                    ? styles.over
                    : styles.normal
                  }
                  >
                  <span>{msg}</span>
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        { isLoaded && filesData.length > 0 && (
            <div className={styles.tempList}>
              <CustomDetailsList
                columns={columns}
                items={filesData}
              />
            </div>
          )}
        <div className={styles.buttonBox}>
          {!removeButton && filesData.length > 0 && (<CustomButton disabled={this._disabledUpload()} id='uploadFile' label='Subir archivos' icon='CloudUpload' onClick={this._prepareUploadFile} />)}
        </div>
      </div>
    );
  }

  private _prepareUploadFile = () => {
    const { files, filesData } = this.state;
    const { onUploadFile, metadataList, removeButton } = this.props;
    if (metadataList && metadataList.length > 0){
      onUploadFile(files, filesData, metadataList);
    } else {
      onUploadFile(files, filesData);
    }

    if(!removeButton){
      this.setState({
        files: [],
        filesData: [],
      });
    }
    
  }
  
  private _onDeleteItemAction = (selectedKey : string) => {
    const { files, filesData } = this.state;
    let indice = filesData.map(f => f.key).indexOf(selectedKey);

    let filesModified = [];

    for (let f in files){
      if ( indice !== parseInt(f) ){
        filesModified.push(files[f]);
      }
    }

    this.setState({
      files: filesModified,
      filesData : filesData.filter(f => f.key !== selectedKey),
      isLoaded: false
    });
  }

}

