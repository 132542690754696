import * as React from 'react';
import { IconButton, IIconProps } from 'office-ui-fabric-react';
import { TooltipHost, ITooltipHostStyles } from 'office-ui-fabric-react/lib/Tooltip';
import { useId } from '@uifabric/react-hooks';

export interface IButtonExampleProps {
    // These are set based on the toggles shown above the examples (not needed in real code)
    disabled?: boolean;
    icon: string;
    onClick: any;
    label: string;
    id: string;
    style?: object;
    clasName?: string;
}
const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', marginRight: 5 } };

export const CustomIconButton: React.FunctionComponent<IButtonExampleProps> = props => {

    const { disabled, id, icon, onClick, label, style, clasName } = props;
    const idiconbutton = useId(id);
    const iconButton: IIconProps = { iconName: icon };


    return (
        <TooltipHost
            content={label}
            id={`tooltip_${idiconbutton}`}
            calloutProps={calloutProps}
            styles={hostStyles}
        >
            <IconButton id={`button_${idiconbutton}`} onClick={onClick} className={clasName} iconProps={iconButton} title={label} ariaLabel={label} disabled={disabled} style={style} />
        </TooltipHost>
    );
};

CustomIconButton.defaultProps = {
    disabled: false,
    style: {},
}