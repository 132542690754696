import {
    GET_HOMOLOGACIONITEM,
    GET_HOMOLOGACIONDOCTOSREQUERIDOS,
    GET_HOMOLOGACIONDOCUMENTOS,
    GET_HOMOLOGACIONETAPA,
    GET_HOMOLOGACIONDOCTOSTIPO,
    GET_INSPECCIONESLIST,

    LOADING,
    LOADING_MENU,
    LOADING_DOCTOSREQUERIDOS,
    LOADING_DOCUMENTOS,
    LOADING_DOCUMENTOSTIPO,
    LOADING_INSPECCIONESLIST,

    CLEAR_HOMOLOGACIONITEM,

    LOADING_COMENTARIOSHOMOLOGACION,
    GET_COMENTARIOSHOMOLOGACION,
    CLEAR_COMENTARIOSHOMOLOGACION,
} from './constants';
import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions'

import apiConfig from '../../config/apiConfigOficial';
import { IEtapaEstadoHomologacion, IHomologacionGeneral } from '../GrillaHomologacion/interfaces';
import { IDoctosRequeridoHomologacion, IDocumentoHomologacion, IDocumentosInspeccionHomologacion, IDocumentosTipoHomologacion, IHomologacionEtapa, IInspeccionHomologacion, IMetadataDoctoHomologacion } from './interfaces';
import { EnumEstadosDocumentosSuministros } from '../utils/enums';
import { IComentarioBase } from '../ItemSuministro/interfaces';

// Decalaraciones de las acciones
interface GetHomologacionItemAction { type: typeof GET_HOMOLOGACIONITEM; itemHomologacion: IHomologacionGeneral; }
interface GetHomologacionDocumentosListAction { type: typeof GET_HOMOLOGACIONDOCUMENTOS; documentosList: IDocumentoHomologacion[]; }
interface GetHomologacionDoctosRequeridosAction { type: typeof GET_HOMOLOGACIONDOCTOSREQUERIDOS; doctosRequeridosList: IDoctosRequeridoHomologacion[]; }
interface GetHomologacionEtapaListAction { type: typeof GET_HOMOLOGACIONETAPA; etapasList: IHomologacionEtapa[]; }
interface GetDoctosTipoHomologacionAction { type: typeof GET_HOMOLOGACIONDOCTOSTIPO; doctosTipoList: IDocumentosTipoHomologacion[]; }
interface GetInspeccionesListAction { type: typeof GET_INSPECCIONESLIST; inspeccionesList: IInspeccionHomologacion[]; }

interface HandleLoadingAction { type: typeof LOADING; loading: boolean; }
interface HandleLoadingMenuAction { type: typeof LOADING_MENU; loading: boolean; }
interface HandleLoadingDoctosRequeridosAction { type: typeof LOADING_DOCTOSREQUERIDOS; loading: boolean; }
interface HandleLoadingDocumentosAction { type: typeof LOADING_DOCUMENTOS; loading: boolean; }
interface HandleLoadingDocumentosTipoAction { type: typeof LOADING_DOCUMENTOSTIPO; loading: boolean; }
interface HandleLoadingInspeccionesListAction { type: typeof LOADING_INSPECCIONESLIST; loading: boolean; }

interface ClearHomologacionItemAction { type: typeof CLEAR_HOMOLOGACIONITEM; }

interface HandleLoadingComentariosAction { type: typeof LOADING_COMENTARIOSHOMOLOGACION; loading: boolean; }
interface GetHomologacionComentariosAction { type: typeof GET_COMENTARIOSHOMOLOGACION; comentariosList: IComentarioBase[]; }
interface ClearHomologacionComentariosAction { type: typeof CLEAR_COMENTARIOSHOMOLOGACION; }

// lista de acciones conocidas
export type ItemHomologacionKnowActions =
    MainKnownAction
|   GetHomologacionItemAction
|   GetHomologacionDocumentosListAction
|   GetHomologacionDoctosRequeridosAction
|   GetHomologacionEtapaListAction
|   GetDoctosTipoHomologacionAction
|   GetInspeccionesListAction
|   HandleLoadingAction
|   HandleLoadingMenuAction
|   HandleLoadingDoctosRequeridosAction
|   HandleLoadingDocumentosAction
|   HandleLoadingDocumentosTipoAction
|   HandleLoadingInspeccionesListAction
|   ClearHomologacionItemAction
|   HandleLoadingComentariosAction
|   GetHomologacionComentariosAction
|   ClearHomologacionComentariosAction
;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

export const ItemHomologacionesActionCreators = {
    clearHomologacionItem: () => ({type: CLEAR_HOMOLOGACIONITEM} as ClearHomologacionItemAction),

    handleLoading: (loading) => ({ type: LOADING, loading } as HandleLoadingAction),
    getHomologacionItem: (homologacionId: string): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        const response: Promise<IHomologacionGeneral> = PromiseGetHomologacionGeneralById(homologacionId);
        response.then( (data) => {
            dispatch({
                type: GET_HOMOLOGACIONITEM,
                itemHomologacion: {
                    ...data,
                    EstadoId: data.Estado && data.Estado.lookupId,
                    NombreProveedorId: data.NombreProveedor && data.NombreProveedor.lookupId,
                    ResponsableFactibilidadId: data.ResponsableFactibilidad && data.ResponsableFactibilidad.lookupId,
                    SolicitanteHomologacionId: data.SolicitanteHomologacion && data.SolicitanteHomologacion.lookupId,
                    GestorCalidadHomologacionId: data.GestorCalidadHomologacion && data.GestorCalidadHomologacion.map(gc => Number(gc.lookupId)),
                    GestorTecnicoHomologacionId: data.GestorTecnicoHomologacion && data.GestorTecnicoHomologacion.map(gt => Number(gt.lookupId)),
                    SolicitanteHomologacionProveedorId: data.SolicitanteHomologacionProveedor && data.SolicitanteHomologacionProveedor.lookupId,
                }
            });
        }).catch( err => dispatch(MainActionCreators.onShowMessageBar(err.toString(),'error')));
    },

    handleDocumentosHomologacionLoading: (loading) => ({ type: LOADING_DOCUMENTOS, loading } as HandleLoadingDocumentosAction),
    getDocumentosHomologacion: (homologacionId: number): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetHomologacionDocumentos`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(homologacionId),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoHomologacion[]>;
            })
            .then(data => {
                dispatch({
                    type: GET_HOMOLOGACIONDOCUMENTOS,
                    documentosList: data.map(d => ({
                        ...d,
                        key: String(d.ID),
                        EtapaId: d.Etapa && Number(d.Etapa.lookupId),
                        EstadoId: d.Estado && Number(d.Estado.lookupId),
                        DocumentosTipoId: d.DocumentosTipo && Number(d.DocumentosTipo.lookupId),
                        CodigoHomologacionId: d.CodigoHomologacion && Number(d.CodigoHomologacion.lookupId),
                        DocumentoRequeridoId: d.DocumentoRequerido && Number(d.DocumentoRequerido.lookupId),
                    }))
                });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    handleDoctosRequeridosHomologacionLoading: (loading) => ({ type: LOADING_DOCTOSREQUERIDOS, loading } as HandleLoadingDoctosRequeridosAction),
    getDoctosRequeridosHomologacion: (homologacionId: number): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetHomologacionDoctosRequeridos`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(homologacionId),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDoctosRequeridoHomologacion[]>;
            })
            .then(data => {
                dispatch({
                    type: GET_HOMOLOGACIONDOCTOSREQUERIDOS,
                    doctosRequeridosList: data.map( d => ({
                        ...d,
                        key: String(d.ID),
                        DocumentosTipoId: d.DocumentosTipo && Number(d.DocumentosTipo.lookupId),
                        HomologacionEtapaId: d.HomologacionEtapa && Number(d.HomologacionEtapa.lookupId),
                        HomologacionGeneralId: d.HomologacionGeneral && Number(d.HomologacionGeneral.lookupId),
                    }))
                });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    handleMenuLoading: (loading: boolean) => ({type: LOADING_MENU, loading} as HandleLoadingMenuAction),
    getHomologacionEtapas: (vendedorAnterior: boolean): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetHomologacionEtapas`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(vendedorAnterior),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IHomologacionEtapa[]>;
            })
            .then(data => {
                dispatch({  type: GET_HOMOLOGACIONETAPA, etapasList: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    uploadDocumentoHomologacion: (files: File[], metadataList: IMetadataDoctoHomologacion[], codigoHomologacionId: number, CantidadDoctosRequeridos, crearDoctoRequerido: boolean = false): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        
        let formData = new FormData();

        for (let f = 0 ; f < files.length ; f += 1) {

            let doctoRequeridoId: any = String(metadataList[f].CodigoDoctoRequeridoId)

            // if (crearDoctoRequerido) {

            //     const jsonObj = {
            //         NombreDocumento: metadataList[f].Detalle,
            //         HomologacionGeneralId: codigoHomologacionId,
            //         HomologacionEtapaId: metadataList[f].EtapaId,
            //         DocumentosTipoId: metadataList[f].DocumentosTipoId
            //     }

            //     const options = {                
            //         method: 'POST',                   
            //         headers: defaultHeaders(),                    
            //         body: JSON.stringify(jsonObj),
                
            //     }
    
            //     const response = await fetch(`${apiConfig.apiUri}/Homologacion/CreateDocumentoRequeridoHomologacion`, options)
    
            //     const id = await response.json()

            //     doctoRequeridoId = String(id)
    
            // }

            //Acá falta validar el Id del documento
            // Si necesita doctorequerido, id 0, si no, el id de sharepoint.
            
            formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.ShpIdDocumento`, String(metadataList[f].idSubido));
            
			formData.append(`documentoHomologacionList[${f}].File`, files[f], files[f].name );
            
			formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.DsgCodigoHomologacion`, String(codigoHomologacionId));
            
			formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.DsgDocumentosTipoId`, String(metadataList[f].DocumentosTipoId));
            
			formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.DsgDocumentosEstadoId`, String(EnumEstadosDocumentosSuministros.EnRevision));
            
			formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.DsgDocumentoRequeridoId`, doctoRequeridoId);
            
			formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.DsgHomologacionEtapaId`, String(metadataList[f].EtapaId));
            
			formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.DsgDetalleDocumento`, metadataList[f].Detalle);
            
			formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.HlpCantidadDoctosRequeridos`, CantidadDoctosRequeridos);

            formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.CreaDoctoRequerido`, String(crearDoctoRequerido));

            formData.append(`documentoHomologacionList[${f}].DocumentoHomologacionMetadata.DsgEmailSolicitante`, String(metadataList[f].EmailSolicitante));

        
		}

		const options = {
			
            method: 'POST',
            
			headers: { Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}` },
            
			body: formData,
        
		}

        fetch(`${apiConfig.apiUri}/Homologacion/UploadDocumentoHomologacion`, options)

        .then(response => {

            if (!response.ok) {

                response.json().then( json => {
					
                    dispatch(MainActionCreators.onShowMessageBar(`El archivo ya existe o el tipo no corresponde al ya existente.`,'error'));
					dispatch(ItemHomologacionesActionCreators.handleDoctosRequeridosHomologacionLoading(false));
					dispatch(ItemHomologacionesActionCreators.handleDocumentosHomologacionLoading(false));
                
				});

            } else {

                dispatch(MainActionCreators.onShowMessageBar(`Carga correcta de archivos.`,'success'));
				dispatch(ItemHomologacionesActionCreators.getDocumentosHomologacion(codigoHomologacionId));
				dispatch(ItemHomologacionesActionCreators.getDoctosRequeridosHomologacion(codigoHomologacionId));
            
			}

        });

    },
    
    handleDocumentosTipoLoading: (loading: boolean) => ({type: LOADING_DOCUMENTOSTIPO, loading} as HandleLoadingDocumentosTipoAction),
    getDocumentosTipoHomologacion:  (): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetHomologacionDoctosTipo`, {
            method: 'POST',
            headers: defaultHeaders(),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentosTipoHomologacion[]>;
            })
            .then(data => {
                dispatch({ 
                    type: GET_HOMOLOGACIONDOCTOSTIPO,
                    doctosTipoList: data.map( d => ({
                        ...d,
                        HomologacionEtapaId: d.HomologacionEtapa.map(i => i.lookupId)
                    }))
                });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    handleLoadingInspecciones: (loading:boolean) => ({ type: LOADING_INSPECCIONESLIST, loading} as HandleLoadingInspeccionesListAction),
    getInspeccionesList: (homologacionId: number): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        const promiseGetInspecciones: Promise<IInspeccionHomologacion[]> = PromiseGetInspeccionesList(homologacionId);
        promiseGetInspecciones.then( data => {
            dispatch({type: GET_INSPECCIONESLIST, inspeccionesList: data});
        });
    },


    /*Comentarios */
    handleLoadingComentarios: (loading) => ({ type: LOADING_COMENTARIOSHOMOLOGACION, loading } as HandleLoadingComentariosAction),
    clearComentariosDocumento: () => ({ type: CLEAR_COMENTARIOSHOMOLOGACION } as ClearHomologacionComentariosAction),
    getComentariosDocumentoById: (documentoId: number): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetComentariosDocumentoHomologacion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(documentoId),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IComentarioBase[]>;
            })
            .then(data => {
                dispatch({ type: GET_COMENTARIOSHOMOLOGACION, comentariosList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error'));
        });
    },
    addComentarioDocumento: (comentario: IComentarioBase): AppThunkAction<ItemHomologacionKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/AddComentarioDocumentoHomologacion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(comentario),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                else {
                    dispatch(ItemHomologacionesActionCreators.getComentariosDocumentoById(comentario.DocumentosHomologacionId));
                }
            })
            .catch(error => {
                dispatch(ItemHomologacionesActionCreators.handleLoadingComentarios(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error'));
        });
    },
};

/**
 * Promesas de homologacion con 2 apis
 * @param homologacionId 
 * @returns 
 */
async function PromiseGetHomologacionGeneralById (homologacionId: string): Promise<IHomologacionGeneral> {
	return new Promise<IHomologacionGeneral>( async (resolve, reject) => {
        
        const responseGetItem = await fetch(`${apiConfig.apiUri}/Homologacion/GetHomologacionItem`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(homologacionId),
        });

        if (!responseGetItem.ok){
            reject(responseGetItem.status.toString());
            return;
        }

        const itemHomologacion: IHomologacionGeneral = await responseGetItem.json();

        const responseGetEstadoEtapa = await fetch(`${apiConfig.apiUri}/Homologacion/GetEtapasHomologacionItem`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(itemHomologacion.ID),
        });

        if (!responseGetEstadoEtapa.ok){
            reject(responseGetEstadoEtapa.status.toString());
            return;
        }

        const EtapaEstado: IEtapaEstadoHomologacion = await responseGetEstadoEtapa.json();

        resolve({
            ...itemHomologacion,
            EtapaEstado : {
                ...EtapaEstado,
                etapaMuestraId: EtapaEstado.etapaMuestra && EtapaEstado.etapaMuestra.lookupId,
                etapaInspeccionId: EtapaEstado.etapaInspeccion && EtapaEstado.etapaInspeccion.lookupId,
                etapaFactibilidadId: EtapaEstado.etapaFactibilidad && EtapaEstado.etapaFactibilidad.lookupId,
                CodigoHomologacionId: EtapaEstado.CodigoHomologacion && EtapaEstado.CodigoHomologacion.lookupId,
            }
        });

	});
}


async function PromiseGetInspeccionesList (homologacionId: number): Promise<IInspeccionHomologacion[]> {
	return new Promise<IInspeccionHomologacion[]>( async (resolve, reject) => {
        const responseGetItem = await fetch(`${apiConfig.apiUri}/Homologacion/GetInspeccionesHomologacionByHomologacion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(homologacionId),
        });

        if (!responseGetItem.ok){
            reject(responseGetItem.status.toString());
            return;
        }
        
        const InspeccionesList: IInspeccionHomologacion[] = await responseGetItem.json();

        let DocumentosListJson: IDocumentosInspeccionHomologacion[] = [];
        if (InspeccionesList.length > 0) {
            const inspeccionesIdList: number[] = InspeccionesList.map( i => i.ID);
            const responseGetDocumentos = await fetch(`${apiConfig.apiUri}/Homologacion/GetDocumentosInspeccionesHomologacion`, {
                method: 'POST',
                headers: defaultHeaders(),
                body: JSON.stringify(inspeccionesIdList),
            });
    
            if (!responseGetDocumentos.ok){
                reject(responseGetDocumentos.status.toString());
                return;
            }
    
            DocumentosListJson = await responseGetDocumentos.json();
        }

        const DoctosList: IDocumentosInspeccionHomologacion[] = DocumentosListJson.map( d => ({
            ...d,
            HomologacionInspeccionId: d.HomologacionInspeccion.lookupId,
        }));

       
        resolve(InspeccionesList.map(i => ({
            ...i,
            EstadoId: i.Estado.lookupId,
            HomologacionGeneralId: i.HomologacionGeneral.lookupId,
            ProveedorId: i.Proveedor.lookupId,
            DocumentosList: DoctosList.filter( d => d.HomologacionInspeccionId === i.ID )
        })));

    });
}
