export const LOADING = 'APP/ITEMINSPECCION/LOADING';
export const GET_ITEMINSPECCION = 'APP/ITEMINSPECCION/GET_ITEMINSPECCION';
export const GET_DOCUMENTOSINSPECCION = 'APP/ITEMINSPECCION/GET_DOCUMENTOSINSPECCION';
export const GET_DOCUMENTOSTIPOINSPECCION = 'APP/ITEMINSPECCION/GET_DOCUMENTOSTIPOINSPECCION';
export const LOADING_DOCUMENTOSINSPECCION = 'APP/ITEMINSPECCION/LOADING_DOCUMENTOSINSPECCION';

export const GET_DOCUMENTOSSUMINISTROSPROVEEDOR = 'APP/ITEMINSPECCION/GET_DOCUMENTOSSUMINISTROSPROVEEDOR';
export const GET_DOCUMENTOSSUMINISTROSINSPECTOR = 'APP/ITEMINSPECCION/GET_DOCUMENTOSSUMINISTROSINSPECTOR';

export const LOADING_COMENTARIOSDOCTOINSPECCION = 'APP/ITEMINSPECCION/LOADING_COMENTARIOSDOCTOINSPECCION';
export const GET_COMENTARIOSDOCTOSINSPECCION = 'APP/ITEMINSPECCION/GET_COMENTARIOSDOCTOSINSPECCION';
export const CLEAR_COMENTARIOSDOCTOINSPECCION = 'APP/ITEMINSPECCION/CLEAR_COMENTARIOSDOCTOINSPECCION';

export const CLEAR_ITEMINSPECCION = 'APP/ITEMINSPECCION/CLEAR_ITEMINSPECCION';


export const GET_DOCUMENTOSANTECEDENTESPROVEEDOR = 'APP/ITEMINSPECCION/GET_DOCUMENTOSANTECEDENTESPROVEEDOR';
export const LOADING_DOCTOSANTECEDENTESPROVEEDOR = 'APP/ITEMINSPECCION/LOADING_DOCTOSANTECEDENTESPROVEEDOR';