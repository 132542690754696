import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';

import { MainActionCreators } from '../Main/actions';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { CustomTextField } from '../Shared/CustomFields/TextField';
import { CustomButton } from '../Shared/Button';
import { IFusionAuthInfo, ILocalLogin } from '../utils/interfaces';
import ApiConfig from '../../config/apiConfigOficial';
import { msalAuth } from '../../config/auth/AuthConfig';
import logo from '../../img/newLogo.jpg';
import * as MasterFunctions from '../../components/utils/functions';
import { IDataParams } from '../../components/utils/interfaces';

import { Loading } from '../Shared/Loading/loading';

//import styles from './Login.module.scss';
import { Separator } from 'office-ui-fabric-react/lib-commonjs/Separator';
import { MainState } from '../Main/reducer';

interface ILoginOwnProps {
}

interface ILoginState {
    loginObject: ILocalLogin;
    data: boolean;
};

interface IConnectedState {
    main: any;    
}  

function mapStateToProps(state: ApplicationState, ownProps: ILoginOwnProps): IConnectedState {
	return {
        main: state.main
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

// define combined props
type ILoginProps =  ILoginOwnProps & IConnectedState &{ dispatch(action: any): void };

class FAuthCodeByToken extends React.PureComponent<ILoginProps, ILoginState> {

    constructor(props: ILoginProps) {
        super(props);
        this.state = {
            loginObject: {
                usuario: '',
                password: '',
            },
            data: false
        }
    }

    public componentDidMount(): void {
        this.processRequestData();
    }

    public processRequestData() {
        let dataFromUrl = MasterFunctions.parseParamsDataFromURL(window.location.search);
        if(dataFromUrl !== null && dataFromUrl !== undefined){
            const { dispatch } = this.props;
            
            let fusionAuthCodeInfo : IFusionAuthInfo = {
                code : dataFromUrl[0].value,
                locale : dataFromUrl[0].value,
                userState : null

            }
            dispatch(MainActionCreators.signInFusionAuth(fusionAuthCodeInfo));
        }
    }    //PassChange

    public render() {
        const { messageError } = this.props.main;
        return (            
            <Loading fullScreen message='Cargando, por favor espere...' />           
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FAuthCodeByToken);