import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { Loading } from '../Shared/Loading/loading';
import { MenuEtapas, IEtapasProceso } from '../Shared/MenuEtapas/menuEtapas';
import { ItemSuministroActionCreators } from './actions';
import { IEtapasSuministro, IProcesoSuministro } from './interfaces';

import Antecedentes from './internalComponents/Antecedentes';
import Entregables from './internalComponents/Entregables';
import ProtocolosPruebas from './internalComponents/ProtocolosPruebas';
import DocNacInt from './internalComponents/DocNacInt';

import styles from './ItemSuministro.module.scss';
import { Separador } from '../Shared/Separador/separador';
import { IUsuarioLogin } from '../utils/interfaces';
import Recepcion from './internalComponents/Recepcion';

interface IConnectedState {
    loading: boolean;
    itemSuministro: IProcesoSuministro;
    etapasSuministro: IEtapasSuministro[];
    etapaActual: number;
    etapasProceso: IEtapasProceso[];
    user: IUsuarioLogin;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {

	return {
        loading: state.itemSuministro.loading,
        itemSuministro: state.itemSuministro.itemSuministro,
        etapasSuministro: state.itemSuministro.etapasSuministro,
        etapaActual: state.itemSuministro.etapaActual,
        etapasProceso: state.itemSuministro.etapasProceso,
        user: state.main.user,
	};

}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});


interface IItemSuministroOwnsProps {
    match: any;
    history: any;
}

interface IItemSuministroState {
    isLoaded: boolean;
    porcentajeAvance: number;
    cerrado: boolean;
}

// define combined props
type IItemSuministroProps = IItemSuministroOwnsProps & IConnectedState & { dispatch(action: any): void };

class ItemSuministro extends React.PureComponent<IItemSuministroProps, IItemSuministroState> {

    constructor(props: IItemSuministroProps) {

        super(props);

        this.state = {
            isLoaded: false,
            porcentajeAvance: 0,
            cerrado: false
        }


    }

    public componentDidMount() {

        const { params } = this.props.match;

        const { history } = this.props;

        this.props.dispatch(ItemSuministroActionCreators.handleLoading(true));

        if (params.idsuministro){
            this.props.dispatch(ItemSuministroActionCreators.getEtapasSuministro());
            this.props.dispatch(ItemSuministroActionCreators.getItemSuministroById(params.idsuministro));
        } else {
            history.push('/notfound');
        }

    }
    
    public componentDidUpdate() {

        const { itemSuministro, etapasSuministro } = this.props;

        const { isLoaded } = this.state;

        if (!isLoaded && itemSuministro && etapasSuministro) {

            const etapaActual: number = itemSuministro.EtapaSuministroId;
            const etapas: IEtapasProceso[] = etapasSuministro.map( e => ({
                Nombre: e.Title,
                Orden: e.ID,
                Complete: etapaActual > e.ID,
                Active: etapaActual === e.ID
            }));

            // dibujo las etapas nuevas, dependiendo la sig. configuración.
            const etapasProveedor = [
                [1,2], // antecedentes
                [3], // entregables
                [4,5], // protocolos
                [6], // nacionalización
                [7,8,9] // recepción
            ];

            this.props.dispatch(ItemSuministroActionCreators.setEtapasSuministros(etapas, etapasProveedor.map((c, i) => { return c.includes(etapaActual) ? i+1 : -1 }).filter( ex => ex !== -1)[0]));
            
            if (itemSuministro.EtapaSuministro.lookupValue === "Cerrado") this.setState({ isLoaded: true, cerrado: true })

            switch(etapaActual) {

                case 1: case 2: case 8: case 9: 
                    this.setState({ isLoaded: true, porcentajeAvance: 0})
                    break

                case 3: 
                    this.setState({ isLoaded: true, porcentajeAvance: 25})
                    break

                case 4: case 5: 
                    this.setState({ isLoaded: true, porcentajeAvance: 50})
                    break

                case 6: case 7: 
                    this.setState({ isLoaded: true, porcentajeAvance: 75})
                    break

                default:
                    this.setState({ isLoaded: true, porcentajeAvance: 0})
                    break

            }

            if(itemSuministro.EtapaSuministro.lookupId === 8 || itemSuministro.EtapaSuministro.lookupId === 9 ){
                this.setState({isLoaded: true, porcentajeAvance: 100})
            }
        }

    }

    public componentWillUnmount(){

        this.props.dispatch(ItemSuministroActionCreators.clearItemSuministro());

    }
    
    private _renderEtapaSuministros = () : JSX.Element => {

        const { etapaActual } = this.props;

        switch(etapaActual){

            case 1: // antecedenteas
                return (<Antecedentes />);

            case 2: // entregables
                return (<Entregables />);

            case 3: // protocolos
                return (<ProtocolosPruebas {...this.props} />);

            case 4: // doc nac-int
                return (<DocNacInt />);

            case 5: // recepción
                return (<Recepcion />);

            default:
                return (<div>404: Not Found!</div>);
                
        }

    }

    public render() {

        const { loading, etapasProceso, etapaActual, itemSuministro } = this.props;


        return (

            <div className={styles.ItemSuministroContainer} >

                {loading && <Loading message='Cargando suministro...' fullScreen />}

                {etapasProceso && 

                    <>
                        <div className={styles.containerEtapas}>
                            <div className={styles.whiteSpace}></div>
                            <MenuEtapas 
                            etapasList={etapasProceso}
                            onChangeEtapa={(nuevaEtapa: number) => this.props.dispatch(ItemSuministroActionCreators.changeEtapaSuministro(nuevaEtapa)) }
                            selected={etapaActual}/>

                            <div className={styles.bordeAvance}>
                                <div className={styles.contenedorAvance}>
                                {`Porcentaje de avance: ${itemSuministro.EtapaSuministro_x003a__x0020_Por.lookupValue}`}
                                </div>
                            </div>

                        </div>

                        <Separador />

                        <React.Fragment>

                            {this._renderEtapaSuministros()}

                        </React.Fragment>

                    </>

                }

            </div>

        );

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ItemSuministro);
