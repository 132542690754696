import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IHomologacionGeneral } from './interfaces';
import { GrillaHomologacionesActionCreators } from './actions';
import styles from './GrillaHomologacion.module.scss';
import { CustomDetailsList } from '../Shared/Detailslist/detailsList';
import { Loading } from '../Shared/Loading/loading';
import { IUsuarioLogin } from '../utils/interfaces';
import { Separador } from '../Shared/Separador/separador';
import { CustomIconButton } from '../Shared/IconButton';
import { EnumEstadoHomologacion, EnumEstadosMuestraHomologacion, EnumHomologacionFaseEstadoMuestra } from '../utils/enums';
import { CustomButton } from '../Shared/Button';
import PanelNuevo from './internalComponents/PanelNuevo';
import Accordion from '../Shared/Accordion/Accordion';
import AccordionItem from '../Shared/Accordion/AccordionItem';
import { TextField } from 'office-ui-fabric-react';
import { CommonComboBox } from '../Shared/CustomFields/ComboBoxField';

interface IConnectedState {
    homologacionesList: IHomologacionGeneral[];
    loading: boolean;
    user: IUsuarioLogin;
    loadingCarga: boolean;
}

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        homologacionesList: state.grillaHomologacion.homologacionesList,
        loading: state.grillaHomologacion.loading,
        user: state.main.user,
        loadingCarga: state.grillaHomologacion.loadingCarga,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IGrillaHomologacionOwnProps {
    history: any;
}

const estadosHomologacion = [
    {key: 1, text: 'Aprobado'},
    {key: 2, text: 'Rechazado'},
    {key: 3, text: 'Rechazado Sin Proceso'},
    {key: 4, text: 'En proceso'},
    {key: 5, text: 'Solicitud'},

];

// define combined props
type IGrillaHomologacionProps = IGrillaHomologacionOwnProps & IConnectedState &{ dispatch(action: any): void };

interface IGrillaHomologacionState {
    isLoaded: boolean;
    openPanel: boolean;
    homologacionToAdd?: IHomologacionGeneral;
    internalItems: any[];
    filterObject: any;
}


class GrillaHomologacion extends React.PureComponent<IGrillaHomologacionProps, IGrillaHomologacionState> {

    constructor(props: IGrillaHomologacionProps) {
        super(props);
        this.state = {
            isLoaded: false,
            openPanel: false,
            internalItems: [],
            filterObject:{
                id: '',
                descripcion : '',
                modelo:'',
                nivelTension: '',
                fabricante:'',
                lineaFuncionamiento: '',
                estado: ''
            }
        };
    }

    public componentDidMount() {
        const { usuarioProveedor } = this.props.user;
        this.props.dispatch(GrillaHomologacionesActionCreators.handleLoading(true));
        this.props.dispatch(GrillaHomologacionesActionCreators.getHomologacionesByProveedor(usuarioProveedor.proveedor_x003a_Rut.lookupValue));
    }

    public componentDidUpdate() {
        const { isLoaded } = this.state;
        const { homologacionesList } = this.props;

        if ( !isLoaded && homologacionesList ){
            this.setState({
                internalItems: homologacionesList,
                isLoaded: true,
            });
        }

        if ( this.props.loadingCarga ){
            window.location.reload();
        }
    }
    
    private _onClickProcesarItem = (item: IHomologacionGeneral): void => {
        const { history } = this.props;
        history.push(`/itemHomologacion/${item.UniqueId}`);
    }

    private _onOpenPanel = (): void => {
        const { homologacionesList, user } = this.props;
        this.setState({
            openPanel: true,
            homologacionToAdd: {
                ID: null,
                EsVendedorAnterior: homologacionesList.filter(h => h.EstadoId === EnumEstadoHomologacion.Aprobado).length > 0,
                EstadoId: EnumEstadoHomologacion.Solicitud,
                FabricanteProducto: '',
                GestorCalidadHomologacionId: [], 
                GestorTecnicoHomologacionId: [],
                ModeloProducto: '',
                LineaFuncionamientoProducto: 'Distribución',
                NivelTensionProducto: 'No Aplica',
                NombreProveedorId: user.usuarioProveedor.proveedor_x003a_ID.lookupId,
                ResponsableFactibilidadId: null,
                SolicitanteHomologacionId: null,
                Title: '',
                UniqueId: null,
                SolicitanteHomologacionProveedorId: user.usuarioProveedor.id,
                FechaCierre: undefined,
                MotivoRechazo: '',
                FechaInicioProceso: undefined,
                Codigo: '',
                UbicacionFabrica: '',
                SolicitanteNombre: '',
                SolicitanteTelefono: '',
                Muestra_Cantidad: '',
                Muestra_Informacion: '',
                Muestra_Estado: EnumHomologacionFaseEstadoMuestra.NoInformada,
                Vigencia : 2
                
            } as any
        });
    }

    private _onClosePanel = (): void => {
        this.setState({
            openPanel: false,
            homologacionToAdd: undefined,
        });
    }

    private _onAcceptPanel = ( file: File[]): void => {
        const { homologacionToAdd } = this.state;
        const { usuarioProveedor } = this.props.user;
        this.props.dispatch(GrillaHomologacionesActionCreators.handleLoading(true));
        this.props.dispatch(GrillaHomologacionesActionCreators.addHomologacionGeneral({...homologacionToAdd, fileHomologacion: file}, usuarioProveedor.proveedor_x003a_Rut.lookupValue));
        this._onClosePanel();
        //window.location.reload();
    }

    private _onChangeHomologacionItem = (ev, option): void => {
        const { homologacionToAdd } = this.state;
        let homologacionModified: IHomologacionGeneral = undefined;
        const { id, value } = ev.target;
        switch (id){
            case "FabricanteProducto":
                homologacionModified = {...homologacionToAdd, FabricanteProducto: value };
                break;
            case "LineaFuncionamientoProducto":
                homologacionModified = {...homologacionToAdd, LineaFuncionamientoProducto: option.key };
                break;
            case "ModeloProducto":
                homologacionModified = {...homologacionToAdd, ModeloProducto: value };
                break;
            case "NivelTensionProducto":
                homologacionModified = {...homologacionToAdd, NivelTensionProducto: option.key };
                break;
            case "Title":
                homologacionModified = {...homologacionToAdd, Title: value };
                break;
            case "UbicacionFabrica":
                homologacionModified = {...homologacionToAdd, UbicacionFabrica: value };
                break;
            case "SolicitanteNombre":
                homologacionModified = {...homologacionToAdd, SolicitanteNombre: value };
                break;
            case "SolicitanteTelefono":
                homologacionModified = {...homologacionToAdd, SolicitanteTelefono: value };
                break;
            default:
                homologacionModified = { ...homologacionToAdd };
        }
        this.setState({
            homologacionToAdd: homologacionModified,
        })
    }

    private _onCleanFilter = ():void => {
        const { homologacionesList } = this.props;
        this.setState({
            filterObject:{
                id: '',
                descripcion : '',
                modelo:'',
                nivelTension: '',
                fabricante:'',
                lineaFuncionamiento: '',
                estado: ''
            },
            internalItems: homologacionesList,
    
        });
    }

    private _onChangeFilter = (ev:any, option: any):void => {

        const { filterObject } = this.state;

        const { homologacionesList} = this.props;
  
        let filterObjectModified: any = undefined;
  
        let internalItemsModified: any[] = [];
  
        const { id, value, checked } = ev.target;

        switch(id){
  
          case 'idText':
              filterObjectModified = { ...filterObject, id: value };
              break;
          case 'descripcionText':
              filterObjectModified = { ...filterObject, descripcion: value };
              break;
              
            case 'modeloText':
                filterObjectModified = { ...filterObject, modelo: value};
                break;
            case 'nivelTensionText':
                    filterObjectModified = { ...filterObject, nivelTension: value};
                break;
            case 'fabricanteText':
                    filterObjectModified = { ...filterObject, fabricante: value};
                break;
            case 'lineaFuncionamientoText':
                    filterObjectModified = { ...filterObject, lineaFuncionamiento: value};
                break;
            case 'estadoText':
                    filterObjectModified = { ...filterObject, estado: option};
            break;
          
  
          default:
              filterObjectModified = { ...filterObject };
        }
  
        //genero el filtro directo en la lista con todos los valores anteriores
        internalItemsModified = homologacionesList.filter( it =>
  
          // substring
          (it.ID != null && it.ID.toString().toLowerCase().indexOf(filterObjectModified.id.toLowerCase()) > -1 ) &&
          (it.Title && it.Title !== null && it.Title.toLowerCase().indexOf(filterObjectModified.descripcion.toLowerCase()) > -1) &&
          (it.ModeloProducto && it.ModeloProducto  !== null && it.ModeloProducto.toLowerCase().indexOf(filterObjectModified.modelo.toLowerCase()) > -1) &&
          (it.NivelTensionProducto && it.NivelTensionProducto  !== null && it.NivelTensionProducto.toLowerCase().indexOf(filterObjectModified.nivelTension.toLowerCase()) > -1) &&
          (it.FabricanteProducto && it.FabricanteProducto  !== null && it.FabricanteProducto.toLowerCase().indexOf(filterObjectModified.fabricante.toLowerCase()) > -1) &&
          (it.LineaFuncionamientoProducto && it.LineaFuncionamientoProducto  !== null && it.LineaFuncionamientoProducto.toLowerCase().indexOf(filterObjectModified.lineaFuncionamiento.toLowerCase()) > -1) &&
          ((it.Estado && it.Estado.lookupValue !== null && filterObjectModified.estado && it.Estado.lookupValue === filterObjectModified.estado.text ) || !filterObjectModified.estado)

        );
  
        this.setState({
            filterObject: filterObjectModified,
            internalItems: internalItemsModified,
        });
    }

    
    public render() {
        const { loading, homologacionesList } = this.props;
        const { openPanel, homologacionToAdd, internalItems, filterObject } = this.state;

        return (
            <div className={styles.GrillaHomologacion} >
                { loading && <Loading message='Cargando listado de homologaciones...' fullScreen={false} messagePosition='right' /> }
                <Text variant='xxLarge'>Lista Homologaciones</Text>
                <Separador />
                <div style={{ textAlign: 'left', paddingTop: 10}} >
                    <CustomButton icon='Add' onClick={this._onOpenPanel} label='Solicitar homologación' id='CrearNuevaHomologacionGeneral'/>
                </div>
                <Separador mensaje='Filtros' />

                <Accordion id='filtroHomologacion'>
                    <AccordionItem id='itemFiltroHomologacion' title='Abrir Filtros'>
                        <div className='ms-Grid' dir='ltr'>
                            <div className='ms-Grid-row'>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                <TextField id='idText' onChange={this._onChangeFilter} value={filterObject.id} label='Id'  />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                <TextField id='descripcionText' onChange={this._onChangeFilter} value={filterObject.descripcion} label='Descripción'  />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                <TextField id='modeloText' onChange={this._onChangeFilter} value={filterObject.modelo} label='Modelo'  />
                            </div>

                            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                                <TextField id='nivelTensionText' onChange={this._onChangeFilter} value={filterObject.nivelTension} label='Nivel de Tensión'  />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <TextField id='fabricanteText' onChange={this._onChangeFilter} value={filterObject.fabricante} label='Fabricante'  />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <TextField id='lineaFuncionamientoText' onChange={this._onChangeFilter} value={filterObject.lineaFuncionamiento} label='L. Funcionamiento'  />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <CommonComboBox key='estadoText' options={estadosHomologacion} id='estadoText' onChange={this._onChangeFilter} selectedKey={filterObject.estado && filterObject.estado.key } label='Estado'  />
                            </div>

                            <div className='ms-Grid-row'>
                                <div className="ms-Grid-col ms-lg12" style={{ textAlign: 'right' }}>
                                    <br/>
                                    <CustomButton id='clearFilter' onClick={this._onCleanFilter} label='Limpiar Filtros' />
                                </div>
                            </div>

                            </div>
                        </div>
                    </AccordionItem>
                </Accordion>

                { homologacionesList && homologacionesList.length > 0 && (
                    <CustomDetailsList
                        columns={[
                            {
                                key: 'columnaAcciones',
                                name: 'Procesar',
                                fieldName: 'UniqueId',
                                iconName: 'Processing',
                                minWidth: 50,
                                maxWidth: 50,
                                isResizable: false,
                                clickToSort: false,
                                onRender: (item: IHomologacionGeneral) => (<CustomIconButton icon='Processing' onClick={() => this._onClickProcesarItem(item)} label='Procesar' id={`procesar_${item.ID}`} />)
                            },
                            {
                                key: 'Id',
                                name: 'Id',
                                fieldName: 'ID',
                                minWidth: 50,
                                maxWidth: 100,
                                isResizable: true,
                                isRowHeader:true,
                            },
                            {
                                key: 'columnaTitle',
                                name: 'Descripción',
                                fieldName: 'Title',
                                minWidth: 120,
                                maxWidth: 150,
                                isResizable: true,
                                isRowHeader:true,
                            },
                            {
                                key: 'columnaModeloProducto',
                                name: 'Modelo',
                                fieldName: 'ModeloProducto',
                                minWidth: 120,
                                maxWidth: 150,
                                isResizable: true,
                            },
                            {
                                key: 'columnaNivelTensionProducto',
                                name: 'Nivel de tensión',
                                fieldName: 'NivelTensionProducto',
                                minWidth: 120,
                                maxWidth: 150,
                                isResizable: true,
                            },
                            {
                                key: 'columnaFabricante',
                                name: 'Fabricante',
                                fieldName: 'FabricanteProducto',
                                minWidth: 120,
                                maxWidth: 150,
                                isResizable: true,
                            },
                            {
                                key: 'columnaLineaFuncionamientoProducto',
                                name: 'Linea funcionamiento producto',
                                fieldName: 'LineaFuncionamientoProducto',
                                minWidth: 120,
                                maxWidth: 150,
                                isResizable: true,
                            },
                            {
                                key: 'columnaEstado',
                                name: 'Estado',
                                fieldName: 'EstadoId',
                                minWidth: 120,
                                maxWidth: 150,
                                onRender: (item: IHomologacionGeneral) => {
                                    switch (item.EstadoId){

                                        case EnumEstadoHomologacion.Aprobado:
                                            return 'Aprobado';

                                        case EnumEstadoHomologacion.EnProceso:
                                            return 'En proceso';

                                        case EnumEstadoHomologacion.Solicitud:
                                            return 'Solicitud';

                                        case EnumEstadoHomologacion.Rechazado:
                                            return 'Rechazado'

                                        case EnumEstadoHomologacion.RechazadoSinProceso:
                                            return 'Rechazado sin proceso';
                                    }
                                }
                            }
                        ]}
                        items={internalItems.length>0 ? internalItems: []}
                    />
                )}
                <PanelNuevo
                    openPanel={openPanel}
                    homologacionItem={homologacionToAdd}
                    onClose={this._onClosePanel}
                    onAccept={this._onAcceptPanel}
                    onChange={this._onChangeHomologacionItem}
                    
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrillaHomologacion);
