import { Action, Reducer } from 'redux';
import {
    SIGNIN,
    RESPONSE_SIGNIN,
    SIGNOUT,
    LOADING,
    MESSAGEERROR,
    CLEANMESSAGEERROR,
    SHOWMESSAGEBAR,
    HIDEMENSAGEBAR,
    RESPONSE_RECOVERPASSWD
} from './constants';
import { MainKnownAction } from './actions';
import { IUsuarioLogin } from '../utils/interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MainState {
    isAuthenticated: boolean;
    loading: boolean,
    user?: IUsuarioLogin;
    AuthenticateType?: string;
    messageError?: string;
    configurationPanel: boolean;
    showMessageBar: boolean;
    message?: string;
    code?: string;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const MainReducer: Reducer<MainState> = (state: MainState | undefined, incomingAction: Action): MainState => {
    if (state === undefined) {
        return {
            isAuthenticated: false,
            loading: false,
            configurationPanel: false,
            showMessageBar: false,
        };
    }
    const action = incomingAction as MainKnownAction;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading! };
        case RESPONSE_SIGNIN:
            return { ...state, isAuthenticated: true, loading: false, user: action.user, AuthenticateType: action.authType, messageError: undefined };
        case SIGNOUT:
            return { ...state, isAuthenticated: false, loading: false, user: undefined, AuthenticateType: undefined, messageError: undefined };
        case SIGNIN:
            return { ...state, isAuthenticated: true, loading: false, user: action.user };
        case MESSAGEERROR:
            return { ...state, messageError: action.messageError, loading: false };
        case CLEANMESSAGEERROR:
            return { ...state, messageError: undefined };
        case SHOWMESSAGEBAR:
            return { ...state, showMessageBar: true, message: action.message, code: action.code };
        case HIDEMENSAGEBAR:
            return { ...state, showMessageBar: false, message: undefined, code: undefined };
        case RESPONSE_RECOVERPASSWD:
                return { ...state, isAuthenticated: true, loading: false, user: action.user, AuthenticateType: action.authType, messageError: undefined };
        default:
            return state;
    }
};
