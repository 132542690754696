import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IInspeccionFabrica, IInspeccionFabricaItem } from './interfaces';
import { GrillaInspeccionesActionCreators } from './actions';
import styles from './GrillaInspeccion.module.scss';
import { CustomDetailsList, ICustomColumns } from '../Shared/Detailslist/detailsList';
import { CustomIconButton } from '../Shared/IconButton';
import { Loading } from '../Shared/Loading/loading';
import { IUsuarioLogin } from '../utils/interfaces';
import { EnumEstadoInspeccion } from '../utils/enums';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { BuildEstadosInspeccion, ConvertSharepointDate } from '../utils/functions';
import { CommonComboBox } from '../Shared/CustomFields/ComboBoxField';
import { IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/index';
import { Separador } from '../Shared/Separador/separador';

const iconClass = mergeStyles({
	fontSize: 15,
	padding: '0px 2px',
	marginTop: 1,
});
  
const baseContainerClass =  mergeStyles({
	color: 'white',
	borderRadius: 20,
	padding: '6px 36px'
});
  
const containerClass = mergeStyleSets({
	aprobada: [{ backgroundColor: 'green' }, baseContainerClass],
	rechazada: [{ backgroundColor: 'red' }, baseContainerClass],
	enProceso: [{ backgroundColor: 'gold' }, baseContainerClass],
});


interface IConnectedState {
    listaInspecciones: IInspeccionFabrica[];
    loading: boolean;
    user: IUsuarioLogin;
}


function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        listaInspecciones: state.grillaInspeccion.listaInspecciones,
        loading: state.grillaInspeccion.loading,
        user: state.main.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IGrillaInspeccionOwnProps {
    history: any;
}

// define combined props
type IGrillaInspeccionProps = IGrillaInspeccionOwnProps & IConnectedState &{ dispatch(action: any): void };

interface IGrillaInspeccionState{
    isLoaded: boolean;
    items: IInspeccionFabricaItem[];
    columns: ICustomColumns[];

    optionsEstados: IComboBoxOption[];
    filterObject: IFilterInspeccion;
}

interface IFilterInspeccion {
    estadoInspeccionId: EnumEstadoInspeccion;
}
class GrillaInspeccion extends React.PureComponent<IGrillaInspeccionProps, IGrillaInspeccionState> {

    private EmptyFilterObject: IFilterInspeccion = {
        estadoInspeccionId: EnumEstadoInspeccion.EnProceso,
    }

    constructor(props: IGrillaInspeccionProps) {
        super(props);
        this.state = {
            isLoaded: false,
            filterObject: this.EmptyFilterObject,
            optionsEstados: BuildEstadosInspeccion(),
            items: [],
            columns: [
                {
                    key: 'columnaAcciones',
                    name: 'Procesar',
                    fieldName: 'uniqueid',
                    iconName: 'Processing',
                    minWidth: 40,
                    maxWidth: 40,
                    isResizable: false,
                    clickToSort: false,
                    onRender: (item: IInspeccionFabricaItem) => (<CustomIconButton icon='Processing' onClick={() => this._onClickProcesarItem(item)} label='Procesar' id={`procesar_${item.key}`} />)
                },
                {
                    key: 'columnaCodigo',
                    name: 'Codigo Inspección',
                    fieldName: 'codigo',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                    isRowHeader: true,
                },
                {
                    key: 'columnaOC',
                    name: 'OC Asociada',
                    fieldName: 'ocasociada',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                },
                {
                    key: 'columnaOrigen',
                    name: 'Origen',
                    fieldName: 'origen',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                },
                {
                    key: 'columnaSuministro',
                    name: 'Suministro',
                    fieldName: 'suministro',
                    minWidth: 600,
                    maxWidth: 750,
                    isResizable: true,
                },
                {
                    key: 'columnaFechaInicio',
                    name: 'Fecha de inicio estimada',
                    fieldName: 'fechaInicio',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                },
                {
                    key: 'columnaFechaTermino',
                    name: 'Fecha de término estimada',
                    fieldName: 'fechaTermino',
                    minWidth: 100,
                    maxWidth: 150,
                    isResizable: true,
                },
                {
                    key: 'columnaEstado',
                    name: 'Estado',
                    fieldName: 'estadoId',
                    minWidth: 150,
                    maxWidth: 150,
                    isResizable: false,
                    onRender: (item: IInspeccionFabricaItem) => {
                        switch(item.estadoId){
                            case EnumEstadoInspeccion.EnProceso:
                                return (<div className={containerClass.enProceso}><FontIcon iconName="Processing" className={iconClass} /> En proceso</div>);
                            case EnumEstadoInspeccion.Rechazada:
                                return (<div className={containerClass.rechazada}><FontIcon iconName="Cancel" className={iconClass} /> Rechazada</div>);
                            case EnumEstadoInspeccion.Aprobada:
                                return (<div className={containerClass.aprobada}><FontIcon iconName="Accept" className={iconClass} /> Aprobada</div>);
                        }
                    }
                },
            ]
        }
    }

    public componentDidMount() {
        const { usuarioProveedor } = this.props.user;
        this.props.dispatch(GrillaInspeccionesActionCreators.handleLoading(true));
        if ( usuarioProveedor.proveedorInspector){
            this.props.dispatch(GrillaInspeccionesActionCreators.getInspeccionesListByInspector(usuarioProveedor.proveedorInspector_x003a_RUT.lookupValue));
        } else {
            this.props.dispatch(GrillaInspeccionesActionCreators.getInspeccionesListByProveedor(usuarioProveedor.proveedor_x003a_Rut.lookupValue));
        }
    }

    public componentDidUpdate(){
        const { isLoaded, filterObject } = this.state;
        const { listaInspecciones } = this.props;
        if ( !isLoaded && listaInspecciones ){
            this.setState({
                items: this._buildItemsGrillaSuministros(listaInspecciones).filter(i=>i.estadoId === filterObject.estadoInspeccionId),
                isLoaded: true,
            });
        }
    }

    private _buildItemsGrillaSuministros = ( listaInspecciones: IInspeccionFabrica[] ): IInspeccionFabricaItem[] => {
        return listaInspecciones.map(s => ({
            key: s.ID,
            ocasociada: s.CodigoSuministro_x003a_OCAsociad.lookupValue,
            suministro: s.NombreSuministro.lookupValue,
            estado: s.EstadoInspeccion.lookupValue,
            estadoId: s.EstadoInspeccionId,
            uniqueid: s.UniqueId,
            codigo: s.Codigo !== null? s.Codigo: 'NOCODE',
            fechaInicio: s.FechaInicio !== null? ConvertSharepointDate(s.FechaInicio): 'Fecha no definida',
            fechaTermino: s.FechaTermino !== null? ConvertSharepointDate(s.FechaTermino): 'Fecha no definida'
        }));
    }

    private _onClickProcesarItem = (item :IInspeccionFabricaItem): void => {
        const { history } = this.props;
        history.push(`/itemInspeccion/${item.uniqueid}`);
    }

    private _onChangeFilter = ( ev, option ):void => {
        const { filterObject } = this.state;
        const { listaInspecciones } = this.props;
        let filterObjectModified: IFilterInspeccion = undefined;
        let internalItems: IInspeccionFabricaItem[] = [];
        const { id } = ev.target;
        switch(id){
            case 'estadoInspeccionId':
                filterObjectModified = { ...filterObject, estadoInspeccionId: option.key };
                break;
            default:
                filterObjectModified = { ...filterObject };
        }

        internalItems = this._buildItemsGrillaSuministros(listaInspecciones).filter( i => i.estadoId === filterObjectModified.estadoInspeccionId );

        this.setState({
            filterObject: filterObjectModified,
            items: internalItems,
        });
    }

    public render() {
        const { loading } = this.props;
        const { columns, items, filterObject, optionsEstados } = this.state;

        return (
            <div className={styles.grillaInspeccionContainer} >
                { loading && <Loading message='Cargando listado de inspecciones...' fullScreen={false} messagePosition='right' /> }
                <Text variant='xxLarge'>Inspecciones en fábrica</Text>
                <Separador mensaje='Filtros' />
                <div className="ms-Grid" dir="ltr" style={{ textAlign: 'left' }}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3"><CommonComboBox label='Estado inspecciones' id='estadoInspeccionId' onChange={this._onChangeFilter} key='estadoInspeccionId' options={optionsEstados} selectedKey={filterObject.estadoInspeccionId} /></div>
                    </div>
                </div>
                <Separador />
                { items.length > 0? (
                    <CustomDetailsList
                        columns={columns}
                        items={items}
                        isCompactMode={false}
                        addFilterInput={false}
                    />
                ):null}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrillaInspeccion);
