import * as React from 'react';
import { IHomologacionGeneral } from '../../GrillaHomologacion/interfaces';
import styles from '../ItemInspeccionHomologacion.module.scss';

interface IInformacionHomologacionProps {
    itemHomologacion: IHomologacionGeneral;
}

const InformacionHomologacion: React.FunctionComponent<IInformacionHomologacionProps> = ({ itemHomologacion }) => {
    return (
        <div className={styles.InformacionHomologacion}>
            <div className={styles.infoContent}>
                {itemHomologacion && (
                    <table>
                        <tbody>
                            <tr>
                                <td className={styles.key}>Proveedor: </td>
                                <td className={styles.value}>{itemHomologacion.NombreProveedor !== null ? itemHomologacion.NombreProveedor.lookupValue : ''}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Descripción del producto</td>
                                <td className={styles.value}>{itemHomologacion.Title}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Fabricante del producto: </td>
                                <td className={styles.value}>{itemHomologacion.FabricanteProducto}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Modelo: </td>
                                <td className={styles.value}>{itemHomologacion.ModeloProducto}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Nivel de tensión: </td>
                                <td className={styles.value}>{itemHomologacion.NivelTensionProducto}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Linea de funcionamiento</td>
                                <td className={styles.value}>{itemHomologacion.LineaFuncionamientoProducto}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Responsable factibilidad: </td>
                                <td className={styles.value}>{itemHomologacion.ResponsableFactibilidad !== null ? itemHomologacion.ResponsableFactibilidad.lookupValue : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default InformacionHomologacion;