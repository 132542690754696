import { Action, Reducer } from 'redux';
import {
    LOADING,
    GET_INSPECCIONESHOMOLOGACION,
} from './constants';
import { GrillaHomologacionKnownAction } from './actions';
import { IInspeccionHomologacion } from '../ItemHomologacion/interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface GrillaHomologacionInspeccionState {
    inspeccionesList?: IInspeccionHomologacion[];
    loading: boolean;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const GrillaHomologacionInspeccionReducer: Reducer<GrillaHomologacionInspeccionState> = (state: GrillaHomologacionInspeccionState | undefined, incomingAction: Action): GrillaHomologacionInspeccionState => {
    if (state === undefined) {
        return {
            loading: false,
        };
    }
    const action = incomingAction as GrillaHomologacionKnownAction;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading };
        case GET_INSPECCIONESHOMOLOGACION:
            return {
                ...state,
                inspeccionesList: action.inspeccionesList.map( i => ({
                    ...i,
                    EstadoId: i.Estado && i.Estado.lookupId,
                    ProveedorId: i.Proveedor && i.Proveedor.lookupId,
                    HomologacionGeneralId: i.HomologacionGeneral && i.HomologacionGeneral.lookupId,
                })),
                loading: false
            };
        default:
            return state;
    }
};


