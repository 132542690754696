import * as React from 'react';
import { IInspeccionFabrica } from '../interfaces';
import styles from '../ItemInspeccion.module.scss';
import { CustomTextField } from '../../Shared/CustomFields/TextField';

export interface IInformacionGeneralProps {
    inspeccionItem: IInspeccionFabrica,
}

export const InformacionGeneral: React.FunctionComponent<IInformacionGeneralProps> = ({ inspeccionItem }) => (
    <div className={ styles.informacionGeneral }>
        <h1>1. Información General</h1>
        <div className={ styles.informacionGeneralContent }>
            <CustomTextField defaultValue={inspeccionItem.CodigoSuministro_x003a_OCAsociad.lookupValue} id='OCAsociada' label='Orden de compra asociada' onChange={() => void(0)} disabled />
            <CustomTextField defaultValue={inspeccionItem.NombreSuministro.lookupValue} id='producto' label='Producto' onChange={() => void(0)} disabled />
        </div>
    </div>
);
