import { Action, Reducer } from 'redux';
import {
    LOADING,
    GET_ITEMSUMINISTRO,
    SETMENU_SUMINISTRO,
    CHANGEMENU_SUMINISTRO,
    GET_ETAPASSUMINISTRO,
    GET_TIPODOCUMENTOSSUMINISTRO,
    LOADING_COMENTARIOS,
    GET_COMENTARIOSDOCUMENTO,
    CLEAR_COMENTARIOSDOCUMENTO,
    GET_DOCUMENTOSSUMINISTRO,
    CLEAR_DOCUMENTOSSUMINISTRO,
    CLEAR_ITEMSUMINISTRO,
    GET_TIPODOCUMENTONACINT,
    GET_DOCUMENTOSIMPORTACION,
    GET_COMENTARIOIMPORTACION,
    LOADING_DOWNLOADDOCUMENTO,
    GET_DOCUMENTOSREQUERIDOSSUMINISTROS,
    LOADING_INSPECCIONESFABRICASUMINISTRO,
    GET_INSPECCIONESFABRICASUMINISTRO,
    GET_DOCTOSENTREGABLESEXISTENTES,
    LOADING_DOCUMENTOSANTECEDENTESBYPROVEEDOR,
    GET_DOCUMENTOSANTECEDENTESBYPROVEEDOR,
    LOADING_DOCUMENTOS,
    LOADING_DOCTOSREQUERIDOS,
    GET_NOCONFORMIDADESBYSUMINISTRO,
    GET_DOCUMENTOSRECEPCION,
    GET_DOCUMENTOSPROTOCOLOSFAT
} from './constants';
import { ItemSuministroKnowActions } from './actions';
import { IComentarioBase, IEtapasSuministro, IProcesoSuministro, IDocumentosTipo, IDocumentoSuministro, IDocumentoImportacion, IDocumentoRequerido } from './interfaces';
import { IEtapasProceso } from '../Shared/MenuEtapas/menuEtapas';
import { IInspeccionFabrica } from '../ItemInspeccion/interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ItemSuministroState {
    loading: boolean;
    itemSuministro?: IProcesoSuministro;
    etapasProceso?: IEtapasProceso[];
    etapaActual: number;
    etapasSuministro?: IEtapasSuministro[];
    tipoDocumentosSuministro: IDocumentosTipo[];
    comentariosList: IComentarioBase[];
    loadingComentarios: boolean;
    documentosList: IDocumentoSuministro[];
    tipoDocNacIntList: IDocumentosTipo[];
    documentosImportacionList: IDocumentoImportacion[];
    comentarioImportacionList: IComentarioBase[];
    loadingDownloadDocumento: any;
    doctosRequeridosList?: IDocumentoRequerido[];
    inspeccionesList?: IInspeccionFabrica[];
    loadingInspecciones: boolean;
    doctosExistentesList?: IDocumentoSuministro[];
    loadingDoctosAntecedentes: boolean;
    doctosAntecedentesList?: IDocumentoSuministro[];
    loadingDocumentos: boolean;
    loadingDoctosRequeridos: boolean;
    noConformidadesList?: any;
    documentosRecepcionList?: IDocumentoSuministro[];
    documentosProtocolosFAT? : IDocumentoSuministro[];
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const ItemSuministroReducer: Reducer<ItemSuministroState> = (state: ItemSuministroState | undefined, incomingAction: Action): ItemSuministroState => {
    if (state === undefined) {
        return {
            loading: false,
            etapaActual: -1,
            tipoDocumentosSuministro: [],
            comentariosList: [],
            documentosList: [],
            loadingComentarios: false,
            tipoDocNacIntList: [],
            documentosImportacionList: [],
            comentarioImportacionList: [],
            loadingDownloadDocumento: {},
            loadingInspecciones: false,
            loadingDoctosAntecedentes: false,
            loadingDocumentos: false,
            loadingDoctosRequeridos: false,
            noConformidadesList: [],
            documentosProtocolosFAT :[]
        };
    }
    
    const action = incomingAction as ItemSuministroKnowActions;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading };
        case GET_ITEMSUMINISTRO:
            const { TipoProceso, TipoSuministro, LineaDeNegocio, EtapaSuministro, NombreSuministro, NombreProveedor } = action.itemSuministro;
            const itemSuministro: IProcesoSuministro = {
                ...action.itemSuministro,
                TipoProcesoId: TipoProceso? TipoProceso.lookupId:undefined,
                TipoSuministroId: TipoSuministro.lookupId,
                LineaDeNegocioId: LineaDeNegocio.lookupId,
                EtapaSuministroId: EtapaSuministro.lookupId,
                NombreSuministroId: NombreSuministro.lookupId,
                NombreProveedorId: NombreProveedor.lookupId,
            };
            return { ...state, itemSuministro, loading: false };
        case SETMENU_SUMINISTRO:
            return { ...state, etapasProceso: action.etapas, etapaActual: action.etapaActual };
        case CHANGEMENU_SUMINISTRO:
            return { ...state, etapaActual: action.nuevaEtapa };
        case GET_ETAPASSUMINISTRO:
            return { ...state, etapasSuministro: action.etapas };
        case GET_TIPODOCUMENTOSSUMINISTRO:
            const tipoDocumentosSuministro: IDocumentosTipo[] = action.tipoDocumentosList.map( td => ({
                ...td,
                MaestroFasesId: td.MaestroFases.lookupId,
            }));
            return { ...state, tipoDocumentosSuministro};
        case LOADING_COMENTARIOS:
            return { ...state, loadingComentarios: action.loading };
        case GET_COMENTARIOSDOCUMENTO:
            return { ...state, comentariosList: action.comentariosList, loadingComentarios: false };
        case CLEAR_COMENTARIOSDOCUMENTO:
            return { ...state, comentariosList: [], comentarioImportacionList: [] };
        case GET_DOCUMENTOSSUMINISTRO:
            const documentosList: IDocumentoSuministro[] = action.documentosList.map( d => ({
                ...d,
                DocumentosTipoId: d.DocumentosTipo.lookupId,
                CodigoSuministroId: d.CodigoSuministro.lookupId,
                DocumentosEstadoId: d.DocumentosEstado.lookupId,
                CodigoDoctoRequeridoId: d.CodigoDoctoRequerido.lookupId,
            }));
            return { ...state, documentosList, loadingDocumentos: false };

        case CLEAR_DOCUMENTOSSUMINISTRO:
            return { ...state, documentosList: [], documentosImportacionList: [], doctosExistentesList: undefined, };

        case CLEAR_ITEMSUMINISTRO:
            return { ...state,
                itemSuministro: undefined,
                etapasProceso: undefined,
                etapaActual: -1,
                etapasSuministro: undefined,
                doctosAntecedentesList: undefined,
                doctosRequeridosList: undefined,
                documentosImportacionList: [],
                doctosExistentesList: undefined,
                documentosList: [],
            };

        case GET_TIPODOCUMENTONACINT:
            const tipoDocNacIntList: IDocumentosTipo[] = action.tipoDocNacIntList.map( td=> ({
                ...td,
                MaestroFasesId: td.MaestroFases.lookupId,
            }));
            return { ...state, tipoDocNacIntList };
            
        case GET_DOCUMENTOSIMPORTACION:
            const documentosImportacionList: IDocumentoImportacion[] = action.documentosImportacionList.map(di => ({
                ...di,
                DocumentosTipoId: di.DocumentosTipo.lookupId,
                CodigoSuministroId: di.CodigoSuministro.lookupId,
                DocumentosEstadoId: di.DocumentosEstado.lookupId,
            }));
            return { ...state, documentosImportacionList, loading: false };
        case GET_COMENTARIOIMPORTACION:
            return { ...state, comentarioImportacionList: action.comentarioImportacionList, loadingComentarios: false };
            
        case LOADING_DOWNLOADDOCUMENTO:
            let loadingModified = { ...state.loadingDownloadDocumento };
            loadingModified[action.key] = action.loading;
            return { ...state, loadingDownloadDocumento: loadingModified };

        case LOADING_DOCTOSREQUERIDOS:
            return {...state, loadingDoctosRequeridos: action.loading };
        case GET_DOCUMENTOSREQUERIDOSSUMINISTROS:
            const doctosRequeridosList: IDocumentoRequerido[] = action.doctosRequeridosList.map( dr => ({
                ...dr,
                DocumentosTipoId: dr.DocumentosTipo.lookupId,
            }));
            return { ...state, doctosRequeridosList, loadingDoctosRequeridos: false};
        case LOADING_INSPECCIONESFABRICASUMINISTRO:
            return { ...state, loadingInspecciones: action.loading };
        case GET_INSPECCIONESFABRICASUMINISTRO:
            const  inspeccionesList: IInspeccionFabrica[] = action.inspeccionesList.map( i => ({ 
                ...i,
                EstadoInspeccionId: i.EstadoInspeccion.lookupId,
                CodigoSuministroId: i.CodigoSuministro.lookupId,
                NombreSuministroId: i.NombreSuministro.lookupId,
                OrigenInspeccionFabricaId: i.NombreSuministro.lookupId,
             }));
            return { ...state, loadingInspecciones: false, inspeccionesList };

        case GET_DOCTOSENTREGABLESEXISTENTES:
            const doctosExistentesList: IDocumentoSuministro[] = action.doctoExistentesList.map(d => ({
                ...d,
                CodigoSuministroId: d.CodigoSuministro.lookupId,
                DocumentosTipoId: d.DocumentosTipo.lookupId,
                DocumentosEstadoId: d.DocumentosEstado.lookupId,
                CodigoDoctoRequeridoId: d.CodigoDoctoRequerido.lookupId
            }))
            return {...state, doctosExistentesList, loadingDocumentos: false };
        
        
        case LOADING_DOCUMENTOSANTECEDENTESBYPROVEEDOR:
            return {...state, loadingDoctosAntecedentes: action.loading };

        case GET_DOCUMENTOSANTECEDENTESBYPROVEEDOR:
            return {
                ...state,
                loadingDoctosAntecedentes: false,
                doctosAntecedentesList: action.doctosAntecedentes.map(d => ({
                    ...d,
                    DocumentosTipoId: d.DocumentosTipo.lookupId,
                    CodigoSuministroId: d.CodigoSuministro.lookupId,
                    DocumentosEstadoId: d.DocumentosEstado.lookupId,
                    CodigoDoctoRequeridoId: d.CodigoDoctoRequerido.lookupId,
                })),
            };
        
        case LOADING_DOCUMENTOS:
            return {...state, loadingDocumentos: action.loading };
        case GET_NOCONFORMIDADESBYSUMINISTRO:
            return{...state, noConformidadesList: action.noConformidadesList}
        case GET_DOCUMENTOSRECEPCION:
            return {...state, documentosRecepcionList: action.documentosRecepcionList}
        case GET_DOCUMENTOSPROTOCOLOSFAT:
            return {...state, documentosProtocolosFAT: action.documentosProtocolosFATList}
        default:
            return state;
    }
};
