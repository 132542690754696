import { Panel, PanelType } from 'office-ui-fabric-react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ItemSuministroActionCreators } from '../../ItemSuministro/actions';
import { CustomButton } from '../../Shared/Button';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { CustomIconButton } from '../../Shared/IconButton';
import { Separador } from '../../Shared/Separador/separador';
import { IMaestroMaterial, IDocumentosMateriales } from '../interfaces';
import ApiConfig from '../../../config/apiConfigOficial';
import styles from '../MaestroMateriales.module.scss';
//import { ApplicationState } from '../../../config/store';

interface IPanelMaterialProps {
    material: IMaestroMaterial;
    openPanel: boolean;
    onClose: ( ) => void;
}

export const PanelMaterial : React.FunctionComponent<IPanelMaterialProps> = (props) => {
    const { material, openPanel} = props;
    const dispatch = useDispatch();
    
    /*const loadingDownloadDocumento = useSelector( (state: ApplicationState) => state.itemSuministro.loadingDownloadDocumento );

    const [ loadingDownload, setLoadingDownload ] = React.useState<any>({});

    React.useEffect(() => {
        setLoadingDownload(loadingDownload);
    },[loadingDownloadDocumento]);
    */

    const _onRenderFooter = ():JSX.Element => {
        return (<CustomButton icon='Cancel' cancel onClick={() => props.onClose()} label='Cerrar' id='cerrarPanel'/>);
    }

    function _onDownloadDocument (uniqueid:string) :void {
        const { material } = props;
        const selectedDocto = material.DocumentosList.find( d => d.UniqueId === uniqueid);
        dispatch(ItemSuministroActionCreators.loadingDownloadDocumento(true, selectedDocto.FileLeafRef));
        dispatch(ItemSuministroActionCreators.downloadDocumento(`${ApiConfig.baseUriDocuments}${selectedDocto.FileRef}`,selectedDocto.FileLeafRef)); 
    }

    return (
        <Panel
            isOpen={openPanel}
            onDismiss={() => props.onClose()}
            headerText={material && `Información del material ${material.CodigoSAP !== null? material.CodigoSAP: '[NO CODE]'}`}
            closeButtonAriaLabel="Cerrar"
            onRenderFooterContent={_onRenderFooter}
            isFooterAtBottom
            type={PanelType.medium}
            id={material && String(material.UniqueId)}
        >
            { material &&  (
                <div className={styles.PanelMaterial}>
                    <div className={styles.InformacionMaterial}>
                        <Separador mensaje='Información general' />
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.titulo}>Código SAP:</td>
                                    <td className={styles.contenido}>{material.CodigoSAP !== null? material.CodigoSAP: "Código SAP no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Descripción breve:</td>
                                    <td className={styles.contenido}>{material.Title}</td>
                                </tr>
                                {/* <tr>
                                <td className={styles.titulo}>Código de almacen:</td>
                                    <td className={styles.contenido}>{material.CodigoAlmacen !== null? material.CodigoAlmacen: "Código no definido"}</td>
                                </tr> */}
                                <tr>
                                    <td className={styles.titulo}>Descripción Completa:</td>
                                    <td className={styles.contenido}>{material.DescripcionTecnica ? material.DescripcionTecnica : "Descripción técnica no definida."}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Unidad de medida:</td>
                                    <td className={styles.contenido}>{material.UnidadMedida? material.UnidadMedida: "Unidad de medida no definido"}</td>
                                </tr>
                               {/*  <tr>
                                    <td className={styles.titulo}>Crítico:</td>
                                    <td className={styles.contenido}>{material.Critico}</td>
                                </tr> */}
                                <tr>
                                    <td className={styles.titulo}>Grupo de Articulos:</td>
                                    <td className={styles.contenido}>{material.Familia? material.Familia.lookupValue : 'Grupo de Articulos no definida.'}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Peso bruto:</td>
                                    <td className={styles.contenido}>{material.PesoBruto? material.PesoBruto: "Peso bruto no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Peso neto:</td>
                                    <td className={styles.contenido}>{material.PesoNeto? material.PesoNeto: "Peso neto no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Valor Medio:</td>
                                    <td className={styles.contenido}>{material.ValorMedio? material.ValorMedio: "Valor medio no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Atributo extra uno:</td>
                                    <td className={styles.contenido}>{material.AttrExtraUno? material.AttrExtraUno: "Valor no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Atributo extra dos:</td>
                                    <td className={styles.contenido}>{material.AttrExtraDos? material.AttrExtraDos: "Valor no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Atributo extra tres:</td>
                                    <td className={styles.contenido}>{material.AttrExtraTres? material.AttrExtraTres: "Valor no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Atributo extra cuatro:</td>
                                    <td className={styles.contenido}>{material.AttrExtraCuatro? material.AttrExtraCuatro: "Valor no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Atributo extra cinco:</td>
                                    <td className={styles.contenido}>{material.AttrExtraCinco? material.AttrExtraCinco: "Valor no definido"}</td>
                                </tr>
                                 
                                {/*            Valores y parametros nuevo producto de la integracion
                                <tr>
                                    <td className={styles.titulo}>Stock:</td>
                                    <td className={styles.contenido}>{material.StockProducto? material.StockProducto: "Stock no definido"}</td>
                                </tr> 
                                <tr>
                                    <td className={styles.titulo}>Dimensiones:</td>
                                    <td className={styles.contenido}>{material.? material.: "Dimensiones no definido"}</td>
                                </tr> 
                                <tr>
                                    <td className={styles.titulo}>Redondeo:</td>
                                    <td className={styles.contenido}>{material.? material.: "Redondeo no definido"}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titulo}>Transito:</td>
                                    <td className={styles.contenido}>{material.? material.: "Transito no definido"}</td>
                                </tr>
                                  */}
                            </tbody>
                        </table>
                    </div>
                    
                    {material && material.CodigoSAP !== null && material.CodigoSAP !== '' && (
                        <div className={styles.DocumentosMaterial}>
                            <Separador mensaje='Archivos' />
                            {material.DocumentosList && material.DocumentosList.length > 0 && (
                                <CustomDetailsList
                                    id="ArchivosContainer"
                                    items={material.DocumentosList}
                                    columns={[
                                        {
                                            key: 'columnFileIconDocumento',
                                            name: 'iconfile',
                                            fieldName: 'iconfile',
                                            minWidth: 16,
                                            maxWidth: 16,
                                            iconName: 'Page',
                                            clickToSort: false,
                                        },
                                        {
                                            key: 'columnDownload',
                                            name: 'download',
                                            fieldName: 'UniqueId',
                                            iconName: 'PageLink',
                                            minWidth: 40,
                                            maxWidth: 40,
                                            onRender: (item: IDocumentosMateriales) => <CustomIconButton icon='PageLink' onClick={() => _onDownloadDocument(item.UniqueId)} label='Descargar documento' id={`downloadDocument${item.UniqueId}`} /> 
                                        },
                                        {
                                            key: 'columnname',
                                            name: 'Nombre',
                                            fieldName: 'FileLeafRef',
                                            minWidth: 220,
                                            maxWidth: 220,
                                            isRowHeader: true,
                                        }
                                    ]}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
      </Panel>
    );
};