import {
    LOADING,
    GET_HOMOLOGACIONESLIST,
    LOADING_CARGA
} from './constants';
import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions'
import { IHomologacionGeneral } from './interfaces';

import apiConfig from '../../config/apiConfigOficial';

// Decalaraciones de las acciones
interface HanldeLoadingAction { type: typeof LOADING; loading: boolean; }
interface GetHomologacionesListAction { type: typeof GET_HOMOLOGACIONESLIST; homologacionesList: IHomologacionGeneral[]; }
interface HanldeLoadingCargaAction { type: typeof LOADING_CARGA; loadingCarga: boolean; }

// lista de acciones conocidas
export type GrillaHomologacionKnownAction =
    MainKnownAction
|   HanldeLoadingAction
|   GetHomologacionesListAction
|   HanldeLoadingCargaAction
;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

export const GrillaHomologacionesActionCreators = {
    handleLoading: (loading) => ({ type: LOADING, loading } as HanldeLoadingAction),
    handleLoadingCarga: (loadingCarga) => ({ type: LOADING_CARGA, loadingCarga } as HanldeLoadingCargaAction),
    getHomologacionesByProveedor: (rutProveedor: string): AppThunkAction<GrillaHomologacionKnownAction> => async (dispatch) => {

        fetch(`${apiConfig.apiUri}/Homologacion/GetHomologacionByProveedor`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(rutProveedor),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IHomologacionGeneral[]>;
            })
            .then(data => {
                dispatch({ type: GET_HOMOLOGACIONESLIST, homologacionesList: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    addHomologacionGeneral: (homologacion: IHomologacionGeneral, rutProveedor: string) : AppThunkAction<GrillaHomologacionKnownAction> => async(dispatch) => {

        let formData = new FormData();
        formData.append(`homologacionItem.Title`, homologacion.Title);
        formData.append(`homologacionItem.NombreProveedorId`, String(homologacion.NombreProveedorId));
        formData.append(`homologacionItem.FabricanteProducto`, homologacion.FabricanteProducto);
        formData.append(`homologacionItem.ModeloProducto`, homologacion.ModeloProducto);
        formData.append(`homologacionItem.NivelTensionProducto`, homologacion.NivelTensionProducto);
        formData.append(`homologacionItem.LineaFuncionamientoProducto`, homologacion.LineaFuncionamientoProducto);
        formData.append(`homologacionItem.EsVendedorAnterior`, String(homologacion.EsVendedorAnterior));
        formData.append(`homologacionItem.SolicitanteHomologacionProveedorId`, String(homologacion.SolicitanteHomologacionProveedorId));
        formData.append(`homologacionItem.UbicacionFabrica`, homologacion.UbicacionFabrica);
        formData.append(`homologacionItem.SolicitanteNombre`, homologacion.SolicitanteNombre);
        formData.append(`homologacionItem.SolicitanteTelefono`, homologacion.SolicitanteTelefono);
        for (let index = 0; index < homologacion.fileHomologacion.length; index++) {
            const element = homologacion.fileHomologacion[index];
            formData.append(`homologacionItem.FileHomologacion`, element);
            
        }
        formData.append(`homologacionItem.Vigencia`,String(homologacion.Vigencia));

        
        fetch(`${apiConfig.apiUri}/Homologacion/NuevoProcesoHomologacionGeneral`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}` },
            body: formData,
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<boolean>;
            })
            .then(added => {
                if (added){
                    //dispatch(GrillaHomologacionesActionCreators.getHomologacionesByProveedor(rutProveedor));
                    dispatch(GrillaHomologacionesActionCreators.handleLoadingCarga(true));
                } else{
                    dispatch(GrillaHomologacionesActionCreators.handleLoading(false));
                    dispatch(MainActionCreators.onShowMessageBar('No fue posible agregar el nuevo proceso de homologación, intentelo más tarde.','error'));
                }
            })
            .catch(error => {
                dispatch(GrillaHomologacionesActionCreators.handleLoading(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error'));
            });

    },

};