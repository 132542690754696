import {
    LOADING,
    GET_INSPECCIONESLIST,
} from './constants';
import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions'
import { IInspeccionFabrica } from './interfaces';

import apiConfig from '../../config/apiConfigOficial';

// Decalaraciones de las acciones
interface HanldeLoadingAction { type: typeof LOADING; loading: boolean; }
interface GetInspeccionesListAction { type: typeof GET_INSPECCIONESLIST; listaInspecciones: IInspeccionFabrica[]; }

// lista de acciones conocidas
export type GrillaInspeccionKnownAction =
    MainKnownAction
|   HanldeLoadingAction
|   GetInspeccionesListAction
;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

export const GrillaInspeccionesActionCreators = {
    handleLoading: (loading) => ({ type: LOADING, loading } as HanldeLoadingAction),
    getInspeccionesListByProveedor: (rutProveedor: string): AppThunkAction<GrillaInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/InspeccionFabrica/GetInspeccionFabricaByProveedor`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(rutProveedor),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IInspeccionFabrica[]>;
            })
            .then(data => {
                dispatch({ type: GET_INSPECCIONESLIST, listaInspecciones: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },
    getInspeccionesListByInspector: (rutInspector: string): AppThunkAction<GrillaInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/InspeccionFabrica/GetInspeccionFabricaByInspector`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(rutInspector),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IInspeccionFabrica[]>;
            })
            .then(data => {
                dispatch({ type: GET_INSPECCIONESLIST, listaInspecciones: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

};