import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { useHistory } from "react-router-dom";
import { IUsuarioProveedor } from '../utils/interfaces';

interface INavProps {
    onSignOut: any;
    usuarioProveedor: IUsuarioProveedor;
}

export const NavMenu: React.FunctionComponent<INavProps> = ({onSignOut, usuarioProveedor}) => {
    let history = useHistory();
    
    function _handlerItemsNav(ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: ICommandBarItemProps){
        ev.preventDefault();
        history.push(item.href);
    }

    function _goBack(ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: ICommandBarItemProps){
        ev.preventDefault();
        history.goBack();
    }

    function _createMenuNavigation() {
        const { proveedor, proveedorInspector } = usuarioProveedor;
        let itemsNav: ICommandBarItemProps[] = [
            {
                key: 'home',
                text: 'Inicio',
                iconProps: { iconName: 'Home' },
                onClick: _handlerItemsNav,
                href:'/'
            },
            {
                key: 'giback',
                text: 'Volver',
                iconProps: { iconName: 'Back' },
                onClick: _goBack,
            },
        ];

        if (proveedor) {
            itemsNav.push({
                key: 'grillaSuministros',
                text: 'Lista Suministros',
                iconProps: { iconName: 'GridViewMedium' },
                onClick: _handlerItemsNav,
                href:'/grillasuministro'
            });
        }

        if (proveedorInspector){
            itemsNav.push({
                key: 'grillaInspeccion',
                text: 'Inspecciones en fábrica',
                iconProps: { iconName: 'GridViewMedium' },
                onClick: _handlerItemsNav,
                href:'/grillaInspeccion'
            });
        }

        if (proveedor){
            itemsNav.push({
                key: 'grillaHomologacion',
                text: 'Homologaciones',
                iconProps: { iconName: 'SnapToGrid' },
                onClick: _handlerItemsNav,
                href:'/grillaHomologacion'
            });
        }
        
        if (proveedorInspector){
            itemsNav.push({
                key: 'grillainspeccionhomologacion',
                text: 'Inspecciones de Homologación',
                iconProps: { iconName: 'SnapToGrid' },
                onClick: _handlerItemsNav,
                href:'/grillainspeccionhomologacion'
            });
        }
        
        if (proveedor){
            itemsNav.push({
                key: 'materiales',
                text: 'Materiales',
                iconProps: { iconName: 'ClipboardList' },
                onClick: _handlerItemsNav,
                href:'/materiales'
            });
        }

        return itemsNav;
    }

    return (
        <CommandBar
            items={_createMenuNavigation()}
            farItems={[
            {
                key: 'userInfo',
                text: usuarioProveedor.proveedorInspector? 'Usuario Inspector': usuarioProveedor.proveedor ? 'Usuario Proveedor' : 'Usuario desconocido',
                ariaLabel: 'userInfo',
                iconOnly: true,
                iconProps: { iconName: 'UserOptional' },
                onClick: () => { return; },
            },
            {
                key: 'signOut',
                text: 'Salir',
                ariaLabel: 'Salir',
                iconOnly: true,
                iconProps: { iconName: 'SignOut' },
                onClick: () => onSignOut(),
            },
            ]}
            ariaLabel="Use left and right arrow keys to navigate between commands"
        />
    );
}
