import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { ItemSuministroActionCreators } from '../actions';
import { IProcesoSuministro, IDocumentoImportacion, IDocumentosTipo, IComentarioBase, IMetadataDocumentoItem } from '../interfaces';
import UploadFile from '../../Shared/UploadFile/uploadFile';
import { CommonComboBox } from '../../Shared/CustomFields/ComboBoxField';
import { IComboBoxOption } from 'office-ui-fabric-react/lib/index';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { CustomIconButton } from '../../Shared/IconButton';
import { EnumEstadosDocumentosSuministros, EnumEtapasSuministro, EnumFaseDocumentos, EnumProveedorOrigen } from '../../utils/enums';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import PanelDocNacInt from './PanelDocNacInt';
import { isEqualArrayObject, OrdenaDocumentosTiposList } from '../../utils/functions';

import styles from '../ItemSuministro.module.scss';
import { Separador } from '../../Shared/Separador/separador';

import { CustomTextField } from '../../Shared/CustomFields/TextField';
import { IUsuarioLogin } from '../../utils/interfaces';
import Resumen from '../../Shared/Resumen/Resumen';

const baseContainerClass =  mergeStyles({
    color: 'white',
    borderRadius: 20,
    padding: '6px 36px',
});
  
const containerClass = mergeStyleSets({
    aprobado: [{ backgroundColor: 'green' }, baseContainerClass],
    rechazado: [{ backgroundColor: 'red' }, baseContainerClass],
    enRevision: [{ backgroundColor: 'gold' }, baseContainerClass],
});

  
const iconClass = mergeStyles({
    fontSize: 15,
    padding: '0px 2px',
    marginTop: 1,
});

interface IConnectedState {
    itemSuministro: IProcesoSuministro;
    tipoDocNacIntList: IDocumentosTipo[];
    comentarioImportacionList: IComentarioBase[];
    documentosImportacionList: IDocumentoImportacion[];
    loadingComentarios: boolean;
    noConformidadesList?:any;
    currentUser: IUsuarioLogin;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        itemSuministro: state.itemSuministro.itemSuministro,
        tipoDocNacIntList: state.itemSuministro.tipoDocNacIntList,
        comentarioImportacionList: state.itemSuministro.comentarioImportacionList,
        documentosImportacionList: state.itemSuministro.documentosImportacionList,
        loadingComentarios: state.itemSuministro.loadingComentarios,
        noConformidadesList: state.itemSuministro.noConformidadesList,
        currentUser: state.main.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});


interface IDocNacIntOwnProps {
}

interface IDocNacIntState {
    isLoaded: boolean;
    metadataDocumentosList: IMetadataDocumentoItem[];
    boxOptions: IComboBoxOption[];
    isOpenPanel: boolean;
    selectedDocument?: IDocumentoImportacion;
    usuarioProveedorId: number;
}

// define combined props
type IDocNacIntProps = IDocNacIntOwnProps & IConnectedState &{ dispatch(action: any): void };

class DocNacInt extends React.PureComponent<IDocNacIntProps, IDocNacIntState> {

    constructor(props: IDocNacIntProps) {
        super(props);
        this.state = {
            isLoaded: false,
            metadataDocumentosList: [],
            boxOptions: [],
            isOpenPanel: false,
            usuarioProveedorId: props.currentUser.usuarioProveedor.id,
        };
    }

    componentDidMount() {
        const { itemSuministro } = this.props;
        if (itemSuministro){
            this.props.dispatch(ItemSuministroActionCreators.handleLoading(true));
            this.props.dispatch(ItemSuministroActionCreators.getTipoDocumentoNacionalInernacional());
            this.props.dispatch(ItemSuministroActionCreators.getDocumentosImportacion(itemSuministro.ID));
            this.props.dispatch(ItemSuministroActionCreators.getNoConformidadesBySuministro(itemSuministro.ID));
        }
    }

    componentDidUpdate(prevProps: IDocNacIntProps){
        const { documentosImportacionList, tipoDocNacIntList } = this.props;
        const { isLoaded } = this.state;
        if ( (!isLoaded && tipoDocNacIntList.length > 0 && documentosImportacionList) || (!isEqualArrayObject(documentosImportacionList, prevProps.documentosImportacionList) && tipoDocNacIntList.length > 0) ){
            this.setState({
                isLoaded: true,
                boxOptions: this._buildBoxOptions(tipoDocNacIntList),
            })
        }
    }

    private _buildBoxOptions = (tipoDocumentoList: IDocumentosTipo[]):IComboBoxOption[] => {
        const { itemSuministro } = this.props;
        let tipoDocumento: IDocumentosTipo[] = tipoDocumentoList.filter( td => td.MaestroFasesId === EnumFaseDocumentos.Nacional || td.MaestroFasesId === EnumFaseDocumentos.Otros );
        if ( itemSuministro.NombreProveedor_x003a_Nacional_E.lookupValue === EnumProveedorOrigen.Extranjero)
            tipoDocumento = tipoDocumentoList.filter( td => td.MaestroFasesId === EnumFaseDocumentos.Internacional || td.MaestroFasesId === EnumFaseDocumentos.Otros );
        return OrdenaDocumentosTiposList(tipoDocumento);
    }

    private _onChangeMetadata = (ev, option):void => {
        const { tipoDocNacIntList, currentUser } = this.props;
        const { metadataDocumentosList } = this.state;
        const { id, value } = ev.target;

        const idSplited: string = id.split("@@");

        let metadataListModified: IMetadataDocumentoItem[] = [];
        
        const metadataExist: IMetadataDocumentoItem = metadataDocumentosList.find( m => m.key === idSplited[1] );
        if (!metadataExist) {
            let metadataToAdd: IMetadataDocumentoItem = {
                key: idSplited[1],
                DocumentosTipoId: -1,
                Detalle: '',
                FaseId: -1,
                EmailSolicitante: currentUser.usuario
            };
            switch(idSplited[0]){
                case 'tipoDocumento':
                    metadataToAdd = { ...metadataToAdd, DocumentosTipoId: Number(option.key), FaseId: tipoDocNacIntList.find(t => t.ID === option.key).MaestroFasesId };
                    break;
                case 'detalle':
                    metadataToAdd = { ...metadataToAdd, Detalle: value as string };
                    break;
                default:
                    metadataToAdd = { ...metadataToAdd };
            }
          metadataListModified = metadataDocumentosList;
          metadataListModified.push(metadataToAdd);

        } else {
          let metadataToUpdate: IMetadataDocumentoItem = undefined;
          switch(idSplited[0]){
            case 'tipoDocumento':
                metadataToUpdate = { ...metadataExist, DocumentosTipoId: Number(option.key), FaseId: tipoDocNacIntList.find(t => t.ID === option.key).MaestroFasesId };
                break;
            case 'detalle':
                metadataToUpdate = { ...metadataExist, Detalle: value as string};
                break;
            default:
                metadataToUpdate = { ...metadataExist };
            }
          metadataListModified = metadataDocumentosList.filter( m => m.key !== metadataExist.key );
          metadataListModified.push(metadataToUpdate);
        }

        this.setState({
            metadataDocumentosList: metadataListModified
        });
        this.forceUpdate();
    }

    private _onOpenPanelDocumento = (idDocto: number) => {
        const { documentosImportacionList } = this.props;
        let selectedDocument = documentosImportacionList.find(d => d.ID === idDocto);
        this.setState({
          isOpenPanel: true,
          selectedDocument,
        });
        this.props.dispatch(ItemSuministroActionCreators.handleLoadingComentarios(true));
        this.props.dispatch(ItemSuministroActionCreators.getComentariosImportacionById(selectedDocument.ID));
      }
      
    private _onClosePanelDocumento = () => {
        this.props.dispatch(ItemSuministroActionCreators.clearComentariosDocumento());
        this.setState({
            isOpenPanel: false,
            selectedDocument: undefined,
        });
    }

    private _onUploadFile = (files: any[], fileData: any[], metadataFiles: IMetadataDocumentoItem[], isReupload = false):void => {

        const { itemSuministro } = this.props;
        this.props.dispatch(ItemSuministroActionCreators.clearDocumentosSuministro());
        this.props.dispatch(ItemSuministroActionCreators.handleLoading(true));
        this.setState({
          isLoaded: false,
        });

		let metadataOrdenado: IMetadataDocumentoItem[] = [];
		if (!isReupload) {
			for(let f = 0; f<files.length; f+=1) {
				metadataOrdenado.push(metadataFiles.find(m => m.key === fileData[f].key));
			}
		}

		if (isReupload) metadataOrdenado.push(...metadataFiles)

		new Promise ((res, rej) => { 
			this.props.dispatch(ItemSuministroActionCreators.uploadFileImportacion(files,metadataOrdenado,itemSuministro.ID, isReupload, res));
		})

		.then(() => {
			this.props.dispatch(ItemSuministroActionCreators.getDocumentosImportacion(itemSuministro.ID));
		})

    }

    private _onAddComentario = (comentario: IComentarioBase):void => {
        this.props.dispatch(ItemSuministroActionCreators.handleLoadingComentarios(true));
        this.props.dispatch(ItemSuministroActionCreators.addComentarioDocumentoImportacion(comentario));
    }

    private _onDownloadDocument = (uri: string, fileName: string):void => {
        this.props.dispatch(ItemSuministroActionCreators.downloadDocumento(uri,fileName));
    }

    public render() {
        const {
            metadataDocumentosList,
            boxOptions,
            isLoaded,
            isOpenPanel,
            selectedDocument,
            usuarioProveedorId
        } = this.state;
        const { tipoDocNacIntList, comentarioImportacionList, loadingComentarios, itemSuministro, documentosImportacionList, noConformidadesList } = this.props;
        const newOptions = boxOptions.filter(item => item.key !== 33 && item.itemType === undefined);
        return (
            <div className={"ms-Grid ".concat(styles.DocNacIntContainer)} dir="ltr">
                <h2>Documentación de nacionalización/transporte</h2>
                
                {noConformidadesList.length > 0 ? <div className={styles.avisoContainer}>
                    <Separador mensaje='Información' />
                    <p><b>Estimado usuario: </b>Este suministro se encuentra en proceso de <span>No Conformidad</span></p>
                </div> : null}
                <Resumen itemSuministro={itemSuministro} />
                <Separador mensaje='Documentos disponibles' />
                <CustomDetailsList
                    columns={[
                        {
                          key: 'columniconfileDocSuministro',
                          name: 'iconfile',
                          fieldName: 'iconfile',
                          minWidth: 16,
                          maxWidth: 16,
                          iconName: 'Page',
                        },
                        {
                          key: 'columnAccion',
                          name: 'Más',
                          fieldName: 'ID',
                          minWidth: 50,
                          maxWidth: 50,
                          //iconName: 'More',
                          onRender: (item: IDocumentoImportacion) => (<CustomIconButton id={`opciones_${item.ID}`}icon='More' label='Más información' onClick={() => this._onOpenPanelDocumento(item.ID)} />)
                        },
                        {
                          key: 'columnname',
                          name: 'Nombre',
                          fieldName: 'FileLeafRef',
                          minWidth: 220,
                          maxWidth: 220,
                        },
                        {
                          key: 'columnTipoDocumento',
                          name: 'Tipo de documento',
                          fieldName: 'DocumentosTipo',
                          minWidth: 250,
                          maxWidth: 250,
                          onRender: (item: IDocumentoImportacion) => item.DocumentosTipo.lookupValue,
                        },
                        {
                          key: 'columnDetalle',
                          name: 'Detalle',
                          fieldName: 'Detalle',
                          minWidth: 250,
                          maxWidth: 250,
                        },
                        {
                          key: 'columnstate',
                          name: 'Estado',
                          fieldName: 'DocumentosEstadoId',
                          minWidth: 150,
                          maxWidth: 150,
                          onRender: (item: IDocumentoImportacion) => {

                            switch(item.DocumentosEstadoId){
                            
                             	case EnumEstadosDocumentosSuministros.EnRevision:

                                	return (<div className={containerClass.enRevision}><FontIcon iconName="ReadingMode" className={iconClass} /> En Revisión</div>);
								
								case EnumEstadosDocumentosSuministros.Aprobado:
                                	
									return (<div className={containerClass.aprobado}><FontIcon iconName="Accept" className={iconClass} /> Aprobado</div>);
                              
								case EnumEstadosDocumentosSuministros.Rechazado:
                            	
									return (<div className={containerClass.rechazado}><FontIcon iconName="Cancel" className={iconClass} /> Rechazado</div>);
                            
							}
                          
						  }
                        },
						{
							key: 'ResubirDocumento',
							name: 'Resubir documento',
							fieldName: 'FaseId',
							minWidth: 250,
							maxWidth: 250,
							onRender: ({ DocumentosEstadoId, Detalle, DocumentosTipo, ID, FileLeafRef }) => {

								if (DocumentosEstadoId === 3) return <input type="file" 
								
								onChange={(File) => { 

									const documento = [File.target.files[0]];
                                    const {currentUser} = this.props;

									const metadata = [{

										Detalle: Detalle,
										DocumentosTipoId: DocumentosTipo.lookupId,
										FaseId: 1,
										IdDocumento: ID,
										key: undefined,
                                        nombreDocumentoAntiguo: FileLeafRef,
                                        EmailSolicitante : currentUser.usuario

									}]

									this._onUploadFile(documento, [], metadata, true )
								
								}} 
								
								/>

								else return "No se puede resubir este documento."

							}
						  },
                      ]}
                    items={documentosImportacionList}
                    addFilterInput={false}
                />
                { itemSuministro && itemSuministro.EtapaSuministroId === EnumEtapasSuministro.DoctoNacionalTransporte && isLoaded? (
                    <div className={"ms-Grid-row"}>
                        <div className={"ms-Grid-col ms-lg12"}><Separador mensaje='Carga de documentos' /></div>
                        <div className={"ms-Grid-col ms-lg12"}>
                            <UploadFile
                                onUploadFile={this._onUploadFile}
                                metadataList={metadataDocumentosList}
                                metadataOptionsColumns = {[
                                    {
                                        key: 'columnSelectDocType',
                                        name: 'Tipo Documento',
                                        fieldName: 'DocumentosTipoId',
                                        minWidth: 200,
                                        maxWidth: 200,
                                        onRender: (item) => {
                                            const metadata = metadataDocumentosList.filter(m => m.key === item.key)[0];
                                            let tipoDocumentoItem = metadata? metadata.DocumentosTipoId : '';
                                            return (<CommonComboBox id={`tipoDocumento@@${item.key}`} key={`${item.key}comboboxTipoDocumento`} options={boxOptions} onChange={this._onChangeMetadata} defaultSelectedKey={tipoDocumentoItem}/>);
                                        },
                                        clickToSort: false,
                                    },
                                    {
                                        key: 'columnAddDetails',
                                        name: 'Detalle',
                                        fieldName: 'Detalle',
                                        minWidth: 300,
                                        maxWidth: 300,
                                        onRender: (item) => {
                                            const metadata = metadataDocumentosList.find(m => m.key === item.key);
                                            let detalleItem = metadata? metadata.Detalle : '';
                                            return (<CustomTextField id={`detalle@@${item.key}`} defaultValue={detalleItem} onBlur={this._onChangeMetadata} />);
                                        },
                                    }
                                ]}
                            />

    <p style={{ color: "red" }}> 
		  
          Los siguientes documento son obligatorios: 

        { newOptions && newOptions.length && newOptions.map((item, index, array) => {

            
            const ultimoIndice = newOptions.length - 1

            if(item.itemType) return;

            const stringFormateada = ultimoIndice === index ? " " + item.text : " " + item.text + ","

            return stringFormateada

        })}
        
    </p>
                        </div>
                    </div>
                ) : null }
                <PanelDocNacInt
                    onClose={() => this._onClosePanelDocumento}
                    onAddComentario={(comentario) => this._onAddComentario(comentario) }
                    isOpen={isOpenPanel}
                    selectedDocument={selectedDocument}
                    comentariosList={comentarioImportacionList}
                    loadingComentarios={loadingComentarios}
                    disabled={itemSuministro && itemSuministro.EtapaSuministroId !== EnumEtapasSuministro.DoctoNacionalTransporte }
                    onDescargarDocumento={this._onDownloadDocument}
                    usuarioProveedor={usuarioProveedorId}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocNacInt);
