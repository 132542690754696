import * as React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { CustomButton } from '../../Shared/Button';
import { IDocumentosRequeridosItem, IDocumentoSuministro } from '../interfaces';
import styles from '../ItemSuministro.module.scss';
import { IComentarioBase } from '../interfaces';
import { Comentarios } from '../../Shared/Comentarios/comentarios';
import { Separator } from 'office-ui-fabric-react';
import { ConvertSharepointDate } from '../../utils/functions';

import ApiConfig from '../../../config/apiConfigOficial';
import { EnumEstadosDocumentosSuministros } from '../../utils/enums';

interface IPanelDocumentoProps {
  onClose: any;
  isOpen: boolean;
  selectedDocument: IDocumentoSuministro;
  comentariosList: IComentarioBase[];
  onAddComentario: (comentario:IComentarioBase) => void;
  loadingComentarios: boolean;
  disabled: boolean;
  onDescargarDocumento: (uri: string, fileName: string) => void,
  selectedDoctoRequerido: IDocumentosRequeridosItem,
  usuarioProveedor: number;
  disabledFlujoConectado : boolean;
}

interface IPanelDocumentoState {
  comentarioObject: IComentarioBase;
  attachmentFile : File;
  fileName: string;
}

export default class PanelDocumento extends React.Component<IPanelDocumentoProps, IPanelDocumentoState>  {

  private comentarioClean: IComentarioBase = {
    ID:  -1,
    Title: '',
    isSaesa: false,
    ProveedorInspectorId: -1
  };

  constructor(props: IPanelDocumentoProps){
    super(props);
    this.state = {
      comentarioObject: this.comentarioClean,
      attachmentFile : null,
      fileName : ''
    };
  }

  private _onChangeComentario = (ev): void => {
    const { comentarioObject } = this.state;
    let comentarioObjectModified: IComentarioBase = { ...comentarioObject };
    const { id, value } = ev.target;
    switch(id){
      case 'Title':
        comentarioObjectModified = { ...comentarioObject, Title: value };
        break;
      default:
        comentarioObjectModified = { ...comentarioObject };
        break;
    }
    this.setState({
      comentarioObject: comentarioObjectModified
    });
  }

  private _onAddComentario = () => {
    const { onAddComentario, selectedDocument, usuarioProveedor } = this.props;
    const { comentarioObject, attachmentFile } = this.state;
    onAddComentario({...comentarioObject, DocumentosSuministrosId: selectedDocument.ID, ProveedorInspectorId: usuarioProveedor, AttachFile: attachmentFile });
    this.setState({ comentarioObject: this.comentarioClean, attachmentFile: null, fileName: ''});
  }

  private _onAttachFile = (ev): void =>{
    console.log(ev);
    this.setState({
      attachmentFile : ev.target.files[0],
      fileName: ev.target.files[0].name
    });
  }

  private _onRenderFooter = ():JSX.Element => {
    const { onClose } = this.props;
    return (<CustomButton icon='Cancel' onClick={onClose()} label='Cerrar' id='cerrarpaneldocumento'/>);
  }


  public render () {
    const { isOpen, onClose, selectedDocument, comentariosList, loadingComentarios, disabled, selectedDoctoRequerido, disabledFlujoConectado} = this.props;
    const { comentarioObject, attachmentFile, fileName } = this.state;
    const disableComentarios : boolean = selectedDocument && selectedDocument.DocumentosEstadoId === EnumEstadosDocumentosSuministros.Aprobado;
    return (
      <Panel
        isOpen={isOpen}
        onDismiss={onClose()}
        headerText={selectedDocument? selectedDocument.FileLeafRef : ''}
        closeButtonAriaLabel="Cerrar"
        onRenderFooterContent={this._onRenderFooter}
        isFooterAtBottom={true}
        type={PanelType.medium}
      >
        { selectedDocument && selectedDoctoRequerido ? (
          <div className={styles.panelContainer}>
            <div className={styles.infoDocumento}>
              <h3>Detalles del documento</h3>
              <table>
                <tbody>
                  <tr>
                    <td><b>Nombre:</b></td>
                    <td>{selectedDocument.FileLeafRef} <span style={{ fontSize: 9, color: 'red'}}>{!selectedDoctoRequerido.CalculaAvance && '(Documento extra)'}</span> </td>
                  </tr>
                  <tr>
                    <td><b>Estado:</b></td>
                    <td>{selectedDocument.DocumentosEstado.lookupValue}</td>
                  </tr>
                  <tr>
                    <td><b>Tipo de documento:</b></td>
                    <td>{selectedDocument.DocumentosTipo.lookupValue}</td>
                  </tr>
                  <tr>
                    <td><b>Nombre documento detalle:</b></td>
                    <td>{selectedDoctoRequerido.NombreDocumentoDetalle}</td>
                  </tr>
                  <tr>
                    <td><b>Fase:</b></td>
                    <td>{selectedDocument.Fase.lookupValue}</td>
                  </tr>
                  <tr>
                    <td><b>Fecha aprobación:</b></td>
                    <td>{selectedDocument.FechaAprobacion? ConvertSharepointDate(selectedDocument.FechaAprobacion): 'No definida'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.downloadDocto}>
              <CustomButton id={`downloadDocto-${selectedDocument.ID}`} label='Descargar' icon='DownloadDocument' onClick={() => this.props.onDescargarDocumento(`${ApiConfig.baseUriDocuments}${selectedDocument.FileRef}`,selectedDocument.FileLeafRef)} />
            </div>
            <Separator />
            { comentariosList && (<Comentarios hidden={disableComentarios || disabledFlujoConectado} loading={loadingComentarios} comentarioObject={comentarioObject} comentariosList={comentariosList} onAddComentario={this._onAddComentario} onChangeComentario={this._onChangeComentario} onAttachFile={this._onAttachFile} attachFile={attachmentFile} />)}
          </div>
          ): null }
      </Panel>
    );
  }

}
