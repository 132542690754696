import * as React from 'react';
import styles from '../ItemInspeccionHomologacion.module.scss';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { CustomIconButton } from '../../Shared/IconButton';
import { IDocumentosInspeccionHomologacion, IInspeccionHomologacion } from '../../ItemHomologacion/interfaces';
import { EnumEstadoInspeccion, EnumEstadosDocumentosSuministros } from '../../utils/enums';
import { FontIcon, mergeStyleSets, mergeStyles } from '@fluentui/react';
import { IUsuarioLogin } from '../../utils/interfaces';
import { IComentarioBase } from '../../ItemSuministro/interfaces';
import PanelDoctoInspeccion from './PanelDocumento';
import { ItemHomologacionesInspeccionActionCreators } from '../actions';
import { IMetadataDoctoInspeccionHomologacion } from '../interfaces';
import { ItemSuministroActionCreators } from '../../ItemSuministro/actions';
import ItemInspeccionHomologacion from '../ItemInspeccionHomologacion';

const baseContainerClass = mergeStyles({
    color: 'white',
    borderRadius: 20,
    padding: '6px 20px',
    textAlign: 'center'
});

const containerClass = mergeStyleSets({
    aprobado: [{ backgroundColor: 'green' }, baseContainerClass],
    rechazado: [{ backgroundColor: 'red' }, baseContainerClass],
    enRevision: [{ backgroundColor: 'gold' }, baseContainerClass],
    sinDocumento: [{ backgroundColor: 'gray' }, baseContainerClass],
});

const iconClass = mergeStyles({
    fontSize: 15,
    padding: '0px 2px',
    marginTop: 1,
});

interface IConnectedState {
    documentosInspeccionList: IDocumentosInspeccionHomologacion[];
    itemInspeccion: IInspeccionHomologacion;
    user: IUsuarioLogin;

    loadingComentarios: boolean;
    comentariosInspeccionList: IComentarioBase[];
}

function mapStateToProps(state: ApplicationState): IConnectedState {
    return {
        documentosInspeccionList: state.itemHomologacionInspeccion.documentosInspeccionesList,
        itemInspeccion: state.itemHomologacionInspeccion.inspeccionItem,
        user: state.main.user,
        loadingComentarios: state.itemHomologacionInspeccion.loadingComentarios,
        comentariosInspeccionList: state.itemHomologacionInspeccion.comentariosInspeccionList,
    };
}

const mapDispatchToProps = (dispatch: (arg0: any) => void) => ({
    dispatch: (action: any) => { dispatch(action); }
});

// define combined props
type IDocumentosInspeccionProps = IConnectedState & { dispatch(action: any): void };

interface IDocumentosInspeccionState {
    isOpenPanel: boolean;
    selectedDocument?: IDocumentosInspeccionHomologacion;
}


class DocumentosInspeccion extends React.PureComponent<IDocumentosInspeccionProps, IDocumentosInspeccionState> {

    constructor(props: IDocumentosInspeccionProps) {
        super(props);
        this.state = {
            isOpenPanel: false,
        };
    }

    private _onOpenDoctoPanel = (doctoId: number): void => {
        const { documentosInspeccionList } = this.props;
        const selectedDocument: IDocumentosInspeccionHomologacion = documentosInspeccionList.find(d => d.ID === doctoId);
        this.setState({
            isOpenPanel: true,
            selectedDocument,
        });
        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.handleLoadingComentariosHomologacionInspeccion(true));
        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.getComentariosInspeccionesHomologacion(doctoId));
    }

    private _onClosePanel = () => {
        this.setState({
            isOpenPanel: false,
            selectedDocument: undefined,
        });
    }

    private _onDownloadDocumento = (url: string, fileName: string): void =>{
        this.props.dispatch(ItemSuministroActionCreators.loadingDownloadDocumento(true, fileName));
        this.props.dispatch(ItemSuministroActionCreators.downloadDocumento(url, fileName));
    }

    private _onAddComentarioInspeccion = (comentario: IComentarioBase): void => {
        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.handleLoadingComentariosHomologacionInspeccion(true));
        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.addComentarioDocumentoHomologacionInspeccion(comentario));
    }

    private _onUploadFile = (files: any[], fileData: any[], metadataFile: IDocumentosInspeccionHomologacion[] | any, isReupload = false): void => {
        const { itemInspeccion, user } = this.props;
        let metadataOrdenada: IMetadataDoctoInspeccionHomologacion[] = [];
        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.handleLoadingDocumentosInspeccion(true));

        metadataOrdenada.push({
            ...metadataFile[0],
            CodigoHomologacionInspeccion: itemInspeccion.ID,
            CodigoHomologacionGeneral: itemInspeccion.HomologacionGeneralId,
            IdDocumento: metadataFile[0].IdDocumento,
        });

        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.uploadDocumentoHomologacionInspeccion(files, metadataOrdenada, itemInspeccion.ID, true));
        this.props.dispatch(ItemHomologacionesInspeccionActionCreators.getDocumentosInspeccionList(itemInspeccion.ID));
    }

    public render(): JSX.Element {
        const { documentosInspeccionList, comentariosInspeccionList, loadingComentarios, user, itemInspeccion } = this.props;
        const { isOpenPanel, selectedDocument } = this.state;
        const usuarioProveedorId = user.usuarioProveedor.id;

        return (
            <div className={styles.DocumentosInspeccion}>
                {documentosInspeccionList && (
                    <CustomDetailsList
                        items={documentosInspeccionList}
                        columns={[
                            {
                                key: 'columniconfileDocSuministro',
                                name: 'iconfile',
                                fieldName: 'iconfile',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Page',
                                clickToSort: false,
                            },
                            {
                                key: 'columnAccion',
                                name: 'Más',
                                fieldName: 'ID',
                                minWidth: 40,
                                maxWidth: 40,
                                onRender: (item: IDocumentosInspeccionHomologacion) => (<CustomIconButton id={`opciones_${item.ID}`} icon='More' label='Información' onClick={() => this._onOpenDoctoPanel(item.ID)}
                                />)
                            },
                            {
                                key: 'columnname',
                                name: 'Nombre',
                                fieldName: 'FileLeafRef',
                                minWidth: 200,
                                maxWidth: 350,
                                isResizable: true,
                                isRowHeader: true,
                            },
                            {
                                key: 'columnTipoDocumento',
                                name: 'Tipo documento',
                                fieldName: 'DocumentosTipo',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item: IDocumentosInspeccionHomologacion) => item.DocumentosTipo.lookupValue,
                            },
                            {
                                key: 'columnaDetalle',
                                name: 'Detalle',
                                fieldName: 'Detalle',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                            },
                            {
                                key: 'columnDocumentoEstado',
                                name: 'Estado',
                                fieldName: 'DocumentosEstadoId',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                onRender: (item: IDocumentosInspeccionHomologacion) => {
                                    switch (item.DocumentosEstadoId) {
                                        case EnumEstadosDocumentosSuministros.EnRevision:
                                            return (<div className={containerClass.enRevision}><FontIcon iconName="ReadingMode" className={iconClass} /> En Revisión</div>);
                                        case EnumEstadosDocumentosSuministros.Aprobado:
                                            return (<div className={containerClass.aprobado}><FontIcon iconName="Accept" className={iconClass} /> Aprobado</div>);
                                        case EnumEstadosDocumentosSuministros.Rechazado:
                                            return (<div className={containerClass.rechazado}><FontIcon iconName="Cancel" className={iconClass} /> Rechazado</div>);
                                    }
                                }

                            },
                            {
                                key: 'columnResubirDocumento',
                                name: 'Resubir Documento',
                                fieldName: 'FileLeafRef',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                onRender: (item: IDocumentosInspeccionHomologacion) => {
                                    if (item.DocumentosEstadoId === 3) return <input type="file"
                                        onChange={(File) => {
                                            const documento = [File.target.files[0]];
                                            const metadata = [{
                                                Detalle: item.Detalle,
                                                DocumentosTipoId: item.DocumentosTipo.lookupId,
                                                DocumentosEstadoId: 1,
                                                FaseId: 1,
                                                IdDocumento: item.ID,
                                                key: undefined,
                                                nombreDocumentoAntiguo: item.FileLeafRef,
                                            }]
                                            this._onUploadFile(documento, [], metadata, true);
                                        }}
                                    />
                                    else return "No se puede resubir este documento."
                                }
                            },
                        ]}
                    />
                )}
                <PanelDoctoInspeccion
                    onClose={() => this._onClosePanel}
                    onDescargarDocumento={this._onDownloadDocumento}
                    onAddComentario={this._onAddComentarioInspeccion}
                    isOpen={isOpenPanel}
                    selectedDocument={selectedDocument}
                    comentariosList={comentariosInspeccionList}
                    loadingComentarios={loadingComentarios}
                    disabled={itemInspeccion && itemInspeccion.EstadoInspeccionId !== EnumEstadoInspeccion.EnProceso}
                    usuarioProveedor={usuarioProveedorId}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentosInspeccion);