import {
    CHANGEMAXSIZE,
    GOTOPAGE,
    LOADING, 
    NEXTPAGE, 
    PREVPAGE,
    GET_MAESTROMATERIALES,
    LOADING_SHOWMAESTROMATERIAL,
    INITIAL_SHOWLIST,
} from './constants';

import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions';

import apiConfig from '../../config/apiConfigOficial';
import { IMaestroMaterial } from './interfaces';

// Decalaraciones de las acciones
interface HanldeLoadingAction { type: typeof LOADING; loading: boolean; }
interface HandleLoadingMaestroMaterialAction { type: typeof LOADING_SHOWMAESTROMATERIAL; loading: boolean; }

interface SetNextPageAction { type: typeof NEXTPAGE; nextPage: number; showList: IMaestroMaterial[]; }
interface SetPrevPageAction { type: typeof PREVPAGE; prevPage: number; showList: IMaestroMaterial[]; }
interface MoveToPageAction { type: typeof GOTOPAGE; page: number; showList: IMaestroMaterial[]; }
interface SetMaxSizeAction { type: typeof CHANGEMAXSIZE; size: number; showList: IMaestroMaterial[]; }

interface GetMaestroMaterialesAction { type: typeof GET_MAESTROMATERIALES; materialesList: IMaestroMaterial[]; }
interface SetInitialShowDataAction { type: typeof INITIAL_SHOWLIST; maxSize: number; showList: IMaestroMaterial[]; }

// lista de acciones conocidas
export type MaestroMaterialesKnownAction =
    MainKnownAction
|   HanldeLoadingAction
|   HandleLoadingMaestroMaterialAction
|	SetNextPageAction
|	SetPrevPageAction
|	MoveToPageAction
|	SetMaxSizeAction
|   GetMaestroMaterialesAction
|   SetInitialShowDataAction
;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

export const MaestroMaterialesActionCreators = {
    handleLoading: (loading) => ({ type: LOADING, loading } as HanldeLoadingAction),

    handleLoadingMaestroMaterial: (loading: boolean) => ({type: LOADING_SHOWMAESTROMATERIAL, loading } as HandleLoadingMaestroMaterialAction),
	
	setInitialData: (maxSize: number) :AppThunkAction<MaestroMaterialesKnownAction> => async (dispatch, getState) => {
		const state = getState();
		if (state && state.maestroMateriales && state.maestroMateriales.MaterialesList){
			const showList: IMaestroMaterial[] = state.maestroMateriales.MaterialesList.slice(0, maxSize);
			dispatch({type: INITIAL_SHOWLIST, maxSize, showList });
		}
	},

	nextPage: (): AppThunkAction<MaestroMaterialesKnownAction> => async (dispatch, getState) => {
		const state = getState();
		if (state && state.maestroMateriales && state.maestroMateriales.MaterialesList){
			const endPage: number = (state.maestroMateriales.pagina + 1) * state.maestroMateriales.maxSize;
			const showList: IMaestroMaterial[] = state.maestroMateriales.MaterialesList.slice(endPage-state.maestroMateriales.maxSize, endPage);
            dispatch({type: NEXTPAGE, nextPage:  state.maestroMateriales.pagina + 1, showList });
		}
	},

	prevPage: (): AppThunkAction<MaestroMaterialesKnownAction> => async (dispatch, getState) => {
		const state = getState();
		if (state && state.maestroMateriales && state.maestroMateriales.MaterialesList){
			const endPage: number = ((state.maestroMateriales.pagina - 1) * state.maestroMateriales.maxSize);
			const showList: IMaestroMaterial[] = state.maestroMateriales.MaterialesList.slice(endPage-state.maestroMateriales.maxSize, endPage);
            dispatch({type: PREVPAGE, prevPage:  state.maestroMateriales.pagina - 1, showList });
		}
	},

	gotoPage: (page: number): AppThunkAction<MaestroMaterialesKnownAction> => async (dispatch, getState) => {
		const state = getState();
		if (state && state.maestroMateriales && state.maestroMateriales.MaterialesList){
			const endPage: number = page * state.maestroMateriales.maxSize;
			const showList: IMaestroMaterial[] = state.maestroMateriales.MaterialesList.slice(endPage-state.maestroMateriales.maxSize, endPage);
            dispatch({type: GOTOPAGE, page, showList });
		}
	},

	changeMaxSize: (size: number): AppThunkAction<MaestroMaterialesKnownAction> => async (dispatch, getState) => {
		const state = getState();
		if (state && state.maestroMateriales && state.maestroMateriales.MaterialesList){
			const showList: IMaestroMaterial[] = state.maestroMateriales.MaterialesList.slice(0, size);
            dispatch({type: CHANGEMAXSIZE, size, showList });
		}
	},
    
    getMaterialesList: (rutProveedor: string): AppThunkAction<MaestroMaterialesKnownAction> => async (dispatch) => {
        
		fetch(`${apiConfig.apiUri}/MaestroMateriales/GetMaterialesByProveedor`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify({ rutProveedor: rutProveedor }),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IMaestroMaterial[]>;
            })
            .then(data => {
                dispatch({ type: GET_MAESTROMATERIALES, materialesList: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    }
};