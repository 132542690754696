import React from 'react';
import {IProcesoSuministro} from '../../ItemSuministro/interfaces';
import styles from './Resumen.module.scss';

interface IResumeViewProps {
  itemSuministro: IProcesoSuministro;
}


export const Resumen: React.FunctionComponent<IResumeViewProps> = ({itemSuministro}) => {
  return (
    itemSuministro? (
      <div className={styles.resumenContainer}>
           <div className={styles.row}>
              <div className={styles.columnLeft}>ID: </div>
              <div className={styles.columnRight}><b>{itemSuministro.ID}</b></div>
          </div>            
                <>
                <div className={styles.row}>
                    <div className={styles.columnLeft}>OC asociada:</div>
                    <div className={styles.columnRight}><b>{itemSuministro.Title}</b></div>
                </div>
                <div className={styles.row}>
                    <div className={styles.columnLeft}>Nombre Suministro <i>[Código SAP]</i>:</div>
                    <div className={styles.columnRight}><b>{itemSuministro.NombreSuministro.lookupValue} <i>[{itemSuministro.NombreSuministro_x003a_CodigoSAP.lookupValue}]</i></b></div>
                </div>
                <div className={styles.row}>
                    <div className={styles.columnLeft}>Proveedor:</div>
                    <div className={styles.columnRight}><b>{`[${itemSuministro.NombreProveedor_x003a_Rut.lookupValue}] ${itemSuministro.NombreProveedor.lookupValue}`}</b></div>
                </div>

                <div className={styles.row}>
                    <div className={styles.columnLeft}>Cantidad:</div>
                    <div className={styles.columnRight}><b>{itemSuministro.Cantidad}</b></div>
                </div>
                <div className={styles.row}>
                    <div className={styles.columnLeft}>Proyecto:</div>
                    <div className={styles.columnRight}><b>{itemSuministro.Proyecto}</b></div>
                </div>
                  </>

      </div>
      ): (<></>)
  )
}

export default Resumen