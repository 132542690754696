import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IDocumentoSuministro, IProcesoSuministro } from '../interfaces';
import { CustomTextField } from '../../Shared/CustomFields/TextField';
import styles from '../ItemSuministro.module.scss';
import { Separador } from '../../Shared/Separador/separador';
import { ItemSuministroActionCreators } from '../actions';
import { Loading } from '../../Shared/Loading/loading';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { CustomIconButton } from '../../Shared/IconButton';
import ApiConfig from '../../../config/apiConfigOficial';
import Resumen from '../../Shared/Resumen/Resumen';

interface IConnectedState {
    itemSuministro: IProcesoSuministro;
    loadingAntecedentes: boolean;
    doctosAntecedentes: IDocumentoSuministro[];
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        itemSuministro: state.itemSuministro.itemSuministro,
        loadingAntecedentes: state.itemSuministro.loadingDoctosAntecedentes,
        doctosAntecedentes: state.itemSuministro.doctosAntecedentesList,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IAntecedentesOwnProps {
}

interface IAntecedentesState {
}

// define combined props
type IAntecedentesProps = IAntecedentesOwnProps & IConnectedState & { dispatch(action: any): void };

class Antecedentes extends React.PureComponent<IAntecedentesProps, IAntecedentesState> {

    public componentDidMount = (): void => {

        const { itemSuministro } = this.props;

        if (itemSuministro){

            this.props.dispatch(ItemSuministroActionCreators.loadingDocumentosAntecedentesByProveedor(true));

            this.props.dispatch(ItemSuministroActionCreators.getDocumentosAntecedentesByProveedor(itemSuministro.CodigoSuministroPadre ? itemSuministro.CodigoSuministroPadre.lookupId :  itemSuministro.ID));

        }

    }

    private _onDownloadDocument = (uri: string, fileName: string):void => {

        this.props.dispatch(ItemSuministroActionCreators.downloadDocumento(uri,fileName));

    }

    public render(): React.ReactNode {

        const { itemSuministro, loadingAntecedentes, doctosAntecedentes} = this.props;

        return itemSuministro && (
            <div className= { "ms-Grid ".concat(styles.AntecedentesContainer)} dir="ltr" >
                <h2>Antecedentes</h2>
                <Separador />
                {/* <CustomTextField id='ocasociada' label='OC Asociada' defaultValue={itemSuministro.Title} onChange={() => void(0)} disabled />
                <CustomTextField id='Suministro' label='Suministro' defaultValue={itemSuministro.NombreSuministro.lookupValue} onChange={() => void(0)} disabled />
                <CustomTextField id='cantidad' label='Cantidad' defaultValue={itemSuministro.Cantidad}  onChange={() => void(0)} disabled />
                <CustomTextField id='tipoSuministro' label='Tipo de suministro' defaultValue={itemSuministro.TipoSuministro.lookupValue} onChange={() => void(0)} disabled /> */}
                <Resumen itemSuministro={itemSuministro} />
                <Separador subtitle mensaje='Documentos'/>
                {loadingAntecedentes && <Loading fullScreen={false} message='Cargando documentos de antecedentes' />}
                {doctosAntecedentes && (
                    <CustomDetailsList
                        items={doctosAntecedentes}
                        columns={[
                            {
                                key: 'columniconfileDocSuministro',
                                name: 'iconfile',
                                fieldName: 'iconfile',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Page',
                                clickToSort: false,
                            },
                            {
                                key: 'columnDownload',
                                name: 'download',
                                fieldName: 'FileRef',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Link',
                                clickToSort: false,
                                onRender: (item: IDocumentoSuministro) => (<CustomIconButton id={`downloadButton${item.FileLeafRef}`} icon='Link' onClick={() => this._onDownloadDocument(`${ApiConfig.baseUriDocuments}${item.FileRef}`, item.FileLeafRef)} label='Descargar' />)
                            },
                            {
                                key: 'columnNombreArchivo',
                                name: 'Nombre de archivo',
                                fieldName: 'FileLeafRef',
                                isRowHeader: true,
                                minWidth: 200,
                                maxWidth: 300,
                                isResizable: true,
                            },
                            {
                                key: 'columnTipoDocumento',
                                name: 'Tipo de documento',
                                fieldName: 'DocumentosTipo',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item : IDocumentoSuministro) => item.DocumentosTipo? item.DocumentosTipo.lookupValue : 'Tipo desconocido'
                            },
                            {
                                key: 'columnDetalle',
                                name: 'Nombre Documento',
                                fieldName: 'CodigoDoctoRequerido_x003a_Detalle',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: (item: IDocumentoSuministro) => item.CodigoDoctoRequerido_x003a_Detalle? item.CodigoDoctoRequerido_x003a_Detalle.lookupValue : 'Detalle desconocido'
                            },
                        ]}
                        addFilterInput={false}
                    />
                )}
            </div>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Antecedentes);

