import {
    SIGNIN,
    RESPONSE_SIGNIN,
    LOADING,
    SIGNOUT,
    MESSAGEERROR,
    CLEANMESSAGEERROR,
    SHOWMESSAGEBAR,
    HIDEMENSAGEBAR,
    RESPONSE_RECOVERPASSWD
} from './constants';

import { AppThunkAction } from '../../config/store/';
import { ILogin, IMS365, IUsuarioLogin, ILocalLogin, IFusionAuthInfo } from '../utils/interfaces';

import apiConfig from '../../config/apiConfigOficial';
import { EnumTipoAcceso } from '../utils/enums';

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface MainShowMessageAction {
    type: typeof SHOWMESSAGEBAR;
    message: string;
    code: string;
}

interface MainLoadingAction { type: typeof LOADING; loading: boolean; }
interface MainCleanMessageAction { type: typeof CLEANMESSAGEERROR; }
interface MainSignOutAction { type: typeof SIGNOUT; }
interface MainRestoreSignInAction { type: typeof SIGNIN; user: IUsuarioLogin }
interface MainSignIn { type: typeof RESPONSE_SIGNIN; user: IUsuarioLogin; authType: string }
interface MainMessageErrorAction { type: typeof MESSAGEERROR, messageError: string }
interface MainHideMessageAction { type: typeof HIDEMENSAGEBAR }
interface MainResponse_RecoverPasswd {type : typeof RESPONSE_RECOVERPASSWD; user: IUsuarioLogin; authType: string}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type MainKnownAction =
    MainShowMessageAction
|   MainLoadingAction
|   MainCleanMessageAction
|   MainSignOutAction
|   MainRestoreSignInAction
|   MainSignIn
|   MainMessageErrorAction
|   MainHideMessageAction
|   MainResponse_RecoverPasswd
;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const MainActionCreators = {

    handleLoading: (loading) => ({ type: LOADING, loading } as MainLoadingAction),
    clearMessageError: () => ({ type: CLEANMESSAGEERROR } as MainCleanMessageAction),
    sendMessageError: (messageError: string) => ({ type: MESSAGEERROR, messageError} as MainMessageErrorAction),

    signOut: () => {
        // limpio mi almacen
        sessionStorage.clear();
        return({ type: SIGNOUT }) as MainSignOutAction;
    },

    restoreSignIn: (user: IUsuarioLogin) => ({ type: SIGNIN, user } as MainRestoreSignInAction),

    signInLocal: (auth: ILocalLogin): AppThunkAction<MainKnownAction> => dispatch => {
        const LoginData: ILocalLogin = {
            usuario: auth.usuario,
            password: auth.password,
            acceso: EnumTipoAcceso.Local
        };
        fetch(`${apiConfig.apiUri}/login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(LoginData),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<ILogin>;
            })
            .then(data => {
                if (data.usuarioLogin.tokenAPI !== null) {
                    // limpio las sesiones
                    sessionStorage.clear();
                    // almaceno en sesión dejo al usuario que tiene toda la información
                    sessionStorage.setItem(apiConfig.UserStorageKey, JSON.stringify(data.usuarioLogin));
                    sessionStorage.setItem(apiConfig.AccessTokenKey, data.usuarioLogin.tokenAPI);
                    sessionStorage.setItem(apiConfig.UserAccessEmail, data.usuarioLogin.usuario);
                    dispatch({ type: RESPONSE_SIGNIN, user: data.usuarioLogin, authType: EnumTipoAcceso.Local });
                } else {
                    dispatch({ type: MESSAGEERROR, messageError: data.usuarioLogin.messageError! });
                }
            })
            .catch(error => dispatch({ type: MESSAGEERROR, messageError: error.toString() }));
    },

    signInMS365: (auth: IMS365): AppThunkAction<MainKnownAction> => dispatch => {
        const LoginData = {
            acceso: EnumTipoAcceso.MS365,
            tokenMS365: auth.idToken.rawIdToken,
            usuario: auth.account.userName
        };
        fetch(`${apiConfig.apiUri}/login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(LoginData),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<ILogin>
            })
            .then(data => {
                if (data.usuarioLogin.tokenAPI !== null) {
                    // limpio, las sesiones
                    sessionStorage.clear();
                    // almaceno en sesión dejo al usuario que tiene toda la información
                    sessionStorage.setItem(apiConfig.AccessTokenKeyMS365, auth.idToken.rawIdToken);
                    sessionStorage.setItem(apiConfig.UserStorageKey, JSON.stringify(data.usuarioLogin));
                    sessionStorage.setItem(apiConfig.AccessTokenKey, data.usuarioLogin.tokenAPI);
                    sessionStorage.setItem(apiConfig.UserAccessEmail, data.usuarioLogin.usuario);
                    // autentico
                    dispatch({ type: RESPONSE_SIGNIN, user: data.usuarioLogin, authType: EnumTipoAcceso.MS365 });
                } else {
                    dispatch({ type: MESSAGEERROR, messageError: data.usuarioLogin.messageError! });
                }
            })
            .catch(error => dispatch({ type: MESSAGEERROR, messageError: error.toString() }));
    },

    signInFusionAuth: (auth: IFusionAuthInfo): AppThunkAction<MainKnownAction> => dispatch => {
        const LoginData = {
            acceso: EnumTipoAcceso.FusionAuth,
            code: auth.code,
            locale: auth.locale,
            userState : auth.userState
        };

        fetch(`${apiConfig.apiUri}/login/GetTokenByCode`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(LoginData),
        })        
        .then(response => {
            if (!response.ok) throw Error(response.status.toString());
            return response.json() as Promise<ILogin>
        })
        .then(data => {
             if (data.usuarioLogin.tokenOAuth !== null) {
                // limpio, las sesiones
                //debugger;
                sessionStorage.clear();
                // almaceno en sesión dejo al usuario que tiene toda la información
                //sessionStorage.setItem(apiConfig.AccessTokenKey, data.usuarioLogin.tokenFAuth);
                sessionStorage.setItem(apiConfig.UserStorageKey, JSON.stringify(data.usuarioLogin));
                sessionStorage.setItem(apiConfig.AccessTokenKey, data.usuarioLogin.tokenOAuth);
                sessionStorage.setItem(apiConfig.UserAccessEmail, data.usuarioLogin.usuario);
                //console.log(data);
                // autentico
                dispatch({ type: RESPONSE_SIGNIN, user: data.usuarioLogin, authType: EnumTipoAcceso.FusionAuth });
            } else {
                dispatch({ type: MESSAGEERROR, messageError: data.usuarioLogin.messageError! });
            }
        })
        .catch(error => dispatch({ type: MESSAGEERROR, messageError: error.toString() }));
    },

    restorePasswd: (auth: IFusionAuthInfo): AppThunkAction<MainKnownAction> => dispatch => {
        const LoginData = {
            acceso: EnumTipoAcceso.FusionAuth,
            code: auth.code,
            locale: auth.locale,
            userState : auth.userState
        };

        fetch(`${apiConfig.apiUri}/login/GetTokenByCodePassChange`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(LoginData),
        })        
        .then(response => {
            if (!response.ok) throw Error(response.status.toString());
            return response.json() as Promise<ILogin>
        })
        .then(data => {
             if (data.usuarioLogin.tokenOAuth !== null) {
                // limpio, las sesiones
                //debugger;
                sessionStorage.clear();
                // almaceno en sesión dejo al usuario que tiene toda la información
                //sessionStorage.setItem(apiConfig.AccessTokenKey, data.usuarioLogin.tokenFAuth);
                sessionStorage.setItem(apiConfig.UserStorageKey, JSON.stringify(data.usuarioLogin));
                sessionStorage.setItem(apiConfig.AccessTokenKey, data.usuarioLogin.tokenOAuth);
                sessionStorage.setItem(apiConfig.UserAccessEmail, data.usuarioLogin.usuario);
                //console.log(data);
                // autentico
                dispatch({ type: RESPONSE_RECOVERPASSWD, user: data.usuarioLogin, authType: EnumTipoAcceso.FusionAuth });
            } else {
                dispatch({ type: MESSAGEERROR, messageError: data.usuarioLogin.messageError! });
            }
        })
        .catch(error => dispatch({ type: MESSAGEERROR, messageError: error.toString() }));
    },

    onShowMessageBar: (message: string, code?: string) => ({ type: SHOWMESSAGEBAR, message, code } as MainShowMessageAction),
    onHideMessageBar: () => ({ type: HIDEMENSAGEBAR } as MainHideMessageAction),
};