import * as React from 'react';
import { ApplicationState } from '../../config/store';
import { useSelector } from 'react-redux';
import Routes from '../../config/routes';
import { Layout } from '../Layout/index';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import Header from '../Header/header'
import { IUsuarioLogin } from '../utils/interfaces';
import { EnumPermissionUserType } from '../utils/enums';

export const RootAuthRoutes: React.FunctionComponent = () => {
    const user: IUsuarioLogin = useSelector( (state: ApplicationState) => state.main.user );
    const { proveedor, proveedorInspector } = user.usuarioProveedor;
    
    return (
        <Layout>
            <Router>
                {/* El Header, es quien manejas las rutas, por lo que tiene que ir incluido en el Router!*/}
                <Header />
                <Switch>
                    {Routes.map( r => {
                        if( r.permission === EnumPermissionUserType.Any || (proveedor && r.permission === EnumPermissionUserType.Proveedor) || (proveedorInspector && r.permission === EnumPermissionUserType.Inspector)){
                            return (<Route key={r.path} exact path={r.path} component={r.component} />);
                        }
                    })}
                    {/* {Routes.map(r => r.permission === EnumPermissionUserType.Any || (proveedor && r.permission === EnumPermissionUserType.Proveedor) || (proveedorInspector && r.permission === EnumPermissionUserType.Inspector) ? <Route key={r.path} exact path={r.path} component={r.component} /> : undefined)} */}
                </Switch>
            </Router>
        </Layout>
    );
}
