import * as React from 'react';
import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/index';

/* const ExampleOptions: IComboBoxOption[] = [
  { key: 'Header1', text: 'First heading', itemType: SelectableOptionMenuItemType.Header },
  { key: 'A', text: 'Option A' },
  { key: 'B', text: 'Option B' },
  { key: 'C', text: 'Option C' },
  { key: 'D', text: 'Option D' },
  { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
  { key: 'Header2', text: 'Second heading', itemType: SelectableOptionMenuItemType.Header },
  { key: 'E', text: 'Option E' },
  { key: 'F', text: 'Option F', disabled: true },
  { key: 'G', text: 'Option G' },
  { key: 'H', text: 'Option H' },
  { key: 'I', text: 'Option I' },
  { key: 'J', text: 'Option J' },
]; */

interface ICommonComboBoxProps {
    id: string;
    options: IComboBoxOption[];
    key: string;
    onChange: any;
    label?: string;
    autocomplete?: 'on' | 'off';
    selectedKey?: string | number;
    defaultSelectedKey?: string | number;
    disabled?:boolean;
}

export const CommonComboBox: React.FunctionComponent<ICommonComboBoxProps> = (props) => {
  return (
    <ComboBox
        { ...props }
        allowFreeform={false}
        onChange= {(ev, options) => props.onChange({target:{ id: props.id }},options)}
    />
  );
};
