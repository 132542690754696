import { ICustomList } from '../Shared/CustomFields/DropdownField';
import apiConfig from '../../config/apiConfigOficial';
import _ from 'lodash';
import { IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/index';
import { IDocumentosTipo } from '../ItemSuministro/interfaces';
import { IDataParams } from './interfaces';
import { EnumEstadoInspeccion } from './enums';

export const DateFormat = (date: Date | string): string => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
}

export const DateFormatInput = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const DateSystem = (date: Date | string): Date => {
    let newDate = new Date();
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    if (typeof date === 'string') {
        let splitDate = date.split("-");
        if (splitDate.length !== 3) return newDate;
        if (splitDate[0].length === 4) {
            // aquí viene en formato yyyy-mm-dd
            newDate.setFullYear(parseInt(splitDate[0], 10));
            newDate.setMonth(parseInt(splitDate[1], 10)-1);
            newDate.setDate(parseInt(splitDate[2], 10));
        } else if (splitDate[0].length === 2) {
            // aquí viene en formato dd-mm-yyyy o dd-mm-yy
            newDate.setDate(parseInt(splitDate[0], 10));
            newDate.setMonth(parseInt(splitDate[1], 10)-1);
            newDate.setFullYear(parseInt(splitDate[2], 10));
        } else return newDate;
    } else {
        newDate.setFullYear(date.getFullYear());
        newDate.setMonth(date.getMonth());
        newDate.setDate(date.getDate());
    }
    return newDate;
} 

/**
 * Función que retorna los siguientes @days días a partir de una fecha.
 * @param date
 * @param days
 */
export const nextDaysMondayFriday = (date: Date, days: number): ICustomList[] => {
    let list: ICustomList[] = [];
    let auxDate = DateSystem(date);

    let max = Math.ceil(days / 2);

    for (let i = 0; i < max; i += 1) {

        // defino el siguiente lunes
        auxDate.setDate(auxDate.getDate() + (1 + 7 - auxDate.getDay()) % 7);
        list.push({
            key: DateFormatInput(auxDate),
            text: `Lunes ${DateFormat(auxDate)}`,
        });

        // defino el siguiente viernes
        auxDate.setDate(auxDate.getDate() + 4);

        list.push({
            key: DateFormatInput(auxDate),
            text: `Viernes ${DateFormat(auxDate)}`,
        });

    }
    return list;
}

export const LanguageTranslation = (textToTranslate: string, extraAttrs: undefined | Array<any> = undefined ): string => {
    // tengo que encontrar otra manera para hacer la llamada al menú
    const StorageUser = JSON.parse(sessionStorage.getItem(apiConfig.UserStorageKey)!);

    if ( !StorageUser || StorageUser === null ) {
        return textToTranslate;
    }
    const { _Controles } = StorageUser.usuarioRol;

    let textTranslated = _Controles[textToTranslate];

    // textTranslated.match(/\[[A-Z\_]*\]/) !== null

    if (textTranslated) {
        if (extraAttrs) {
            extraAttrs.forEach((attr, k) => {
                textTranslated = textTranslated.replace(`@@attr${k + 1}@@`, attr);
            });
            return textTranslated;
        }
        return textTranslated;
    } else {
        return textToTranslate;
    }
}

export const CheckActionPermission = (permission: string) : boolean => {
    const StorageUser = JSON.parse(sessionStorage.getItem(apiConfig.UserStorageKey)!);
    const { _Funciones } = StorageUser.usuarioRol;
    if ( _Funciones.includes(permission) ) {
        return true;
    }
    return false;
}

export const NumberFormat = (number: number, currency: string = 'USD', divideMil: boolean = true): string => {
    const StorageUser = JSON.parse(sessionStorage.getItem(apiConfig.UserStorageKey)!);
    const { ididioma } = StorageUser.usuarioRol;

    // NONONONONO
    const espaniol = 'C64D07E0-07FB-4AC2-9E19-7D3D77F5D26C'.toLowerCase();
    const ingles = '7D7037CC-6DF8-4019-AD75-D0C9FB749B7B'.toLowerCase();
    const portugues = '6153DA92-8966-4913-A60A-93224ACD64FB'.toLowerCase();

    let language: string = '';

    switch (ididioma) {
        case espaniol:
            language = 'es';
            break;
        case portugues:
            language = 'pt';
            break;
        default:
        case ingles:
            language = 'en';
            break;
    }

    let milNumber: number = number;

    /*if (divideMil) {
        milNumber = number / 1000;
    }*/

    let stringNumber: string = '';

    switch (language) {
        case 'br':
            const numberFormatBR = new Intl.NumberFormat('pt-BR', { style: 'currency', currency });
            stringNumber = numberFormatBR.format(milNumber);
            break;
        case 'es':
            const numberFormatES = new Intl.NumberFormat('es-ES', { style: 'currency', currency });
            stringNumber = numberFormatES.format(milNumber);
            break;
        default:
        case 'en':
            const numberFormatUS = new Intl.NumberFormat('en-US', { style: 'currency', currency });
            stringNumber = numberFormatUS.format(milNumber);
            break;
    }

    return stringNumber;
}

export const NumberFormatTraditional = (value: string, isReverse: boolean = false): string | number => {
    // aquí llega un string y envío el numero
    if (isReverse) {
        let numberValue: string = value;
        while (numberValue.indexOf('.') > -1) {
            numberValue = numberValue.replace('.', '');
        }
        numberValue = numberValue.replace(',', '.');
        return parseFloat(numberValue);
    }
    let valueWithFormatt: string = '';
    let cleanValue: string = '';
    let valueDecimal: Array<string> = [];
    const regExpNumber = /[.|&;$%@"'´<>()¿?+a-zA-Z]/g;

    // reviso si tiene una coma, para separación de decimal
    if (value.indexOf(',') > -1) {
        valueDecimal = value.split(',');
        cleanValue = valueDecimal[0].replace(regExpNumber, "");

    } else {
        cleanValue = value.replace(regExpNumber, "");
    }
    // limpio el valor
    while (cleanValue.indexOf('.') > -1) {
        cleanValue = cleanValue.toString().replace('.', '');
    }
    valueWithFormatt = cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    if (valueDecimal.length > 0) {
        valueWithFormatt = valueWithFormatt.concat(',', valueDecimal[1].replace(regExpNumber, ""));
    }
    return valueWithFormatt;
}

export const NumberFormatTraditionalShow = (value: number): string => {
    let stringValue: string = value.toString().replace('.', ',');
    return NumberFormatTraditional(stringValue).toString();
}

/*
1: erlier es mayor
-1: later es mayor
0: son iguales
*/
export const CompareDates = (DateErlier: Date, DateLater: Date): number => {
    let date : number = 0;
    // comparo años
    if (DateErlier.getFullYear() > DateLater.getFullYear()) {
        date = 1;
    } else if (DateErlier.getFullYear() < DateLater.getFullYear()) {
        date = -1;
    } else {
        // comparo meses
        if (DateErlier.getMonth() > DateLater.getMonth()) {
            date = 1
        } else if (DateErlier.getMonth() < DateLater.getMonth()) {
            date = -1;
        } else {
            // comparo días
            if (DateErlier.getDate() > DateLater.getDate()) {
                date = 1;
            } else if (DateErlier.getDate() < DateLater.getDate()) {
                date = - 1;
            } else {
                date = 0;
            }
        }
    }
    return date;

}

export const isEqualArrayObject = (listaUno: any[] = [], listaDos: any[] = []) :boolean => {
    // si los tamaños son distintos

    if( (!listaDos && listaUno) || (!listaUno && listaDos) ){
        return false;
    }

    if (listaUno.length !== listaDos.length){
        return false;
    }
    // los largo son iguales.
    if (listaUno.length > 0){
        for (let i in listaUno){
            if (!_.isEqual(listaUno[i],listaDos[i])){
                return false;
            }
          }
    }
    if (listaDos.length > 0){
        for (let i in listaDos){
            if (!_.isEqual(listaDos[i], listaUno[i])){
                return false;
            }
        }
    }

    return true;
};

export const parseDatetime = (date: Date | string): string => {

    let parseDate: Date;

    if (date instanceof Date){
        parseDate = date;
    } else {
        parseDate = new Date(date);
    }

    let mm: string = ''+parseDate.getMonth()+1;
    let dd: string = ''+parseDate.getDate();
    let yy: number = parseDate.getFullYear();

    let hs: string = ''+parseDate.getHours();
    let ms: string = ''+parseDate.getMinutes();

    if (mm.length < 2)
        mm = '0' + mm;
    if (dd.length < 2)
        dd = '0' + dd;
    if (hs.length < 2)
        hs = '0'+hs;
    if (ms.length < 2)
        ms = '0'+ms;
  
    return [dd, mm, yy].join('/').concat(' ',[hs, ms].join(':'));
};

export function ConvertSharepointDate (SharepointDate: string) {
    let splitDateTime = SharepointDate.split("T");
    let splitDate = splitDateTime[0].split("-");

    let fecha = splitDate.reverse().join('-');
    let fechaHora = fecha.concat(' ', splitDateTime[1].replace('Z', ''));

    return fechaHora;
}

export function OrdenaDocumentosTiposList (documentosTipoList: IDocumentosTipo[]): IComboBoxOption[]  {
    let titleList: { Fase: string, options: IDocumentosTipo[] }[] = [];
    let tiposOptionsList: IComboBoxOption[] = [];
  
    for (let td=0; td<documentosTipoList.length; td+=1){
        const { MaestroFases } = documentosTipoList[td];
        // tengo que ordenar por fases, para que sea como el título.
        let hasTitle = titleList.filter( t => t.Fase === MaestroFases.lookupValue );
        if ( hasTitle.length > 0){
            // existe
            const title: { Fase: string, options: IDocumentosTipo[] } = hasTitle[0];
            let opt: IDocumentosTipo[] = title.options;
            opt.push({...documentosTipoList[td] });
            titleList = titleList.filter( t => t.Fase !== MaestroFases.lookupValue );
            titleList.push({
                Fase: MaestroFases.lookupValue,
                options: opt,
            });
        } else {
            // no existe, la creo
            titleList.push({
                Fase: MaestroFases.lookupValue,
                options: [{...documentosTipoList[td]}],
            });
        }
    }
  
    for(let l=0; l<titleList.length; l+=1){
      const { Fase, options } = titleList[l];
      tiposOptionsList.push({ key: Fase, text: Fase, itemType: SelectableOptionMenuItemType.Header });
      for (let o=0; o<options.length; o+=1){
        const { Title, ID } = options[o];
        tiposOptionsList.push({ key: ID, text: Title });
      }
    }
  
    return tiposOptionsList;
}

export function CleanTextEncode (text: string): string {
    let textReturn: string = text;

    while (textReturn.includes(" ")){
        textReturn = textReturn.replace(" ","x20");
    }

    return textReturn;
}

export function BuildEstadosInspeccion ():IComboBoxOption[] {
    const estadosInpseccionOptions: IComboBoxOption[] = [];

    const enumKeyList = Object.keys(EnumEstadoInspeccion);
    const lengthSplit: number = enumKeyList.length/2;

    const enumKeys = enumKeyList.slice(undefined,lengthSplit);
    const enumValues = enumKeyList.slice(lengthSplit);

    for (let e=0; e<lengthSplit; e+=1) {
        estadosInpseccionOptions.push({
            key: Number(enumKeys[e]),
            text: enumValues[e],
        });
    }

    return estadosInpseccionOptions;
}

export function isImageDocto( filename: string) : boolean {
    const photosList: string[] = ['png','jpg', 'jpeg', 'raw', 'bmp', 'gif', 'svg'];
    let filnameSplited = filename.split(".");
    let extName = filnameSplited[filnameSplited.length -1];
    return photosList.includes(extName);
}

export function parseParamsDataFromURL(data : string){
    let params : string[] = data.split('?');
    let returnData : IDataParams[] = [];		
    if(params.length > 1){			
        if(params.length === 2 && params[1].indexOf('=') > -1 && params[1].indexOf('&') < 0){
            let fParam : string[] = params[1].split('=');
            let dataP : IDataParams = { key : fParam[0], value : fParam[1] };
            returnData.push(dataP);
        }else{
            let subParams : string[] = params[1].split('&');

            subParams.map((subp) => {
                let fParamI : string[] = subp.split('=');
                //console.log(fParamI);
                let dataPI : IDataParams = { key : fParamI[0], value : fParamI[1] };
                returnData.push(dataPI);
                return true;
            });
        }
    }
    return returnData;
}