import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { ItemSuministroActionCreators } from '../actions';
import { IProcesoSuministro,
    IDocumentoSuministro,
    IDocumentosTipo,
    IComentarioBase,
    IDocumentoRequerido,
    IDocumentosRequeridosItem,
    IMetadataDocumentoItem,
} from '../interfaces';
import UploadFile from '../../Shared/UploadFile/uploadFile';
import { CommonComboBox } from '../../Shared/CustomFields/ComboBoxField';
import { IComboBoxOption } from 'office-ui-fabric-react/lib/index';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { CustomIconButton } from '../../Shared/IconButton';
import { EnumDocumentosTipo, EnumEstadosDocumentosSuministros, EnumEtapasSuministro, EnumFaseDocumentos, EnumTipoSuministro } from '../../utils/enums';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import PanelDocumento from './PanelDocumento';
import { isEqualArrayObject, OrdenaDocumentosTiposList } from '../../utils/functions';

import styles from '../ItemSuministro.module.scss';

import { Separador } from '../../Shared/Separador/separador';
import { CustomTextField } from '../../Shared/CustomFields/TextField';
import { DialogModal } from '../../Shared/DialogModal';
import { IUsuarioLogin } from '../../utils/interfaces';
import ApiConfig from '../../../config/apiConfigOficial';
import { Loading } from '../../Shared/Loading/loading';
import Resumen from '../../Shared/Resumen/Resumen';

const baseContainerClass =  mergeStyles({
    color: 'white',
    borderRadius: 20,
    padding: '6px 20px',
    textAlign: 'center'
});
  
const containerClass = mergeStyleSets({
    aprobado: [{ backgroundColor: 'green' }, baseContainerClass],
    rechazado: [{ backgroundColor: 'red' }, baseContainerClass],
    enRevision: [{ backgroundColor: 'gold'  }, baseContainerClass],
    sinDocumento: [{ backgroundColor: 'gray' }, baseContainerClass],
});

  
const iconClass = mergeStyles({
    fontSize: 15,
    padding: '0px 2px',
    marginTop: 1,
});

interface IConnectedState {
    itemSuministro: IProcesoSuministro;
    tipoDocumentosSuministro: IDocumentosTipo[];
    comentariosList: IComentarioBase[];
    documentosList: IDocumentoSuministro[];
    loadingComentarios: boolean;
    doctosRequeridosList: IDocumentoRequerido[];
    doctosExistentesList: IDocumentoSuministro[];
    user: IUsuarioLogin;
    loadingDocumentos: boolean;
    loadingDoctosRequeridos: boolean;
    currentUser : IUsuarioLogin;
}

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        itemSuministro: state.itemSuministro.itemSuministro,
        tipoDocumentosSuministro: state.itemSuministro.tipoDocumentosSuministro,
        comentariosList: state.itemSuministro.comentariosList,
        documentosList: state.itemSuministro.documentosList,
        loadingComentarios: state.itemSuministro.loadingComentarios,
        doctosRequeridosList: state.itemSuministro.doctosRequeridosList,
        doctosExistentesList: state.itemSuministro.doctosExistentesList,
        user: state.main.user,
        loadingDocumentos: state.itemSuministro.loadingDocumentos,
        loadingDoctosRequeridos: state.itemSuministro.loadingDoctosRequeridos,
        currentUser : state.main.user
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IEntregablesOwnProps {
}

interface IEntregablesState {
    isLoaded: boolean;
    // para OTROS doctos, que no se consideran en la subida.
    metadataDocumentosList: IMetadataDocumentoItem[];
    boxOptions: IComboBoxOption[];
    isOpenPanel: boolean;
    doctosRequeridosList: IDocumentosRequeridosItem[];
    selectedDocument?: IDocumentoSuministro;
    selectedDoctoRequerido?: IDocumentosRequeridosItem;
    usuarioProveedorId: number; 
    filesList: any[];
    doctosExistentesList: IDocumentoSuministro[];
}

// define combined props
type IEntregablesProps = IEntregablesOwnProps & IConnectedState &{ dispatch(action: any): void };

class Entregables extends React.PureComponent<IEntregablesProps, IEntregablesState> {

    constructor(props: IEntregablesProps) {
        super(props);
        this.state = {            
            isLoaded: false,
            metadataDocumentosList: [],
            boxOptions: [],
            doctosRequeridosList: [],
            isOpenPanel: false,
            filesList: [],
            usuarioProveedorId: props.user.usuarioProveedor.id,
            doctosExistentesList: [],
        };
    }

    componentDidMount() {
        const { itemSuministro } = this.props;
        if (itemSuministro){
            this.props.dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(true));
            switch(itemSuministro.TipoProcesoId){
                default:
                case EnumTipoSuministro.Completo:
                    this.props.dispatch(ItemSuministroActionCreators.getDocumentosSuministrosSinFiltro(itemSuministro.CodigoSuministroPadre ? itemSuministro.CodigoSuministroPadre.lookupId : itemSuministro.ID));
                    this.props.dispatch(ItemSuministroActionCreators.getTipoDocumentosSuministroByTipo(itemSuministro.TipoSuministroId));
                    this.props.dispatch(ItemSuministroActionCreators.handleLoadingDoctosRequeridos(true));
                    this.props.dispatch(ItemSuministroActionCreators.getDocumentosRequeridosBySuministro(itemSuministro.CodigoSuministroPadre ? itemSuministro.CodigoSuministroPadre.lookupId : itemSuministro.ID));
                    break;
                case EnumTipoSuministro.Expedito:
                    this.props.dispatch(ItemSuministroActionCreators.getDocumentosSuministrosExpedito(itemSuministro.ID));
                    break;
            }
        }
    }

    componentDidUpdate(prevProps: IEntregablesProps){

        const { documentosList, tipoDocumentosSuministro, doctosRequeridosList, doctosExistentesList, itemSuministro } = this.props;
        const { isLoaded } = this.state;

        if ((!isLoaded && tipoDocumentosSuministro.length > 0 && doctosRequeridosList) || (!isEqualArrayObject(documentosList, prevProps.documentosList) && tipoDocumentosSuministro && tipoDocumentosSuministro.length > 0 && doctosRequeridosList) ){
            this.setState({
                isLoaded: true,
                boxOptions: this._buildBoxOptions(tipoDocumentosSuministro, itemSuministro.EtapaSuministroId),
                doctosRequeridosList: this._buildDoctosRequiredList(doctosRequeridosList, documentosList),
            });
        
        }

        if ((!isLoaded && doctosExistentesList)|| (doctosExistentesList && !isEqualArrayObject(doctosExistentesList, prevProps.doctosExistentesList))){
            this.setState({
                isLoaded: true,
                doctosExistentesList,
            });
        }

        // if (isLoaded && tipoDocumentosSuministro.length > 0 && doctosRequeridosList.length > 0 && documentosList.length > 0) this.setState({ ...this.state, doctosRequeridosList: this._buildDoctosRequiredList(doctosRequeridosList, documentosList) })
    }

    private _buildBoxOptions = (doctosTipo: IDocumentosTipo[], etapaActual: EnumEtapasSuministro): IComboBoxOption[] => {

        let optionsDoctos: IDocumentosTipo[] = doctosTipo;
        if (etapaActual !== EnumEtapasSuministro.Entregables) {
            optionsDoctos = doctosTipo.filter( d => d.MaestroFasesId === EnumFaseDocumentos.Otros );
        }

        return OrdenaDocumentosTiposList(optionsDoctos);
    }

    private _buildDoctosRequiredList = (doctosRequeridosList: IDocumentoRequerido[], documentosList: IDocumentoSuministro[]) :IDocumentosRequeridosItem[] => {
        return doctosRequeridosList.map(dr => ({
            key: dr.ID,
            NombreDocumentoDetalle: dr.Title,
            DocumentoTipoId: dr.DocumentosTipoId,
            DocumentoTipo: dr.DocumentosTipo.lookupValue,
            Documento: documentosList.find(d=> d.CodigoDoctoRequeridoId === dr.ID),
            CalculaAvance: dr.CalculaAvance,
            NombreDocumento: documentosList.find(d=> d.CodigoDoctoRequeridoId === dr.ID) ? documentosList.find(d=> d.CodigoDoctoRequeridoId === dr.ID).FileLeafRef : 'Sin Documento',
            VisibleProveedor: dr.VisibleProveedor,
            VisibleInspector: dr.VisibleInspector,
        }));
    }

    private _onDownloadDocument = (uri: string, fileName: string):void => {
        this.props.dispatch(ItemSuministroActionCreators.downloadDocumento(uri,fileName));
    }

    private _onChangeMetadata = (ev, option):void => {
        
        const { tipoDocumentosSuministro, currentUser } = this.props;
        const { metadataDocumentosList } = this.state;
        const { id, value } = ev.target;
        const idSplited: string = id.split("@@");
        let metadataListModified: IMetadataDocumentoItem[] = []; 
        const metadataExist: IMetadataDocumentoItem = metadataDocumentosList.find( m => m.key === idSplited[1] );
        
        if (!metadataExist) {      
            let metadataToAdd: IMetadataDocumentoItem = {
                idSubido: 0,
                key: idSplited[1],
                DocumentosTipoId: -1,
                FaseId: -1,
                Detalle: '',               
                CodigoDoctoRequeridoId: -1,
                EmailSolicitante: currentUser.usuario                
            };
            
            switch(idSplited[0]){            
                case 'tipoDocumento':           
                    metadataToAdd = { ...metadataToAdd, DocumentosTipoId: Number(option.key), FaseId: Number(tipoDocumentosSuministro.find(t => t.ID === option.key).MaestroFasesId), };              
                    break;          
                case 'detalle':       
                    metadataToAdd = { ...metadataToAdd, Detalle: value as string };             
                    break;         
                default:         
                    metadataToAdd = { ...metadataToAdd };
            
            }         
            metadataListModified = metadataDocumentosList;       
            metadataListModified.push(metadataToAdd);
        } else {

            let metadataToUpdate: IMetadataDocumentoItem = undefined;
          
            switch(idSplited[0]){
                case 'tipoDocumento':
                    metadataToUpdate = { ...metadataExist, DocumentosTipoId: Number(option.key), FaseId: Number(tipoDocumentosSuministro.find(t => t.ID === option.key).MaestroFasesId), };    
                    break;     
                case 'detalle':
                    metadataToUpdate = { ...metadataExist, Detalle: value as string};   
                    break;       
                default:       
                    metadataToUpdate = { ...metadataExist };         
            }
            
            metadataListModified = metadataDocumentosList.filter( m => m.key !== metadataExist.key );    
            metadataListModified.push(metadataToUpdate);
        
        }
        this.setState({ metadataDocumentosList: metadataListModified });  
        this.forceUpdate();
    }

    private _onOpenPanelDocumento = (key: number) => {
        const { doctosRequeridosList } = this.state;
        const selectedDoctoRequerido = doctosRequeridosList.find(d => d.key === key);
        this.setState({
          isOpenPanel: true,
          selectedDocument: selectedDoctoRequerido.Documento,
          selectedDoctoRequerido: selectedDoctoRequerido,
        });
        if (selectedDoctoRequerido.Documento){
            this.props.dispatch(ItemSuministroActionCreators.handleLoadingComentarios(true));
            this.props.dispatch(ItemSuministroActionCreators.getComentariosDocumentoById(selectedDoctoRequerido.Documento.ID));
        }
    }
      
    private _onClosePanelDocumento = () => {
        this.props.dispatch(ItemSuministroActionCreators.clearComentariosDocumento());
        this.setState({
            isOpenPanel: false,
            selectedDocument: undefined,
            selectedDoctoRequerido: undefined,
        });
    }

    private _onUploadFile = (files: any[], fileData: any[], metadataFiles: IMetadataDocumentoItem[]):void => {
        
        const { itemSuministro } = this.props;
        this.props.dispatch(ItemSuministroActionCreators.clearDocumentosSuministro());
        this.props.dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(true));

        this.setState({
            filesList: [],
            doctosRequeridosList: [],
            isLoaded: false,
        });

        let metadataOrdenado: IMetadataDocumentoItem[] = [];
        for( let f = 0 ; f < files.length ; f += 1 ){
            metadataOrdenado.push(metadataFiles.find(m => m.key === fileData[f].key))  
        }
        this.props.dispatch(ItemSuministroActionCreators.uploadFileSuministro(files, metadataOrdenado, itemSuministro.ID));

    }

    private _onAddComentario = (comentario:IComentarioBase):void => {
        this.props.dispatch(ItemSuministroActionCreators.handleLoadingComentarios(true));
        this.props.dispatch(ItemSuministroActionCreators.addComentarioDocumento(comentario));
    }

    private _onChangeFiles = (ev):void => {
        const { filesList } = this.state;
        const { files, id } = ev.target;
        let filesListModified = filesList.filter( f => f.id !== id);
        filesListModified.push({
            id,
            file: files[0]
        });
        this.setState({
            filesList: filesListModified,
        });

    }

    private _onPrepareFiles = ():void => {

        const { itemSuministro, tipoDocumentosSuministro, currentUser } = this.props;
        const { filesList, doctosRequeridosList } = this.state;
        let files: File[] = [];
        let metaData: IMetadataDocumentoItem[] = [];

        for (let f = 0 ; f < filesList.length ; f += 1) {           
            const { file, id } = filesList[f];          
            const doctoRequerido: IDocumentosRequeridosItem = doctosRequeridosList.find(d => d.key === Number(id));          
            const tipoDocto: IDocumentosTipo = tipoDocumentosSuministro.find(t => t.ID === doctoRequerido.DocumentoTipoId );
          
            files.push(file);
            
            metaData.push({
                
                idSubido: doctoRequerido.Documento ? doctoRequerido.Documento.ID : 0,           
                key: id,         
                DocumentosTipoId: tipoDocto.ID,          
                FaseId: tipoDocto.MaestroFasesId,           
                Detalle: '',          
                CodigoDoctoRequeridoId: doctoRequerido.key,
                EmailSolicitante : currentUser.usuario   
            });
        
        }
        
        this.props.dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(true));
        this.props.dispatch(ItemSuministroActionCreators.uploadFileSuministro(files, metaData, itemSuministro.ID));

        // limpio los inputs!
        var inputFileList = document.getElementsByName("InputFileUpdateDoctos");
        inputFileList.forEach( i => (i as HTMLInputElement).value = '');

        this.setState({ filesList: [] });
        
    }

    public render() {
        const {
            metadataDocumentosList,
            boxOptions,
            isOpenPanel,
            selectedDocument,
            doctosRequeridosList,
            selectedDoctoRequerido,
            usuarioProveedorId,
            doctosExistentesList,
            filesList
        } = this.state;
        const { comentariosList, loadingComentarios, itemSuministro, loadingDocumentos, loadingDoctosRequeridos } = this.props;

        const disabledFlujoConectado : boolean = itemSuministro.CodigoSuministroPadre !== null;

        const usuario = this.props.currentUser;
        const usuarioProveedor: boolean = usuario.usuarioProveedor && usuario.usuarioProveedor.proveedorInspector === null;

        const doctosRequeridosListProveedor = doctosRequeridosList.filter( d => d.VisibleProveedor === true );
        const doctosRequeridosListInspector = doctosRequeridosList.filter( d => d.VisibleInspector === true );

        const doctosRequeridos = usuarioProveedor ? doctosRequeridosListProveedor : doctosRequeridosListInspector;
        return (
            <div className={"ms-Grid ".concat(styles.EntregablesContainer)} dir="ltr">
                {(loadingDocumentos || loadingDoctosRequeridos) && <Loading fullScreen={false} message='Cargando documentos...' />}
                <h2>Entregables de Ingeniería</h2>
                <Resumen itemSuministro={itemSuministro} />
                <Separador mensaje='Documentos' />
                { itemSuministro && itemSuministro.TipoProcesoId === EnumTipoSuministro.Completo ? (
                    <div>
                        <CustomDetailsList
                            items={doctosRequeridos}
                            columns={[
                                {
                                    key: 'columniconfileDocSuministro',
                                    name: 'iconfile',
                                    fieldName: 'iconfile',
                                    minWidth: 16,
                                    maxWidth: 16,
                                    iconName: 'Page',
                                    clickToSort: false,
                                },
                                {
                                    key: 'columnAccion',
                                    name: 'Más',
                                    fieldName: 'CalculaAvance', // este es un parche
                                    minWidth: 40,
                                    maxWidth: 40,
                                    onRender: (item: IDocumentosRequeridosItem) => (<CustomIconButton id={`opciones_${item.key}`} icon='More' label={item.Documento === undefined? 'Sin Documento':'Más información'} onClick={() => this._onOpenPanelDocumento(item.key)} disabled={item.Documento === undefined} />)
                                },
                                {
                                    key: 'columnTipoDocumento',
                                    name: 'Tipo de documento',
                                    fieldName: 'DocumentoTipo',
                                    isRowHeader: true,
                                    minWidth: 300,
                                    maxWidth: 450,
                                    isResizable: true,
                                    onRender: (item: IDocumentosRequeridosItem) => (<span>{item.DocumentoTipo} <span style={{ fontSize: 9, color: 'red'}}>{!item.CalculaAvance && '(Documento extra)'}</span></span>)
                                },
                                {
                                    key: 'columnNombre',
                                    name: 'Nombre',
                                    fieldName: 'NombreDocumento',
                                    minWidth: 200,
                                    maxWidth: 250,
                                    isResizable: true,
                                },
                                {
                                    key: 'columnDetalle',
                                    name: 'Detalle',
                                    fieldName: 'NombreDocumentoDetalle',
                                    minWidth: 100,
                                    maxWidth: 150,
                                    isResizable: true,
                                },
                                {
                                    key: 'columnasubirarchivo',
                                    name: 'Cargar',
                                    fieldName: 'DocumentoTipoId',
                                    minWidth: 300,
                                    maxWidth: 450,
                                    isResizable: true,
                                    onRender: (item : IDocumentosRequeridosItem) => <CustomTextField name="InputFileUpdateDoctos" onChange={this._onChangeFiles} id={item.key.toString()} type='file' disabled={((item.Documento && item.Documento.DocumentosEstadoId === EnumEstadosDocumentosSuministros.EnRevision) || (item.Documento && item.Documento.DocumentosEstadoId === EnumEstadosDocumentosSuministros.Aprobado) || disabledFlujoConectado )}  />
                                },
                                {
                                    key: 'columnstate',
                                    name: 'Estado',
                                    fieldName: 'Documento',
                                    minWidth: 150,
                                    maxWidth: 150,
                                    onRender: (item: IDocumentosRequeridosItem) => {
                                        if (item.Documento === undefined){
                                            return (<div className={containerClass.sinDocumento}><FontIcon iconName="PageRemove" className={iconClass} /> Sin Documento</div>);
                                        }
                                        switch(item.Documento.DocumentosEstadoId){
                                        case EnumEstadosDocumentosSuministros.EnRevision:
                                            return (<div className={containerClass.enRevision}><FontIcon iconName="ReadingMode" className={iconClass} /> En Revisión</div>);
                                        case EnumEstadosDocumentosSuministros.Aprobado:
                                            return (<div className={containerClass.aprobado}><FontIcon iconName="Accept" className={iconClass} /> Aprobado</div>);
                                        case EnumEstadosDocumentosSuministros.Rechazado:
                                            return (<div className={containerClass.rechazado}><FontIcon iconName="Cancel" className={iconClass} /> Rechazado</div>);
                                        }
                                    }
                                },
                            ]}
                            addFilterInput={false}
                        />
                        <div style={{ textAlign: 'right', marginTop: 15 }}>
                            <DialogModal
                                id = 'onUploadFileDialog'
                                title = 'Cargar archivos'
                                subtitle = {`Está a punto de realizar una carga de ${filesList.length} archivo${filesList.length>1? 's':''}, ¿desea continuar con esta carga?`}
                                successText = 'Aceptar'
                                successAcction = {this._onPrepareFiles}
                                unsuccessText = 'Cancelar'
                                buttomOptions= {{
                                    label: 'Subir archivos',
                                    iconName: 'Upload',
                                    disabled: filesList.length < 1
                                }}
                                buttonType = 'button'
                            /> 
                        </div>
                        {boxOptions.length > 0 ? !disabledFlujoConectado ?(
                            <div className={"ms-Grid-row"}>
                                <div className={"ms-Grid-col ms-lg12"}>
                                    <Separador mensaje='Documentación extra' />
                                </div>
                                <div className={"ms-Grid-col ms-lg12"}>
                                    <UploadFile
                                        onUploadFile={this._onUploadFile}
                                        metadataList={metadataDocumentosList}
                                        metadataOptionsColumns = {[
                                            {
                                                key: 'columnSelectDocType',
                                                name: 'Tipo Documento',
                                                fieldName: 'DocumentosTipoId',
                                                minWidth: 200,
                                                maxWidth: 200,
                                                onRender: (item) => {
                                                    const metadata = metadataDocumentosList.filter(m => m.key === item.key)[0];
                                                    let tipoDocumentoItem = metadata? metadata.DocumentosTipoId : '';
                                                    return (<CommonComboBox id={`tipoDocumento@@${item.key}`} key={`${item.key}comboboxTipoDocumento`} options={boxOptions} onChange={this._onChangeMetadata} defaultSelectedKey={tipoDocumentoItem}/>);
                                                },
                                                clickToSort: false,
                                            },
                                            {
                                                key: 'columnAddDetails',
                                                name: 'Detalle',
                                                fieldName: 'Detalle',
                                                minWidth: 300,
                                                maxWidth: 300,
                                                onRender: (item) => {
                                                    const metadata = metadataDocumentosList.find(m => m.key === item.key);
                                                    let detalleItem = metadata? metadata.Detalle : '';
                                                    return (<CustomTextField id={`detalle@@${item.key}`} defaultValue={detalleItem} onBlur={this._onChangeMetadata} />);
                                                },
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        ) : <>
                            <Separador mensaje='Importante' />
                            <p>Este es un suministro conectado, para subir documentación, ir al suministro ID {itemSuministro.CodigoSuministroPadre.lookupId}</p> 
                            </> : null }
                        
                    </div>
                ) : (
                    <CustomDetailsList
                        items={doctosExistentesList}
                        columns={[
                            {
                                key: 'columniconfileDocSuministro',
                                name: 'iconfile',
                                fieldName: 'iconfile',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Page',
                                clickToSort: false,
                            },
                            {
                                key: 'columnDownload',
                                name: 'download',
                                fieldName: 'FileRef',
                                minWidth: 16,
                                maxWidth: 16,
                                iconName: 'Link',
                                clickToSort: false,
                                onRender: (item: IDocumentoSuministro) => (<CustomIconButton  id={`downloadButton${item.ID}`} icon='Link' onClick={() => this._onDownloadDocument(`${ApiConfig.baseUriDocuments}${item.FileRef}`, item.FileLeafRef)} label='Descargar' />)
                            },
                            {
                                key: 'columnNombreArchivo',
                                name: 'Nombre de archivo',
                                fieldName: 'FileLeafRef',
                                isRowHeader: true,
                                minWidth: 200,
                                maxWidth: 300,
                                isResizable: true,
                            },
                            {
                                key: 'columnTipoDocumento',
                                name: 'Tipo de documento',
                                fieldName: 'DocumentosTipo',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: ( item: IDocumentoSuministro ) => item.DocumentosTipo.lookupValue
                            },
                            {
                                key: 'columnFase',
                                name: 'Fase',
                                fieldName: 'Fase',
                                minWidth: 100,
                                maxWidth: 150,
                                isResizable: true,
                                onRender: ( item: IDocumentoSuministro ) => item.Fase.lookupValue
                            },
                            {
                                key: 'columnDetalle',
                                name: 'Nombre Documento',
                                fieldName: 'CodigoDoctoRequerido_x003a_Detalle',
                                minWidth: 150,
                                maxWidth: 200,
                                isResizable: true,
                                onRender: ( item: IDocumentoSuministro ) => item.CodigoDoctoRequerido_x003a_Detalle.lookupValue
                            },
                        ]}
                        addFilterInput={false}
                    />
                )}
                <PanelDocumento
                    onClose={() => this._onClosePanelDocumento}
                    onAddComentario={this._onAddComentario}
                    isOpen={isOpenPanel}
                    selectedDocument={selectedDocument}
                    comentariosList={comentariosList}
                    loadingComentarios={loadingComentarios}
                    disabled={false}
                    onDescargarDocumento={this._onDownloadDocument}
                    selectedDoctoRequerido={selectedDoctoRequerido}
                    usuarioProveedor={usuarioProveedorId}
                    disabledFlujoConectado={disabledFlujoConectado}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Entregables);
