import * as React from 'react';
import { RichText } from '../../Shared/CustomFields/richtext/richtext';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { ConvertSharepointDate } from '../../utils/functions';
import { IDocumentosInspeccionHomologacion, IInspeccionHomologacion } from '../interfaces';
import styles from '../ItemHomologacion.module.scss';
import ApiConfig from '../../../config/apiConfigOficial';
import { CustomIconButton } from '../../Shared/IconButton';
import { useDispatch } from 'react-redux';
import { ItemSuministroActionCreators } from '../../ItemSuministro/actions';

interface IInpseccionItemProps {
    inspeccionItem: IInspeccionHomologacion;
}


const InspeccionItem: React.FunctionComponent <IInpseccionItemProps> = (props) => {
    const dispatch = useDispatch();

    function _onDownloadDocument(url:string, fileName:string){
        dispatch(ItemSuministroActionCreators.loadingDownloadDocumento(true, fileName));
        dispatch(ItemSuministroActionCreators.downloadDocumento(url, fileName)); 
    }

    return (
        <div className={styles.InspeccionItem}>
            {props.inspeccionItem && (
                <div className={styles.inspeccionItemContent}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={styles.texto}>Fecha Inicio inspección:</td>
                                <td className={styles.valor}><b>{props.inspeccionItem.FechaInicio ? ConvertSharepointDate(props.inspeccionItem.FechaInicio) : 'Sin fecha de inicio'}</b></td>
                            </tr>
                            <tr>
                                <td className={styles.texto}>Fecha término inspección:</td>
                                <td className={styles.valor}><b>{props.inspeccionItem.FechaTermino ? ConvertSharepointDate(props.inspeccionItem.FechaTermino) : 'Sin fecha de término'}</b></td>
                            </tr>
                            <tr>
                                <td className={styles.texto}>Estado:</td>
                                <td className={styles.valor}><b>{props.inspeccionItem.Estado.lookupValue}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={styles.comentarioBox}>
                        <RichText
                            id='Comentarios'
                            label= 'Comentarios'
                            onChange={void(0)}
                            value={props.inspeccionItem.Comentarios}
                            disabled
                            showLable
                        />
                    </div>
                    { props.inspeccionItem.DocumentosList && props.inspeccionItem.DocumentosList.length > 0 && (
                        <div className={styles.listadoBox}>
                            <CustomDetailsList
                                id={`documentosInspecciones`}
                                items={props.inspeccionItem.DocumentosList}
                                columns={[
                                    {
                                        key: 'columnFileIconDocumentoObject',
                                        name: 'iconfile',
                                        fieldName: 'iconfile',
                                        minWidth: 16,
                                        maxWidth: 16,
                                        iconName: 'Page',
                                        clickToSort: false,
                                    },
                                    {
                                        key: 'columnaDescargar',
                                        name: 'Detalle documento',
                                        iconName: 'Download',
                                        fieldName: 'FileRef',
                                        minWidth: 20,
                                        maxWidth: 20,
                                        clickToSort: false,
                                        onRender: (item: IDocumentosInspeccionHomologacion) =>(
                                            <CustomIconButton
                                                icon='Download'
                                                onClick={() => _onDownloadDocument(`${ApiConfig.baseUriDocuments}${item.FileRef}`,item.FileLeafRef)}
                                                label='Descargar'
                                                id={`downloaDocument${item.key}`}
                                            />
                                        )
                                    },
                                    {
                                        key: 'columnaNombreDocumento',
                                        name: 'Nombre Documento',
                                        fieldName: 'FileLeafRef',
                                        minWidth: 200,
                                        maxWidth: 350,
                                        isResizable: true,
                                    },
                                    {
                                        key: 'colulmnaTipoDocumento',
                                        name: 'Tipo documento',
                                        fieldName: 'DocumentosTipo',
                                        minWidth: 200,
                                        maxWidth: 350,
                                        isResizable: true,
                                        onRender: (item: IDocumentosInspeccionHomologacion) => item.DocumentosTipo && item.DocumentosTipo.lookupValue,
                                    },
                                    {
                                        key: 'columnaDetalle',
                                        name: 'Detalle documento',
                                        fieldName: 'Detalle',
                                        minWidth: 200,
                                        maxWidth: 350,
                                        isResizable: true,
                                    },
                                ]}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default InspeccionItem;