export const LOADING = 'APP/ITEMHOMOLOGACION/LOADING';

export const GET_HOMOLOGACIONITEM = 'APP/ITEMHOMOLOGACION/GET_HOMOLOGACIONITEM';
export const GET_HOMOLOGACIONDOCTOSREQUERIDOS = 'APP/ITEMHOMOLOGACION/GET_HOMOLOGACIONDOCTOSREQUERIDOS';
export const GET_HOMOLOGACIONDOCUMENTOS = 'APP/ITEMHOMOLOGACION/GET_HOMOLOGACIONDOCUMENTOS';
export const GET_HOMOLOGACIONDOCTOSTIPO = 'APP/ITEMHOMOLOGACION/GET_HOMOLOGACIONDOCTOSTIPO';

export const GET_HOMOLOGACIONETAPA = 'APP/ITEMHOMOLOGACION/GET_HOMOLOGACIONETAPA';

export const LOADING_MENU = 'APP/ITEMHOMOLOGACION/LOADING_MENU';
export const LOADING_DOCTOSREQUERIDOS = 'APP/ITEMHOMOLOGACION/LOADING_DOCTOSREQUERIDOS';
export const LOADING_DOCUMENTOS = 'APP/ITEMHOMOLOGACION/LOADING_DOCUMENTOS';
export const LOADING_DOCUMENTOSTIPO = 'APP/ITEMHOMOLOGACION/LOADING_DOCUMENTOSTIPO';

export const LOADING_INSPECCIONESLIST = 'APP/ITEMHOMOLOGACION/LOADING_INSPECCIONESLIST';
export const GET_INSPECCIONESLIST = 'APP/ITEMHOMOLOGACION/GET_INSPECCIONESLIST';

export const CLEAR_HOMOLOGACIONITEM = 'APP/ITEMHOMOLOGACION/CLEAR_HOMOLOGACIONITEM';

export const LOADING_COMENTARIOSHOMOLOGACION = 'APP/ITEMHOMOLOGACION/LOADING_COMENTARIOSHOMOLOGACION';
export const GET_COMENTARIOSHOMOLOGACION = 'APP/ITEMHOMOLOGACION/GET_COMENTARIOSHOMOLOGACION';
export const CLEAR_COMENTARIOSHOMOLOGACION = 'APP/ITEMHOMOLOGACION/CLEAR_COMENTARIOSHOMOLOGACION';