import * as React from 'react';
import { connect } from 'react-redux';
import CustomMessageBar from './MessageBar';
import { ApplicationState } from '../../config/store';
import { NavMenu } from './NavMenu';
import { MainActionCreators } from '../Main/actions';
import { msalAuth } from '../../config/auth/AuthConfig';

import grupoSaesa_logo from '../../img/newLogo.jpg';

import styles from './Header.module.scss';
import { IUsuarioLogin } from '../utils/interfaces';
import { EnumTipoAcceso } from '../utils/enums';

interface IConnectedState {
    AuthenticateType: string;
    user: IUsuarioLogin;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        AuthenticateType: state.main.AuthenticateType,
        user: state.main.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

// define combined props
type IHeaderProps =  IConnectedState &{ dispatch(action: any): void };

class Header extends React.Component<IHeaderProps> {

    private _onSignOut = ():void => {
        const { dispatch, AuthenticateType } = this.props;
        if (AuthenticateType === EnumTipoAcceso.MS365){
            //msalAuth.logout();
        }
        dispatch(MainActionCreators.signOut());
    }

    public render() {
        const { usuarioProveedor } = this.props.user;
        return (
            <React.Fragment>
                <CustomMessageBar />
                <div className={styles.headerContent}>
                    <div className={styles.logoContent}>
                        <img width={130} src={grupoSaesa_logo} alt='LogoGrupoSAESA'/>
                    </div>
                    <div className={styles.navContet}>
                        <NavMenu onSignOut={this._onSignOut} usuarioProveedor={usuarioProveedor}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);