import * as React from 'react';
import styles from './MenuEtapas.module.scss';

interface IEtapasHeaderProps {
    etapasList: Array<IEtapasProceso>;
    onChangeEtapa: any;
    selected: number;
}

export interface IEtapasProceso {
    // nombre a mostrar
    Nombre: string;
    // orden en la lista
    Orden: number;
    // proceso en ejecución -> activo
    Active: boolean;
    // proceso completado
    Complete: boolean;
}

export const MenuEtapas: React.FunctionComponent<IEtapasHeaderProps>  = ({etapasList, onChangeEtapa, selected }) =>  {
    let etapasFiltered = etapasList.filter(e => e.Nombre !== "Cerrado sin Inspección");

    return(
        <div className={styles.menuEtapas}>
        <ul className={styles.progressbar}>
            {etapasFiltered.map( (etapa, i) => (
                <li key={`${etapa.Orden}-${i}`} onClickCapture={() => onChangeEtapa(i+1)} className={`${etapa.Active? styles.actual : etapa.Complete? styles.active : ''}`} style={{ cursor: "pointer" }}>
                    <span className={selected === i+1 || ( selected === etapasList.length+1 && i === 0 )? styles.seleccionada:''}>{etapa.Nombre}</span>
                </li>
            ))}
        </ul>
        <div style={{ clear: 'both' }}></div>
    </div>
    );

    
}