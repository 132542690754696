import * as React from 'react';
import styles from './Separador.module.scss';

interface ISeparadorProps {
    mensaje?: string;
    id?: string;
    subtitle?: boolean;
}

export const Separador: React.FunctionComponent<ISeparadorProps> = ({mensaje, id, subtitle}) => (
    <div className={`${styles.SeparadorContent} ${subtitle && styles.SubTitleColor}`} id={id}>
        {mensaje && (<h3>{mensaje}</h3>)}
    </div>
);