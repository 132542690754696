import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';

import { MainActionCreators } from '../Main/actions';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { CustomTextField } from '../Shared/CustomFields/TextField';
import { CustomButton } from '../Shared/Button';
import { ILocalLogin } from '../utils/interfaces';
import ApiConfig from '../../config/apiConfigOficial';
import { msalAuth } from '../../config/auth/AuthConfig';
import logo from '../../img/newLogo.jpg';

import styles from './Login.module.scss';
import { Separator } from 'office-ui-fabric-react/lib-commonjs/Separator';

interface ILoginOwnProps {
}

interface ILoginState {
    loginObject: ILocalLogin,
    loginUrlFusionAuth : string
};

interface IConnectedState {
    main: any;
}  

function mapStateToProps(state: ApplicationState, ownProps: ILoginOwnProps): IConnectedState {
	return {
        main: state.main,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

// define combined props
type ILoginProps =  ILoginOwnProps & IConnectedState &{ dispatch(action: any): void };

class Login extends React.PureComponent<ILoginProps, ILoginState> {

    constructor(props: ILoginProps) {
        super(props);
        this.state = {
            loginObject: {
                usuario: '',
                password: '',
            },
            loginUrlFusionAuth : ApiConfig.LogInAzureB2CUrl
        }
    }

    private _onChangeLoginObject = (input) => {
        const { loginObject } = this.state;
        const { id, value } = input.target;
        let loginObjectModified: ILocalLogin = { usuario: '', password: '' };
        switch (id) {
            case 'usuario':
                loginObjectModified = { ...loginObject, usuario: value };
                break;
            case 'password':
                loginObjectModified = { ...loginObject, password: value };
                break;
            default:
                loginObjectModified = { ...loginObject };
                break;
        }
        this.setState({
            loginObject: loginObjectModified,
        });
    }

    private _onLoginLocalIn = () => {
        const { dispatch } = this.props;
        const { loginObject } = this.state;
        dispatch(MainActionCreators.handleLoading(true));
        dispatch(MainActionCreators.signInLocal(loginObject));
    }

    private _onMs365Login = () => {
        const { dispatch } = this.props;
        dispatch(MainActionCreators.handleLoading(true));
        const userAccount = msalAuth.getAccount();
        if (!userAccount) {
            msalAuth.loginPopup(ApiConfig.msalOptions)
                .then(response => {
                    dispatch(MainActionCreators.signInMS365(response));
                })
                .catch(err => {
                    dispatch(MainActionCreators.signOut());
                    dispatch(MainActionCreators.sendMessageError(err.toString()));
                });
        } else {
            dispatch(MainActionCreators.signOut());
        }
    }

    private _onFusionAuthLogin = () => {
        window.location.href = this.state.loginUrlFusionAuth;
    }
    
    private _onB2CLogin = () => {
        window.location.href = this.state.loginUrlFusionAuth;
    }   

    public render() {
        //const { loginObject } = this.state;
        /*
        
                        <CustomButton
                            label='Login con Microsoft'
                            id='loginMs365'
                            onClick={this._onMs365Login}
                            icon='OfficeLogo'
                        />

                        {messageError && (
                            <div className={styles.ErrorMsje}>
                                <Text variant='medium'>*{messageError}</Text>
                            </div>
                        )}        
        */
        const { messageError } = this.props.main;
        return (
            <React.Fragment>
                <div className={styles.LoginContainer}>
                    <div className={styles.LogoContent} style={{ display: "flex", alignItems: "center", justifyContent:"center" }}>
                        <img src={logo} alt='logo' style={{ width: "80vw", marginBottom: "10vh"}} />
                    </div>
                    <div className={styles.LoginContent}>
                        <CustomButton
                            label='Iniciar sesión'
                            id='loginFusionAuth'
                            onClick={this._onB2CLogin}
                            icon='LaptopSecure'
                        />
                        <div className={styles.divEnlace}>
                            <a href={ApiConfig.recuperarPasswd}>Recuperar Contraseña</a>
                        </div>

                        {messageError && (
                            <div className={styles.ErrorMsje}>
                                <Text variant='medium'>*{messageError}</Text>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
