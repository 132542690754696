import { IComboBoxOption } from "office-ui-fabric-react";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../config/store";
import { IHomologacionGeneral } from "../../GrillaHomologacion/interfaces";
import { CommonComboBox } from "../../Shared/CustomFields/ComboBoxField";
import { CustomTextField } from "../../Shared/CustomFields/TextField";
import UploadFile from "../../Shared/UploadFile/uploadFile";
import { EnumHomologacionEtapa } from "../../utils/enums";
import { ItemHomologacionesActionCreators } from "../actions";
import {
  IDocumentosTipoHomologacion,
  IMetadataDoctoHomologacion,
} from "../interfaces";
import { IUsuarioLogin } from "../../utils/interfaces";

interface IConnectedState {
  homologacionItem: IHomologacionGeneral;
  currentUser : IUsuarioLogin;
}

function mapStateToProps(state: ApplicationState): IConnectedState {
  return {
    homologacionItem: state.itemHomologacion.homologacionItem,
    currentUser : state.main.user
  };
}

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => {
    dispatch(action);
  },
});

interface IItemHomologacionOwnProps {
  doctosTipo: IDocumentosTipoHomologacion[];
  etapaHomologacion: EnumHomologacionEtapa;
  
}

// define combined props
type IUploadDocumentosProps = IItemHomologacionOwnProps &
  IConnectedState & { dispatch(action: any): void };

interface IUploadDocumentosState {
  isLoaded: boolean;

  metadataDocumentosList: IMetadataDoctoHomologacion[];

  optionsBox: IComboBoxOption[];
}

class UploadDocumentos extends React.PureComponent<
  IUploadDocumentosProps,
  IUploadDocumentosState
> {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,

      metadataDocumentosList: [],

      optionsBox: [],
    };
  }

  private _onInitInternalData = () => {
    const { doctosTipo } = this.props;

    this.setState({
      optionsBox: doctosTipo.map((dt) => {
        const max: number = 100;

        let showTitle: string = dt.Title;

        if (dt.Title.length >= max)
          showTitle = dt.Title.slice(0, max).concat("...");

        return { key: Number(dt.ID), text: showTitle };
      }),

      isLoaded: true,
    });
  };

  public componentDidMount() {
    const { doctosTipo } = this.props;

    if (doctosTipo) this._onInitInternalData();
  }

  public componentDidUpdate() {
    const { doctosTipo } = this.props;

    const { isLoaded } = this.state;

    if (!isLoaded && doctosTipo) this._onInitInternalData();
  }

  private _onChangeMetadata = (ev, option): void => {
    const { etapaHomologacion, currentUser } = this.props;
    const { metadataDocumentosList } = this.state;
    const { id, value } = ev.target;
    const idSplited: string = id.split("@@");
    let metadataListModified: IMetadataDoctoHomologacion[] = [];

    const metadataExist: IMetadataDoctoHomologacion =
      metadataDocumentosList.find((m) => m.key === idSplited[1]);

    if (!metadataExist) {
      let metadataToAdd: IMetadataDoctoHomologacion = {

        idSubido: 0,
        key: idSplited[1],
        DocumentosTipoId: null,
        Detalle: "",
        CodigoDoctoRequeridoId: 0,
        EtapaId: etapaHomologacion,
        EmailSolicitante : currentUser.usuario
      };

      switch (idSplited[0]) {
        case "tipoDocumento":
          metadataToAdd = {
            ...metadataToAdd,
            DocumentosTipoId: Number(option.key),
          };

          break;

        case "detalle":
          metadataToAdd = { ...metadataToAdd, Detalle: value as string };

          break;

        default:
          metadataToAdd = { ...metadataToAdd };
      }

      metadataListModified = metadataDocumentosList;

      metadataListModified.push(metadataToAdd);
    } else {
      let metadataToUpdate: IMetadataDoctoHomologacion = undefined;

      switch (idSplited[0]) {
        case "tipoDocumento":
          metadataToUpdate = {
            ...metadataExist,
            DocumentosTipoId: Number(option.key),
          };

          break;

        case "detalle":
          metadataToUpdate = { ...metadataExist, Detalle: value as string };

          break;

        default:
          metadataToUpdate = { ...metadataExist };
      }

      metadataListModified = metadataDocumentosList.filter(
        (m) => m.key !== metadataExist.key
      );

      metadataListModified.push(metadataToUpdate);
    }

    this.setState({ metadataDocumentosList: metadataListModified });
  };

  private _onUploadFile = (
    files: any[],
    fileData: any[],
    metadataFile: IMetadataDoctoHomologacion[]
  ): void => {
    const { homologacionItem } = this.props;

    let metadataOrdenada: IMetadataDoctoHomologacion[] = [];

    for (let f = 0; f < files.length; f += 1) {
      metadataOrdenada.push(
        metadataFile.find((m) => m.key === fileData[f].key)
      );
    }

    this.props.dispatch(
      ItemHomologacionesActionCreators.handleDoctosRequeridosHomologacionLoading(
        true
      )
    );

    this.props.dispatch(
      ItemHomologacionesActionCreators.handleDocumentosHomologacionLoading(true)
    );

    this.props.dispatch(
      ItemHomologacionesActionCreators.uploadDocumentoHomologacion(
        files,
        metadataOrdenada,
        homologacionItem.ID,
        -1,
        true
      )
    );
  };

  public render(): JSX.Element {
    const { metadataDocumentosList, optionsBox } = this.state;
    return (
      <div>
        {optionsBox.length > 0 && (
          <UploadFile
            onUploadFile={this._onUploadFile}
            metadataList={metadataDocumentosList}
            metadataOptionsColumns={[
              {
                key: "columnSelectDocType",
                name: "Tipo Documento",
                fieldName: "DocumentosTipoId",
                minWidth: 200,
                maxWidth: 200,
                onRender: (item) => {
                  const metadata = metadataDocumentosList.filter(
                    (m) => m.key === item.key
                  )[0];
                  let tipoDocumentoItem = metadata
                    ? metadata.DocumentosTipoId
                    : "";
                  return (
                    <CommonComboBox
                      id={`tipoDocumento@@${item.key}`}
                      key={`${item.key}comboboxTipoDocumento`}
                      options={optionsBox}
                      onChange={this._onChangeMetadata}
                      defaultSelectedKey={tipoDocumentoItem}
                    />
                  );
                },
              },
              {
                key: "columnAddDetails",
                name: "Detalle",
                fieldName: "Detalle",
                minWidth: 300,
                maxWidth: 300,
                onRender: (item) => {
                  const metadata = metadataDocumentosList.find(
                    (m) => m.key === item.key
                  );
                  let detalleItem = metadata ? metadata.Detalle : "";
                  return (
                    <CustomTextField
                      id={`detalle@@${item.key}`}
                      defaultValue={detalleItem}
                      onBlur={this._onChangeMetadata}
                    />
                  );
                },
              },
            ]}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentos);
