import * as Main from '../../components/Main/reducer';
import * as GrillaSuministros from '../../components/GrillaSuministros/reducer';
import * as ItemSuministro from '../../components/ItemSuministro/reducer';
import * as GrillaInspeccion from '../../components/GrillaInspeccion/reducer';
import * as ItemInspeccion from '../../components/ItemInspeccion/reducer';
import * as GrillaHomologacion from '../../components/GrillaHomologacion/reducer';
import * as ItemHomologacion from '../../components/ItemHomologacion/reducer';

import * as GrillaHomologacionInspeccion from '../../components/GrillaHomologacionInspeccion/reducer';
import * as ItemHomologacionInspeccion from '../../components/ItemInspeccionHomologacion/reducer';

import * as MaestroMateriales from '../../components/MaestroMateriales/reducer';

// The top-level state object
export interface ApplicationState {
    main: Main.MainState | undefined;
    grillaSuministros: GrillaSuministros.GrillaSuministrosState | undefined;
    itemSuministro: ItemSuministro.ItemSuministroState | undefined;
    grillaInspeccion: GrillaInspeccion.GrillaInspeccionState | undefined;
    itemInspeccion: ItemInspeccion.ItemInspeccionState | undefined;
    grillaHomologacion: GrillaHomologacion.GrillaHomologacionState | undefined;
    itemHomologacion: ItemHomologacion.ItemHomologacionState | undefined;
    grillaHomologacionInspeccion: GrillaHomologacionInspeccion.GrillaHomologacionInspeccionState | undefined;
    itemHomologacionInspeccion: ItemHomologacionInspeccion.ItemHomologacionInspeccionState | undefined;
    maestroMateriales: MaestroMateriales.MaestroMaterialesState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    main: Main.MainReducer,
    grillaSuministros: GrillaSuministros.GrillaSuministrosReducer,
    itemSuministro: ItemSuministro.ItemSuministroReducer,
    grillaInspeccion: GrillaInspeccion.GrillaInspeccionReducer,
    itemInspeccion: ItemInspeccion.ItemInspeccionReducer,
    grillaHomologacion: GrillaHomologacion.GrillaHomologacionReducer,
    itemHomologacion: ItemHomologacion.ItemHomologacionReducer,
    grillaHomologacionInspeccion: GrillaHomologacionInspeccion.GrillaHomologacionInspeccionReducer,
    itemHomologacionInspeccion: ItemHomologacionInspeccion.ItemHomologacionInspeccionReducer,
    maestroMateriales: MaestroMateriales.MaestroMaterialReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}