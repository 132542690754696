import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IHomologacionGeneral } from '../../GrillaHomologacion/interfaces';
import { EnumEstadoHomologacion } from '../../utils/enums';

import styles from '../ItemHomologacion.module.scss';


const Resumen: React.FunctionComponent = () => {
    const homologacionItem: IHomologacionGeneral = useSelector( (state:ApplicationState) => state.itemHomologacion.homologacionItem );

    function showEstado(){
        switch (homologacionItem.EstadoId){
            case EnumEstadoHomologacion.RechazadoSinProceso:
            case EnumEstadoHomologacion.Rechazado:
                return (<div className={styles.Rechazado}><div className={styles.content}>Proceso rechazado</div></div>);
            case EnumEstadoHomologacion.Aprobado:
                return (<div className={styles.Aprobado}><div className={styles.content}>Proceso Aprobado</div></div>);
            case EnumEstadoHomologacion.Solicitud:
                return (<div className={styles.Solicitado}><div className={styles.content}>Proceso En solicitud</div></div>);
            case EnumEstadoHomologacion.EnProceso:
                return '';
        }
    }

    return(
        <div className={styles.ResumenHomologacion}>
            {homologacionItem && showEstado() }
            {homologacionItem && (
                <div className={styles.ResumenContainer}>
                    <h2>Información de homologación {homologacionItem.ID}</h2>
                    <table>
                        <tbody>
                        <tr>
                                <td className={styles.key}>ID:</td>
                                <td className={styles.val}>{homologacionItem.ID}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Descripción:</td>
                                <td className={styles.val}>{homologacionItem.Title}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Fabricante:</td>
                                <td className={styles.val}>{homologacionItem.FabricanteProducto}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Modelo:</td>
                                <td className={styles.val}>{homologacionItem.ModeloProducto}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Nivel de tensión:</td>
                                <td className={styles.val}>{homologacionItem.NivelTensionProducto}</td>
                            </tr>
                            <tr>
                                <td className={styles.key}>Área funcional:</td>
                                <td className={styles.val}>{homologacionItem.LineaFuncionamientoProducto}</td>
                            </tr>
                            
                            {/*<tr>
                                <td className={styles.key}>Encargado de revisión</td>
                                <td className={styles.val}>{homologacionItem.ResponsableFactibilidad && homologacionItem.ResponsableFactibilidad !== null ? homologacionItem.ResponsableFactibilidad.lookupValue:"No asignado"}</td>
                            </tr>*/}
                            <tr>
                                <td className={styles.key}>Ubicación fábrica:</td>
                                <td className={styles.val}>{homologacionItem.UbicacionFabrica}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default Resumen;
