import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import {
    IInspeccionFabrica,
    IDocumentosInspeccion,
    IMetadataDocumentoInspeccionItem
} from '../interfaces';
import styles from '../ItemInspeccion.module.scss';
import UploadFile from '../../Shared/UploadFile/uploadFile';
import { CommonComboBox } from '../../Shared/CustomFields/ComboBoxField';
import { IComboBoxOption } from 'office-ui-fabric-react/lib/index';

import { OrdenaDocumentosTiposList, isEqualArrayObject } from '../../utils/functions';
import { EnumDoctosTipoInspeccion, EnumEstadosDocumentosSuministros, EnumRealizaInspeccion } from '../../utils/enums';
import { ItemInspeccionActionCreators } from '../actions';
import { CustomTextField } from '../../Shared/CustomFields/TextField';
import { IDocumentosTipo } from '../../ItemSuministro/interfaces';
import { IUsuarioLogin } from '../../utils/interfaces';
import { Text } from 'office-ui-fabric-react/lib/Text';

interface ICargaDocumentosState {
    isLoaded: boolean;
    metadataDocumentosList: IMetadataDocumentoInspeccionItem[];
    optionsBox: IComboBoxOption[];
}

interface IConnectedState {
    itemInspeccion: IInspeccionFabrica;
    documentosInspeccionList: IDocumentosInspeccion[];
    documentosTipoInspeccionList: IDocumentosTipo[];
    currentUser: IUsuarioLogin;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        itemInspeccion: state.itemInspeccion.itemInspeccion,
        documentosInspeccionList: state.itemInspeccion.documentosInspeccionList,
        documentosTipoInspeccionList: state.itemInspeccion.doctosTipoInspeccionList,
        currentUser: state.main.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

type IItemInspeccionProps = IConnectedState &{ dispatch(action: any): void };

export class CargaDocumentos extends React.Component<IItemInspeccionProps,ICargaDocumentosState> {

    constructor(props){
        super(props);
        this.state = {
            metadataDocumentosList: [],
            isLoaded: false,
            optionsBox: [],
        };
    }

    public componentDidMount(){
        const { documentosTipoInspeccionList, documentosInspeccionList, itemInspeccion } = this.props;
        if (itemInspeccion && documentosTipoInspeccionList && documentosInspeccionList){
            this.setState({
                isLoaded: true,
                optionsBox: this._onBuildBoxOptions(),
            });
        }
    }

    public componentDidUpdate(prevProps: IItemInspeccionProps){
        const { isLoaded } = this.state;
        const { documentosTipoInspeccionList, documentosInspeccionList, itemInspeccion, currentUser } = this.props;
        if ( (!isLoaded && documentosTipoInspeccionList && documentosInspeccionList && itemInspeccion) || (documentosTipoInspeccionList && itemInspeccion && !isEqualArrayObject(documentosInspeccionList, prevProps.documentosInspeccionList)) ){
            this.setState({
                isLoaded: true,
                optionsBox: this._onBuildBoxOptions(),
            });
        }
    }

    private _onChangeMetadata = (ev, option):void => {
        const { itemInspeccion, currentUser } = this.props;
        const { metadataDocumentosList } = this.state;
        const { id, value } = ev.target;

        const idSplited: string = id.split("@@");

        let metadataListModified: IMetadataDocumentoInspeccionItem[] = [];
        
        const metadataExist: IMetadataDocumentoInspeccionItem = metadataDocumentosList.find( m => m.key === idSplited[1] );
        if (!metadataExist) {
            let metadataToAdd: IMetadataDocumentoInspeccionItem = {
                key: idSplited[1],
                DocumentosTipoId: -1,
                CodigoSuministroId: itemInspeccion.CodigoSuministroId,
                CodigoInspeccionId: itemInspeccion.ID,
                DocumentosEstadoId: EnumEstadosDocumentosSuministros.EnRevision,
                Detalle: '',
                EmailSolicitante : currentUser.usuario
            };
            switch(idSplited[0]){
                case 'tipoDocumento':
                    metadataToAdd = { ...metadataToAdd, DocumentosTipoId: Number(option.key) };
                    break;
                case 'detalle':
                    metadataToAdd = { ...metadataToAdd, Detalle: value as string };
                    break;
                default:
                    metadataToAdd = { ...metadataToAdd };
            }
          metadataListModified = metadataDocumentosList;
          metadataListModified.push(metadataToAdd);

        } else {
          let metadataToUpdate: IMetadataDocumentoInspeccionItem = undefined;
          switch(idSplited[0]){
            case 'tipoDocumento':
                metadataToUpdate = { ...metadataExist, DocumentosTipoId: Number(option.key) };
                break;
            case 'detalle':
                metadataToUpdate = { ...metadataExist, Detalle: value as string};
                break;
            default:
                metadataToUpdate = { ...metadataExist };
            }
          metadataListModified = metadataDocumentosList.filter( m => m.key !== metadataExist.key );
          metadataListModified.push(metadataToUpdate);
        }

        this.setState({
            metadataDocumentosList: metadataListModified
        });
    }

    private _onUploadFile = (files: any[], fileData: any[], metadataFile: IMetadataDocumentoInspeccionItem[]):void => {
        const { itemInspeccion, currentUser } = this.props;

        let metadataOrdenada: IMetadataDocumentoInspeccionItem[] = [];
        for(let f=0; f<files.length; f+=1){
            metadataOrdenada.push(metadataFile.find(m => m.key === fileData[f].key));
        }
        
        const esProveedor: boolean = !currentUser.usuarioProveedor.proveedor;
        const esProveedorInspector: boolean = currentUser.usuarioProveedor.proveedorInspector !== null ? true : false;
        this.props.dispatch(ItemInspeccionActionCreators.handleLoadingDocumentosInspeccion(true));
        this.props.dispatch(ItemInspeccionActionCreators.uploadInspeccionesFile(files, metadataOrdenada, itemInspeccion.ID, esProveedor, false, esProveedorInspector));
    }

    private _onBuildBoxOptions = ():IComboBoxOption[] => {
        const { documentosTipoInspeccionList, itemInspeccion, currentUser } = this.props;
        // soy proveedor y la inspeccion la realiza el proveedor, cargo solo los doctos posteriores
        if (itemInspeccion.RealizaInspeccion === EnumRealizaInspeccion.Proveedor && currentUser.usuarioProveedor.proveedor){
            return OrdenaDocumentosTiposList(documentosTipoInspeccionList.filter( t => t.MaestroFases.lookupValue !== 'Previa' && t.MaestroFases.lookupValue !== 'Resultado' )); 
        }

        if (itemInspeccion.RealizaInspeccion === EnumRealizaInspeccion.Inspector) {
            if (!currentUser.usuarioProveedor.proveedorInspector){
                const tipoDoctosPrevia: IDocumentosTipo[] = documentosTipoInspeccionList.filter( t => t.MaestroFases.lookupValue !== 'Posterior' && t.MaestroFases.lookupValue !== 'Resultado' );
                return OrdenaDocumentosTiposList(tipoDoctosPrevia);
            }

            if (currentUser.usuarioProveedor.proveedorInspector){
                const tipoDoctosPosterior: IDocumentosTipo[] = documentosTipoInspeccionList.filter( t => t.MaestroFases.lookupValue !== 'Previa' && t.MaestroFases.lookupValue !== 'Resultado' );
                return OrdenaDocumentosTiposList(tipoDoctosPosterior);
            }

        }
        return OrdenaDocumentosTiposList(documentosTipoInspeccionList);
    }


    render(): JSX.Element {
        const { itemInspeccion, documentosInspeccionList, currentUser } = this.props;
        const { metadataDocumentosList, optionsBox } = this.state;

        // validacion flujo Proveedor - Inspector
        const disabledInspector: boolean = (
            // si es inspector, reviso que pueda cargar solo los posteriores
            (itemInspeccion.RealizaInspeccion === EnumRealizaInspeccion.Inspector && currentUser.usuarioProveedor.proveedorInspector && documentosInspeccionList && documentosInspeccionList.filter(di => di.DocumentosEstadoId === EnumEstadosDocumentosSuministros.Aprobado && (di.DocumentosTipoId === EnumDoctosTipoInspeccion.CartaInvitacion || di.DocumentosTipoId === EnumDoctosTipoInspeccion.CronogramaEjecucion)).length === 0) ||
            (itemInspeccion.RealizaInspeccion === EnumRealizaInspeccion.Proveedor && false)
        );

        return (
            <div className={ styles.CargaDocumentos }>
                <h1>4. Carga documentos</h1>
                <div className={ styles.CargaDocumentosContent }>
                    {disabledInspector && <Text variant='medium' style={{color: 'grey', fontStyle: 'italic' }}>Falta que se aprueben documentos previos a la inspección</Text>}
                    {!disabledInspector && optionsBox.length > 0 ? (
                        <UploadFile
                            onUploadFile={this._onUploadFile}
                            metadataList={metadataDocumentosList}
                            metadataOptionsColumns = {[
                                {
                                    key: 'columnSelectDocType',
                                    name: 'Tipo Documento',
                                    fieldName: 'DocumentosTipoId',
                                    minWidth: 200,
                                    maxWidth: 200,
                                    onRender: (item) => {
                                        const metadata = metadataDocumentosList.filter(m => m.key === item.key)[0];
                                        let tipoDocumentoItem = metadata? metadata.DocumentosTipoId : '';
                                        return (<CommonComboBox id={`tipoDocumento@@${item.key}`} key={`${item.key}comboboxTipoDocumento`} options={optionsBox} onChange={this._onChangeMetadata} defaultSelectedKey={tipoDocumentoItem}/>);
                                    }
                                },
                                {
                                    key: 'columnAddDetails',
                                    name: 'Detalle',
                                    fieldName: 'Detalle',
                                    minWidth: 300,
                                    maxWidth: 300,
                                    onRender: (item) => {
                                        const metadata = metadataDocumentosList.find(m => m.key === item.key);
                                        let detalleItem = metadata? metadata.Detalle : '';
                                    return (<CustomTextField id={`detalle@@${item.key}`} defaultValue={detalleItem} onBlur={this._onChangeMetadata} />);
                                    }
                                }
                            ]}
                        />
                    ): null}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CargaDocumentos);