import {
    LOADING,
    GET_ITEMSUMINISTRO,
    SETMENU_SUMINISTRO,
    CHANGEMENU_SUMINISTRO,
    GET_ETAPASSUMINISTRO,
    GET_TIPODOCUMENTOSSUMINISTRO,
    LOADING_COMENTARIOS,
    GET_COMENTARIOSDOCUMENTO,
    CLEAR_COMENTARIOSDOCUMENTO,
    GET_DOCUMENTOSSUMINISTRO,
    CLEAR_DOCUMENTOSSUMINISTRO,
    CLEAR_ITEMSUMINISTRO,
    GET_TIPODOCUMENTONACINT,
    GET_DOCUMENTOSIMPORTACION,
    GET_COMENTARIOIMPORTACION,
    LOADING_DOWNLOADDOCUMENTO,
    GET_DOCUMENTOSREQUERIDOSSUMINISTROS,
    LOADING_INSPECCIONESFABRICASUMINISTRO,
    GET_INSPECCIONESFABRICASUMINISTRO,
    GET_DOCTOSENTREGABLESEXISTENTES,
    LOADING_DOCUMENTOSANTECEDENTESBYPROVEEDOR,
    GET_DOCUMENTOSANTECEDENTESBYPROVEEDOR,
    LOADING_DOCUMENTOS,
    LOADING_DOCTOSREQUERIDOS,
    GET_NOCONFORMIDADESBYSUMINISTRO,
    GET_ACTARECEPCION,
    GET_DOCUMENTOSRECEPCION,
    GET_DOCUMENTOSPROTOCOLOSFAT
} from './constants';
import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions'
import {
    IProcesoSuministro,
    IEtapasSuministro,
    IDocumentosTipo,
    IComentarioBase,
    IDocumentoSuministro,
    IDocumentoImportacion,
    IDocumentoRequerido,
    IMetadataDocumentoItem
} from './interfaces';
import apiConfig from '../../config/apiConfigOficial';
import { IEtapasProceso } from '../Shared/MenuEtapas/menuEtapas';
import { EnumEstadosDocumentosSuministros } from '../utils/enums';
import { IInspeccionFabrica } from '../ItemInspeccion/interfaces';

// Decalaraciones de las acciones
interface HandleLoadingAction { type: typeof LOADING; loading: boolean; }
interface GetItemSuministroAction { type: typeof GET_ITEMSUMINISTRO; itemSuministro: IProcesoSuministro; }
interface SetMenuSuministroAction { type: typeof SETMENU_SUMINISTRO; etapas: IEtapasProceso[]; etapaActual: number; }
interface ChangeMenuSuministroAction { type: typeof CHANGEMENU_SUMINISTRO; nuevaEtapa: number; }
interface GetEtapasSuministroAction { type: typeof GET_ETAPASSUMINISTRO; etapas: IEtapasSuministro[]; }
interface GetTipoDocumentoSuministroAction { type: typeof GET_TIPODOCUMENTOSSUMINISTRO; tipoDocumentosList: IDocumentosTipo[]; }
interface HandleLoadingComentariosAction { type: typeof LOADING_COMENTARIOS; loading: boolean; }
interface GetComentariosDocumentoAction { type: typeof GET_COMENTARIOSDOCUMENTO; comentariosList: IComentarioBase[]; }
interface ClearComentariosDocumentoAction { type: typeof CLEAR_COMENTARIOSDOCUMENTO; }
interface GetDocumentosSuministroAction { type: typeof GET_DOCUMENTOSSUMINISTRO; documentosList: IDocumentoSuministro[]; }
interface ClearDocumentosSuministroAction { type: typeof CLEAR_DOCUMENTOSSUMINISTRO; }
interface ClearItemSuministroAction { type: typeof CLEAR_ITEMSUMINISTRO; }
interface GetTipoDocumentoNacIntAction { type: typeof GET_TIPODOCUMENTONACINT; tipoDocNacIntList: IDocumentosTipo[]; };
interface GetDocumentosImportacionAction { type: typeof GET_DOCUMENTOSIMPORTACION; documentosImportacionList: IDocumentoImportacion[]; }
interface GetComentarioImportacionAction { type: typeof GET_COMENTARIOIMPORTACION; comentarioImportacionList: IComentarioBase[]; }
interface HandleLoadingDownloadDocumentoAction { type: typeof LOADING_DOWNLOADDOCUMENTO; loading: boolean; key: string; }
interface GetDocumentosRequeridosSuministroAction { type: typeof GET_DOCUMENTOSREQUERIDOSSUMINISTROS; doctosRequeridosList: IDocumentoRequerido[]; }
interface LoadingInspeccionesFabricaSuministroAction { type: typeof LOADING_INSPECCIONESFABRICASUMINISTRO; loading: boolean; }
interface GetInspeccionesFabricaSuministroAction { type: typeof GET_INSPECCIONESFABRICASUMINISTRO; inspeccionesList: IInspeccionFabrica[]; }
interface GetDoctosEntregablesExistentesAction { type: typeof GET_DOCTOSENTREGABLESEXISTENTES; doctoExistentesList: IDocumentoSuministro[]; }

interface LoadingDocumentosAntecedentesByProveedor { type: typeof LOADING_DOCUMENTOSANTECEDENTESBYPROVEEDOR; loading: boolean; }
interface GetDocumentosAntecedentesByProveedor { type: typeof GET_DOCUMENTOSANTECEDENTESBYPROVEEDOR; doctosAntecedentes: IDocumentoSuministro[]; }
interface LoadingDocumentosAction { type: typeof LOADING_DOCUMENTOS; loading: boolean; }
interface HandleLoadingDoctosRequeriosAction { type: typeof LOADING_DOCTOSREQUERIDOS; loading: boolean; }
interface GetNoConformidadesBySuministro { type: typeof GET_NOCONFORMIDADESBYSUMINISTRO; noConformidadesList: any }
interface GetActaRecepcionAction { type: typeof GET_ACTARECEPCION; actaRecepcion: IDocumentoSuministro; }
interface GetDocumentosRecepcionAction { type: typeof GET_DOCUMENTOSRECEPCION; documentosRecepcionList: IDocumentoSuministro[]; }
interface GetDocumentosProtocolosFATAction { type: typeof GET_DOCUMENTOSPROTOCOLOSFAT; documentosProtocolosFATList: IDocumentoSuministro[]; }


// lista de acciones conocidas
export type ItemSuministroKnowActions =
    MainKnownAction
    | HandleLoadingAction
    | GetItemSuministroAction
    | SetMenuSuministroAction
    | ChangeMenuSuministroAction
    | GetEtapasSuministroAction
    | GetTipoDocumentoSuministroAction
    | HandleLoadingComentariosAction
    | GetComentariosDocumentoAction
    | ClearComentariosDocumentoAction
    | GetDocumentosSuministroAction
    | ClearDocumentosSuministroAction
    | ClearItemSuministroAction
    | GetTipoDocumentoNacIntAction
    | GetDocumentosImportacionAction
    | GetComentarioImportacionAction
    | HandleLoadingDownloadDocumentoAction
    | GetDocumentosRequeridosSuministroAction
    | LoadingInspeccionesFabricaSuministroAction
    | GetInspeccionesFabricaSuministroAction
    | GetDoctosEntregablesExistentesAction
    | LoadingDocumentosAntecedentesByProveedor
    | GetDocumentosAntecedentesByProveedor
    | LoadingDocumentosAction
    | HandleLoadingDoctosRequeriosAction
    | GetNoConformidadesBySuministro
    | GetActaRecepcionAction
    | GetDocumentosRecepcionAction
    | GetDocumentosProtocolosFATAction
    ;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

export const ItemSuministroActionCreators = {

    handleLoading: (loading) => ({ type: LOADING, loading } as HandleLoadingAction),

    clearItemSuministro: () => ({ type: CLEAR_ITEMSUMINISTRO } as ClearItemSuministroAction),
    getItemSuministroById: (idSuministro: string): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/ProcesoSuministro/GetProcesoSuministro`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(idSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IProcesoSuministro>;
            })
            .then(data => {
                dispatch({ type: GET_ITEMSUMINISTRO, itemSuministro: data });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    setEtapasSuministros: (etapas: IEtapasProceso[], etapaActual: number) => ({ type: SETMENU_SUMINISTRO, etapas, etapaActual } as SetMenuSuministroAction),
    changeEtapaSuministro: (nuevaEtapa: number) => ({ type: CHANGEMENU_SUMINISTRO, nuevaEtapa } as ChangeMenuSuministroAction),

    getEtapasSuministro: (): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {

        const options = {
            method: 'POST',
            headers: defaultHeaders(),
        }

        fetch(`${apiConfig.apiUri}/ProcesoSuministro/GetEtapasProcesoSuministroProveedor`, options)
            .then(response => {

                if (!response.ok) throw Error(response.status.toString());

                return response.json() as Promise<IEtapasSuministro[]>;

            })
            .then(data => {

                dispatch({ type: GET_ETAPASSUMINISTRO, etapas: data.filter(d => d.ID !== 8 && d.ID !== 9), });

            })
            .catch(error => {

                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));

            });

    },

    getTipoDocumentosSuministroByTipo: (tipoSuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetTipoDocumentoProcesoSuministro`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(tipoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentosTipo[]>;
            })
            .then(data => {
                dispatch({ type: GET_TIPODOCUMENTOSSUMINISTRO, tipoDocumentosList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    handleLoadingDoctosRequeridos: (loading) => ({ type: LOADING_DOCTOSREQUERIDOS, loading } as HandleLoadingDoctosRequeriosAction),
    getDocumentosRequeridosBySuministro: (suministroId: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentosRequeridosBySuministro`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(suministroId),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoRequerido[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSREQUERIDOSSUMINISTROS, doctosRequeridosList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
                dispatch(ItemSuministroActionCreators.handleLoadingDoctosRequeridos(false));
            });
    },



    handleLoadingComentarios: (loading) => ({ type: LOADING_COMENTARIOS, loading } as HandleLoadingComentariosAction),
    clearComentariosDocumento: () => ({ type: CLEAR_COMENTARIOSDOCUMENTO } as ClearComentariosDocumentoAction),
    getComentariosDocumentoById: (documento: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetComentarioDocumentoProcesoSuministro`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(documento),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IComentarioBase[]>;
            })
            .then(data => {
                dispatch({ type: GET_COMENTARIOSDOCUMENTO, comentariosList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },
    addComentarioDocumento: (comentario: IComentarioBase): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        const formData = new FormData();

        formData.append("Title", comentario.Title);
        formData.append("isSaesa", comentario.isSaesa.toString());
        formData.append("ProveedorInspectorId", comentario.ProveedorInspectorId.toString());
        formData.append("DocumentosSuministrosId", comentario.DocumentosSuministrosId.toString());

        if (comentario.AttachFile) {
            formData.append("fileName", comentario.AttachFile.name);
            formData.append("fileData", comentario.AttachFile);
        }
        
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/AddComentarioDocumentoProcesoSuministro`, {
            method: 'POST',
            headers: {Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`},
            body: formData,
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                else {
                    dispatch(ItemSuministroActionCreators.getComentariosDocumentoById(comentario.DocumentosSuministrosId));
                }
            })
            .catch(error => {
                dispatch(ItemSuministroActionCreators.handleLoadingComentarios(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },


    clearDocumentosSuministro: () => ({ type: CLEAR_DOCUMENTOSSUMINISTRO } as ClearDocumentosSuministroAction),
    loadingDocumentosSuministro: (loading: boolean) => ({ type: LOADING_DOCUMENTOS, loading } as LoadingDocumentosAction),
    getDocumentosSuministros: (codigoSuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentoProcesoSuministroProveedor`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSSUMINISTRO, documentosList: data, });
            })
            .catch(error => {
                dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    getDocumentosSuministrosSinFiltro: (codigoSuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentoProcesoSuministro`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSSUMINISTRO, documentosList: data, });
            })
            .catch(error => {
                dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    getDocumentosRecepcion: (codigoSuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentoProcesoSuministroRecepcion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSRECEPCION, documentosRecepcionList: data, });
            })
            .catch(error => {
                dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    getDocumentosProtocolosFAT: (codigoSuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentosProcesoSuministroProtocolosFAT`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSPROTOCOLOSFAT, documentosProtocolosFATList: data, });
            })
            .catch(error => {
                dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    uploadFileSuministro: (files: File[], metadataList: IMetadataDocumentoItem[], codigoSuministoId: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {

        let formData = new FormData();

        for (let f = 0; f < files.length; f += 1) {

            formData.append(`documentoProcesoSuministro[${f}].DocumentoSuministroGeneral.ShpIdDocumento`, String(metadataList[f].idSubido));
            formData.append(`documentoProcesoSuministro[${f}].File`, files[f], files[f].name);
            formData.append(`documentoProcesoSuministro[${f}].DocumentoSuministroGeneral.DsgCodigoSuministro`, String(codigoSuministoId));
            formData.append(`documentoProcesoSuministro[${f}].DocumentoSuministroGeneral.DsgDocumentosEstadoId`, String(EnumEstadosDocumentosSuministros.EnRevision));
            formData.append(`documentoProcesoSuministro[${f}].DocumentoSuministroGeneral.DsgFaseId`, String(metadataList[f].FaseId));
            formData.append(`documentoProcesoSuministro[${f}].DocumentoSuministroGeneral.DsgDocumentosTipoId`, String(metadataList[f].DocumentosTipoId));
            formData.append(`documentoProcesoSuministro[${f}].DocumentoSuministroGeneral.DsgCodigoDoctoRequerodId`, String(metadataList[f].CodigoDoctoRequeridoId));
            formData.append(`documentoProcesoSuministro[${f}].DocumentoSuministroGeneral.DsgDetalleDocumento`, metadataList[f].Detalle);
            formData.append(`documentoProcesoSuministro[${f}].DocumentoSuministroGeneral.DsgEmailSolicitante`, String(metadataList[f].EmailSolicitante));

        }

        const options = {
            method: 'POST',
            headers: { Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}` },
            body: formData,
        }

        fetch(`${apiConfig.apiUri}/DocumentoSuministro/UploadDocumentoProcesoSuministro`, options)

            .then(response => {

                if (!response.ok) {

                    response.json().then(json => {
                        dispatch(MainActionCreators.onShowMessageBar(`El archivo ya existe o el tipo no corresponde al ya existente.`, 'error'));
                        dispatch(ItemSuministroActionCreators.getDocumentosSuministros(codigoSuministoId));
                        dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                        dispatch(ItemSuministroActionCreators.getDocumentosRequeridosBySuministro(codigoSuministoId));
                    });

                } else {
                    dispatch(MainActionCreators.onShowMessageBar(`Carga correcta de archivos.`, 'success'));
                    dispatch(ItemSuministroActionCreators.getDocumentosRequeridosBySuministro(codigoSuministoId));
                    dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                    dispatch(ItemSuministroActionCreators.getDocumentosSuministros(codigoSuministoId));
                }

            });

    },

    uploadActaRecepcion: (files: Blob, metadata: string, codigoSuministoId: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        let formData = new FormData();
        const file = new File([files], metadata, { type: 'application/pdf' });
        formData.append(`documentoProcesoSuministro[0].File`, file, file.name);
        formData.append(`documentoProcesoSuministro[0].DocumentoSuministroGeneral.DsgCodigoSuministro`, String(codigoSuministoId));
        formData.append(`documentoProcesoSuministro[0].DocumentoSuministroGeneral.DsgDocumentosEstadoId`, String(EnumEstadosDocumentosSuministros.Aprobado));
        formData.append(`documentoProcesoSuministro[0].DocumentoSuministroGeneral.DsgDocumentosTipoId`, String(46));

        const options = {
            method: 'POST',
            headers: { Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}` },
            body: formData,
        }
        
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/UploadDocumentoProcesoSuministroRecepcion`, options)

            .then(response => {

                if (!response.ok) {

                    response.json().then(json => {
                        dispatch(MainActionCreators.onShowMessageBar(`El archivo ya existe o el tipo no corresponde al ya existente.`, 'error'));
                        dispatch(ItemSuministroActionCreators.getDocumentosSuministros(codigoSuministoId));
                        dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                        dispatch(ItemSuministroActionCreators.getDocumentosRequeridosBySuministro(codigoSuministoId));
                        dispatch(ItemSuministroActionCreators.getDocumentosRecepcion(codigoSuministoId));
                    });

                } else {
                    dispatch(MainActionCreators.onShowMessageBar(`Acta generada correctamente.`, 'success'));
                    dispatch(ItemSuministroActionCreators.getDocumentosRequeridosBySuministro(codigoSuministoId));
                    dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                    dispatch(ItemSuministroActionCreators.getDocumentosSuministros(codigoSuministoId));
                    dispatch(ItemSuministroActionCreators.getDocumentosRecepcion(codigoSuministoId));
                }

            });

    },

    getTipoDocumentoNacionalInernacional: (): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoImportacion/GetTipoDocumentoNacionalInternacional`, {
            method: 'GET',
            headers: defaultHeaders(),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentosTipo[]>;
            })
            .then(data => {
                const doctosTipo = data.filter(docto => docto.ID !== 43)
                dispatch({ type: GET_TIPODOCUMENTONACINT, tipoDocNacIntList: doctosTipo });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    getDocumentosImportacion: (codigoSuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoImportacion/GetDocumentosImportacion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoImportacion[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSIMPORTACION, documentosImportacionList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    uploadFileImportacion: (files: File[], metadataList: IMetadataDocumentoItem[], codigoSuministoId: number, isReupload: boolean, res): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {

        let formData = new FormData();


        for (let f = 0; f < files.length; f += 1) {



            formData.append(`documentosImportacion[${f}].File`, files[f], files[f].name);

            formData.append(`documentosImportacion[${f}].DocumentoImportacion.DsgCodigoSuministro`, String(codigoSuministoId));

            formData.append(`documentosImportacion[${f}].DocumentoImportacion.DsgDocumentosEstadoId`, String(EnumEstadosDocumentosSuministros.EnRevision));

            formData.append(`documentosImportacion[${f}].DocumentoImportacion.DsgDocumentosTipoId`, String(metadataList[f].DocumentosTipoId));

            formData.append(`documentosImportacion[${f}].DocumentoImportacion.DsgDetalle`, metadataList[f].Detalle);

            if (isReupload) {
                formData.append(`documentosImportacion[${f}].DocumentoImportacion.DocumentoId`, metadataList[f].IdDocumento);
            } else {
                formData.append(`documentosImportacion[${f}].DocumentoImportacion.DocumentoId`, "0");
            }

            formData.append(`documentosImportacion[${f}].DocumentoImportacion.DsgnombreDocumentoAntiguo`, metadataList[f].nombreDocumentoAntiguo);
            formData.append(`documentosImportacion[${f}].DocumentoImportacion.DsgEmailSolicitante`, metadataList[f].EmailSolicitante);

        }

        fetch(`${apiConfig.apiUri}/DocumentoImportacion/UploadDocumentoImportacion`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}` },
            body: formData,
        })
            .then(response => {

                if (!response.ok) {

                    response.json().then(json => {

                        dispatch(MainActionCreators.onShowMessageBar(json.join(' - '), 'error'));

                        res()

                    });

                } else {

                    dispatch(ItemSuministroActionCreators.getDocumentosImportacion(codigoSuministoId));

                }

            });
    },

    getComentariosImportacionById: (documento: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoImportacion/GetComentariosDocumentosImportacion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(documento),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IComentarioBase[]>;
            })
            .then(data => {
                dispatch({ type: GET_COMENTARIOIMPORTACION, comentarioImportacionList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    addComentarioDocumentoImportacion: (comentario: IComentarioBase): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoImportacion/AddComentarioDocumentoImportacion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(comentario),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                else dispatch(ItemSuministroActionCreators.getComentariosImportacionById(comentario.DocumentosImportacionId));
            })
            .catch(error => {
                dispatch(ItemSuministroActionCreators.handleLoadingComentarios(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    loadingDownloadDocumento: (loading, key) => ({ type: LOADING_DOWNLOADDOCUMENTO, loading, key } as HandleLoadingDownloadDocumentoAction),
    downloadDocumento: (url: string, fileName: string): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/DownloadDocumentoProcesoSuministro`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(url),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.blob();
            })
            .then((blob: Blob) => {
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
                dispatch({ type: LOADING_DOWNLOADDOCUMENTO, loading: false, key: fileName } as HandleLoadingDownloadDocumentoAction);
            })
            .catch(error => {
                console.log('HORROOOOR!', error);
            });
    },

    loadingInspeccionesFabrica: (loading: boolean) => ({ type: LOADING_INSPECCIONESFABRICASUMINISTRO, loading } as LoadingInspeccionesFabricaSuministroAction),
    getInspeccionesFabrica: (suministroId: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/InspeccionFabrica/GetInspeccionFabricaBySuministroId`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(suministroId),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IInspeccionFabrica[]>;
            })
            .then(data => {
                dispatch({ type: GET_INSPECCIONESFABRICASUMINISTRO, inspeccionesList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    getDocumentosSuministrosExpedito: (codigoSuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentoProcesoSuministroProveedorExpedito`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCTOSENTREGABLESEXISTENTES, doctoExistentesList: data, });
            })
            .catch(error => {
                dispatch(ItemSuministroActionCreators.loadingDocumentosSuministro(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    loadingDocumentosAntecedentesByProveedor: (loading: boolean) => ({ type: LOADING_DOCUMENTOSANTECEDENTESBYPROVEEDOR, loading } as LoadingDocumentosAntecedentesByProveedor),
    getDocumentosAntecedentesByProveedor: (codigoSuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentosAntecedentesByProveedor`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSANTECEDENTESBYPROVEEDOR, doctosAntecedentes: data, });
            })
            .catch(error => {
                dispatch(ItemSuministroActionCreators.loadingDocumentosAntecedentesByProveedor(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    getNoConformidadesBySuministro: (idsuministro: number): AppThunkAction<ItemSuministroKnowActions> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/ProcesoSuministro/GetNoConformidadesBySuministro`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(idsuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_NOCONFORMIDADESBYSUMINISTRO, noConformidadesList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

};