import { Action, Reducer } from 'redux';
import {
    LOADING,
    GET_INSPECCIONESLIST,
} from './constants';
import { GrillaInspeccionKnownAction } from './actions';
import { IInspeccionFabrica } from './interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface GrillaInspeccionState {
    listaInspecciones?: IInspeccionFabrica[];
    loading: boolean;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const GrillaInspeccionReducer: Reducer<GrillaInspeccionState> = (state: GrillaInspeccionState | undefined, incomingAction: Action): GrillaInspeccionState => {
    if (state === undefined) {
        return {
            loading: false,
        };
    }
    const action = incomingAction as GrillaInspeccionKnownAction;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading };
        case GET_INSPECCIONESLIST:
            const listaInspecciones = action.listaInspecciones.map( i => ({
                ...i,
                CodigoSuministroId: i.CodigoSuministro && i.CodigoSuministro.lookupId,
                EstadoInspeccionId: i.EstadoInspeccion && i.EstadoInspeccion.lookupId,
                NombresuministroId: i.NombreSuministro && i.NombreSuministro.lookupId,
            }));
            return { ...state, listaInspecciones, loading: false, };
        default:
            return state;
    }
};
