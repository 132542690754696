// obtengo el hostname y lo puedo verificar
const hostname = window && window.location && window.location.hostname;


const AccessTokenKeyMS365 = `SPBOX$H4R3P01NTK3YMSTR3$$31$$1NC0V20201224v2.${hostname}`;
const AccessTokenKeyGSuit = `SPBOX$H4R3P01NTK3YG5U1TV20201224v2.${hostname}`;
const AccessTokenKeyFusionAuth = `SPBOX$H4R3P01NTK3YG5U1TV20201224v2.${hostname}`;
const AccessTokenKey = `$PB0X$H4R3P01NT$3RV1C3V20201224v2.${hostname}`;
const UserStorageKey = `U$3R$T0R4G3K3Y$PB0XV20201224v2.${hostname}`;
const UserAccessEmail = `U$3R4CC3$$3M41L.${hostname}`;

// microsoft
let microsoft_idclient = '4ce684db-701c-41f9-974b-aa2c2fde077f';
const msalOptions = { scopes: ["openid", "profile", "user.read"] };
let baseUriDocuments = 'https://frontel.sharepoint.com';

// google
let google_idclient = '';

//fusion auth params
let frontEndAppUrl = 'http://localhost';
//let backEndAppUrl = 'https://localhost';
//let redirectURI = 'http://localhost:9000/oauth-callback';
let fusionAuth_GetCodeMethod = 'oauth2/authorize';
//let fusionAuth_GetTokenMethod = 'oauth2/token';

// ports
let clientPort = 5005;

let apiUri = 'https://app-gesdoc-wsvc-prd-01.azurewebsites.net/api';
let clientUrlSuccessLogin = 'https://app-gesdoc-fe-prd-01.azurewebsites.net/fauthcodebytoken';

	//TODO SAESA
	//SAESA
	let fusionAuth_BaseUrl = 'https://idm.gruposaesa.cl';
	//SAESA
	let fusionAuth_tenantID = 'abe2474a-093c-462d-87ff-87dcd7350a8e';
	//SAESA
	let fusionAuth_clientID = '92169ffb-7cb3-4da8-9fb0-572b73221a37';
	//SAESA
	let fusionAuth_clientSecret = 'duuKn05877SBiyBsRpadTZjD8G2kJgt0eLWZ2noTla4';	

let LogInFusionAuthUrl = `${fusionAuth_BaseUrl}/${fusionAuth_GetCodeMethod}?client_id=${fusionAuth_clientID}&response_type=code&redirect_uri=${clientUrlSuccessLogin}&tenantId=${fusionAuth_tenantID}`;

let LogInAzureB2CUrl = 'https://idmgsexternos.b2clogin.com/idmgsexternos.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_gestor-abastecimiento&client_id=80be8c38-66ec-4c50-a0a7-47c8b40ca80b&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp-gesdoc-fe-prd-01.azurewebsites.net%2Ffauthcodebytoken&scope=openid&response_type=code&prompt=login';
let recuperarPasswd = 'https://idmgsexternos.b2clogin.com/idmgsexternos.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_gestor-abastecimiento-restablecimiento-contrasena&client_id=80be8c38-66ec-4c50-a0a7-47c8b40ca80b&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp-gesdoc-fe-prd-01.azurewebsites.net%2Ffauthcodebytokenchangepasswd&scope=openid&response_type=code&prompt=login';
const config = {
    apiUri,
    AccessTokenKeyMS365,
    AccessTokenKeyGSuit,
	AccessTokenKeyFusionAuth,
    AccessTokenKey,
    UserStorageKey,
    microsoft_idclient,
    microsoft_authority: 'https://login.microsoftonline.com/common',
    google_idclient,
    msalOptions,
    baseUriDocuments,
    UserAccessEmail,
	//Fusion Auth Configs
	LogInFusionAuthUrl,
	LogInAzureB2CUrl,
	recuperarPasswd
};

export default config;
