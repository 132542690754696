import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { Text } from 'office-ui-fabric-react/lib/Text';
import styles from './MaestroMateriales.module.scss';

import { IUsuarioLogin } from '../utils/interfaces';
import { Loading } from '../Shared/Loading/loading';
import { MaestroMaterialesActionCreators } from './actions';
import { IMaestroMaterial } from './interfaces';
import { CustomIconButton } from '../Shared/IconButton';
import _ from 'lodash';
import { FontIcon } from '@fluentui/react';
import { isEqualArrayObject } from '../utils/functions';
import Card from './InternalComponents/Card';
import { Separador } from '../Shared/Separador/separador';
import { PanelMaterial } from './InternalComponents/PanelMaterial';

interface IConnectedState {
    loading: boolean;
    user: IUsuarioLogin;

    MaterialesList: IMaestroMaterial[];
    showList: IMaestroMaterial[];
    paginaActual: number;
    maxSize: number;
    lastPage: number;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        loading: state.maestroMateriales.loading,
        user: state.main.user,
        MaterialesList: state.maestroMateriales.MaterialesList,
        showList: state.maestroMateriales.showList,
        paginaActual: state.maestroMateriales.pagina,
        maxSize: state.maestroMateriales.maxSize,
        lastPage: state.maestroMateriales.lastPage,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IMaestroMaterialesOwnProps {
}

// define combined props
type IMaestroMaterialesProps = IMaestroMaterialesOwnProps & IConnectedState &{ dispatch(action: any): void };

interface IMaestroMaterialesState{
    isLoaded: boolean;
    openPanel: boolean;
    materialSelected?: IMaestroMaterial,
}

class MaestroMateriales extends React.PureComponent<IMaestroMaterialesProps, IMaestroMaterialesState> {

    constructor(props: IMaestroMaterialesProps) {
        super(props);
        this.state = {
            isLoaded: false,
            openPanel: false,
        }
    }

    public componentDidMount(): void {

        const { usuarioProveedor } = this.props.user;

        const { proveedor_x003a_Rut } = usuarioProveedor

        const { lookupValue } = proveedor_x003a_Rut
        
        this.props.dispatch(MaestroMaterialesActionCreators.handleLoading(true));
        
        this.props.dispatch(MaestroMaterialesActionCreators.getMaterialesList(lookupValue));
    
    }


    public componentDidUpdate(prevProps: IMaestroMaterialesProps): void {
        const { MaterialesList } = this.props;
        const { isLoaded } = this.state;
        if ((!isLoaded && MaterialesList && MaterialesList.length > 0) || (MaterialesList && !isEqualArrayObject(MaterialesList, prevProps.MaterialesList))){
            this.props.dispatch(MaestroMaterialesActionCreators.handleLoadingMaestroMaterial(true));
            this.props.dispatch(MaestroMaterialesActionCreators.setInitialData(30));
            this.setState({
                isLoaded: true
            });
        }
    }

    private _onPrevPage = (): void => {
        this.props.dispatch(MaestroMaterialesActionCreators.handleLoading(true));
        this.props.dispatch(MaestroMaterialesActionCreators.prevPage());
    }

    private _onNextPage = (): void => {
        this.props.dispatch(MaestroMaterialesActionCreators.handleLoading(true));
        this.props.dispatch(MaestroMaterialesActionCreators.nextPage());
    }

    private _onGoToPage = (page: number): void => {
        this.props.dispatch(MaestroMaterialesActionCreators.handleLoading(true));
        this.props.dispatch(MaestroMaterialesActionCreators.gotoPage(page));
    }

    private _renderPageMenu = () => {
        const { paginaActual, lastPage } = this.props;

        let init: number = 0;
        let end: number = 0;

        if (paginaActual === 1){
            init = paginaActual;
            end = paginaActual+10;
        } else if (paginaActual <= 5){
            init = 1;
            end = 11;
        } else if ( paginaActual === lastPage ){
            init = paginaActual -10;
            end = lastPage+1;
        } else if ( paginaActual > lastPage -5 ) {
            init = lastPage - 10;
            end = lastPage+1;
        } else {
            init = paginaActual - 5;
            end = paginaActual + 5;
        }

        if (end > lastPage && lastPage < 10){
            init = 1;
            end = lastPage +1;
        }

        return (_.range(init,end).map( (p,i) => (
            <div key={i} className={`${styles.numerIcon} ${paginaActual === p && styles.actual} `} onClick={() => this._onGoToPage(p)}>{p}</div>
        )));
    }

    private _onOpenPanel = (item: IMaestroMaterial):void => {
        this.setState({
            openPanel: true,
            materialSelected: item,
        });
    }

    private _onClosePanel = ():void => {
        this.setState({
            openPanel: false,
            materialSelected: undefined,
        });
    }

    public render() {
        const {showList, paginaActual, lastPage, loading} = this.props
        const { materialSelected, openPanel} = this.state;
        if (loading) return <Loading fullScreen message="Cargando materiales..." />
        return (
            <div className={styles.MaestroMaterialesContainer}>
                <Text variant='xxLarge'>Materiales</Text>
                <Separador />
                <div className={styles.CardContainer}>
                    {showList && showList.map( s => (<Card key={s.UniqueId} material={s} openPanel={() => this._onOpenPanel(s)} />))}
                </div>
                <Separador />
                <div className={styles.Footer}>
                    <div className={styles.FooterContainer}>
                        <CustomIconButton
                            label='Primera página'
                            onClick={() => this._onGoToPage(1)}
                            icon='DoubleChevronLeft'
                            id='goToFirstPage'
                            disabled={paginaActual === 1 || lastPage === 1}
                        />
                        <CustomIconButton
                            label='Página anterior'
                            onClick={this._onPrevPage}
                            icon='ChevronLeft'
                            id='goPrevPage'
                            disabled={paginaActual === 1 || lastPage === 1}
                        />
                        {paginaActual > 6 && (<FontIcon aria-label="MoreInit" iconName="More" />)}
                        {this._renderPageMenu()}
                        {((paginaActual < (lastPage -4)) && (lastPage > 10)) && (<FontIcon aria-label="MoreLast" iconName="More" />)}
                        <CustomIconButton
                            label='Página siguiente'
                            onClick={this._onNextPage}
                            icon='ChevronRight'
                            id='goPrevPage'
                            disabled={paginaActual === lastPage || lastPage === 0}
                        />
                        <CustomIconButton
                            label='Última página'
                            onClick={() => this._onGoToPage(lastPage)}
                            icon='DoubleChevronRight'
                            id='goToLastPage'
                            disabled={paginaActual === lastPage || lastPage === 0}
                        />
                    </div>
                </div>

                <PanelMaterial
                    material={materialSelected}
                    openPanel={openPanel}
                    onClose={this._onClosePanel}
                />

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaestroMateriales);
