import React from 'react';
import { Loading } from '../Shared/Loading/loading';
import * as MasterFunctions from '../../components/utils/functions';
import { useDispatch } from 'react-redux';
import { IFusionAuthInfo } from '../utils/interfaces';
import { MainActionCreators } from '../Main/actions';

const ChangePass = () => {


  React.useEffect(()=>{
    processRequestData();
  }, []);

const dispatch = useDispatch();

const processRequestData =() =>{
  let dataFromUrl = MasterFunctions.parseParamsDataFromURL(window.location.search);

  if(dataFromUrl !== null && dataFromUrl !== undefined){

    let fusionAuthCodeInfo : IFusionAuthInfo = {
      code : dataFromUrl[0].value,
      locale : dataFromUrl[0].value,
      userState : null 
    } 

    dispatch(MainActionCreators.restorePasswd(fusionAuthCodeInfo));

  }
}

  return (
    <Loading fullScreen message='Cargando... Por favor espere'></Loading>
)
}

export default ChangePass;


