import { Action, Reducer } from 'redux';
import {
    LOADING,
	NEXTPAGE,
	PREVPAGE,
	GOTOPAGE,
	CHANGEMAXSIZE,
	GET_MAESTROMATERIALES,
	LOADING_SHOWMAESTROMATERIAL,
	INITIAL_SHOWLIST,
} from './constants';
import { MaestroMaterialesKnownAction } from './actions';
import { IMaestroMaterial } from './interfaces';
import { isImageDocto } from '../utils/functions';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MaestroMaterialesState {
    MaterialesList?: IMaestroMaterial[];
    loading: boolean;
	loadingMaestroMaterial: boolean;
	pagina: number;
	maxSize: number;
	showList: IMaestroMaterial[];
    lastPage: number;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const MaestroMaterialReducer: Reducer<MaestroMaterialesState> = (state: MaestroMaterialesState | undefined, incomingAction: Action): MaestroMaterialesState => {
    if (state === undefined) {
        return {
            loading: false,
			loadingMaestroMaterial: false,
            pagina: -1,
            maxSize: -1,
            showList: [],
            lastPage: -1,
        };
    }
    const action = incomingAction as MaestroMaterialesKnownAction;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading };

		case LOADING_SHOWMAESTROMATERIAL:
			return { ...state, loadingMaestroMaterial: action.loading };

		case INITIAL_SHOWLIST:
			return {
				...state,
				pagina: 1,
				maxSize: action.maxSize,
				showList: action.showList,
				lastPage: Math.trunc(state.MaterialesList.length / action.maxSize),
				loadingMaestroMaterial: false,
			};

		case NEXTPAGE:
			return {
				...state,
				pagina:  action.nextPage,
				showList: action.showList,
				loading: false,
			};
		
		case PREVPAGE:
			return {
				...state,
				pagina: action.prevPage,
				showList: action.showList,
				loading: false,
			};
		
		case GOTOPAGE:
			return {
				...state,
				pagina: action.page,
				showList: action.showList,
				loading: false,
			};
		
		case CHANGEMAXSIZE:
			return {
				...state,
				pagina: 1,
				maxSize: action.size,
				showList: action.showList,
				lastPage: Math.trunc(state.MaterialesList.length / action.size),
				loading: false,
			};

		case GET_MAESTROMATERIALES:
			return {
				...state,
				MaterialesList: action.materialesList.map( mm => ({
					...mm,
					Created: new Date(mm.Created)
				})),
				loading: false,
			};

        default:
            return state;
    }
};
