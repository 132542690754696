import { UserAgentApplication } from 'msal';
import apiConfig from '../apiConfig';

const msalConfig = {
    authority: apiConfig.microsoft_authority,
    clientId: apiConfig.microsoft_idclient,
    redirectUri: document.getElementById('root')!.baseURI,
};

export const msalAuth = new UserAgentApplication({
    auth: msalConfig,
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    },
});

//export const msalActions 