import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { useBoolean } from '@uifabric/react-hooks';
import { CustomButton } from './Button';
import { CustomIconButton } from './IconButton';

const modelProps = {
    isBlocking: true,
    topOffsetFixed: true,
    styles: { main: { maxWidth: 500 } },
};

interface IDialogModalProps {
    children?: any;
    id: string;
    title?: string;
    subtitle?: string;
    successText: string;
    successAcction: any;
    unsuccessText: string;
    unsuccessAction?: any;
    buttomOptions: IButtomOptions;
    buttonType?: string;
}

interface IButtomOptions {
    label: string;
    iconName: string;
    disabled?: boolean;
}

export const DialogModal: React.FunctionComponent<IDialogModalProps> = (props) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const { children, title, subtitle, id, successText, unsuccessText, successAcction, unsuccessAction, buttomOptions, buttonType } = props;

    const _onSuccessAction = () => {
        successAcction();
        toggleHideDialog();
    }

    const _onUnsuccessAction = () => {
        if (unsuccessAction) unsuccessAction();
        toggleHideDialog();
    }

    const _onRenderButton = () => {
        switch (buttonType) {
            case 'icon':
                return (<CustomIconButton disabled={buttomOptions.disabled} icon={buttomOptions.iconName} onClick={toggleHideDialog} label={buttomOptions.label} id={`DialogModal_${id}`}/>);
            case 'button':
            default:
                return (<CustomButton id={`DialogModal_${id}`} onClick={toggleHideDialog} label={buttomOptions.label} icon={buttomOptions.iconName} disabled={buttomOptions.disabled} />);
        }
    }

    return (
        <div id={`DialogModal_${id}`}>
            {_onRenderButton()}
            <Dialog
                hidden={hideDialog}
                onDismiss={_onUnsuccessAction}
                modalProps={modelProps}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: title? title: '',
                    subText: subtitle? subtitle: '',
                }}
            >
                {children? children:<></>}
                <DialogFooter>
                    <PrimaryButton onClick={_onSuccessAction} text={successText} id={`DialogModal-buttomSuccess-${id}`} iconProps= {{iconName: 'Accept'}}/>
                    <DefaultButton onClick={_onUnsuccessAction} text={unsuccessText} id={`DialogModal-buttomCancel-${id}`} iconProps= {{iconName: 'Cancel'}} />
            </DialogFooter>
            </Dialog>
        </div>

    );
};
