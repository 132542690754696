import * as React from 'react';

import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import styles from './Loading.module.scss';
import { Stack } from '@fluentui/react';

interface ILoadingProps {
    fullScreen: boolean;
    message: string;
    messagePosition?: 'top' | 'left' | 'right'; // default 'bottom"
}

export const Loading: React.FunctionComponent<ILoadingProps> = (props) => {
    return(
    <Stack>
        { props.fullScreen ? (
            <div
                className={styles.LoadingFullScreen}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="loadingPagesTitle"
                data-backdrop="static"
            >
                <div className={styles.LoadingContent}>
                <Spinner
                    size={SpinnerSize.large}
                    label={props.message}
                    ariaLive="assertive"
                    labelPosition={props.messagePosition}
                />
                </div>
            </div>
        ):(
            <div
                className={styles.Loading}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="loadingPagesTitle"
                data-backdrop="static"
            >
                <div className={styles.LoadingContent}>
                <Spinner
                    size={SpinnerSize.medium}
                    label={props.message}
                    ariaLive="assertive"
                    labelPosition={props.messagePosition}
                />
                </div>
            </div>
        )}
    </Stack>
    );
};