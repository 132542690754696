import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

interface ICustomTextFieldProps {
    label?: string,
    required?: boolean,
    placeholder?: string,
    onChange?: any,
    styles?: object,
    type?: string,
    id: string,
    value?: any,
    defaultValue?: any,
    disabled?: boolean,
    onBlur?: any,
    className?: string;
    name?: string;
    multiple?: boolean;
};

export const CustomTextField: React.FunctionComponent<ICustomTextFieldProps> = (props: ICustomTextFieldProps) => {
    return (
        props.type === 'file'? (
            <div>
              <label>{props.label}</label>
              <input
                name={props.name}
                type='file'
                value={props.value as string[]}
                id={props.id}
                disabled={props.disabled}
                onChange={props.onChange}
                title={props.label}
                placeholder='Seleccione un archivo...'
                multiple={props.multiple}
              />
            </div>
          ): (
            <TextField
                {...props}
            />
          )
        
        
    ); 
};