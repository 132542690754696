import * as React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { CustomButton } from '../../Shared/Button';
import styles from '../ItemInspeccionHomologacion.module.scss';
import { IComentarioBase } from '../../ItemSuministro/interfaces';
import { Comentarios } from '../../Shared/Comentarios/comentarios';
import { Separator } from 'office-ui-fabric-react';
import { ConvertSharepointDate } from '../../utils/functions';

import ApiConfig from '../../../config/apiConfigOficial';
import { EnumEstadosDocumentosSuministros } from '../../utils/enums';
import { Loading } from '../../Shared/Loading/loading';
import { IDocumentosInspeccionHomologacion } from '../../ItemHomologacion/interfaces';

interface IPanelDocumentoProps {
  onClose: any;
  isOpen: boolean;
  selectedDocument: IDocumentosInspeccionHomologacion;
  comentariosList: IComentarioBase[];
  loadingComentarios: boolean;
  disabled?: boolean;
  usuarioProveedor?: number;
  onAddComentario: (comentario: IComentarioBase) => void;
  onDescargarDocumento: (uri: string, fileName: string) => void,
}

interface IPanelDocumentoState {
  comentarioObject: IComentarioBase;
}

export default class PanelDoctoInspeccion extends React.Component<IPanelDocumentoProps, IPanelDocumentoState>  {

  private comentarioClean: IComentarioBase = {
    ID: -1,
    Title: '',
    isSaesa: false,
    ProveedorInspectorId: -1,
  };

  constructor(props: IPanelDocumentoProps) {
    super(props);
    this.state = {
      comentarioObject: this.comentarioClean,
    };
  }

  private _onChangeComentario = (ev): void => {
    const { comentarioObject } = this.state;
    let comentarioObjectModified: IComentarioBase = { ...comentarioObject };
    const { id, value } = ev.target;
    switch (id) {
      case 'Title':
        comentarioObjectModified = { ...comentarioObject, Title: value };
        break;
      default:
        comentarioObjectModified = { ...comentarioObject };
        break;
    }
    this.setState({
      comentarioObject: comentarioObjectModified
    });
  }

  private _onAddComentario = () => {
    const { onAddComentario, selectedDocument, usuarioProveedor } = this.props;
    const { comentarioObject } = this.state;
    onAddComentario({ ...comentarioObject, DocumentosHomologacionId: selectedDocument.ID, ProveedorInspectorId: usuarioProveedor });
    this.setState({ comentarioObject: this.comentarioClean });
  }

  private _onRenderFooter = (): JSX.Element => {
    const { onClose } = this.props;
    return (<CustomButton icon='Cancel' onClick={onClose()} label='Cerrar' id='cerrarpaneldocumento' />);
  }

  public render() {
    const { isOpen, onClose, selectedDocument, comentariosList, loadingComentarios, disabled } = this.props;
    const { comentarioObject } = this.state;
    const disableComentarios: boolean = selectedDocument && selectedDocument.DocumentosEstadoId === EnumEstadosDocumentosSuministros.Aprobado;

    return (
      <Panel
        isOpen={isOpen}
        onDismiss={onClose()}
        headerText={selectedDocument ? selectedDocument.FileLeafRef : ''}
        closeButtonAriaLabel="Cerrar"
        onRenderFooterContent={this._onRenderFooter}
        isFooterAtBottom={true}
        type={PanelType.medium}
      >
        {selectedDocument ? (
          <div className={styles.panelContainer}>
            <div className={styles.infoDocumento}>
              <h3>Detalles del documento</h3>
              <table>
                <tbody>
                  <tr>
                    <td><b>Nombre:</b></td>
                    <td>{selectedDocument.FileLeafRef}</td>
                  </tr>
                  <tr>
                    <td><b>Estado:</b></td>
                    <td>{selectedDocument.DocumentosEstado.lookupValue}</td>
                  </tr>
                  <tr>
                    <td><b>Tipo de documento:</b></td>
                    <td>{selectedDocument.DocumentosTipo.lookupValue}</td>
                  </tr>
                  <tr>
                    <td><b>Detalle:</b></td>
                    <td>{selectedDocument.Detalle}</td>
                  </tr>
                  <tr>
                    <td><b>Fecha aprobación:</b></td>
                    <td>{selectedDocument.FechaAprobacion ? ConvertSharepointDate(selectedDocument.FechaAprobacion) : 'No definida'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.downloadDocto}>
              <CustomButton id={`downloadDocto-${selectedDocument.ID}`} label='Descargar' icon='DownloadDocument' onClick={() => this.props.onDescargarDocumento(`${ApiConfig.baseUriDocuments}${selectedDocument.FileRef}`, selectedDocument.FileLeafRef)} />
            </div>
            <Separator />
            {comentariosList ? (<Comentarios hidden={disableComentarios} loading={loadingComentarios} comentarioObject={comentarioObject} comentariosList={comentariosList} onAddComentario={this._onAddComentario} onChangeComentario={this._onChangeComentario} />) : <Loading message='Cargando comentarios...' fullScreen={false} />}
          </div>
        ) : null}
      </Panel>
    );
  }

}
