import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IHomologacionGeneral } from '../../GrillaHomologacion/interfaces';
import { ItemSuministroActionCreators } from '../../ItemSuministro/actions';
import { CustomButton } from '../../Shared/Button';
import { Separador } from '../../Shared/Separador/separador';
import { EnumEstadoHomologacion, EnumHomologacionEtapa } from '../../utils/enums';
import { isEqualArrayObject } from '../../utils/functions';
import { ItemHomologacionesActionCreators } from '../actions';
import { IDoctosRequeridoHomologacion, IDocumentoHomologacion, IDocumentosTipoHomologacion, IMetadataDoctoHomologacion } from '../interfaces';
import styles from '../ItemHomologacion.module.scss';
import DoctosRequeridos from './DoctosRequeridos';
import UploadDocumentos from './UploadDocumentos';

interface IFileList {
    file: File;
    id: number;
}

interface IConnectedState {
    RequisitosCalidad: IDoctosRequeridoHomologacion[];
    Documentos: IDocumentoHomologacion[];
    homologacionItem: IHomologacionGeneral;
    doctosTipoListReqCal: IDocumentosTipoHomologacion[];
    loadingDocument: any;
}

function mapStateToProps(state: ApplicationState): IConnectedState {
    return {
        RequisitosCalidad: state.itemHomologacion.doctosRequeridossListReqCal,
        Documentos: state.itemHomologacion.doctosListReqCal,
        homologacionItem: state.itemHomologacion.homologacionItem,
        doctosTipoListReqCal: state.itemHomologacion.doctosTipoListReqCal,
        loadingDocument: state.itemSuministro.loadingDownloadDocumento,
    };
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

// define combined props
type IRequisitosCalidadProps = IConnectedState & { dispatch(action: any): void };

interface IRequisitosCalidadState {
    isLoaded: boolean;
    DoctosRequeridosList: IDoctosRequeridoHomologacion[];
    filesList: IFileList[];
}

class RequisitosCalidad extends React.PureComponent<IRequisitosCalidadProps, IRequisitosCalidadState> {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            DoctosRequeridosList: [],
            filesList: [],
        };
    }

    private _onInitInternalData = () => {
        const { Documentos, RequisitosCalidad } = this.props;
        this.setState({
            DoctosRequeridosList: RequisitosCalidad.map(rt => ({
                ...rt,
                Documento: Documentos.find(dr => dr.DocumentoRequeridoId === rt.ID),
            })),
            isLoaded: true,
        });
    }

    public componentDidMount() {
        const { Documentos, RequisitosCalidad, doctosTipoListReqCal } = this.props;
        if (RequisitosCalidad && Documentos && doctosTipoListReqCal) {
            this._onInitInternalData();
        }
    }

    public componentDidUpdate(prevProps: IRequisitosCalidadProps) {
        const { Documentos, RequisitosCalidad, doctosTipoListReqCal } = this.props;
        const { isLoaded } = this.state;
        if (RequisitosCalidad && Documentos && doctosTipoListReqCal && (!isLoaded || !isEqualArrayObject(prevProps.RequisitosCalidad, RequisitosCalidad) || !isEqualArrayObject(prevProps.Documentos, Documentos))) {
            this._onInitInternalData();
        }
    }

    private _onUploadFilesRequeridos = () => {

        const { filesList, DoctosRequeridosList } = this.state;
        const { homologacionItem, RequisitosCalidad } = this.props;
        let files: File[] = [];
        let metaData: IMetadataDoctoHomologacion[] = [];

        for (let f = 0; f < filesList.length; f += 1) {
            const { file, id } = filesList[f];
            const doctoRequerido: IDoctosRequeridoHomologacion = DoctosRequeridosList.find(d => d.ID === Number(id));

            files.push(file);
            metaData.push({
                idSubido: doctoRequerido.Documento !== undefined ? doctoRequerido.Documento.ID : 0,
                key: String(id),
                DocumentosTipoId: doctoRequerido.DocumentosTipoId,
                Detalle: doctoRequerido.Title,
                CodigoDoctoRequeridoId: doctoRequerido.ID,
                EtapaId: EnumHomologacionEtapa.ReqCalidad,
                EmailSolicitante: ''
            });
        }

        // envío los documentos a guardar
        this.props.dispatch(ItemHomologacionesActionCreators.handleDoctosRequeridosHomologacionLoading(true));

        this.props.dispatch(ItemHomologacionesActionCreators.handleDocumentosHomologacionLoading(true));

        this.props.dispatch(ItemHomologacionesActionCreators.uploadDocumentoHomologacion(files, metaData, homologacionItem.ID, RequisitosCalidad.filter(r => r.CalculaAvance).length));

        // limpio los inputs!
        var inputFileList = document.getElementsByName("InputFileUpdateDoctos.ReqCalidad");

        inputFileList.forEach(i => (i as HTMLInputElement).value = '');

        this.setState({ filesList: [], isLoaded: false });

    }

    private _onChangeFiles = (ev: any): void => {
        const { filesList } = this.state;
        const { files, id } = ev.target;
        let filesListModified: IFileList[] = filesList.filter(f => f.id !== Number(id));
        filesListModified.push({
            id: Number(id),
            file: files[0]
        });

        this.setState({
            filesList: filesListModified
        });
    }

    private _onDownloadDocument = (uri: string, fileName: string): void => {
        this.props.dispatch(ItemSuministroActionCreators.loadingDownloadDocumento(true, fileName));
        this.props.dispatch(ItemSuministroActionCreators.downloadDocumento(uri, fileName));
    }

    public render(): JSX.Element {
        const { doctosTipoListReqCal, loadingDocument, homologacionItem } = this.props;
        const { DoctosRequeridosList } = this.state;
        return (
            <div className={`${styles.RequisitosCalidad}`}>
                <Separador mensaje='Documentos requeridos' subtitle />
                <DoctosRequeridos
                    doctosRequeridosList={DoctosRequeridosList}
                    onChangeFiles={this._onChangeFiles}
                    etapaHomologacion='ReqCalidad'
                    downloadDocument={this._onDownloadDocument}
                    loadingDoctoDownload={loadingDocument}
                />
                <div className={styles.ButtonBox}>
                    <CustomButton
                        onClick={this._onUploadFilesRequeridos}
                        label='Subir archivos'
                        id='uploadFilesRequeridos.ReqCalidad'
                        icon='Upload'
                        disabled={homologacionItem.EstadoId !== EnumEstadoHomologacion.EnProceso || this.state.filesList.length === 0}
                    />
                </div>
                {doctosTipoListReqCal && homologacionItem.EstadoId === EnumEstadoHomologacion.EnProceso && (
                    <>
                        <Separador mensaje='Carga documentos opcionales' subtitle />
                        <UploadDocumentos
                            doctosTipo={doctosTipoListReqCal}
                            etapaHomologacion={EnumHomologacionEtapa.ReqCalidad} />
                    </>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequisitosCalidad);