import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IInspeccionFabrica, IDocumentosInspeccion } from './interfaces';
import { ItemInspeccionActionCreators } from './actions';
import styles from './ItemInspeccion.module.scss';
import { Loading } from '../Shared/Loading/loading';

import { InformacionGeneral } from './internalComponents/informacionGeneral';
import VistaDoctosInpsecciones from './internalComponents/vistaDocumentos';
import { DocumentosSuministros } from './internalComponents/documentosSuministros';
import CargaDocumentos from './internalComponents/cargaDocumentos';
import { EnumEstadoInspeccion } from '../utils/enums';
import { IUsuarioLogin } from '../utils/interfaces';
import ComentariosRechazo from './internalComponents/comentariosRechazo';

interface IConnectedState {
    itemInspeccion: IInspeccionFabrica;
    loading: boolean;
    documentosInspeccionList: IDocumentosInspeccion[];
    loadingDocumentos: boolean;
    user: IUsuarioLogin;

    loadingDoctosAntecedentes: boolean;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        itemInspeccion: state.itemInspeccion.itemInspeccion,
        loading: state.itemInspeccion.loading,
        documentosInspeccionList: state.itemInspeccion.documentosInspeccionList,
        loadingDocumentos: state.itemInspeccion.loadingDocumentos,
        loadingDoctosAntecedentes: state.itemInspeccion.loadingDoctosAntecedentes,
        user: state.main.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IItemInspeccionOwnProps {
    match: any;
    history: any;
}

// define combined props
type IItemInspeccionProps = IItemInspeccionOwnProps & IConnectedState &{ dispatch(action: any): void };

interface IItemInspeccionState{
    isLoaded: boolean;
}

class ItemInspeccion extends React.PureComponent<IItemInspeccionProps, IItemInspeccionState> {

    constructor(props: IItemInspeccionProps) {
        super(props);
        this.state = {
            isLoaded: false,
        }
    }

    public componentDidMount() {
        const { params } = this.props.match;
        const { history } = this.props;
        this.props.dispatch(ItemInspeccionActionCreators.handleLoading(true));
        if (params.idinspeccion){
            this.props.dispatch(ItemInspeccionActionCreators.handleLoading(true));
            this.props.dispatch(ItemInspeccionActionCreators.getDocumentosTipoInspeccion());
            this.props.dispatch(ItemInspeccionActionCreators.getItemInspeccion(params.idinspeccion));
        } else {
            history.push('/notfound');
        }
    }
    
    public componentDidUpdate(){
        const { itemInspeccion } = this.props;
        const { isLoaded } = this.state;
        const { usuarioProveedor } = this.props.user;

        if (!isLoaded && itemInspeccion){
            if (usuarioProveedor.proveedorInspector){
                this.props.dispatch(ItemInspeccionActionCreators.getDocumentosSuministrosInspector(itemInspeccion.CodigoSuministroId));
            } else if (usuarioProveedor.proveedor){
                this.props.dispatch(ItemInspeccionActionCreators.handleDoctosAntecedentesProveedor(true));
                this.props.dispatch(ItemInspeccionActionCreators.getDocumentosAntecedentesProveedor(itemInspeccion.CodigoSuministroId));
                this.props.dispatch(ItemInspeccionActionCreators.getDocumentosSuministrosProveedor(itemInspeccion.CodigoSuministroId));
            }
            this.setState({
                isLoaded: true
            });
        }
    }

    public componentWillUnmount(){
        this.props.dispatch(ItemInspeccionActionCreators.clearItemInspeccion());
    }

    public render() {
        const { loading, itemInspeccion, loadingDocumentos, loadingDoctosAntecedentes } = this.props;
        return (
            itemInspeccion ? (
            <div className={styles.ItemInspeccionContainer} >
                { (loading || loadingDocumentos || loadingDoctosAntecedentes) && <Loading message='Cargando inspección...' fullScreen={false} messagePosition='right' /> }
                <Text variant='xxLarge'>Inspecciones en fábrica {itemInspeccion.Codigo}</Text> <br/>
                <Text variant='medium' style={{color: 'grey', fontStyle: 'italic' }}>Inspección realizada por {itemInspeccion.RealizaInspeccion}</Text>
                <InformacionGeneral inspeccionItem={itemInspeccion} />
                <DocumentosSuministros />
                <VistaDoctosInpsecciones />
                { itemInspeccion.EstadoInspeccionId === EnumEstadoInspeccion.EnProceso && (<CargaDocumentos /> )}
                { itemInspeccion.EstadoInspeccionId === EnumEstadoInspeccion.Rechazada && (<ComentariosRechazo itemInspeccion={itemInspeccion} /> )}

            </div>
            ) : <Loading message='Cargando inspección...' fullScreen={false} messagePosition='right' />
            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemInspeccion);
