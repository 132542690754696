export const LOADING = 'APP/ITEMSUMINISTRO/LOADING';
export const GET_ITEMSUMINISTRO = 'APP/ITEMSUMINISTRO/GET_ITEMSUMINISTRO';
export const GET_ETAPASSUMINISTRO = 'APP/ITEMSUMINISTRO/GET_ETAPASSUMINISTRO';
export const SETMENU_SUMINISTRO = 'APP/ITEMSUMINISTRO/SETMENU_SUMINISTRO';
export const CHANGEMENU_SUMINISTRO = 'APP/ITEMSUMINISTRO/CHANGEMENU_SUMINISTRO';
export const LOADING_COMENTARIOS = 'APP/ITEMSUMINISTRO/LOADING_COMENTARIOS';
export const GET_COMENTARIOSDOCUMENTO = 'APP/ITEMSUMINISTRO/GET_COMENTARIOSDOCUMENTO';
export const CLEAR_COMENTARIOSDOCUMENTO = 'APP/ITEMSUMINISTRO/CLEAR_COMENTARIOSDOCUMENTO';
export const GET_TIPODOCUMENTOSSUMINISTRO = 'APP/ITEMSUMINISTRO/GET_TIPODOCUMENTOSSUMINISTRO';
export const GET_DOCUMENTOSREQUERIDOSSUMINISTROS = 'APP/ITEMSUMINISTRO/GET_DOCUMENTOSREQUERIDOSSUMINISTROS';
export const GET_DOCUMENTOSSUMINISTRO = 'APP/ITEMSUMINISTRO/GET_DOCUMENTOSSUMINISTRO';
export const CLEAR_DOCUMENTOSSUMINISTRO = 'APP/ITEMSUMINISTRO/CLEAR_DOCUMENTOSSUMINISTRO';
export const CLEAR_ITEMSUMINISTRO = 'APP/ITEMSUMINISTRO/CLEAR_ITEMSUMINISTRO';
export const UPLOAD_SUMINISTROFILE = 'APP/ITEMSUMINISTRO/UPLOAD_SUMINISTROFILE';
export const GET_TIPODOCUMENTONACINT = 'APP/ITEMSUMINISTRO/GET_TIPODOCUMENTONACINT';
export const GET_DOCUMENTOSIMPORTACION = 'APP/ITEMSUMINISTRO/GET_DOCUMENTOSIMPORTACION';
export const GET_COMENTARIOIMPORTACION = 'APP/ITEMSUMINISTRO/GET_COMENTARIOIMPORTACION';
export const LOADING_DOWNLOADDOCUMENTO = 'APP/ITEMSUMINISTRO/LOADING_DOWNLOADDOCUMENTO';
export const GET_DOCTOSPROTOCOLOSPRUEBAS = 'APP/ITEMSUMINISTRO/GET_DOCTOSPROTOCOLOSPRUEBAS';
export const LOADING_DOCTOSPROTOCOLOSPRUEBAS = 'APP/ITEMSUMINISTRO/LOADING_DOCTOSPROTOCOLOSPRUEBAS';
export const LOADING_INSPECCIONESFABRICASUMINISTRO = 'APP/ITEMSUMINISTRO/LOADING_INSPECCIONESFABRICASUMINISTRO';
export const GET_INSPECCIONESFABRICASUMINISTRO = 'APP/ITEMSUMINISTRO/GET_INSPECCIONESFABRICASUMINISTRO';
export const GET_DOCTOSENTREGABLESEXISTENTES = 'APP/ITEMSUMINISTRO/GET_DOCTOSENTREGABLESEXISTENTES';

export const LOADING_DOCUMENTOSANTECEDENTESBYPROVEEDOR = 'APP/ITEMSUMINISTRO/LOADING_DOCUMENTOSANTECEDENTESBYPROVEEDOR';
export const GET_DOCUMENTOSANTECEDENTESBYPROVEEDOR = 'APP/ITEMSUMINISTRO/GET_DOCUMENTOSANTECEDENTESBYPROVEEDOR';
export const LOADING_DOCUMENTOS = 'APP/ITEMSUMINISTRO/LOADING_DOCUMENTOS';
export const LOADING_DOCTOSREQUERIDOS = 'APP/ITEMSUMINISTRO/LOADING_DOCTOSREQUERIDOS';
export const GET_NOCONFORMIDADESBYSUMINISTRO = 'APP/ITEMSUMINISTRO/GET_NOCONFORMIDADESBYSUMINISTRO';

export const GET_ACTARECEPCION = 'APP/SUMINISTRO/GET_ACTARECEPCION';
export const GET_DOCUMENTOSRECEPCION = 'APP/SUMINISTRO/GET_DOCUMENTOSRECEPCION';
export const GET_DOCUMENTOSPROTOCOLOSFAT = 'APP/SUMINISTRO/GET_DOCUMENTOSPROTOCOLOSFAT';