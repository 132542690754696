import {
    LOADING,
    GET_ITEMINSPECCION,
    GET_DOCUMENTOSINSPECCION,
    GET_DOCUMENTOSTIPOINSPECCION,
    LOADING_DOCUMENTOSINSPECCION,
    GET_DOCUMENTOSSUMINISTROSINSPECTOR,
    GET_DOCUMENTOSSUMINISTROSPROVEEDOR,
    LOADING_COMENTARIOSDOCTOINSPECCION,
    GET_COMENTARIOSDOCTOSINSPECCION,
    CLEAR_COMENTARIOSDOCTOINSPECCION,
    CLEAR_ITEMINSPECCION,
    GET_DOCUMENTOSANTECEDENTESPROVEEDOR,
    LOADING_DOCTOSANTECEDENTESPROVEEDOR,
} from './constants';

import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions'
import { IInspeccionFabrica, IDocumentosInspeccion, IMetadataDocumentoInspeccionItem } from './interfaces';

import apiConfig from '../../config/apiConfigOficial';
import { IComentarioBase, IDocumentosTipo, IDocumentoSuministro } from '../ItemSuministro/interfaces';

// Decalaraciones de las acciones
interface HanldeLoadingAction { type: typeof LOADING; loading: boolean; }
interface GetItemInspeccionAction { type: typeof GET_ITEMINSPECCION; itemInspeccion: IInspeccionFabrica; }
interface GetDocumentosInspeccionAction { type: typeof GET_DOCUMENTOSINSPECCION; documentosInspeccionList: IDocumentosInspeccion[]; }
interface GetInformacionInspeccionAction { type: typeof GET_DOCUMENTOSTIPOINSPECCION; doctosTipoInspeccionList: IDocumentosTipo[]; }
interface LoadingDocumentoInspeccionAction { type: typeof LOADING_DOCUMENTOSINSPECCION; loading: boolean; }
interface GetDocumentosSuministrosProveedorAction { type: typeof GET_DOCUMENTOSSUMINISTROSPROVEEDOR; DoctosSuministrosList: IDocumentoSuministro[]; }
interface GetDocumentosSuministrosInspectorAction { type: typeof GET_DOCUMENTOSSUMINISTROSINSPECTOR; DoctosSuministrosList: IDocumentoSuministro[]; }
interface LoadingComentariosDoctoInspeccionAction { type: typeof LOADING_COMENTARIOSDOCTOINSPECCION; loading: boolean; }
interface GetComentariosDoctoInspeccionAction { type: typeof GET_COMENTARIOSDOCTOSINSPECCION; comentariosList: IComentarioBase[]; }
interface ClearComentariosAction { type: typeof CLEAR_COMENTARIOSDOCTOINSPECCION; }
interface ClearItemInspeccionoAction { type: typeof CLEAR_ITEMINSPECCION; }
interface HandleLoadingDoctosAntecedentesProveedorAction { type: typeof LOADING_DOCTOSANTECEDENTESPROVEEDOR; loading: boolean; }
interface GetDoctosAntecedentesProveedorAction { type: typeof GET_DOCUMENTOSANTECEDENTESPROVEEDOR, DoctosAntecedentesList: IDocumentoSuministro[]; }


// lista de acciones conocidas
export type ItemInspeccionKnownAction =
    MainKnownAction
|   HanldeLoadingAction
|   GetItemInspeccionAction
|   GetDocumentosInspeccionAction
|   GetInformacionInspeccionAction
|   LoadingDocumentoInspeccionAction
|   GetDocumentosSuministrosProveedorAction
|   GetDocumentosSuministrosInspectorAction
|   LoadingComentariosDoctoInspeccionAction
|   GetComentariosDoctoInspeccionAction
|   ClearComentariosAction
|   ClearItemInspeccionoAction
|   HandleLoadingDoctosAntecedentesProveedorAction
|   GetDoctosAntecedentesProveedorAction
;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

export const ItemInspeccionActionCreators = {
    handleLoading: (loading) => ({ type: LOADING, loading } as HanldeLoadingAction),
    
    clearItemInspeccion: () => ({ type: CLEAR_ITEMINSPECCION } as ClearItemInspeccionoAction),
    getItemInspeccion: (idInspeccion: number): AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/InspeccionFabrica/GetInspeccionFabrica`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(idInspeccion),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IInspeccionFabrica>;
            })
            .then(data => {
                dispatch({ type: GET_ITEMINSPECCION, itemInspeccion: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    handleLoadingDocumentosInspeccion: (loading) => ({ type: LOADING_DOCUMENTOSINSPECCION, loading } as LoadingDocumentoInspeccionAction),
    getDocumentosInpseccion: (idInspeccion: number, esProveedor: boolean): AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        let url: string = esProveedor ?
            `${apiConfig.apiUri}/DocumentoInspeccion/GetDocumentosInspeccionProveedor` :
            `${apiConfig.apiUri}/DocumentoInspeccion/GetDocumentosInspeccionInspector` ;
        fetch(url, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(idInspeccion),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentosInspeccion[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSINSPECCION, documentosInspeccionList: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    getDocumentosTipoInspeccion: () :AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoInspeccion/GetDocumentosTipoInspeccion`, {
            method: 'GET',
            headers: defaultHeaders(),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentosTipo[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSTIPOINSPECCION, doctosTipoInspeccionList: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    uploadInspeccionesFile : (files: File[], metadataList: IMetadataDocumentoInspeccionItem[], codigoInspeccionId: number, esProveedor: boolean, isReupload = false, esProveedorInspector : boolean): AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        let formData = new FormData();

        for(let f=0; f<files.length; f+=1){
            formData.append(`documentosInspeccionList[${f}].File`, files[f], files[f].name );
            formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgCodigoSuministroId`, String(metadataList[f].CodigoSuministroId));
            formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgCodigoInspeccionId`,String(codigoInspeccionId));
            formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgDocumentosTipoId`, String(metadataList[f].DocumentosTipoId));
            formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgDocumentosEstadoId`, String(metadataList[f].DocumentosEstadoId));
            formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgDetalle`, metadataList[f].Detalle);
            formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgEsProveedor`, String(esProveedor));
            if(isReupload){
                formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgId`, metadataList[f].IdDocumento);
            }else{
                formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgId`, "0");
            }
            formData.append(`documentosImportacion[${f}].DocumentoImportacion.nombreDocumentoAntiguo`, metadataList[f].nombreDocumentoAntiguo);
            formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgEmailSolicitante`, String(metadataList[f].EmailSolicitante));
            if(esProveedorInspector){
                formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgVisibleProveedor`, String(false));
                formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgVisibleProveedorInspector`, String(true));
                
            }else{
                formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgVisibleProveedor`, String(true));
                formData.append(`documentosInspeccionList[${f}].DocumentoInspeccionFabrica.DsgVisibleProveedorInspector`, String(true));
            }

        }

        fetch(`${apiConfig.apiUri}/DocumentoInspeccion/UploadDocumentoInspeccion`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}` },
            body: formData,
        })
        .then(response => {
            if (!response.ok){
                response.json().then( json => {
                    if(esProveedorInspector){
                        dispatch(MainActionCreators.onShowMessageBar(`El archivo ya existe o el tipo no corresponde al ya existente.`,'error'));
                        dispatch(ItemInspeccionActionCreators.getDocumentosInpseccion(codigoInspeccionId, false));
                    }else{
                        dispatch(MainActionCreators.onShowMessageBar(`El archivo ya existe o el tipo no corresponde al ya existente.`,'error'));
                        dispatch(ItemInspeccionActionCreators.getDocumentosInpseccion(codigoInspeccionId, true));
                    }
                    

                });
            } else {
                if(esProveedorInspector){
                    dispatch(MainActionCreators.onShowMessageBar(`Carga correcta de archivos.`,'success'));
                    dispatch(ItemInspeccionActionCreators.getDocumentosInpseccion(codigoInspeccionId, false));
                }else{
                    dispatch(MainActionCreators.onShowMessageBar(`Carga correcta de archivos.`,'success'));
                dispatch(ItemInspeccionActionCreators.getDocumentosInpseccion(codigoInspeccionId, true));
                }
                
            }
        });
    },
    getDocumentosSuministrosProveedor: (codigoSuministro: number): AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentoProcesoSuministroProveedor`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSSUMINISTROSPROVEEDOR, DoctosSuministrosList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error'));
        });
    },

    getDocumentosSuministrosInspector: (codigoSuministro: number): AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentoProcesoSuministroInspector`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSSUMINISTROSINSPECTOR, DoctosSuministrosList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error'));
        });
    },
    
    handleLoadingComentariosInspeccion: (loading) => ({ type: LOADING_COMENTARIOSDOCTOINSPECCION, loading } as LoadingComentariosDoctoInspeccionAction),
    clearComentarios: () => ({ type: CLEAR_COMENTARIOSDOCTOINSPECCION } as ClearComentariosAction),
    getComentariosInspeccionesFabricaById: (doctoInspeccionId: number): AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoInspeccion/GetComentarioDocumentoInspecciones`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(doctoInspeccionId),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IComentarioBase[]>;
            })
            .then(data => {
                dispatch({ type: GET_COMENTARIOSDOCTOSINSPECCION, comentariosList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error'));
        });
    },
    addComentarioDocumentoInspeccion: (comentario: IComentarioBase): AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoInspeccion/AddComentarioDocumentoInspecciones`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(comentario),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                else {
                    dispatch(ItemInspeccionActionCreators.getComentariosInspeccionesFabricaById(comentario.DocumentosInspeccionFabricaId));
                }
            })
            .catch(error => {
                dispatch(ItemInspeccionActionCreators.handleLoadingComentariosInspeccion(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error'));
        });
    },

    handleDoctosAntecedentesProveedor: (loading:boolean) => ({type: LOADING_DOCTOSANTECEDENTESPROVEEDOR, loading} as HandleLoadingDoctosAntecedentesProveedorAction),
    getDocumentosAntecedentesProveedor: (codigoSuministro: number): AppThunkAction<ItemInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/DocumentoSuministro/GetDocumentosAntecedentesByProveedor`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(codigoSuministro),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSANTECEDENTESPROVEEDOR, DoctosAntecedentesList: data, });
            })
            .catch(error => {
                dispatch(ItemInspeccionActionCreators.handleDoctosAntecedentesProveedor(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error'));
        });
    },
};