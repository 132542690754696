
// para manedjo de errores desde el servicio
export const EnumTipoError = {
    Debug: 0,
    Information: 1,
    Waringn: 2,
}

// tipo de acceso
export const EnumTipoAcceso = {
    FusionAuth: 'FAUTH',
    MS365: 'MS365',
    Local: 'Local'
}

export enum EnumGestoresGroups {
    gestoresCalidad = 15,
    gestoresCompras = 20,
    gestoresIngenieria = 16,
    gestoresLogistica = 18,
    gestoresPMO = 17,
    gestoresTransporte = 21,
}

export enum EnumEstadosDocumentosSuministros {
    EnRevision = 1,
    Aprobado = 2,
    Rechazado = 3,
}

export const EnumPermissionUserType = {
    Proveedor: 'PROVEEDOR',
    Inspector: 'INSPECTOR',
    Any: 'ANY',
}

export enum EnumEstadoInspeccion {
    Aprobada = 1,
    Rechazada = 2,
    EnProceso = 3,
}

export enum EnumDocumentosTipo {
    ProtocoloPruebas = 25,
    IngSismica = 39,
}

export enum EnumFaseDocumentos {
    Otros = 10,
    Nacional = 4,
    Internacional = 3,
}

export enum EnumTipoSuministro {
    Expedito = 1,
    Completo = 2,
}

export enum EnumEtapasSuministro {
    Antecedentes = 1,
    Entregables = 3,
    ProtocoloPruebas = 5,
    DoctoNacionalTransporte = 6,
    Cerrado = 8,
    CerradoSinInspeccion = 9,
}

export const EnumProveedorOrigen = {
    Nacional: 'Nacional',
    Extranjero: 'Extranjero',
};

/*
****************************** HOMOLOGACION
*/

export enum EnumHomologacionEtapa {
    Factibilidad = 1,
    ReqTecnicos = 2,
    ReqCalidad = 3,
    ReqEstrategicos = 4,
    Muestra = 5,
    Inspeccion = 6,
};


export enum EnumEstadoHomologacion {
    Solicitud = 1,
    EnProceso = 2,
    Rechazado = 3,
    Aprobado = 4,
    RechazadoSinProceso = 5,
};

export enum EnumHomologacionFaseEstadoFactibilidad {
    EnEvaluacion = 7,
    EvaluacionFinalizada = 8,
}


export enum EnumHomologacionFaseEstadoMuestra {
    NoAplica = "No Aplica",
    Solicitada = "Solicitada",
    RecibidaRevision = "Recibida y en revisión",
    MuestraRevisada = "Muestra revisada",
    NoDefinida = "No Definida",
    NoInformada = "NoInformada"
}

export enum EnumHomologacionFaseEstadoInspeccion {
    NoAplica = 1,
    EnEjecucion = 5,
    Realizada = 6,
}

export const EnumEstadosMuestraHomologacion = {
    NoDefinida: 9,
    Rechazada : 11,
    Aprobada : 10,
};

// inspecciones de fábrica
export enum EnumDoctosTipoInspeccion {
    CartaInvitacion = 21,
    CronogramaEjecucion = 22,
};

export const EnumRealizaInspeccion = {
    Proveedor: "Proveedor (Fábrica)",
    Inspector: "Proveedor Inspector"
}