export const LOADING = 'APP/MAESTROMATERIALES/LOADING';

export const NEXTPAGE = 'APP/MAESTROMATERIALES/NEXTPAGE';
export const PREVPAGE = 'APP/MAESTROMATERIALES/PREVPAGE';
export const GOTOPAGE = 'APP/MAESTROMATERIALES/GOTOPAGE';
export const CHANGEMAXSIZE = 'APP/MAESTROMATERIALES/CHANGEMAXSIZE';

export const GET_MAESTROMATERIALES = 'APP/MAESTROMATERIALES/GET_MAESTROMATERIALES';
export const LOADING_SHOWMAESTROMATERIAL = 'APP/MAESTROMATERIALES/LOADING_SHOWMAESTROMATERIAL';

export const INITIAL_SHOWLIST = 'APP/MAESTROMATERIALES/INITIAL_SHOWLIST';