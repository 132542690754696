import * as React from 'react';
import styles from '../ItemInspeccionHomologacion.module.scss';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { CustomIconButton } from '../../Shared/IconButton';
import ApiConfig from '../../../config/apiConfigOficial';
import { IDocumentoHomologacion } from '../../ItemHomologacion/interfaces';

interface IDocumentosHomologacionProps {
    onDownloadDocumento: (url:string, filename: string) => void
}

const DocumentosHomologacion: React.FunctionComponent<IDocumentosHomologacionProps> = (props) => {
    const documentosHomologacion: IDocumentoHomologacion[] = useSelector( (state: ApplicationState) => state.itemHomologacionInspeccion.documentosHomologacionList );
    const loadingDoctoDownload:any = useSelector((state:ApplicationState) => state.itemSuministro.loadingDownloadDocumento);

    return (
        <div className={ styles.DocumentosHomologacion }>
            {documentosHomologacion && (
                <CustomDetailsList
                    items={documentosHomologacion}
                    columns={[
                        {
                            key: 'columniconfileDocSuministro',
                            name: 'iconfile',
                            fieldName: 'iconfile',
                            minWidth: 16,
                            maxWidth: 16,
                            iconName: 'Page',
                            clickToSort: false,
                        },
                        {
                            key: 'columnDownload',
                            name: 'Nombre',
                            iconName: 'Download',
                            fieldName: 'FileRef',
                            minWidth: 40,
                            maxWidth: 40,
                            isResizable: false,
                            onRender: (item: IDocumentoHomologacion) => (<CustomIconButton id={`doownload_${item.ID}`} icon='Download' label='Descargar' onClick={() => props.onDownloadDocumento(`${ApiConfig.baseUriDocuments}${item.FileRef}`,item.FileLeafRef)} disabled={loadingDoctoDownload && loadingDoctoDownload[item.FileLeafRef]} />)
                        },
                        {
                            key: 'columnname',
                            name: 'Nombre',
                            fieldName: 'FileLeafRef',
                            minWidth: 200,
                            maxWidth: 350,
                            isResizable: true,
                            isRowHeader: true,
                        },
                        {
                            key: 'columnTipoDocumento',
                            name: 'Tipo documento',
                            fieldName: 'DocumentosTipo',
                            minWidth: 150,
                            maxWidth: 200,
                            isResizable: true,
                            onRender: (item: IDocumentoHomologacion) => item.DocumentosTipo.lookupValue,
                        },
                        {
                            key: 'columnaDetalle',
                            name: 'Detalle',
                            fieldName: 'DocumentoRequerido',
                            minWidth: 150,
                            maxWidth: 200,
                            isResizable: true,
                            onRender: (item: IDocumentoHomologacion) => item.DocumentoRequerido? item.DocumentoRequerido.lookupValue:'',
                        },
                    ]}
                />
            )}
        </div>
    );
}

export default DocumentosHomologacion;