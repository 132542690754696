import * as React from 'react';
import styles from './Carousel.module.scss';



const CarouselLeftArrow: React.FunctionComponent<any> = ({ onClick }) => {
    return (
        <a
          href="#"
          className={`${styles.carousel__arrow} ${styles['carousel__arrow--left']}`}
          onClick={onClick}
        >
          <span className="fa fa-2x fa-angle-left" />
        </a>
    );
}


const CarouselRightArrow: React.FunctionComponent<any> = ({ onClick }) => {
    return (
        <a
            href="#"
            className={`${styles.carousel__arrow} ${styles['carousel__arrow--right']}`}
            onClick={onClick}
        >
            <span className="fa fa-2x fa-angle-right" />
        </a>
    );
}

const CarouselIndicator: React.FunctionComponent<any> = ({ index, activeIndex, onClick, key }) => {
    return (
      <li key={key}>
        <a
          className={ index === activeIndex ? `${styles.carousel__indicator} ${styles['carousel__indicator--active']}` : styles.carousel__indicator }
          onClick={onClick}
        />
      </li>
    );
}


const CarouselSlide: React.FunctionComponent<any> = ({ index, activeIndex, slide, key }) => {
    return (
      <li
        key={key}
        className={styles.carousel__slide}
        style={index === activeIndex ? {display: 'block'}:{display: 'none'} }
      >
        {slide.content}
      </li>
    );
}

const Carousel: React.FunctionComponent<any> = ({ slides, showArrows, showIndicators }) => {

    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    
    function goToSlide(index):void {
        setActiveIndex(index);
    }

    function goToPrevSlide(e):void {
        e.preventDefault();

        let index = activeIndex;
        let slidesLength = slides.length;

        if (index < 1) {
            index = slidesLength;
        }

        --index;
        setActiveIndex(index);
    }

    function goToNextSlide(e): void {
        e.preventDefault();

        let index = activeIndex;
        let slidesLength = slides.length - 1;

        if (index === slidesLength) {
            index = -1;
        }

        ++index;
        setActiveIndex(index);
    }

    return (
        <div className={styles.carousel}>
            
            {showArrows && (<CarouselLeftArrow onClick={goToPrevSlide} />)}
    
            <ul className={styles.carousel__slide}>
                {slides.map((slide, index) =>
                    <CarouselSlide
                        key={index}
                        index={index}
                        activeIndex={activeIndex}
                        slide={slide}
                    />
                )}
            </ul>

            {showArrows && (<CarouselRightArrow onClick={goToNextSlide} />)}

            {showIndicators && (
                <ul className={styles.carousel__indicators}>
                    {slides.map((slide, index) => (
                        <CarouselIndicator
                            key={index}
                            index={index}
                            activeIndex={activeIndex}
                            onClick={e => goToSlide(index)}
                        />
                    ))}
                </ul>
            )}
        </div>
      );

}

export default Carousel;