import * as React from 'react';
import styles from './NotFound.module.scss';

export const NotFound: React.FunctionComponent = () => (
    <div className={styles.NotFoundContainer}>
        <h1>NOT FOUND!</h1>
    </div>
);


