import * as React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { CustomButton } from '../../Shared/Button';
import { IDoctosRequeridoHomologacion } from '../interfaces';
import styles from '../ItemHomologacion.module.scss';

import ApiConfig from '../../../config/apiConfigOficial';
import { IComentarioBase } from '../../ItemSuministro/interfaces';
import { Separador } from '../../Shared/Separador/separador';
import { Comentarios } from '../../Shared/Comentarios/comentarios';
import { EnumEstadosDocumentosSuministros } from '../../utils/enums';
import { useDispatch, useSelector } from 'react-redux';
import { IUsuarioLogin } from '../../utils/interfaces';
import { ApplicationState } from '../../../config/store';
import { ItemHomologacionesActionCreators } from '../actions';

interface IPanelDocumentoProps {
  onClose: any;
  isOpen: boolean;
  onDescargarDocumento: (uri: string, fileName: string) => void;
  selectedDoctoRequerido: IDoctosRequeridoHomologacion;
  loadingDoctoDownload: any;
  disabled: boolean;
}

const PanelDocumento: React.FunctionComponent<IPanelDocumentoProps> = (props) => {
  const dispatch = useDispatch();
  const {isOpen, selectedDoctoRequerido, loadingDoctoDownload, disabled } = props;
  const selectedDocument = selectedDoctoRequerido && selectedDoctoRequerido.Documento;

  const loadingComentarios: boolean = useSelector((state:ApplicationState) => state.itemHomologacion.loadingComentarios);
  const comentariosList: IComentarioBase[] = useSelector((state:ApplicationState) => state.itemHomologacion.ComentariosList);
  const user: IUsuarioLogin = useSelector((state:ApplicationState) => state.main.user);

  const disableComentarios : boolean = selectedDocument && selectedDocument.EstadoId === EnumEstadosDocumentosSuministros.Aprobado;
  
  const [ comentarioObject, setComentarioObject ] = React.useState<IComentarioBase>({
    ID:  null,
    Title: "",
    isSaesa: false,
    ProveedorInspectorId: null,
  });

  function _onChangeComentario(ev){
    let comentarioObjectModified: IComentarioBase = { ...comentarioObject };
    const { id, value } = ev.target;
    switch(id){
      case 'Title':
        comentarioObjectModified = { ...comentarioObject, Title: value };
        break;
      default:
        comentarioObjectModified = { ...comentarioObject };
        break;
    }

    setComentarioObject(comentarioObjectModified);
  }

  function _onAddComentario ():void {
    
    dispatch(ItemHomologacionesActionCreators.handleLoadingComentarios(true));
    dispatch(ItemHomologacionesActionCreators.addComentarioDocumento({
      ...comentarioObject,
      DocumentosHomologacionId: selectedDocument.ID,
      ProveedorInspectorId: user.usuarioProveedor.id
    }));

    setComentarioObject({
      ID:  null,
      Title: "",
      isSaesa: false,
      ProveedorInspectorId: null,
    });
  }


  return (
    <Panel
      isOpen={isOpen}
      onDismiss={props.onClose}
      headerText={selectedDocument? selectedDocument.FileLeafRef : ''}
      closeButtonAriaLabel="Cerrar"
      onRenderFooterContent={() => (
        <CustomButton icon='Cancel' onClick={props.onClose} label='Cerrar' id='cerrarpaneldocumento'/>
      )}
      isFooterAtBottom
      type={PanelType.medium}
    >
      {selectedDocument && (
        <div className={styles.panelContainer}>
          <div className={styles.infoDocumento}>
            <h3>Detalles del documento</h3>
            <table>
              <tbody>
                <tr>
                  <td><b>Nombre:</b></td>
                  <td>{selectedDocument.FileLeafRef} <span style={{ fontSize: 9, color: 'red'}}>{!selectedDoctoRequerido.CalculaAvance && '(Documento extra)'}</span> </td>
                </tr>
                <tr>
                  <td><b>Tipo de documento:</b></td>
                  <td>{selectedDocument.DocumentosTipo.lookupValue}</td>
                </tr>
                <tr>
                  <td><b>Nombre documento detalle:</b></td>
                  <td>{selectedDoctoRequerido.Title}</td>
                </tr>
                <tr>
                  <td><b>Estado:</b></td>
                  <td>{selectedDocument.Estado.lookupValue}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.downloadDocto}>
            <CustomButton disabled={loadingDoctoDownload && loadingDoctoDownload[selectedDocument.FileLeafRef]} id={`downloadDocto-${selectedDocument.ID}`} label='Descargar' icon='DownloadDocument' onClick={() => props.onDescargarDocumento(`${ApiConfig.baseUriDocuments}${selectedDocument.FileRef}`,selectedDocument.FileLeafRef)} />
          </div>
          <Separador />
            { comentariosList? (<Comentarios hidden={disableComentarios} loading={loadingComentarios} comentarioObject={comentarioObject} comentariosList={comentariosList} onAddComentario={_onAddComentario} onChangeComentario={_onChangeComentario} />):null}
        </div>
        )}
    </Panel>
  );

}


export default PanelDocumento;