import * as React from 'react';
import { FontIcon } from '@fluentui/react/lib/Icon';

import styles from './Accordion.module.scss';

interface IAccordionItemProps {
    title: string;
    children: any;
    id: number | string;

    // propiedades calculadas
    functionOpenCloseAccordionItem?: (self: any) => void;
    active?: boolean;
}

const AccordionItem: React.FunctionComponent <IAccordionItemProps> = (props) => {
    
  const [height, setHeight] = React.useState<string>("0px");
  
  const content = React.useRef(null);

  function toogleAccordion(): void {
    props.functionOpenCloseAccordionItem(props);
    setHeight(props.active? "0px" : `${content.current.scrollHeight}px`);
  }
    return (
        <div className={styles.AccordionSection} id={`AccordionItem${String(props.id)}`}>
            <button className={`${styles.Accordion} ${props.active && styles.active}`} onClick={toogleAccordion}>
                <span className={styles.title}>{props.title}</span>
                {props.active? (<FontIcon aria-label="Close" iconName="ChevronDownSmall" className={styles.icon}/>) : (<FontIcon aria-label="Open" iconName="ChevronRightSmall"  className={styles.icon}/>)}
            </button>
            <div className={styles.Content} ref={content} style={{ maxHeight: `${height}` }}>
                {props.children}
            </div>
        </div>
    );
}

export default AccordionItem;