import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { MainActionCreators } from '../Main/actions';

import {
  Stack,
  MessageBar,
  MessageBarType,
  IStackProps,
} from 'office-ui-fabric-react';

const verticalStackProps: IStackProps = {
  styles: { root: { overflow: 'hidden', width: '100%' }, },
  tokens: { childrenGap: 20 },
};

interface IConnectedState {
    message: string;
    showMessageBar: boolean;
    code: string;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        message: state.main.message,
        showMessageBar: state.main.showMessageBar,
        code: state.main.code,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

// define combined props
type ICustomMessageBarProps = IConnectedState &{ dispatch(action: any): void };

class CustomMessageBar extends React.PureComponent<ICustomMessageBarProps> {

    private setHide = () => {
        const { dispatch } = this.props;
        dispatch(MainActionCreators.onHideMessageBar());
    }

    private _defineType = () => {
        const {code} = this.props;
        switch (code) {
            case 'blocked':
                return MessageBarType.blocked;
            case 'error':
                return MessageBarType.error;
            case 'success':
                return MessageBarType.success;
            case 'warning':
                return MessageBarType.warning;
            case 'severalWarning':
                return MessageBarType.severeWarning;
            default:
            case 'info':
                return MessageBarType.info;
        }
    }

    componentDidUpdate = (prevProps) => {
        const { message } = this.props;
        if (message && prevProps.message !== message) {
            this._waitToClose();
        }
    }

    private sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    private _waitToClose = async () => {
        await this.sleep(6000); // 6 seconds
        this.props.dispatch(MainActionCreators.onHideMessageBar());
    }


    private _defineTruncated = (): boolean => {
        const { message } = this.props;
        if (message && message.length > 155)
            return true;
        return false;
    }

    public render() {
        const { showMessageBar, message } = this.props;
        const truncated = this._defineTruncated();
        return (
            <Stack {...verticalStackProps} style={{ position: 'fixed', width: '70%', right: 12, top: 12, zIndex: 3000 }}>
                {
                    showMessageBar &&
                    <MessageBar
                        messageBarType={this._defineType()}
                        isMultiline={false}
                        onDismiss={() => this.setHide()}
                        dismissButtonAriaLabel='Close'
                        truncated={truncated}
                        overflowButtonAriaLabel={truncated ? 'See More' : ''}
                    >
                        {message}
                    </MessageBar>
                }
            </Stack>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomMessageBar);