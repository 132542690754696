import * as React from 'react';
import styles from './Richtext.module.scss';
import { Label } from '@fluentui/react/lib/Label';
import { Text } from '@fluentui/react/lib/Text';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
require('suneditor/dist/css/suneditor.min.css');

interface IRichTextProps {
    id: string;
    label: string;
    onChange: (ev: any, opt?: any) => void;
    placeholder?: string;
    value: string;
    height?: string | number;
    width?: string | number;
    disabled?: boolean;
    showLable?: boolean;
    required?: boolean;
}

// https://github.com/mkhstar/suneditor-react
// https://github.com/JiHong88/SunEditor/blob/master/README.md#options
// http://suneditor.com/sample/index.html
export const RichText: React.FunctionComponent<IRichTextProps> = (props) => {


    const [ internalValue, setInternalValue ] = React.useState(props.value);

    function onInternalChange(content){
        setInternalValue(content);
        props.onChange({target: {id: props.id, content}}, content);
    }

    return (
        <div className={ styles.Richtext }>
          <div className={ styles.Richtextcontainer }>
              {props.showLable? (<Label disabled={props.disabled}>{props.label}<span style={{ color: 'rgb(164, 38, 44)'}}>{props.required? ' *':''}</span></Label>):(<></>)}
              <SunEditor
                name={props.label}
                placeholder={props.placeholder}
                defaultValue={internalValue}
                setContents={props.value}
                onChange={ (content) => onInternalChange(content)}
                lang='es'
                height={props.height? props.height : 250}
                width={props.width}
                enable={!props.disabled}
                disable={props.disabled}
                showToolbar={!props.disabled}
                setDefaultStyle="font-family: Arial; font-size: 16px;" 
                setOptions={{
                    buttonList: [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        ['paragraphStyle', 'blockquote'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        '/', // Line break
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                        ['table', 'link'],
                        ['fullScreen','preview']
                    ],
                }}
            />
            {props.required && (internalValue === '' || internalValue === null || internalValue === '<p><br></p>') && (<Text variant='small' style={{ color: 'rgb(164, 38, 44)'}}>{`El campo ${props.showLable? `de ${props.label.toLowerCase()}`:''} es requerido.`}</Text>)}
          </div>
        </div>
      );

}