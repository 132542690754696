import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IUsuarioLogin } from '../utils/interfaces';

import styles from './Home.module.scss';

interface IConnectedState {
    user: IUsuarioLogin;
}  

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        user: state.main.user,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

// define combined props
type IHomeProps = IConnectedState &{ dispatch(action: any): void };

class Home extends React.PureComponent<IHomeProps> {
    render() {
        return (
            <div className={styles.HomeContainer} >
                <Text variant='mediumPlus'>Bienvenido usuario <b>{this.props.user.usuario}</b></Text>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
