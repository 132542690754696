import {
    LOADING,
    GET_SUMINISTROSLIST,
    GET_ALLNOCONFORMIDADES
} from './constants';

import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions'
import { IProcesoSuministro } from './interfaces';

import apiConfig from '../../config/apiConfigOficial';

// Decalaraciones de las acciones
interface HanldeLoadingAction { type: typeof LOADING; loading: boolean; }
interface GetSuministrosListAction { type: typeof GET_SUMINISTROSLIST; listaSuministros: IProcesoSuministro[]; }
interface GetAllNoConformidadesAction { type: typeof GET_ALLNOCONFORMIDADES; listaNoConformidades: any }

// lista de acciones conocidas
export type GrillaSuministroKnownAction =
    MainKnownAction
|   HanldeLoadingAction
|   GetSuministrosListAction
|   GetAllNoConformidadesAction
;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    "Authorization": "Bearer " +  sessionStorage.getItem(apiConfig.AccessTokenKey)
});

export const GrillaSuministrosActionCreators = {

    handleLoading: (loading) => ({ type: LOADING, loading } as HanldeLoadingAction),
    
    getSuministrosProveedorList: (rutProveedor: string): AppThunkAction<GrillaSuministroKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/ProcesoSuministro/GetProcesoSuministroProveedor`, {
            method: 'POST',
            body: JSON.stringify(rutProveedor),
            headers: defaultHeaders(),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IProcesoSuministro[]>;
            })
            .then(data => {
                dispatch({ type: GET_SUMINISTROSLIST, listaSuministros: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },

    getAllNoConformidades: (): AppThunkAction<GrillaSuministroKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/ProcesoSuministro/GetAllNoConformidades`, {
            method: 'GET',
            headers: defaultHeaders()
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<any>;
            })
            .then(data => {

                let newData = data.map(i =>{
                    
                    if(i.SuministroGeneral === null ){
                        return newData = {
                            ...i,
                            SuministroGeneral:{
                                lookupId: -1,
                                lookupValue : "-1"
                            }
                        }
                    }else{

                    
                    if(i.SuministroGeneral.lookupValue === null || i.SuministroGeneral.lookupValue === null){
                        return newData = {
                            ...i,
                            SuministroGeneral:{
                                lookupId:-1,
                                lookupValue : "-1"
                            }
                        }
                    }}
                    return i;
                })
                dispatch({ type: GET_ALLNOCONFORMIDADES, listaNoConformidades: newData });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(),'error')));
    },
};