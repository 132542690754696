import { FontIcon } from 'office-ui-fabric-react';
import * as React from 'react';
import Carousel from '../../Shared/Carousel/Carousel';
import { CustomIconButton } from '../../Shared/IconButton';
import { isImageDocto } from '../../utils/functions';
import { IMaestroMaterial } from '../interfaces';
import styles from '../MaestroMateriales.module.scss';

interface ICardProps {
    material: IMaestroMaterial;
    openPanel: (material: IMaestroMaterial) => void;
}

const maxFontLarge: number = 100;

const Card: React.FunctionComponent<ICardProps> = (props) => {
    const { material } = props;
    return(
        <div className={styles.Card}>
            <p className={styles.code}>{material.CodigoSAP !== null? material.CodigoSAP: '[NO CODE]'}</p>
            <div className={styles.img}>
                {material && material.DocumentosList.length > 0 ? (
                    <Carousel showArrows={false} showIndicators slides={material.DocumentosList.filter(d => d.Principal && isImageDocto(d.FileLeafRef)).map(i => ({ content: (<img alt={i.FileLeafRef} src={`data:image/jpeg;base64,${i.imgbyte}`}/>)}))} />
                ):(
                    <>
                        <span><FontIcon aria-label="Blocked" iconName="Blocked" /></span>
                        <span>NO IMG</span>
                    </>
                )}
            </div>
            {material.Title.length > maxFontLarge ? (
                <div className={styles.description}>{material.Title.slice(0,(maxFontLarge-2))}...</div>
            ):(
                <div className={styles.description}>{material.Title}.</div>
            )}
            <div className={styles.bottonBox}>
                <CustomIconButton
                    label='Más información'
                    onClick={() => props.openPanel(props.material)}
                    icon='MiniExpand'
                    id={`openPanelMaterial${props.material.UniqueId}`}
                />
            </div>
        </div>
    );
}

export default Card;
