import * as React from 'react';
import { useDispatch } from 'react-redux';
import { CustomTextField } from '../CustomFields/TextField';
import { CustomButton } from '../Button';
import styles from './Comentarios.module.scss';

import { Separator } from 'office-ui-fabric-react/lib/Separator';

import { parseDatetime, ConvertSharepointDate } from '../../utils/functions';

import { Loading } from '../Loading/loading';
import { IComentarioBase } from '../../ItemSuministro/interfaces';
import { CustomIconButton } from '../IconButton';
import ApiConfig from '../../../config/apiConfigOficial';
import { ItemSuministroActionCreators } from '../../ItemSuministro/actions';
import { mergeStyles } from '@fluentui/react';

interface IComentarioProps {
    comentariosList: Array<IComentarioBase>;
    comentarioObject: IComentarioBase;
    onAddComentario: any;
    onChangeComentario: any;
    hidden?: boolean;
    disabled?: boolean;
    loading: boolean;
    attachFile? : File;
    onAttachFile? : any;

}

export const Comentarios:React.FunctionComponent<IComentarioProps> = ({
    onAddComentario,
    onChangeComentario,
    comentarioObject,
    comentariosList,
    disabled,
    hidden,
    loading,
    attachFile,
    onAttachFile
}) => {

    const dispatch = useDispatch();

    const [inputKey, setInputKey] = React.useState('');
    console.log(comentariosList);

    function _downloadDocument( url:string, fileName: string) {
        dispatch(ItemSuministroActionCreators.downloadDocumento(`${ApiConfig.baseUriDocuments}${url}`,fileName));
    }

    const formatNombreArchivo = (titulo:string) =>{

        let tituloCorto = '';
        if(titulo !== undefined){
            if(titulo.length > 35 ) {
                tituloCorto = titulo.substring(0,35).concat('...')
            }
            else{
                tituloCorto = titulo;
            }
        }
        
        return tituloCorto
    }
    const notSaesaIcon = mergeStyles({
        color: 'black',
    });

    return (
        <div className={styles.comentarios}>
            <ul className={styles.comentariobox}>
                {loading && <Loading fullScreen={false} message='Cargando...'/>}
                {comentariosList.map( comentario => comentario.isSaesa? (
                        <li key={comentario.ID} className={styles.externo}>
                            <div className={styles.autor}>{comentario.Author.lookupValue} ({ConvertSharepointDate(comentario.Created)})</div>
                            <div></div>
                            <div className={styles.comentario}>{comentario.Attachment_URL && comentario.Attachment_URL !== '' ? (<CustomIconButton id={`DownloadAttachMent_${comentario.ID}`} icon='Download' onClick={() => _downloadDocument(comentario.Attachment_URL,comentario.Attachment_FileName)} label="Descargar adjunto"/>):(<></>)}{comentario.Title}</div>
                        </li>
                    ):(
                        <li key={comentario.ID} className={styles.interno}>
                            <div className={styles.autor}>{`${comentario.ProveedorInspector_x003a_Nombre && comentario.ProveedorInspector_x003a_Nombre.lookupValue} (${ comentario.ProveedorInspector_x003a_Nombre && comentario.ProveedorInspector.lookupValue})`} ({ConvertSharepointDate(comentario.Created)})</div>                            
                                <div className={styles.comentario}>{comentario.Title}</div>
                                    <div className={styles.contenedorComentario}>
                                        {comentario.Attachment_FileName && (                                        <div className={styles.enlaceDescarga}><a onClick={() => _downloadDocument(comentario.Attachment_URL,comentario.Attachment_FileName)}><CustomIconButton clasName={styles.icono} id={`DownloadAttachMent_${comentario.ID}`} icon='Attach' onClick={() => _downloadDocument(comentario.Attachment_URL,comentario.Attachment_FileName)} label="Descargar adjunto"/><p>{formatNombreArchivo(comentario.Attachment_FileName)}</p></a></div>)}
                                    </div>
                        </li>
                    ))}
            </ul>
            { hidden ? null : <Separator/> }
            { hidden ? null : (
                <div className={styles.comentarioInfo}>
                    <h3>Recuerde:</h3>
                    <ul>
                        <li>Esto no es un chat. Ingrese la mayor cantidad de información que pueda.</li>
                        <li>Sea preciso en lo que quiere escribir.</li>
                        <li>Tamaño máximo: 255 caracteres.</li>
                    </ul>
                </div>
            )}
            { hidden ? null : (
                <div className={styles.nuevocomentariobox}>
                    <div className={styles.row}>
                        <div className={styles.columnMax}>
                            <CustomTextField value={comentarioObject.Title} disabled={disabled} id='Title' label='Comentario' onChange={onChangeComentario} placeholder='Comentario' />
                        </div>
                        <div className={styles.columnButton}>
                            <div className={styles.contenedorBotones}>
                                <CustomButton disabled={disabled || comentarioObject.Title === ''} id='addCommentario' label='Enviar' onClick={onAddComentario} icon='CommentAdd' />
                                {onAttachFile && (<input key={inputKey} disabled={disabled || comentarioObject.Title === ''} id='AttachmentFile' type='file' onChange={onAttachFile} /> )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}