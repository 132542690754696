import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IHomologacionGeneral } from '../../GrillaHomologacion/interfaces';
import { EnumEstadoHomologacion, EnumEstadosMuestraHomologacion, EnumHomologacionEtapa, EnumHomologacionFaseEstadoMuestra } from '../../utils/enums';
import UploadDocumentos from './UploadDocumentos';

import styles from '../ItemHomologacion.module.scss';
import { IDocumentoHomologacion, IDocumentosTipoHomologacion } from '../interfaces';
import { CustomDetailsList } from '../../Shared/Detailslist/detailsList';
import { ItemSuministroActionCreators } from '../../ItemSuministro/actions';
import { CustomIconButton } from '../../Shared/IconButton';
import ApiConfig from '../../../config/apiConfigOficial';
import { Separador } from '../../Shared/Separador/separador';

const Muestra: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const homologacionItem: IHomologacionGeneral = useSelector((state: ApplicationState) => state.itemHomologacion.homologacionItem);
    const doctosTipoListMuestra: IDocumentosTipoHomologacion[] = useSelector((state: ApplicationState) => state.itemHomologacion.doctosTipoListMuestra);
    const doctosListMuestra: IDocumentoHomologacion[] = useSelector((state: ApplicationState) => state.itemHomologacion.doctosListMuestra);

    function _onDownloadDocument(url: string, fileName: string) {
        dispatch(ItemSuministroActionCreators.loadingDownloadDocumento(true, fileName));
        dispatch(ItemSuministroActionCreators.downloadDocumento(url, fileName));
    }

    function _showMensajeEstado(estado: string): JSX.Element {
        switch (estado) {
            case EnumHomologacionFaseEstadoMuestra.MuestraRevisada:
                return (<div className={styles.Aprobado}>Muestra revisada</div>);
            case EnumHomologacionFaseEstadoMuestra.RecibidaRevision:
                return (<div className={styles.Solicitado}>Muestra en revisión</div>);
            case EnumHomologacionFaseEstadoMuestra.Solicitada:
                return (<div className={styles.Solicitado}>Muestra solicitada</div>);
            default:
                return (<></>);
        }
    }

    const disabledController: boolean = homologacionItem && homologacionItem.Muestra_Estado !== "Solicitada";

    return (
        <div className={styles.Muestra}>
            {homologacionItem && (
                <div>
                    <div style={{ margin: 5 }} >
                        {_showMensajeEstado(homologacionItem.Muestra_Estado)}
                        {homologacionItem.EtapaEstado.etapaMuestra.lookupId !== EnumEstadosMuestraHomologacion.NoDefinida && (
                            homologacionItem.EtapaEstado.etapaMuestra.lookupId === EnumEstadosMuestraHomologacion.Aprobada ? (
                                <div className={styles.Aprobado}>
                                    <div className={styles.content}>Muestra Aprobada</div>
                                </div>
                            ) : (
                                <div className={styles.Rechazado}>
                                    <div className={styles.content}>Muestra Rechazada</div>
                                </div>
                            )
                        )}
                    </div>
                    {(homologacionItem && homologacionItem.Muestra_Estado !== "Solicitada"  && homologacionItem.Muestra_Estado !== "No definida" && homologacionItem.Muestra_Estado !== "No Aplica") || homologacionItem.Muestra_Estado === EnumHomologacionFaseEstadoMuestra.RecibidaRevision || homologacionItem.Muestra_Estado === EnumHomologacionFaseEstadoMuestra.MuestraRevisada
                     ? (
                        <div>
                            <div style={{ paddingBottom: 15 }}>
                                <Separador mensaje='Información' subtitle />
                                <p style={{ textAlign: 'justify', paddingTop: 13 }}>Esta etapa corresponde a <b>muestras físicas</b> que usted debe enviar a SAESA para completar el proceso de la homologación del material. La(s) <b>{homologacionItem.Muestra_Cantidad && homologacionItem.Muestra_Cantidad !== null && homologacionItem.Muestra_Cantidad.trim() !== '' ? homologacionItem.Muestra_Cantidad : '[CANTIDAD NO DISPONIBLE]'}</b> muestra(s) deben ser entregadas a <b>{homologacionItem.Muestra_Informacion && homologacionItem.Muestra_Informacion !== null && homologacionItem.Muestra_Informacion.trim() !== "" ? homologacionItem.Muestra_Informacion : '[INFORMACIÓN NO DISPONIBLE]'}</b>. Cargue los documentos necesarios para validar el envío requerido.</p>
                            </div>
                            {doctosListMuestra && doctosListMuestra.length > 0 && (<Separador mensaje='Documentos disponibles' subtitle />)}
                            {doctosListMuestra && doctosListMuestra.length > 0 && (
                                <div style={{ marginBottom: 35 }}>
                                    <CustomDetailsList
                                        id={`documentosMuestra`}
                                        items={doctosListMuestra}
                                        columns={[
                                            {
                                                key: 'columnFileIconDocumentoObject',
                                                name: 'iconfile',
                                                fieldName: 'iconfile',
                                                minWidth: 16,
                                                maxWidth: 16,
                                                iconName: 'Page',
                                                clickToSort: false,
                                            },
                                            {
                                                key: 'columnaDescargar',
                                                name: 'Detalle documento',
                                                iconName: 'Download',
                                                fieldName: 'FileRef',
                                                minWidth: 20,
                                                maxWidth: 20,
                                                clickToSort: false,
                                                onRender: (item: IDocumentoHomologacion) => (
                                                    <CustomIconButton
                                                        icon='Download'
                                                        onClick={() => _onDownloadDocument(`${ApiConfig.baseUriDocuments}${item.FileRef}`, item.FileLeafRef)}
                                                        label='Descargar'
                                                        id={`downloaDocument${item.key}`}
                                                    />
                                                )
                                            },
                                            {
                                                key: 'columnaNombreDocumento',
                                                name: 'Nombre Documento',
                                                fieldName: 'FileLeafRef',
                                                minWidth: 200,
                                                maxWidth: 350,
                                                isResizable: true,
                                            },
                                            {
                                                key: 'colulmnaTipoDocumento',
                                                name: 'Tipo documento',
                                                fieldName: 'DocumentosTipo',
                                                minWidth: 200,
                                                maxWidth: 350,
                                                isResizable: true,
                                                onRender: (item: IDocumentoHomologacion) => item.DocumentosTipo && item.DocumentosTipo.lookupValue,
                                            },
                                            {
                                                key: 'columnaDetalle',
                                                name: 'Detalle documento',
                                                fieldName: 'DocumentoRequerido',
                                                minWidth: 200,
                                                maxWidth: 350,
                                                isResizable: true,
                                                onRender: (item: IDocumentoHomologacion) => item.DocumentoRequerido && item.DocumentoRequerido.lookupValue,
                                            },
                                        ]}
                                    />
                                </div>
                            )}
                            
                            {doctosTipoListMuestra && !disabledController && homologacionItem.EstadoId === EnumEstadoHomologacion.EnProceso 
                            &&
                            (
                                <>
                                    <Separador mensaje='Carga de documentos' subtitle />
                                    <div>
                                        <UploadDocumentos
                                            doctosTipo={doctosTipoListMuestra}
                                            etapaHomologacion={EnumHomologacionEtapa.Muestra} />
                                    </div>
                                </>
                            )}
                        </div>
                    ) 
                    
                    : homologacionItem.Muestra_Estado !== EnumHomologacionFaseEstadoMuestra.NoAplica 
                        ? homologacionItem.Muestra_Estado === EnumHomologacionFaseEstadoMuestra.Solicitada 
                            ? (<>
                                <div style={{ padding: '10px 0' }}>
                                <Separador mensaje='Información' />
                                <p style={{ textAlign: 'justify', paddingTop: 13 }}>Esta etapa corresponde a <b>muestras físicas</b> que usted debe enviar a SAESA para completar el proceso de la homologación del material. La(s) <b>{homologacionItem.Muestra_Cantidad && homologacionItem.Muestra_Cantidad !== null && homologacionItem.Muestra_Cantidad.trim() !== '' ? homologacionItem.Muestra_Cantidad : '[CANTIDAD NO DISPONIBLE]'}</b> muestra(s) deben ser entregadas a <b>{homologacionItem.Muestra_Informacion && homologacionItem.Muestra_Informacion !== null && homologacionItem.Muestra_Informacion.trim() !== "" ? homologacionItem.Muestra_Informacion : '[INFORMACIÓN NO DISPONIBLE]'}</b></p>
                            </div>
                              </>) 
                            
                            :(

                            <div style={{ padding: '10px 0' }}>
                                <Separador mensaje='Información' />
                                <span>Se informará si se requiere Muestra</span>
                            </div>
                        )
                        : 
                        ( 
                            <div style={{ padding: '10px 0' }}>
                                <Separador mensaje='Información' />
                                <span>No será necesaria la entrega de una muestra para la evaluación del producto.</span>
                            </div>
                        )}
                </div>
            )}
        </div>
    )
}

export default Muestra;