import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IProcesoSuministro } from './interfaces';
import { GrillaSuministrosActionCreators } from './actions';
import styles from './GrillaSuministros.module.scss';
import { CustomDetailsList, ICustomColumns } from '../Shared/Detailslist/detailsList';
import { CustomIconButton } from '../Shared/IconButton';
import { Loading } from '../Shared/Loading/loading';
import { IUsuarioLogin } from '../utils/interfaces';
import { ConvertSharepointDate } from '../utils/functions';
import { filter } from 'lodash';
import { Separador } from '../Shared/Separador/separador';
import Accordion from '../Shared/Accordion/Accordion';
import AccordionItem from '../Shared/Accordion/AccordionItem';
import { TextField } from 'office-ui-fabric-react';
import { CommonComboBox } from '../Shared/CustomFields/ComboBoxField';
import { CustomButton } from '../Shared/Button';
import { DatePicker } from '@fluentui/react';

interface IConnectedState {
    listaSuministros: IProcesoSuministro[];
    loading: boolean;
    user: IUsuarioLogin;
    listaNoConformidades: any[];
} 

const etapasSuministro = [
    {key:1, text: 'Antecedentes'},
    {key:2, text: 'Gestores'},
    {key:3, text: 'Entregables de Ingeniería'},
    {key:4, text: 'Inspecciones FAT'},
    {key:5, text: 'Protocolos de pruebas FAT'},
    {key:6, text: 'Documentacion de nacionalización/transporte'},
    {key:7, text: 'Recepcion'},
    {key:8, text: 'Cerrado'},
    {key:9, text:'Cerrado sin Inspección'}
];

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        listaSuministros: state.grillaSuministros.listaSuministros,
        loading: state.grillaSuministros.loading,
        user: state.main.user,
        listaNoConformidades : state.grillaSuministros.listaNoConformidades
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IGrillaSuministrosOwnProps {
    history: any;
}

// define combined props
type IGrillaSuministrosProps = IGrillaSuministrosOwnProps & IConnectedState &{ dispatch(action: any): void };

interface IGrillaSuministrosState{
    isLoaded: boolean;
    items: IItemSuministro[],
    columns: ICustomColumns[],
    filterObject: any;
    internalNoConformidades: any[];
}

interface IItemSuministro {
    key: number;
    id: number;
    etapa: string;
    ocasociada: string;
    cantidad: number;
    suministro: string;
    fechaEntrega: string;
    fechaEmisionOC: string;
    uniqueId: string;
    noConformidad: boolean;
}

class GrillaSuministros extends React.PureComponent<IGrillaSuministrosProps, IGrillaSuministrosState> {

    constructor(props: IGrillaSuministrosProps) {
        super(props);
        this.state = {
            isLoaded: false,
            items: [],
            internalNoConformidades: [],
            filterObject:{
                id:'',
                ordenCompra:'',
                suministro:'',
                etapa: '',
                fechaEntrega:'',
                fechaEmision:''
            },
            columns: [
                {
                    key: 'columnaId',
                    name: '#',
                    fieldName: 'id',
                    minWidth: 50,
                    maxWidth: 50,
                    isResizable: false,
                    isRowHeader:true,
                },
                {
                    key: 'columnaAcciones',
                    name: 'Procesar',
                    fieldName: 'uniqueId',
                    iconName: 'Processing',
                    minWidth: 50,
                    maxWidth: 50,
                    isResizable: false,
                    clickToSort: false,
                    onRender: (item: IItemSuministro) => (<CustomIconButton icon='Processing' onClick={() => this._onClickProcesarItem(item)} label='Procesar' id={`procesar_${item.key}`} />)
                },
                {
                    key: 'columnaOC',
                    name: 'OC Asociada',
                    fieldName: 'ocasociada',
                    minWidth: 120,
                    maxWidth: 150,
                    isResizable: true,
                    isRowHeader: false,
                },
                {
                    key: 'columnaCantidad',
                    name: 'Cantidad',
                    fieldName: 'cantidad',
                    minWidth: 120,
                    maxWidth: 150,
                    isResizable: true,
                },
                {
                    key: 'columnaSuministro',
                    name: 'Suministro',
                    fieldName: 'suministro',
                    minWidth: 400,
                    maxWidth: 650,
                    isResizable: true,
                },
                {
                    key: 'columnaEtapa',
                    name: 'Etapa',
                    fieldName: 'etapa',
                    minWidth: 150,
                    maxWidth: 150,
                    isResizable: false,
                    onRender: (item: IItemSuministro) => item.etapa
                },
                {
                    key: 'columnaNoConformidad',
                    name: 'No Conformidad',
                    fieldName: 'noConformidad',
                    minWidth: 150,
                    maxWidth: 150,
                    isResizable: false,
                    onRender: (item: IItemSuministro) => item.noConformidad ? <b>No Conformidad Activa</b> : null
                },
                {
                    key: 'columnaFechaEntrega',
                    name: 'Fecha entrega producto',
                    fieldName: 'fechaEntrega',
                    minWidth: 150,
                    maxWidth: 150,
                    isResizable: false,
                },
                {
                    key: 'columnaFechaEmisionOC',
                    name: 'Fecha emisión OC',
                    fieldName: 'fechaEmisionOC',
                    minWidth: 150,
                    maxWidth: 150,
                    isResizable: false,
                }
            ]
        }
    }

    public componentDidMount() {
        const { user } = this.props;
        this.props.dispatch(GrillaSuministrosActionCreators.handleLoading(true));
        this.props.dispatch(GrillaSuministrosActionCreators.getSuministrosProveedorList(user.usuarioProveedor.proveedor_x003a_Rut.lookupValue));
        this.props.dispatch(GrillaSuministrosActionCreators.getAllNoConformidades());
    }

    public componentDidUpdate(){
        const { isLoaded } = this.state;
        const { listaSuministros, listaNoConformidades } = this.props;
        if ( !isLoaded && listaSuministros && listaNoConformidades ){
            this.setState({
                items: this._buildItemsGrillaSuministros(listaSuministros, listaNoConformidades),
                isLoaded: true
            });
        }
    }

    private _buildItemsGrillaSuministros = ( listaSuministros: IProcesoSuministro[], listaNoConformidades?:any ): IItemSuministro[] => {

        const newListaSuministros = listaSuministros;

        return listaSuministros.map( l => ({
            key: l.ID,
            ocasociada: l.Title,
            cantidad: l.Cantidad,
            suministro: l.NombreSuministro.lookupValue,
            fechaEntrega: l.FechaEntregaProducto? ConvertSharepointDate(l.FechaEntregaProducto):'Fecha no definida',
            fechaEmisionOC: l.FechaEmisionOC? ConvertSharepointDate(l.FechaEmisionOC): 'Fecha no definida',
            uniqueId: l.UniqueId,
            id: l.ID,
            etapa: l.EtapaSuministro ? l.EtapaSuministro.lookupValue: 'Etapa no disponible',
            noConformidad: listaNoConformidades.find(nc => nc.SuministroGeneral.lookupId === l.ID && nc.Finalizada !== true) ? true : false
        }));
    }

    private _onClickProcesarItem = (item :IItemSuministro): void => {
        const { history } = this.props;
        history.push(`/itemSuministro/${item.uniqueId}`);
    }

    private _onCleanFilter = ():void =>{
        const { listaSuministros, listaNoConformidades } = this.props;
        this.setState({
            filterObject:{
                id:'',
                ordenCompra:'',
                suministro:'',
                etapa: '',
                fechaEntrega:'',
                fechaEmision:''
            },
            items: this._buildItemsGrillaSuministros(listaSuministros, listaNoConformidades)
        }
            
        );

    }

    private _onChangeFilter = (ev:any, option:any) => {
        const {filterObject, items} = this.state;
        const {listaSuministros, listaNoConformidades} = this.props;

        let filterObjectModified : any = undefined;
        let internalItemsModified : any[] = [];

        const { id, value, checked} = ev.target;

        switch(id){
            case 'idText':
                filterObjectModified = { ...filterObject, id: value };
                break;
            case 'ocText':
                filterObjectModified = { ...filterObject, ordenCompra: value };
                break;  
            case 'suministroText':
                filterObjectModified = { ...filterObject, suministro: value }; 
                break;
            case 'etapaText':
                filterObjectModified = { ...filterObject, etapa: option };
                break;
            case 'fechaEntregaText':
                filterObjectModified = { ...filterObject, fechaEntrega: value };
                break;
            case 'fechaEmisionText':
                filterObjectModified = { ...filterObject, fechaEmision: value };
                break;
            default:
                filterObjectModified= {...filterObject};
            }

            internalItemsModified = listaSuministros.filter(it =>
                (it.ID !== null && it.ID.toString().toLowerCase().indexOf(filterObjectModified.id.toLowerCase())>-1)&&
                (it.Title !== null && it.Title.toString().toLowerCase().indexOf(filterObjectModified.ordenCompra.toLowerCase())>-1) &&
                (it.NombreSuministro.lookupValue !== null && it.NombreSuministro.lookupValue.toString().toLowerCase().indexOf(filterObjectModified.suministro.toLowerCase())>-1)&&
                ((it.EtapaSuministro.lookupId !== null && filterObjectModified.etapa.key && it.EtapaSuministro.lookupId === filterObjectModified.etapa.key ) || !filterObjectModified.etapa.key) &&
                ((it.FechaEntregaProducto !== null && filterObjectModified.fechaEntrega && it.FechaEntregaProducto === filterObjectModified.fechEntrega ) || !filterObjectModified.fechaEntrega) &&
                ((it.FechaEmisionOC !== null && filterObjectModified.fechaEmision && it.FechaEmisionOC === filterObjectModified.fechaEmision ) || !filterObjectModified.fechaEmision)            
            );
            this.setState({
                filterObject: filterObjectModified,
                items: this._buildItemsGrillaSuministros(internalItemsModified, listaNoConformidades)
            })
        }
    

    public render() {
        const { loading, listaNoConformidades } = this.props;
        const { columns, items, filterObject , internalNoConformidades } = this.state;
        return (
            <div className={styles.container} >
                { loading && <Loading message='Cargando listado de suministros...' fullScreen={false} messagePosition='right' /> }
                <Text variant='xxLarge'>Lista Suministros</Text>
                <Separador mensaje='Filtros' />
                <Accordion id='filtroSuministro'>
                    <AccordionItem id='itemFiltrosSuministros' title='Abrir Filtros'>
                        <div className='ms-Grid' dir='ltr'>
                            <div className='ms-Grid-row'>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                <TextField id='idText' onChange={this._onChangeFilter} value={filterObject.id} label='Id'  />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                <TextField id='ocText' onChange={this._onChangeFilter} value={filterObject.ordenCompra} label='OC Asociada'  />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                <TextField id='suministroText' onChange={this._onChangeFilter} value={filterObject.suministro} label='Suministro'  />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <CommonComboBox key='etapaText' options={etapasSuministro} id='etapaText' onChange={this._onChangeFilter} selectedKey={filterObject.etapa && filterObject.etapa.key } label='Etapa'  />
                            </div>

                            <div className='ms-Grid-row'>
                                <div className="ms-Grid-col ms-lg12" style={{ textAlign: 'right' }}>
                                    <br/>
                                    <CustomButton id='clearFilter' onClick={this._onCleanFilter} label='Limpiar Filtros' />
                                </div>
                            </div>

                            </div>
                        </div>
                    </AccordionItem>
                </Accordion>
                { items.length > 0  ? (
                    <CustomDetailsList
                        columns={columns}
                        items={items}
                        isCompactMode={false}
                        addFilterInput={false}
                    />
                ):null}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrillaSuministros);
