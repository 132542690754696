import { INavLinkGroup } from 'office-ui-fabric-react';
import *  as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { CustomButton } from '../../Shared/Button';
import Navigation from '../../Shared/Navigation';
import { Separador } from '../../Shared/Separador/separador';
import { EnumEstadoHomologacion, EnumHomologacionEtapa } from '../../utils/enums';
import { IHomologacionEtapa } from '../interfaces';
import styles from '../ItemHomologacion.module.scss';
import Inspeccion from './Inspeccion';
import Muestra from './Muestra';
import RequisitosCalidad from './RequisitosCalidad';
import RequisitosEstrategicos from './RequisitosEstrategicos';
import RequisitosTecnicos from './RequisitosTecnicos';
import VistaPrevia from './VistaPrevia';


import * as html2pdf from 'html2pdf.js';
import { IHomologacionGeneral } from '../../GrillaHomologacion/interfaces';

const MainCapitulo: React.FunctionComponent = () => {

    const etapasList: IHomologacionEtapa[] = useSelector( (state:ApplicationState) => state.itemHomologacion.etapasList );
    const homologacionItem : IHomologacionGeneral = useSelector((state: ApplicationState) => state.itemHomologacion.homologacionItem);
    
    const [menu, setMenu] = React.useState<INavLinkGroup[]>([]);
  
    React.useEffect(() => {

        if (etapasList && etapasList.length > 0) {
            setMenu([{ links: etapasList.map( (e, k) => ({
                key: e.CodigoInterno,
                name: e.Title,
                url: void(0),
                target: "_self",
                isExpanded: true,
                customPropNavItemId: e.CodigoInterno,
                icon: e.IconName
            }))}]);
        }
    },[etapasList]);

    const uuid = new Date().getMilliseconds();


    const descargarPDF = () =>{

        const divToPrint = document.getElementById('cartaHomologacion');
        let opt ={
            filename: "carta-homologacion.pdf",
            image: {type: "jpg", quality: 0.98},
            html2canvas: {scale:2, useCORS: true},
            jsPDF:{unit: "in", format:"a4", orientation: "portrait"},
            pagebreak:{mode: ['avoid-all', 'css', 'legacy']}
        }

        divToPrint.setAttribute("class", styles.contentVistaPreviaPrint);

        html2pdf()
        .set(opt)
        .from(divToPrint)
        .toPdf()
        .outputPdf("blob", opt.jsPDF)
        .save()
    }

    return (
        <div className={`ms-Grid`} dir="ltr">
            <div className={`ms-Grid-row ${styles.MainCapitulos}`}>
                <div className={`ms-Grid-col ms-sm6 ms-md4 ms-lg2 ${styles.menu}`}>
                    {menu.length > 0 && (
                        <Navigation
                            parentElementId={`PARCAPHOM_${uuid}`}
                            navigationDataList={menu}
                            defaultSelectedKey="REQTEC"
                            extraPixelsToScroll={0}
                        />
                    )}
                </div>
                {etapasList && (
                    <div className={`ms-Grid-col ms-sm6 ms-md8 ms-lg10 ${styles.container}`} id={`PARCAPHOM_${uuid}`}>
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.ReqTecnicos) && (<Separador id='REQTEC' mensaje='Requisitos Técnicos'/>)}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.ReqTecnicos) && (
                            <RequisitosTecnicos />
                        )}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.ReqCalidad) && (<Separador id='REQCAL' mensaje='Requisitos de Calidad'/>)}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.ReqCalidad) && (
                            <RequisitosCalidad />
                        )}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.ReqEstrategicos) && (<Separador id='REQEST' mensaje='Requisitos Estratégicos'/>)}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.ReqEstrategicos) && (
                            <RequisitosEstrategicos />
                        )}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.Muestra) && (<Separador id='MUESTR' mensaje='Muestra'/>)}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.Muestra) && (
                            <Muestra />
                        )}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.Inspeccion) && (<Separador id='INSPEC' mensaje='Inspección'/>)}
                        {etapasList.find( e => e.ID === EnumHomologacionEtapa.Inspeccion) && (
                            <Inspeccion />
                        )}
                        <Separador />
                        <VistaPrevia />
                        <Separador />
                        {homologacionItem && (homologacionItem.Docto_Material.length > 0 && homologacionItem.EstadoId === EnumEstadoHomologacion.Aprobado) && (
                            <CustomButton
                            label='Descargar PDF'
                            id='downloadpdf'
                            onClick={descargarPDF}
                            disabled={homologacionItem.EstadoId !== 4}
                        ></CustomButton>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default MainCapitulo;