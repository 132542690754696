import { Panel, PanelType } from 'office-ui-fabric-react';
import * as React from 'react';
import { CustomButton } from '../../Shared/Button';
import { CustomTextField } from '../../Shared/CustomFields/TextField';
import { CommonComboBox } from '../../Shared/CustomFields/ComboBoxField';
import { Separador } from '../../Shared/Separador/separador';
import { IHomologacionGeneral } from '../interfaces';

import styles from '../GrillaHomologacion.module.scss';
import { ComboBox, IComboBoxOption } from '@fluentui/react';
import UploadFile from '../../Shared/UploadFile/uploadFile';
import { IMetadataDoctoHomologacion } from '../../ItemHomologacion/interfaces';

interface IPanelNuevoProps {
    openPanel: boolean;
    homologacionItem: IHomologacionGeneral;
    onClose: any;
    onAccept: (file: File[]) => void;
    onChange: (ev, opt) => void;
}

const PanelNuevo : React.FunctionComponent<IPanelNuevoProps> = (props) => {
    const {openPanel, homologacionItem} = props;

    const [files, setFiles] = React.useState<File[]>(undefined);
    const [metadataDocumentosList, setmetadataDocumentosList] = React.useState<IMetadataDoctoHomologacion[]>([]);
    const [options, setOptions] = React.useState<IComboBoxOption[]>([
        {
          key: 1,
          text: "Brochure"
        }
      ]);

    const areasFuncionamientoOptions = [
        { id: 'Distribución', key: 'Distribución', text: 'Distribución'},
        { id: 'Generación', key: 'Generación', text: 'Generación'},
        { id: 'Transmisión', key: 'Transmisión', text: 'Transmisión'}
    
      ];
    
      const nivelesTensionOptions = [
        { id: 'No Aplica', key: 'No Aplica', text: 'No Aplica'},
        { id: 'Baja tensión (BT): ≤ 1kV',key: 'Baja tensión (BT): ≤ 1kV', text: 'Baja tensión (BT): ≤ 1kV' },
        { id: 'Media tensión (MT): > 1 kV hasta ≤ 23 kV',key: 'Media tensión (MT): > 1 kV hasta ≤ 23 kV', text: 'Media tensión (MT): > 1 kV hasta ≤ 23 kV'},
        { id: 'Alta tensión (AT): > 23 kV hasta ≤ 230 kV',key: 'Alta tensión (AT): > 23 kV hasta ≤ 230 kV', text: 'Alta tensión (AT): > 23 kV hasta ≤ 230 kV'},
        { id: 'Extra alta tensión (EAT): > 230 kV',key: 'Extra alta tensión (EAT): > 230 kV', text: 'Extra alta tensión (EAT): > 230 kV'}
      ];

    function _onRenderFooter ():JSX.Element {
        return (
            <div>
                <CustomButton icon='Cancel' onClick={props.onClose} label='Cerrar' id='cancelnewhomologacion' cancel/>
                <CustomButton icon='Add' onClick={() => props.onAccept(files)} label='Guardar' id='savenewhomologacion' disabled={
                    (homologacionItem && (
                        homologacionItem.FabricanteProducto.trim() === '' ||
                        homologacionItem.LineaFuncionamientoProducto.trim() === '' ||
                        homologacionItem.ModeloProducto.trim() === '' ||
                        homologacionItem.NivelTensionProducto.trim() === '' ||
                        homologacionItem.Title.trim() === '' ||
                        homologacionItem.UbicacionFabrica.trim() === '' ||
                        homologacionItem.SolicitanteNombre.trim() === '' ||
                        homologacionItem.SolicitanteTelefono.trim() === ''
                    )) || !files
                }/>
            </div>
        );
    }

    const _onUploadFile = (files: File[], fileData: any[], metadataFiles: any[]):void => {
        setFiles(files);
      }
    
    const _onChangeMetadata = (ev, option):void => {
        const { id, value } = ev.target;
        const idSplited: string = id.split("@@");
    
        let metadataListModified: any[] = [];
    
        const metadataExist = metadataDocumentosList.find( m => m.key === idSplited[1] );
        if (!metadataExist) {
    
            let metadataToAdd = {
                key: idSplited[1],
                tipoDocumento: -1,
                detalle: ''
            };
    
            switch(idSplited[0]){
                case 'tipoDocumento':
                    metadataToAdd = { ...metadataToAdd, tipoDocumento: Number(option.key) };
                    break;
                case 'detalle':
                    metadataToAdd = { ...metadataToAdd, detalle: value as string };
                    break;
                default:
                    metadataToAdd = { ...metadataToAdd };
    
          }
    
          metadataListModified = metadataDocumentosList;
          metadataListModified.push(metadataToAdd);
    
        } else {
    
          let metadataToUpdate = undefined;
    
          switch(idSplited[0]){
            case 'tipoDocumento':
                metadataToUpdate = { ...metadataExist, tipoDocumento: Number(option.key) };
                break;
            case 'detalle':
                metadataToUpdate = { ...metadataExist, detalle: value as string};
                break;
            default:
                metadataToUpdate = { ...metadataExist };
          }
    
          metadataListModified = metadataDocumentosList.filter( m => m.key !== metadataExist.key );
          metadataListModified.push(metadataToUpdate);
        }
          setmetadataDocumentosList(metadataListModified); 
            
    }
    return (
        <Panel
            isOpen={openPanel}
            onDismiss={props.onClose}
            headerText='Solicitar una nueva homologación'
            closeButtonAriaLabel="Cerrar"
            onRenderFooterContent={_onRenderFooter}
            isFooterAtBottom
            type={PanelType.medium}
            layerProps={{ eventBubblingEnabled: true }}
        >
            { homologacionItem  && (
                <div>
                    <Separador mensaje='Información del Producto'/>
                    <CustomTextField value={homologacionItem.FabricanteProducto} id='FabricanteProducto' label='Fabricante' onChange={props.onChange} required/>
                    <CustomTextField value={homologacionItem.ModeloProducto} id='ModeloProducto' label='Modelo' onChange={props.onChange} required/>
                    {/* <CustomTextField value={homologacionItem.LineaFuncionamientoProducto} id='LineaFuncionamientoProducto' label='Área de funcionamiento' onChange={props.onChange} required/>
                    <CustomTextField value={homologacionItem.NivelTensionProducto} id='NivelTensionProducto' label='Nivel de tensión' onChange={props.onChange} required/> */}
                    <CommonComboBox
                        key='LineaFuncionamientoProducto'
                        id='LineaFuncionamientoProducto'
                        label='Área de Funcionamiento'
                        options={areasFuncionamientoOptions}
                        onChange={props.onChange}
                        selectedKey={homologacionItem.LineaFuncionamientoProducto}
                    />
                    <CommonComboBox 
                        key='NivelTensionProducto'
                        id='NivelTensionProducto'
                        label='Nivel de tensión'
                        options={nivelesTensionOptions}
                        onChange={props.onChange}
                        selectedKey={homologacionItem.NivelTensionProducto}
                    />
                    <CustomTextField value={homologacionItem.Title} id='Title' label='Descripción' onChange={props.onChange} required/>
                    <Separador mensaje='Información del Proveedor'/>
                    <CustomTextField value={homologacionItem.UbicacionFabrica} id='UbicacionFabrica' label='Ubicación de la fábrica' onChange={props.onChange} required/>
                    <Separador mensaje="Información del solicitante" />
                    <CustomTextField value={homologacionItem.SolicitanteNombre} id='SolicitanteNombre' label='Nombre del solicitante' onChange={props.onChange} required/>
                    <CustomTextField value={homologacionItem.SolicitanteTelefono} id='SolicitanteTelefono' label='Teléfono del solicitante' onChange={props.onChange} required/>
                    <Separador mensaje='Carga de Brochure'/>
                    <div className={styles.cargaBrochure}>
                        {/* <CustomTextField multiple type='file' id='AddBrochure' onChange={(ev) => setFiles(ev.target.files)} /> */}
                        <UploadFile
                            onUploadFile={_onUploadFile}
                            metadataList={metadataDocumentosList}
                            metadataOptionsColumns={[
                              {
                                key: "columnSelectDocType",
                                name: "Tipo Documento",
                                fieldName: "tipoDocumento",
                                minWidth: 200,
                                maxWidth: 200,
                                onRender: (item) => {
                                  return (
                                    <CommonComboBox
                                      id={`tipoDocumento@@${item.key}`}
                                      key={`${item.key}comboboxTipoDocumento`}
                                      options={options}
                                      onChange={_onChangeMetadata}
                                      defaultSelectedKey={1}
                                      disabled={true}
                                    />
                                  );
                                },
                              },
                          ]}
                          removeButton
                        />
                    </div>
                </div>
            )}
        </Panel>
    )
}

export default PanelNuevo;