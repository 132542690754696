import {
    GET_INSPECCIONBYID,
    GET_DOCUMENTOSINPSECCIONLIST,
    GET_DOCUMENTOSHOMOLOGACIONLIST,

    LOADING,
    LOADING_DOCUMENTOSINSPECCION,
    LOADING_DOCUMENTOSHOMOLOGACION,

    CLEAR_INSPECCIONITEM,
    LOADING_COMENTARIOSHOMOLOGACIONISPECCION,
    GET_COMENTARIOSHOMOLOGACIONINSPECCION,
    CLEAR_COMENTARIOSHOMOLOGACIONINSPECCION,
} from './constants';
import { AppThunkAction } from '../../config/store';
import { MainActionCreators, MainKnownAction } from '../Main/actions'

import apiConfig from '../../config/apiConfigOficial';
import { IDocumentoHomologacion, IDocumentosInspeccionHomologacion, IInspeccionHomologacion } from '../ItemHomologacion/interfaces';
import { IMetadataDoctoInspeccionHomologacion } from './interfaces';
import { EnumEstadosDocumentosSuministros } from '../utils/enums';
import { IComentarioBase } from '../ItemSuministro/interfaces';

// Decalaraciones de las acciones
interface HanldeLoadingAction { type: typeof LOADING; loading: boolean; }
interface GetItemInspeccionHomologacionAction { type: typeof GET_INSPECCIONBYID; itemInspeccion: IInspeccionHomologacion; }
interface ClearItemInspeccionHomologacionAction { type: typeof CLEAR_INSPECCIONITEM; }

interface HandleLoadingDoctosHomologacionAction { type: typeof LOADING_DOCUMENTOSHOMOLOGACION; loading: boolean; }
interface HandleLoadingDoctosInspeccionAction { type: typeof LOADING_DOCUMENTOSINSPECCION; loading: boolean; }
interface GetDocumentosHomologacionListAction { type: typeof GET_DOCUMENTOSHOMOLOGACIONLIST; doctosList: IDocumentoHomologacion[]; }
interface GetDocumentosInspeccionesListAction { type: typeof GET_DOCUMENTOSINPSECCIONLIST; doctosList: IDocumentosInspeccionHomologacion[]; }
interface HandleLoadingComentariosAction { type: typeof LOADING_COMENTARIOSHOMOLOGACIONISPECCION; loadingComentarios: boolean; }
interface GetHomologacionComentariosAction { type: typeof GET_COMENTARIOSHOMOLOGACIONINSPECCION; comentariosList: IComentarioBase[]; }
interface ClearHomologacionComentariosAction { type: typeof CLEAR_COMENTARIOSHOMOLOGACIONINSPECCION; }

// lista de acciones conocidas
export type ItemHomologacionInspeccionKnownAction =
    MainKnownAction
    | HanldeLoadingAction
    | GetItemInspeccionHomologacionAction
    | ClearItemInspeccionHomologacionAction
    | HandleLoadingDoctosHomologacionAction
    | GetDocumentosHomologacionListAction
    | HandleLoadingDoctosInspeccionAction
    | GetDocumentosInspeccionesListAction
    | GetHomologacionComentariosAction
    | HandleLoadingComentariosAction
    | ClearHomologacionComentariosAction
    ;

const defaultHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}`,
});

export const ItemHomologacionesInspeccionActionCreators = {
    handleLoading: (loading) => ({ type: LOADING, loading } as HanldeLoadingAction),
    clearItemInspeccionHomologacion: () => ({ type: CLEAR_INSPECCIONITEM } as ClearItemInspeccionHomologacionAction),
    getItemInspeccionHomologacionById: (inspeccionuuid: string): AppThunkAction<ItemHomologacionInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetInspeccionHomologacionItem`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(inspeccionuuid),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IInspeccionHomologacion>;
            })
            .then(data => {
                dispatch({ type: GET_INSPECCIONBYID, itemInspeccion: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error')));
    },


    handleLoadingDocumentosHomologacion: (loading) => ({ type: LOADING_DOCUMENTOSHOMOLOGACION, loading } as HandleLoadingDoctosHomologacionAction),
    getDocumentosHomologacionList: (homologacionid: number): AppThunkAction<ItemHomologacionInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetHomologacionDocumentosInspector`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(homologacionid),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentoHomologacion[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSHOMOLOGACIONLIST, doctosList: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error')));
    },

    handleLoadingDocumentosInspeccion: (loading) => ({ type: LOADING_DOCUMENTOSINSPECCION, loading } as HandleLoadingDoctosInspeccionAction),
    getDocumentosInspeccionList: (inspeccionId: number): AppThunkAction<ItemHomologacionInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetDocumentosInspeccionesHomologacionInspector`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify([inspeccionId]),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IDocumentosInspeccionHomologacion[]>;
            })
            .then(data => {
                dispatch({ type: GET_DOCUMENTOSINPSECCIONLIST, doctosList: data });
            })
            .catch(error => dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error')));
    },

    uploadDocumentoHomologacionInspeccion: (files: File[], metadataList: IMetadataDoctoInspeccionHomologacion[], inspeccionId: number, isReupload = false): AppThunkAction<ItemHomologacionInspeccionKnownAction> => async (dispatch) => {
        let formData = new FormData();

        for (let f = 0; f < files.length; f += 1) {
            formData.append(`documentoHomologacionList[${f}].File`, files[f], files[f].name);
            formData.append(`documentoHomologacionList[${f}].DocumentoInspeccionMetadata.DsgHomologacionInseccion`, String(metadataList[f].CodigoHomologacionInspeccion));
            formData.append(`documentoHomologacionList[${f}].DocumentoInspeccionMetadata.DsgHomologacionGeneral`, String(metadataList[f].CodigoHomologacionGeneral));
            formData.append(`documentoHomologacionList[${f}].DocumentoInspeccionMetadata.DsgDocumentosTipoId`, String(metadataList[f].DocumentosTipoId));
            formData.append(`documentoHomologacionList[${f}].DocumentoInspeccionMetadata.DsgDocumentosEstadoId`, String(EnumEstadosDocumentosSuministros.EnRevision));
            formData.append(`documentoHomologacionList[${f}].DocumentoInspeccionMetadata.DsgDetalleDocumento`, String(metadataList[f].DetalleDocumento));
            if (isReupload) {
                formData.append(`documentoHomologacionList[${f}].DocumentoInspeccionMetadata.DsgDocumentoId`, String(metadataList[f].IdDocumento));
            } else {
                formData.append(`documentoHomologacionList[${f}].DocumentoInspeccionMetadata.DsgDocumentoId`, "0");
            }
            formData.append(`documentoHomologacionList[${f}].DocumentoInspeccionMetadata.DsgEmailSolicitante`, String(metadataList[f].EmailSolicitante));
        }
        fetch(`${apiConfig.apiUri}/Homologacion/UploadDocumentosHomologacionInspeccion`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${sessionStorage.getItem(apiConfig.AccessTokenKey)}` },
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    response.json().then(json => {
                        dispatch(MainActionCreators.onShowMessageBar(`El archivo ya existe o el tipo no corresponde al ya existente.`, 'error'));
                        dispatch(ItemHomologacionesInspeccionActionCreators.handleLoadingDocumentosInspeccion(false));
                    });
                } else {
                    dispatch(MainActionCreators.onShowMessageBar(`Carga correcta de archivos.`, 'success'));
                    dispatch(ItemHomologacionesInspeccionActionCreators.getDocumentosInspeccionList(inspeccionId));
                }
            });
    },

    handleLoadingComentariosHomologacionInspeccion: (loadingComentarios) => ({ type: LOADING_COMENTARIOSHOMOLOGACIONISPECCION, loadingComentarios } as HandleLoadingComentariosAction),
    clearComentarios: () => ({ type: CLEAR_COMENTARIOSHOMOLOGACIONINSPECCION } as ClearHomologacionComentariosAction),
    getComentariosInspeccionesHomologacion: (doctoInspeccionId: number): AppThunkAction<ItemHomologacionInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/GetComentariosDocumentoHomologacionInspeccion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(doctoInspeccionId),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                return response.json() as Promise<IComentarioBase[]>;
            })
            .then(data => {
                dispatch({ type: GET_COMENTARIOSHOMOLOGACIONINSPECCION, comentariosList: data, });
            })
            .catch(error => {
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

    addComentarioDocumentoHomologacionInspeccion: (comentario: IComentarioBase): AppThunkAction<ItemHomologacionInspeccionKnownAction> => async (dispatch) => {
        fetch(`${apiConfig.apiUri}/Homologacion/AddComentarioDocumentoHomologacionInspeccion`, {
            method: 'POST',
            headers: defaultHeaders(),
            body: JSON.stringify(comentario),
        })
            .then(response => {
                if (!response.ok) throw Error(response.status.toString());
                else {
                    dispatch(ItemHomologacionesInspeccionActionCreators.getComentariosInspeccionesHomologacion(comentario.DocumentosHomologacionId));
                }
            })
            .catch(error => {
                dispatch(ItemHomologacionesInspeccionActionCreators.handleLoadingComentariosHomologacionInspeccion(false));
                dispatch(MainActionCreators.onShowMessageBar(error.toString(), 'error'));
            });
    },

};