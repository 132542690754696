import * as React from 'react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { CustomTextField } from '../CustomFields/TextField';

import styles from './DetailsList.module.scss';
import { isEqualArrayObject } from '../../utils/functions';

interface ICustomDetailsListState {
    internalItems: any[];
    internalColumns: IColumn[];

    announcedMessage?: string;
    inputLabel?: string;
    isLoaded: boolean;
};

interface ICustomDetailsListProps {
    columns: ICustomColumns[];
    items: any[];
    addFilterInput?: boolean;
    isCompactMode?: boolean;
    keyFilterOne?: string;
    keyFilterTwo?: string;
    id?: string;
    onItemInvoked?: void;
};

export interface ICustomColumns {
    key: string,
    name: string,
    minWidth: number,
    maxWidth: number,
    iconName?: string,
    fieldName?: string,
    onRender?: any,
    isRowHeader?: boolean,
    isResizable?: boolean,
    isSorted?: boolean,
    isSortedDescending?: boolean,
    data?: any,
    isPadded?: boolean,
    isCollapsible?: boolean,
    clickToSort?: boolean,
};

const FILE_ICONS: { name: string }[] = [
    { name: 'accdb' },
    { name: 'audio' },
    { name: 'code' },
    { name: 'csv' },
    { name: 'docx' },
    { name: 'dotx' },
    { name: 'mpp' },
    { name: 'mpt' },
    { name: 'model' },
    { name: 'one' },
    { name: 'onetoc' },
    { name: 'potx' },
    { name: 'ppsx' },
    { name: 'pdf' },
    { name: 'photo' },
    { name: 'pptx' },
    { name: 'presentation' },
    { name: 'potx' },
    { name: 'pub' },
    { name: 'rtf' },
    { name: 'spreadsheet' },
    { name: 'txt' },
    { name: 'vector' },
    { name: 'vsdx' },
    { name: 'vssx' },
    { name: 'vstx' },
    { name: 'xlsx' },
    { name: 'xltx' },
    { name: 'xsn' },
  ];

export class CustomDetailsList extends React.Component<ICustomDetailsListProps, ICustomDetailsListState> {

    constructor(props: ICustomDetailsListProps) {
        super(props);
        this.state = {
            internalItems: props.items,
            internalColumns: this._generateColumns(props.columns),
            announcedMessage: undefined,
            inputLabel: this._generateInputLabel(),
            isLoaded: false,
        };
    }

    public componentDidMount(){
      const { columns, items } = this.props;
      if (columns && items){
        this.setState({
            internalItems: this._generateItemsFromColumns(items,columns),
            isLoaded: true,
        }); 
      }    
    }
  
    public componentDidUpdate( prevProps: ICustomDetailsListProps, prevState:  ICustomDetailsListState ) {
        const { columns, items } = this.props;
        const { isLoaded } = this.state;
        if ( !isLoaded || !isEqualArrayObject(items, prevProps.items)){
          this.setState({
              internalItems: this._generateItemsFromColumns(items,columns),
              isLoaded: true,
          }); 
        }    
    }

    private _generateItemsFromColumns = (items: any[], columns: IColumn[]): any[] => {
        let itemsModified: any[] = [];
        const audioFiles: string[] = ['mp3', 'mid', 'midi', 'wav', 'wma'];
        const photoFiles: string[] = ['png','jpg', 'jpeg', 'raw', 'bmp', 'gif', 'svg'];
        const codeFile: string[] = ['sql', 'ts', 'tsx', 'js', 'jsx'];
        let fileType: {name: string} = {name: 'txt'};
    
        for (let i in items){
          const item = items[i];
          let itemObject:any = { key: item.key };
          const itemObjectKey = Object.keys(items[i]);
          for (let c in columns){
            const {fieldName, key} = columns[c];
            if (itemObjectKey.includes(fieldName)){
              itemObject[fieldName] = item[fieldName];
            } else {
              switch(key){
                case 'columnFileIconDocumento':
                  const doctoTypeSplited: string[] = item.FileLeafRef.split('.');
                  const doctotype: string = doctoTypeSplited[doctoTypeSplited.length -1];

                  fileType = FILE_ICONS.find( fi => fi.name === doctotype );
                  if ( !fileType ) {
                    fileType = {name: 'txt'};
                  }
                  if ( photoFiles.includes(doctotype) ) {
                    fileType = {name: 'photo'};
                  } else if (audioFiles.includes(doctotype)) {
                    fileType = {name: 'audio'};
                  } else if (codeFile.includes(doctotype)) {
                    fileType = {name: 'code'};
                  }
                  itemObject['iconfile'] = (
                    <img
                      src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${fileType.name}.svg`}
                      className={styles.fileIconImg}
                      alt={`${fileType.name} file icon`}
                    />
                  );
                break;
                case 'columnFileIconDocumentoObject':
                  fileType = {name: 'txt'};
                  if (item.Documento){
                    const doctoTypeSplited: string[] = item.Documento.FileLeafRef.split('.');
                    const doctotype: string = doctoTypeSplited[doctoTypeSplited.length -1];

                    fileType = FILE_ICONS.find( fi => fi.name === doctotype );
                    if ( !fileType ) {
                      fileType = {name: 'txt'};
                    }
                    if ( photoFiles.includes(doctotype) ) {
                      fileType = {name: 'photo'};
                    } else if (audioFiles.includes(doctotype)) {
                      fileType = {name: 'audio'};
                    } else if (codeFile.includes(doctotype)) {
                      fileType = {name: 'code'};
                    }
                  }
                  itemObject['iconfile'] = (
                    <img
                      src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${fileType.name}.svg`}
                      className={styles.fileIconImg}
                      alt={`${fileType.name} file icon`}
                    />
                  );
                  break;
                case 'columniconfileUploadTemp':
                  const doctoNameUpload: string[] = item.path.split('.');
                  const docTypeUpload: string = doctoNameUpload[doctoNameUpload.length-1];
                  fileType = FILE_ICONS.find( fi => fi.name === docTypeUpload );
                  if ( !fileType ) {
                    fileType = {name: 'txt'};
                  }
                  if ( photoFiles.includes(docTypeUpload.toLowerCase())) {
                    fileType = {name: 'photo'};
                  } else if (audioFiles.includes(docTypeUpload.toLowerCase())) {
                    fileType = {name: 'audio'};
                  } else if (codeFile.includes(docTypeUpload.toLowerCase())) {
                    fileType = {name: 'code'};
                  }
                  itemObject['iconfile'] = (
                    <img
                      src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${fileType.name}.svg`}
                      className={styles.fileIconImg}
                      alt={`${fileType.name} file icon`}
                    />
                  );
                  break;
                case 'columniconfile':
                  const doctoNameGeneric: string[] = item.path.split('.');
                  const docTypeGeneric: string = doctoNameGeneric[doctoNameGeneric.length-1];
                  fileType = FILE_ICONS.find( fi => fi.name === docTypeGeneric );
                  if ( !fileType ) {
                    fileType = {name: 'txt'};
                  }
                  if ( photoFiles.includes(docTypeGeneric) ) {
                    fileType = {name: 'photo'};
                  } else if (audioFiles.includes(docTypeGeneric)) {
                    fileType = {name: 'audio'};
                  } else if (codeFile.includes(docTypeGeneric)) {
                    fileType = {name: 'code'};
                  }
                  itemObject['iconfile'] = (
                    <img
                      src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${fileType.name}.svg`}
                      className={styles.fileIconImg}
                      alt={`${fileType.name} file icon`}
                    />
                  );
                  break;
                case 'columniconfileDocSuministro':
                  fileType = FILE_ICONS.filter( fi => fi.name === item.type )[0];
                  if ( !fileType ) {
                    fileType = {name: 'txt'};
                  }
                  if ( photoFiles.includes(item.type) ) {
                    fileType = {name: 'photo'};
                  } else if (audioFiles.includes(item.type)) {
                    fileType = {name: 'audio'};
                  } else if (codeFile.includes(item.type)) {
                    fileType = {name: 'code'};
                  }
                  itemObject['iconfile'] = (
                    <img
                      src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${fileType.name}.svg`}
                      className={styles.fileIconImg}
                      alt={`${fileType.name} file icon`}
                    />
                  );
                  break;
                default:
                  // definición por cada caso especial
                  break;
              }
            }
          }
          itemsModified.push(itemObject);
        }
        return itemsModified;
      }


    private _generateInputLabel(): string | undefined {
        let inputLabel: string | undefined = undefined;
        const { keyFilterOne, keyFilterTwo } = this.props;
        if (keyFilterOne && !keyFilterTwo) {
            inputLabel = `ingrese ${keyFilterOne}`;
        } else if (keyFilterOne && keyFilterTwo) {
            inputLabel = `ingrese ${keyFilterOne} o ${keyFilterTwo}`;
        }
        return inputLabel;
    }

    // genero mi columna nueva en base a la custom con menos información.
    private _generateColumns (customColumns : ICustomColumns[]): IColumn[] {
        const newColumns: IColumn[] = [];
        for (let col in customColumns){
          const {iconName, key, name, fieldName, minWidth, maxWidth, clickToSort, onRender, isRowHeader, isResizable, isSorted, isSortedDescending, data, isCollapsible } = customColumns[col];
            newColumns.push({
                className: iconName? styles.fileIconCell:undefined,
                iconClassName: iconName? styles.fileIconHeaderIcon:undefined,
                isIconOnly: iconName? true : false,
                key: key,
                name: name,
                ariaLabel: `Column for ${key}, press to order asc or desc`,
                iconName: iconName,
                fieldName: fieldName,
                minWidth: minWidth,
                maxWidth: maxWidth,
                onColumnClick: clickToSort === false ? undefined : this._onColumnClick,
                onRender: onRender,
                isRowHeader: isRowHeader,
                isResizable: isResizable,
                isSorted: isSorted,
                isSortedDescending: isSortedDescending,
                data: data,
                isCollapsible: isCollapsible,
            });
        };
        return newColumns;
    }

    public render() {
        const { internalColumns, internalItems, inputLabel } = this.state;
        const { isCompactMode, addFilterInput, id } = this.props;
        return (
            <Fabric>
                { addFilterInput && <CustomTextField id={`ButtomFilter-${id}`} label='Filtro' placeholder={inputLabel} onChange={this._onChangeFilterText} className={styles.inputControl} /> }
                <DetailsList
                    items={internalItems.length > 0 ? internalItems : []}
                    columns={internalColumns.length > 0 ? internalColumns : []}
                    compact={isCompactMode}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                />
            </Fabric>
        );
    }

    private _onChangeFilterText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        const { items, keyFilterOne, keyFilterTwo } = this.props;
        if (keyFilterOne && !keyFilterTwo) {
            this.setState({
                internalItems: text ? items.filter(i => i[keyFilterOne].toLowerCase().indexOf(text) > -1) : items,
            });
        } else if (keyFilterOne && keyFilterTwo) {
            this.setState({
                internalItems: text ? items.filter(i => i[keyFilterOne].toLowerCase().indexOf(text) > -1 || i[keyFilterTwo].toLowerCase().indexOf(text) > -1) : items,
            });
        }
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { internalColumns, internalItems } = this.state;
        const newColumns: IColumn[] = internalColumns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                this.setState({
                    announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}`,
                });
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(internalItems, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            internalColumns: newColumns,
            internalItems: newItems,
        });
    };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

