import * as React from 'react';
import { DefaultButton, PrimaryButton, IIconProps, IButtonStyles } from 'office-ui-fabric-react';
import { useId } from '@uifabric/react-hooks';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';

export interface IButtonProps {
    // These are set based on the toggles shown above the examples (not needed in real code)
    disabled?: boolean;
    icon?: string;
    onClick: any;
    label: string;
    id: string;
    style?: object;
    cancel?: boolean;
}

export const CustomButton: React.FunctionComponent<IButtonProps> = (props) => {
    const buttonIcon: IIconProps = { iconName: props.icon };
    const id = useId(props.id);

    const theme = getTheme();
    const ButtonStyles: IButtonStyles = {
        root: {
            ...props.style,
            backgroundColor: theme.palette.themePrimary,
            borderColor: theme.palette.themePrimary,
            padding: '20px 50px',
            margin: '3px 15px'
        },
        rootHovered: {
            backgroundColor: theme.palette.themeDark,
            borderColor: theme.palette.themePrimary,
        }
    }

    const ButtonStylesCancel: IButtonStyles = {
        root: {
            ...props.style,
            backgroundColor: theme.palette.white,
            borderColor: theme.palette.themePrimary,
            color:theme.palette.themePrimary,
            padding: '20px 50px',
            margin: '3px 15px'
        },
        rootHovered: {
            backgroundColor: theme.palette.themeLight,
            color:theme.palette.themePrimary,
        }
    }

    return (props.cancel ?
        (<DefaultButton {...props} id={id} styles={ButtonStylesCancel} text={props.label} allowDisabledFocus  iconProps={props.icon? buttonIcon:undefined} />) :
        (<PrimaryButton {...props} id={id} styles={ButtonStyles} text={props.label} allowDisabledFocus  iconProps={props.icon? buttonIcon:undefined}  />)
    );
};
