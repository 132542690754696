import { Text } from 'office-ui-fabric-react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ApplicationState } from '../../config/store';
import { IInspeccionHomologacion } from '../ItemHomologacion/interfaces';
import { ItemInspeccionActionCreators } from '../ItemInspeccion/actions';
import { ItemSuministroActionCreators } from '../ItemSuministro/actions';
import { Loading } from '../Shared/Loading/loading';
import { EnumEstadoInspeccion } from '../utils/enums';
import { ItemHomologacionesInspeccionActionCreators } from './actions';
import CargaDocumentos from './internalComponents/CargaDocumentos';
import DocumentosHomologacion from './internalComponents/DocumentosHomologacion';
import DocumentosInspeccion from './internalComponents/DocumentosInspeccion';
import InformacionHomologacion from './internalComponents/InformacionHomologacion';
import styles from './ItemInspeccionHomologacion.module.scss';

interface IItemInspeccionHomologacionProps {
}

const ItemInspeccionHomologacion: React.FunctionComponent<IItemInspeccionHomologacionProps> = (props) => {
    const dispatch = useDispatch();
    const { idinspeccion } = useParams<{ idinspeccion: string }>();

    const itemInspeccionHomologacion: IInspeccionHomologacion = useSelector((state: ApplicationState) => state.itemHomologacionInspeccion.inspeccionItem);
    const loading: boolean = useSelector((state: ApplicationState) => state.itemHomologacionInspeccion.loading);
    const loadingDocumentosHomologacion: boolean = useSelector((state: ApplicationState) => state.itemHomologacionInspeccion.loadingDocumentosHomologacion);
    const loadingDocumentosInspeccion: boolean = useSelector((state: ApplicationState) => state.itemHomologacionInspeccion.loadingDocumentosInspeccion);

    React.useEffect(() => {
        dispatch(ItemHomologacionesInspeccionActionCreators.handleLoading(true));
        dispatch(ItemHomologacionesInspeccionActionCreators.getItemInspeccionHomologacionById(idinspeccion));
    }, [idinspeccion, dispatch]);


    React.useEffect(() => {
        if (itemInspeccionHomologacion) {
            dispatch(ItemInspeccionActionCreators.getDocumentosTipoInspeccion());
            dispatch(ItemHomologacionesInspeccionActionCreators.handleLoadingDocumentosHomologacion(true));
            dispatch(ItemHomologacionesInspeccionActionCreators.getDocumentosHomologacionList(itemInspeccionHomologacion.HomologacionGeneralId));
            dispatch(ItemHomologacionesInspeccionActionCreators.handleLoadingDocumentosInspeccion(true));
            dispatch(ItemHomologacionesInspeccionActionCreators.getDocumentosInspeccionList(itemInspeccionHomologacion.ID));
        }
    }, [itemInspeccionHomologacion, dispatch]);


    React.useEffect(() => {
        return () => {
            dispatch(ItemHomologacionesInspeccionActionCreators.clearItemInspeccionHomologacion());
        };
    }, [dispatch]);

    function _onDownloadDocumento(url: string, fileName: string): void {
        dispatch(ItemSuministroActionCreators.loadingDownloadDocumento(true, fileName));
        dispatch(ItemSuministroActionCreators.downloadDocumento(url, fileName));
    }

    function showEstado(estadoId: number): JSX.Element {
        switch (estadoId) {
            case EnumEstadoInspeccion.Aprobada:
                return (<div className={styles.Aprobado}><div className={styles.aprobadoContent}>Inspección Aprobada!</div></div>);
            case EnumEstadoInspeccion.Rechazada:
                return (<div className={styles.Rechazado}><div className={styles.rechazadoContent}>Inspección Rechazada!</div></div>);
            default:
                return (<></>);
        }
    }

    return (
        <div className={styles.ItemInspeccionHomologacion}>
            {(loading || loadingDocumentosHomologacion || loadingDocumentosInspeccion) && <Loading fullScreen message='Cargando inspección de homologación...' />}
            <Text variant='xxLarge'>Inspección de homologación</Text>
            {itemInspeccionHomologacion && (
                <div>
                    <h1>1. Información de homologación</h1>
                    {showEstado(itemInspeccionHomologacion.EstadoId)}
                    <InformacionHomologacion itemHomologacion={itemInspeccionHomologacion.HomologacionGeneralItem} />
                    <h1>2. Documentos homologación</h1>
                    <DocumentosHomologacion onDownloadDocumento={_onDownloadDocumento} />
                    <h1>3. Documentos de inspección</h1>
                    <DocumentosInspeccion />
                    {itemInspeccionHomologacion.EstadoId === EnumEstadoInspeccion.EnProceso && (<h1>4. Carga de documentos</h1>)}
                    {itemInspeccionHomologacion.EstadoId === EnumEstadoInspeccion.EnProceso && (<CargaDocumentos />)}
                </div>
            )}
        </div>
    );
}

export default ItemInspeccionHomologacion;