import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { MainActionCreators } from '../Main/actions';

import { LoginMain } from './login';
import { RootAuthRoutes } from './auth';
import { msalAuth } from '../../config/auth/AuthConfig';
import apiConfig from '../../config/apiConfigOficial';
import { Loading } from '../Shared/Loading/loading';

import * as MasterFunctions from '../../components/utils/functions';
import { IDataParams } from '../../components/utils/interfaces';

interface IMainOwnProps {
}

interface IConnectedState {
    main: any;
}  

function mapStateToProps(state: ApplicationState, ownProps: IMainOwnProps): IConnectedState {
	return {
        main: state.main,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

// define combined props
type IMainProps =  IMainOwnProps & IConnectedState &{ dispatch(action: any): void };

class Main extends React.PureComponent<IMainProps> {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(MainActionCreators.handleLoading(true));
        const userStorage = sessionStorage.getItem(apiConfig.UserStorageKey);
        const tokenMS365 = sessionStorage.getItem(apiConfig.AccessTokenKeyMS365);
        const UserAccesEmail = sessionStorage.getItem(apiConfig.UserAccessEmail);
        const userAccount = msalAuth.getAccount();
        
        if (userStorage) {
            dispatch(MainActionCreators.restoreSignIn(JSON.parse(userStorage)));
        } else if (tokenMS365 && UserAccesEmail) {
            // tengo el token de ms365 en el localStorage
            dispatch(MainActionCreators.signInMS365({ idToken: { rawIdToken: tokenMS365 }, account: { userName: UserAccesEmail, name: ''} }));
        } else if (userAccount !== null) {
            msalAuth.acquireTokenSilent(apiConfig.msalOptions)
                .then(response => {
                    dispatch(MainActionCreators.signInMS365(response));
                })
                .catch(err => {
                    // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                    if (err.name === "InteractionRequiredAuthError") {
                        return msalAuth.acquireTokenPopup(apiConfig.msalOptions)
                            .then(response => {
                                // get access token from response
                                dispatch(MainActionCreators.signInMS365(response));
                            })
                            .catch(err => {
                                // handle error
                                console.log(err);
                                dispatch(MainActionCreators.signOut());
                            });
                    }
                });
        } else {
            dispatch(MainActionCreators.signOut());
            dispatch(MainActionCreators.handleLoading(false));
        }
    }

    componentDidUpdate(prevProps : IMainProps) {
        //const { isLoaded } = this.state;
        const { user, messageError } = this.props.main;

        if(prevProps.main.messageError !== messageError || 
           prevProps.main.user !== user){
            
            let dataFromUrl = MasterFunctions.parseParamsDataFromURL(window.location.search);
            let dataCodeInfo : IDataParams = dataFromUrl.find(params => params.key  === "code");
            if(dataCodeInfo !== null && dataCodeInfo !== undefined){
                
                //Al existir datos en el login de FAuth 
                //es necesario hacer redirección, ya que el 
                //dato del login no es necesario que siga existiendo en URL
                window.location.href = "/";
            }
        }
    }

    public render() { 
        const { loading, isAuthenticated } = this.props.main;
        if (loading) return <Loading fullScreen message='Cargando, por favor espere...' />;
        return isAuthenticated ? <RootAuthRoutes /> : <LoginMain />;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);


