import Home from '../components/Home/home';
import Login from '../components/Login/login';

import GrillaSuministro from '../components/GrillaSuministros/grillaSuministros';
import ItemSuministro from '../components/ItemSuministro/itemSuministro';
import GrillaInspeccion from '../components/GrillaInspeccion/grillaInspeccion';
import ItemInspeccion from '../components/ItemInspeccion/itemInspeccion';
import { NotFound } from '../components/NotFound/notFound';
import { EnumPermissionUserType } from '../components/utils/enums';
import GrillaHomologacion from '../components/GrillaHomologacion/GrillaHomologacion';
import ItemHomologacion from '../components/ItemHomologacion/ItemHomologacion';

import GrillaHomologacionInspeccion from '../components/GrillaHomologacionInspeccion/GrillaHomologacionInspeccion';
import ItemInspeccionHomologacion from '../components/ItemInspeccionHomologacion/ItemInspeccionHomologacion';

import MaestroMateriales from '../components/MaestroMateriales/MaestroMateriales';

import FAuthCodeByToken from '../components/FAuthCodeByToken/codebytoken';
import ChagePass from '../components/FAuthPassChange/changePass';

interface IRoutes {
    path: string;
    component: any;
    permission?: string;
}

export const paths = {
    any: '*',
    root: '/',
    changeCodeByToken: '/fauthcodebytoken',
    grillaSuministro: '/grillasuministro',
    itemSuministro: '/itemSuministro/:idsuministro',
    grillaInspeccion: '/grillaInspeccion',
    itemInspeccion: '/itemInspeccion/:idinspeccion',
    grillaHomologacion: '/grillaHomologacion',
    itemHomologacion: '/itemHomologacion/:idhomologacion',
    grillaHomologacionInspeccion: '/grillainspeccionhomologacion',
    itemInspeccionHomologacion: '/inspeccionHomologacion/:idinspeccion',
    maestroMateriales: '/materiales',
    changePassword : '/fauthcodebytokenchangepasswd',
    notFound: '/notfound',
};

export const permission = {
    any: EnumPermissionUserType.Any,
    root: EnumPermissionUserType.Any,
    fauthcodetoken : EnumPermissionUserType.Any,
    itemSuministro: EnumPermissionUserType.Proveedor,
    grillaSuministro: EnumPermissionUserType.Proveedor,
    grillaHomologacion: EnumPermissionUserType.Proveedor,
    itemHomologacion: EnumPermissionUserType.Proveedor,
    grillaInspeccion: EnumPermissionUserType.Any,
    itemInspeccion: EnumPermissionUserType.Any,
    grillaHomologacionInspeccion: EnumPermissionUserType.Inspector,
    itemInspeccionHomologacion: EnumPermissionUserType.Inspector,
    maestroMateriales: EnumPermissionUserType.Proveedor,
    notFound: EnumPermissionUserType.Any,
    changePassword : EnumPermissionUserType.Any,
};

export const publicRoutes: IRoutes[] = [
    { path: paths.changeCodeByToken, component: FAuthCodeByToken },
    { path: paths.changePassword, component: ChagePass },
    { path: paths.root, component: Login },
    { path: paths.any, component: Login }  
];

const privateRoutes: IRoutes[] = [
    { path: paths.root, component: Home, permission: permission.root },    
    { path: paths.itemSuministro, component: ItemSuministro, permission: permission.itemSuministro },
    { path: paths.grillaSuministro, component: GrillaSuministro, permission: permission.grillaSuministro },
    { path: paths.grillaInspeccion, component: GrillaInspeccion, permission: permission.grillaInspeccion },
    { path: paths.grillaHomologacion, component: GrillaHomologacion, permission: permission.grillaHomologacion },
    { path: paths.itemInspeccion, component: ItemInspeccion, permission: permission.itemInspeccion },
    { path: paths.itemHomologacion, component: ItemHomologacion, permission: permission.itemHomologacion },
    { path: paths.grillaHomologacionInspeccion, component: GrillaHomologacionInspeccion, permission: permission.grillaHomologacionInspeccion },
    { path: paths.itemInspeccionHomologacion, component: ItemInspeccionHomologacion, permission: permission.itemInspeccionHomologacion },
    { path: paths.maestroMateriales, component: MaestroMateriales, permission: permission.maestroMateriales },
    { path: paths.notFound, component: NotFound, permission: permission.notFound },
    { path: paths.any, component: Home, permission: permission.any },
]; 

export default privateRoutes;
