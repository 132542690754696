import { Action, Reducer } from 'redux';
import {
    LOADING,
    GET_ITEMINSPECCION,
    GET_DOCUMENTOSINSPECCION,
    GET_DOCUMENTOSTIPOINSPECCION,
    LOADING_DOCUMENTOSINSPECCION,
    GET_DOCUMENTOSSUMINISTROSINSPECTOR,
    GET_DOCUMENTOSSUMINISTROSPROVEEDOR,
    LOADING_COMENTARIOSDOCTOINSPECCION,
    GET_COMENTARIOSDOCTOSINSPECCION,
    CLEAR_COMENTARIOSDOCTOINSPECCION,
    CLEAR_ITEMINSPECCION,
    GET_DOCUMENTOSANTECEDENTESPROVEEDOR,
    LOADING_DOCTOSANTECEDENTESPROVEEDOR,
} from './constants';
import { ItemInspeccionKnownAction } from './actions';
import { IInspeccionFabrica, IDocumentosInspeccion } from './interfaces';
import { IComentarioBase, IDocumentoSuministro, IDocumentosTipo } from '../ItemSuministro/interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ItemInspeccionState {
    loading: boolean;
    loadingDocumentos: boolean;
    itemInspeccion?: IInspeccionFabrica;
    documentosInspeccionList?: IDocumentosInspeccion[];
    doctosTipoInspeccionList?: IDocumentosTipo[];
    documentosSuministosList?: IDocumentoSuministro[];
    loadingComentarioInspeccion: boolean;
    comentariosInspeccionList?: IComentarioBase[];

    loadingDoctosAntecedentes: boolean;
    doctosAntecedentesProveedorList?: IDocumentoSuministro[];
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const ItemInspeccionReducer: Reducer<ItemInspeccionState> = (state: ItemInspeccionState | undefined, incomingAction: Action): ItemInspeccionState => {
    if (state === undefined) {
        return {
            loading: false,
            loadingDocumentos: false,
            loadingComentarioInspeccion: false,
            loadingDoctosAntecedentes: false,
        };
    }
    const action = incomingAction as ItemInspeccionKnownAction;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading };
            case GET_ITEMINSPECCION:
                const itemInspeccion:IInspeccionFabrica = {
                    ...action.itemInspeccion,
                    EstadoInspeccionId: action.itemInspeccion.EstadoInspeccion.lookupId,
                    CodigoSuministroId: action.itemInspeccion.CodigoSuministro.lookupId,
                    NombreSuministroId: action.itemInspeccion.NombreSuministro.lookupId,
                };
                return { ...state, itemInspeccion, loading: false, };
        case CLEAR_ITEMINSPECCION:
            return {
                ...state,
                itemInspeccion: undefined,
                documentosInspeccionList: undefined,
                documentosSuministosList: undefined,
                loading: false,
                doctosTipoInspeccionList: undefined,
                doctosAntecedentesProveedorList: undefined,
            };
        case GET_DOCUMENTOSINSPECCION:
            const documentosInspeccionList: IDocumentosInspeccion[] = action.documentosInspeccionList.map(di=>({
                ...di,
                DocumentosTipoId: di.DocumentosTipo.lookupId,
                DocumentosEstadoId: di.DocumentosEstado.lookupId,
            }))

            return { ...state, documentosInspeccionList, loadingDocumentos: false, };
        case GET_DOCUMENTOSTIPOINSPECCION:
            const doctosTipoInspeccionList: IDocumentosTipo[] = action.doctosTipoInspeccionList.map(dt => ({
                ...dt,
                MaestroFasesId: dt.MaestroFases.lookupId,
            }));
            return { ...state, doctosTipoInspeccionList };
        case LOADING_DOCUMENTOSINSPECCION:
            return { ...state, loadingDocumentos: action.loading, };
            
        case GET_DOCUMENTOSSUMINISTROSINSPECTOR:
            const documentosSuministosListInspector: IDocumentoSuministro[] = action.DoctosSuministrosList.map(d => ({
                ...d,
                DocumentosTipoId: d.DocumentosTipo.lookupId,
                CodigoSuministroId: d.CodigoSuministro.lookupId,
                DocumentosEstadoId: d.DocumentosEstado.lookupId,
                CodigoDoctoRequeridoId: d.CodigoDoctoRequerido.lookupId,
            }));
            return {...state, documentosSuministosList: documentosSuministosListInspector };

        case GET_DOCUMENTOSSUMINISTROSPROVEEDOR:
            const documentosSuministosListProveedor: IDocumentoSuministro[] = action.DoctosSuministrosList.map(d => ({
                ...d,
                DocumentosTipoId: d.DocumentosTipo.lookupId,
                CodigoSuministroId: d.CodigoSuministro.lookupId,
                DocumentosEstadoId: d.DocumentosEstado.lookupId,
                CodigoDoctoRequeridoId: d.CodigoDoctoRequerido.lookupId,
            }));
            return {...state, documentosSuministosList: documentosSuministosListProveedor };


        case LOADING_COMENTARIOSDOCTOINSPECCION:
            return {...state, loadingComentarioInspeccion: action.loading};
        case GET_COMENTARIOSDOCTOSINSPECCION:
            const comentariosInspeccionList: IComentarioBase[] = action.comentariosList.map( c => ({
                ...c,
            }));
            return {...state, comentariosInspeccionList, loadingComentarioInspeccion: false };

        case CLEAR_COMENTARIOSDOCTOINSPECCION:
            return {...state, comentariosInspeccionList:[]};
        case LOADING_DOCTOSANTECEDENTESPROVEEDOR:
            return {...state, loadingDoctosAntecedentes: action.loading };
        case GET_DOCUMENTOSANTECEDENTESPROVEEDOR:
            return {...state, loadingDoctosAntecedentes:false, doctosAntecedentesProveedorList: action.DoctosAntecedentesList.map(d => ({
                ...d,
                DocumentosTipoId: d.DocumentosTipo.lookupId,
                CodigoSuministroId: d.CodigoSuministro.lookupId,
                DocumentosEstadoId: d.DocumentosEstado.lookupId,
                CodigoDoctoRequeridoId: d.CodigoDoctoRequerido.lookupId,
            })) };
        default:
            return state;
    }
};
