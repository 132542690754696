import * as React from 'react';
import { publicRoutes } from '../../config/routes';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';  

function RootLoginMain () {
    return class extends React.Component {
        render() {
            return (
                <Router>
                    <Switch>
                        {publicRoutes.map(r => <Route key={r.path} exact path={r.path} component={r.component} />)}
                    </Switch>
                </Router>
            );
        }
    }
}

export const LoginMain = RootLoginMain();