import { Action, Reducer } from 'redux';
import {
    LOADING,
    GET_HOMOLOGACIONESLIST,
    LOADING_CARGA
} from './constants';
import { GrillaHomologacionKnownAction } from './actions';
import { IHomologacionGeneral } from './interfaces';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface GrillaHomologacionState {
    homologacionesList?: IHomologacionGeneral[];
    loading: boolean;
    loadingCarga: boolean;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const GrillaHomologacionReducer: Reducer<GrillaHomologacionState> = (state: GrillaHomologacionState | undefined, incomingAction: Action): GrillaHomologacionState => {
    if (state === undefined) {
        return {
            loading: false,
            loadingCarga: false
        };
    }
    const action = incomingAction as GrillaHomologacionKnownAction;
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading };
        case GET_HOMOLOGACIONESLIST:
            return {
                ...state,
                homologacionesList: action.homologacionesList.map( h => ({
                    ...h,
                    EstadoId: h.Estado && h.Estado.lookupId,
                    NombreProveedorId: h.NombreProveedor && h.NombreProveedor.lookupId,
                    ResponsableFactibilidadId: h.ResponsableFactibilidad && h.ResponsableFactibilidad.lookupId,
                    SolicitanteHomologacionId: h.SolicitanteHomologacion && h.SolicitanteHomologacion.lookupId,
                    SolicitanteHomologacionProveedorId: h.SolicitanteHomologacionProveedor && h.SolicitanteHomologacionProveedor.lookupId,
                    GestorCalidadHomologacionId: h.GestorCalidadHomologacion && h.GestorCalidadHomologacion.map(gc => gc.lookupId ),
                    GestorTecnicoHomologacionId: h.GestorTecnicoHomologacion && h.GestorTecnicoHomologacion.map(gt => gt.lookupId ),
                })),
                loading: false
            };
        case LOADING_CARGA:
            return { ...state, loadingCarga: action.loadingCarga };
        default:
            return state;
    }
};
