export const LOADING = 'APP/ITEMHOMOLOGACIONINSPECCION/LOADING';
export const GET_INSPECCIONBYID = 'APP/ITEMHOMOLOGACIONINSPECCION/GET_INSPECCIONBYID';

export const CLEAR_INSPECCIONITEM = 'APP/ITEMHOMOLOGACIONINSPECCION/CLEAR_INSPECCIONITEM';

export const LOADING_DOCUMENTOSHOMOLOGACION = 'APP/ITEMHOMOLOGACIONINSPECCION/LOADING_DOCUMENTOSHOMOLOGACION';
export const GET_DOCUMENTOSHOMOLOGACIONLIST = 'APP/ITEMHOMOLOGACIONINSPECCION/GET_DOCUMENTOSHOMOLOGACIONLIST';

export const LOADING_DOCUMENTOSINSPECCION = 'APP/ITEMHOMOLOGACIONINSPECCION/LOADING_DOCUMENTOSINSPECCION';
export const GET_DOCUMENTOSINPSECCIONLIST = 'APP/ITEMHOMOLOGACIONINSPECCION/GET_DOCUMENTOSINPSECCIONLIST';

export const LOADING_COMENTARIOSHOMOLOGACIONISPECCION = 'APP/ITEMHOMOLOGACION/LOADING_COMENTARIOSHOMOLOGACIONISPECCION';
export const GET_COMENTARIOSHOMOLOGACIONINSPECCION = 'APP/ITEMHOMOLOGACION/GET_COMENTARIOSHOMOLOGACIONINSPECCION';
export const CLEAR_COMENTARIOSHOMOLOGACIONINSPECCION = 'APP/ITEMHOMOLOGACION/CLEAR_COMENTARIOSHOMOLOGACIONINSPECCION';