import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../config/store';
import { IHomologacionGeneral } from '../../GrillaHomologacion/interfaces';
import Accordion from '../../Shared/Accordion/Accordion';
import AccordionItem from '../../Shared/Accordion/AccordionItem';
import { Separador } from '../../Shared/Separador/separador';
import { EnumHomologacionFaseEstadoInspeccion } from '../../utils/enums';
import { IInspeccionHomologacion } from '../interfaces';
import styles from '../ItemHomologacion.module.scss';

import InspeccionItem from './InspeccionItem';


const Inspeccion: React.FunctionComponent = () => {
    const homologacionItem: IHomologacionGeneral = useSelector((state:ApplicationState) => state.itemHomologacion.homologacionItem);
    const InspeccionesList: IInspeccionHomologacion[] = useSelector((state:ApplicationState) => state.itemHomologacion.inspeccionesList);
    
    return (
        <div className={styles.Inspeccion}>
            {homologacionItem && (
                <div>
                    {
                    InspeccionesList && InspeccionesList.length > 0 && (
                        <Accordion id='inspeccionesAccordion'>
                            {InspeccionesList.map(i => ( <AccordionItem key={i.ID} id={i.ID} title={`Inspección código ${i.Codigo}`} ><InspeccionItem inspeccionItem={i} /></AccordionItem>))}
                        </Accordion>
                    )}
                    {homologacionItem.InspeccionEtapa.toLowerCase() === "no definida" && (
                        <div>
                            <Separador mensaje='Información de la inspección' subtitle/>
                            <span>Se informará si se requiere de una inspección.</span>
                        </div>
                    )}

                    {homologacionItem.InspeccionEtapa.toLowerCase() === "no requerida" && (
                        <div>
                            <Separador mensaje='Información de la inspección' subtitle/>
                            <span>No se requerirá de un proceso de <b>inspección</b> para esta homologación.</span>
                        </div>
                    )}

                    {homologacionItem.InspeccionEtapa.toLowerCase() === "requerida" && InspeccionesList.length === 0  && (
                        <div>
                            <Separador mensaje='Información de la inspección' subtitle/>
                            <span><b>Inspección requerida</b>, se visualizará cuando la inspección esté creada.</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Inspeccion;