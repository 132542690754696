import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../config/store';
import { ItemHomologacionesActionCreators } from './actions';
import styles from './ItemHomologacion.module.scss';
import { Loading } from '../Shared/Loading/loading';
import { IUsuarioLogin } from '../utils/interfaces';
import { IHomologacionGeneral } from '../GrillaHomologacion/interfaces';
import Resumen from './InternalComponents/Resumen';
import MainCapitulo from './InternalComponents/MainCapitulo';
import { EnumEstadoHomologacion } from '../utils/enums';
import { RichText } from '../Shared/CustomFields/richtext/richtext';

interface IConnectedState {
    user: IUsuarioLogin;
    homologacionItem: IHomologacionGeneral;
    loading: boolean;
    loadingMenu: boolean;
    
    loadingDoctosRequeridos: boolean;
    loadingDocumentos: boolean;
    loadingDoctosTipo: boolean;
    loadingInspecciones: boolean;
}

function mapStateToProps(state: ApplicationState): IConnectedState {
	return {
        user: state.main.user,
        homologacionItem: state.itemHomologacion.homologacionItem,
        loading: state.itemHomologacion.loading,
        loadingMenu: state.itemHomologacion.loadingMenu,
        
        loadingDoctosRequeridos: state.itemHomologacion.loadingDoctosRequeridos,
        loadingDocumentos: state.itemHomologacion.loadingDocumentos,
        loadingDoctosTipo: state.itemHomologacion.loadingDoctosTipo,
        
        loadingInspecciones: state.itemHomologacion.loadingInspecciones,
	};
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: (action) => { dispatch(action); }
});

interface IItemHomologacionOwnProps {
    match: any;
    history: any;
}

// define combined props
type IItemHomologacionProps = IItemHomologacionOwnProps & IConnectedState &{ dispatch(action: any): void };

interface IItemHomologacionState {
    isLoaded: boolean;
}


class ItemHomologacion extends React.PureComponent<IItemHomologacionProps, IItemHomologacionState> {

    constructor(props: IItemHomologacionProps) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    public componentDidMount() {
        const { params } = this.props.match;
        const { history } = this.props;
        this.props.dispatch(ItemHomologacionesActionCreators.handleLoading(true));
        if (params.idhomologacion){
            this.props.dispatch(ItemHomologacionesActionCreators.handleLoading(true));
            this.props.dispatch(ItemHomologacionesActionCreators.getHomologacionItem(params.idhomologacion));
        } else {
            history.push('/notfound');
        }
    }

    public componentWillUnmount (){
        this.props.dispatch(ItemHomologacionesActionCreators.clearHomologacionItem());
    }

    public componentDidUpdate() {
        const { homologacionItem } = this.props;
        const { isLoaded } = this.state;
        if (!isLoaded && homologacionItem) {
            if (homologacionItem.EstadoId === EnumEstadoHomologacion.EnProceso || homologacionItem.EstadoId === EnumEstadoHomologacion.Aprobado) {
                this.props.dispatch(ItemHomologacionesActionCreators.handleMenuLoading(true));
                this.props.dispatch(ItemHomologacionesActionCreators.getHomologacionEtapas(homologacionItem.EsVendedorAnterior));
                this.props.dispatch(ItemHomologacionesActionCreators.handleDoctosRequeridosHomologacionLoading(true));
                this.props.dispatch(ItemHomologacionesActionCreators.getDoctosRequeridosHomologacion(homologacionItem.ID));
                this.props.dispatch(ItemHomologacionesActionCreators.handleDocumentosHomologacionLoading(true));
                this.props.dispatch(ItemHomologacionesActionCreators.getDocumentosHomologacion(homologacionItem.ID));
                this.props.dispatch(ItemHomologacionesActionCreators.handleDocumentosTipoLoading(true));
                this.props.dispatch(ItemHomologacionesActionCreators.getDocumentosTipoHomologacion());
                this.props.dispatch(ItemHomologacionesActionCreators.handleLoadingInspecciones(true));
                this.props.dispatch(ItemHomologacionesActionCreators.getInspeccionesList(homologacionItem.ID));
            }
            this.setState({
                isLoaded: true
            });
        }
    }

    public render() {

        const { loadingMenu, loading, homologacionItem, loadingDoctosRequeridos, loadingDocumentos, loadingDoctosTipo, loadingInspecciones } = this.props;
        
        if (loadingMenu || loading || loadingDoctosRequeridos || loadingDocumentos || loadingDoctosTipo || loadingInspecciones) return (<Loading message='Cargando información de homologación...' fullScreen={false} />)

        return (

            <div className={styles.ItemHomologacion}>
                
                <Resumen />

                {homologacionItem && (

                    <div>
                        
                        {(homologacionItem.EstadoId === EnumEstadoHomologacion.RechazadoSinProceso || homologacionItem.EstadoId === EnumEstadoHomologacion.Rechazado) && (
                            <RichText
                                id=''
                                label = 'Razón del rechazo'
                                onChange={void(0)}
                                value={homologacionItem.MotivoRechazo}
                                disabled
                                showLable
                            />
                        )}
                        
                        {homologacionItem.EstadoId !== EnumEstadoHomologacion.RechazadoSinProceso && homologacionItem.EstadoId !== EnumEstadoHomologacion.Solicitud && (<MainCapitulo />)}
                    
                    </div>
                )}

            </div>

        );

    }
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemHomologacion);
