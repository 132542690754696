import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../config/store";
import styles from '../ItemHomologacion.module.scss';
import logo from '../../../img/newLogo.jpg'
import { ISharePointObject } from "../../utils/interfaces";
import { EnumEstadoHomologacion } from "../../utils/enums";

export const DateFormatInput = ( date: Date | string ):string => {

    if (!date || date === null ){
        return 'Proceso no cerrado';
    }

    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');
}

const splitElements = (string) : []  =>{
    return string.split(";-;");
}

function VistaPrevia () {

    const [materialesExistentes, setMaterialesExistentes] = useState([]);
    const [materialesNuevos, setMaterialesNuevos] = useState([]);

    const itemHomologacion = useSelector( (state:ApplicationState) => state.itemHomologacion.homologacionItem );
    const date = DateFormatInput(itemHomologacion.FechaCierre);

    const _getObjectMaterialesExistentes = (docto_material :ISharePointObject[], docto_material_nombre : ISharePointObject[]) =>{

        const objectMateriales = docto_material.map((item, index) =>{
            return{
                ...item,
                nombre:docto_material_nombre[index].lookupValue
            }
        })


        return objectMateriales;
    }

    const _getObjectMaterialesNuevos = (CodigoSap , DescSAP) =>{
        const arrayCodigosSap = splitElements(CodigoSap);
        const arrayDesc = splitElements(DescSAP);

        const objectMaterialesNuevos = arrayCodigosSap.map((item,index)=>{
            return{
                item,
                descripcion: arrayDesc[index]
            }

        })

        return objectMaterialesNuevos;

    }   

    useEffect(() => {
        if (itemHomologacion){
            if(itemHomologacion.Docto_Material && itemHomologacion.Docto_Material.length > 0 ){
                const objectMaterialesExistentes = _getObjectMaterialesExistentes(itemHomologacion.Docto_Material, itemHomologacion.Docto_Material_x003a_Nombre);
                setMaterialesExistentes(objectMaterialesExistentes);
                
            }

            if(itemHomologacion.Docto_CodigoSAP && itemHomologacion.Docto_DescripcionSAP){
                const objectMaterialesNuevos = _getObjectMaterialesNuevos(itemHomologacion.Docto_CodigoSAP, itemHomologacion.Docto_DescripcionSAP);
                setMaterialesNuevos(objectMaterialesNuevos);
                

            }           
        }
    }, []);
    return (
        <div id="cartaHomologacion" className={styles.contentVistaPrevia}>
        {itemHomologacion && (itemHomologacion.Docto_Material.length > 0 && itemHomologacion.EstadoId === EnumEstadoHomologacion.Aprobado) &&  (
            <div id={`HomologacionGeneral-${itemHomologacion && itemHomologacion.Codigo}`} style={{
                padding: '10px 20px',
                fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
            }}>
                <div style={{ padding: '10px 20px' }}>
                    <div style={{ float: 'left'}}>
                        <img alt="SaesaLogo" src={logo} width={170} />
                    </div>
                    <div style={{ float: 'right'}}>
                        <p>Osorno, <b>{date}</b></p>
                    </div>
                    <div style={{ clear: 'both'}} />
                </div>
                <div>
                    <p style={{ marginBottom: -10 }}>Sr.(es): {itemHomologacion.NombreProveedor && `${itemHomologacion.NombreProveedor.lookupValue}, ${itemHomologacion.NombreProveedor_x003a_Rut.lookupValue}`}</p>
                    <p style={{ marginBottom: -10 }}>Solicitante: {itemHomologacion.SolicitanteNombre} </p>
                    <p style={{ marginBottom: -10 }}>Asunto: Homologación de {itemHomologacion.Title}</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h1 style={{ fontSize: 21, fontWeight: 'bold', marginTop: 25, marginBottom: 15 }}>CARTA DE HOMOLOGACIÓN N° {itemHomologacion.Codigo}</h1>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <p style={{ marginBottom: -10 }}>Junto con saludar, tenemos el agrado de informarle que el proceso de homologación de su producto ha finalizado, con resultados satisfactorios.</p>
                    <p style={{ marginBottom: 10 }}>Por medio de la presente, el Grupo SAESA ha homologado:</p>
                    <table style={{
                        borderLeft: `5px solid rgb(199, 224, 244)`,
                        width: '100%',
                    }}>
                        <tbody>
                            <tr style={{ width: '100%', height: 22 }} >
                                <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 170 }}>Producto: </td>
                                <td style={{ marginLeft: 10, marginRight: 10 }}>{itemHomologacion.Title}</td>
                            </tr>
                            <tr style={{ width: '100%', height: 22 }}>
                                <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 170 }}>Fabricante: </td>
                                <td style={{ marginLeft: 10, marginRight: 10 }}>{itemHomologacion.FabricanteProducto}</td>
                            </tr>
                            <tr style={{ width: '100%', height: 22 }}>
                                <td style={{ fontWeight: 'bold', paddingLeft: 10, width: 170 }}>Ubicación de la Fábrica:</td>
                                <td style={{ marginLeft: 10, marginRight: 10 }}>{itemHomologacion.UbicacionFabrica}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{
                        borderTop: `5px solid rgb(199, 224, 244)`,
                        borderBottom: `1px solid rgb(199, 224, 244)`,
                        width: '100%',
                        marginTop: 10
                    }}>
                        <thead>
                            <tr style={{ height: 26 }}>
                                <th style={{ borderBottom: `1px solid rgb(199, 224, 244)` }}>Código SAP SAESA</th>
                                <th style={{ borderBottom: `1px solid rgb(199, 224, 244)` }}>Descripción SAP SAESA</th>
                                <th style={{ borderBottom: `1px solid rgb(199, 224, 244)` }}>Modelo del Proveedor</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                                {materialesExistentes && materialesExistentes.length > 0 && materialesExistentes !== null }{
                                    materialesExistentes.map((item, index) =>(<tr key={index} style={{ height: 26 }}>
                                        <td style={{ textAlign: 'center' }}>{item.lookupValue !== null ? item.lookupValue :'[No definido]'}</td>
                                        <td style={{ textAlign: 'center' }}>{item.nombre !== null ? item.nombre :'[No definido]'}</td>
                                        {index === 0 ? (<td style={{ textAlign: 'center' }}>{itemHomologacion.ModeloProducto}</td>) : <></>}
                                        
                                        </tr>))
                                }

                                {materialesNuevos && materialesNuevos.length > 0 && materialesNuevos !== null}{
                                    materialesNuevos.map((item,index) =>(<tr key={index} style={{ height: 26 }}>
                                        <td style={{ textAlign: 'center' }}>{item.item !== null ? item.item :'[No definido]'}</td>
                                        <td style={{ textAlign: 'center' }}>{item.descripcion !== null ? item.descripcion :'[No definido]'}</td>
                                        {index === 0 && materialesExistentes.length === 0 ? (<td style={{ textAlign: 'center' }}>{itemHomologacion.ModeloProducto}</td>) : <></>}
                                </tr>))
                                }
                                
                            
                        </tbody>
                    </table>
                    <p style={{ marginBottom: -10, textAlign: 'justify' }}>El periodo de vigencia es de <b>2 años</b>, contados a partir de la fecha de emisión de la presente carta. </p>
                    <p style={{ marginBottom: -10, textAlign: 'justify' }}>Cabe destacar que, la homologación del producto descrito anteriormente se vencerá automáticamente en caso de: </p>
                    <ol style={{ marginTop: 20}}>
                        <li style={{ marginBottom: 4 }}>El fabricante modifique o actualice cualquier valor técnico declarado dentro del proceso de homologación.</li>
                        <li style={{ marginBottom: 4 }}>El fabricante modifique la línea de producción evaluada durante el proceso de homologación.</li>
                        <li style={{ marginBottom: 4 }}>El fabricante entregue el producto de otra planta que no sea la descrita en este documento.</li>
                        <li style={{ marginBottom: 4 }}>El fabricante deje de producir el producto homologado. </li>
                    </ol>
                    <p style={{ marginBottom: -10, textAlign: 'justify' }}>Será responsabilidad del fabricante, proveedor o representante informar al Grupo SAESA, cualquiera de los casos planteados, y solicitar nuevamente el proceso de homologación del producto modificado o actualizado.</p>
                    <p style={{ marginBottom: -10, textAlign: 'justify' }}>Anexo a la presente carta, se encuentra las hojas de datos garantizadas entregadas por el fabricante, representante o proveedor. </p>
                    <p style={{ marginBottom: -10, textAlign: 'justify' }}>Será responsabilidad el fabricante, proveedor o representante solicitar con al menos 30 días de anticipación la renovación de la homologación del producto, entregando los requisitos que fueron modificados o actualizados. O en su defecto informando mediante una carta certificada que su producto no ha sufrido ningún tipo de modificación.</p>
                    <p style={{ marginBottom: -10, textAlign: 'justify' }}>Este es un documento de copia no controlada.</p>
                    <p style={{ marginBottom: -10, textAlign: 'justify' }}>Sin más que agregar.</p>
                </div>
                <div style={{ marginTop: 30 }}>
                    <table style={{
                        width: '100%',
                        borderBottom: `5px solid rgb(199, 224, 244)`,
                        textAlign: 'center',
                    }}>
                        <tbody>
                            <tr>
                                <td style={{
                                    borderBottom: `1px solid rgb(199, 224, 244)`,
                                    verticalAlign: 'top',
                                    width: '50%',
                                }}><b>Nombre/s:</b> {itemHomologacion.GestorTecnicoHomologacion && itemHomologacion.GestorTecnicoHomologacion.length > 0 && itemHomologacion.GestorTecnicoHomologacion.map( gt => gt.lookupValue).join(", ")}</td>
                                <td style={{
                                    borderBottom: `1px solid rgb(199, 224, 244)`,
                                    verticalAlign: 'top',
                                    width: '50%',
                                }}><b>Nombre/s:</b> {itemHomologacion.GestorCalidadHomologacion && itemHomologacion.GestorCalidadHomologacion.length > 0 && itemHomologacion.GestorCalidadHomologacion.map( gc => gc.lookupValue).join(", ")} </td>
                            </tr>
                            <tr>
                                <td>Responsable/s de aprobación técnica</td>
                                <td>Responsable/s de aprobación de calidad</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 10 }}>ÁREA TÉCNICA </td>
                                <td style={{ fontWeight: 10 }}>ÁREA CONTROL CALIDAD Y SUMINISTROS </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )}
    </div>
    )
}

export default VistaPrevia